import React, { useState } from "react";
import _ from "lodash";
import {
  AddNoteData,
  GetSingleTrustDetail,
} from "../../../../../redux/features/TrustsService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../../Common/Loader";

const AddNotes = ({ setNotesData, isNotesData, id }) => {
  const { getSingleTrustDetail } = useSelector((state) => state.trusts);
  const dispatch = useDispatch();
  const [isNoteName, setNoteName] = useState("");
  const { getUserDetail } = useSelector((state) => state.auths);
  const [loading, setLoading] = useState(false);

  const handleNoteSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      setLoading(true);
      const data = {
        trustId: id,
        note: isNoteName,
      };
      const resp = await dispatch(AddNoteData(data));
      if (resp.type === "trusts/AddNoteData/fulfilled") {
        await dispatch(GetSingleTrustDetail({ trustId: id }));
        setLoading(false);
      }
    } else {
      setNotesData([...isNotesData, { note: isNoteName, createdAt: moment() }]);
    }
    setNoteName("");
  };

  return (
    <>
      {loading && <Loader />}
      {getUserDetail?.role !== 4 && (
        <div className="mt-5">
          <div className="mian_part_sec ">
            <div className="col-xl-5 col-lg-8 col-md-12">
              <div className="title_acordian">
                <h5>Notes Log</h5>
              </div>
              <form onSubmit={(e) => handleNoteSubmit(e)}>
                <div className="mian_check_box_sec notes">
                  {id
                    ? _.map(
                        _.orderBy(
                          getSingleTrustDetail?.noteList,
                          ["createdAt"],
                          ["asc"]
                        ),
                        (isNotesDetail, isNoteIndex) => (
                          <label
                            className="container"
                            key={`${isNoteIndex}-note`}
                          >
                            {moment(isNotesDetail?.createdAt).format(
                              "LL @ h:mm"
                            )}{" "}
                            {getUserDetail?.firstName} {getUserDetail?.lastName}
                            <p>{isNotesDetail?.note} </p>
                            <div className="HighlightDot"></div>
                          </label>
                        )
                      )
                    : _.map(
                        _.orderBy(isNotesData, ["createdAt"], ["asc"]),
                        (noteObject, index) => (
                          <label className="container" key={index}>
                            <p>
                              {moment(noteObject?.createdAt).format(
                                "LL @ h:mm"
                              )}{" "}
                              {getUserDetail?.firstName}{" "}
                              {getUserDetail?.lastName}
                            </p>
                            <p>{noteObject?.note}</p>
                            <div className="HighlightDot"></div>
                          </label>
                        )
                      )}
                </div>
                <div className="submit d-flex align-items-center mb-4 mt-1">
                  <input
                    type="text"
                    className="form-control notename-textbox"
                    id="isNoteName"
                    name="isNoteName"
                    aria-describedby="emailHelp"
                    placeholder="Enter Note Here..."
                    value={isNoteName || ""}
                    onChange={(e) => setNoteName(e.target?.value)}
                  />
                  <div className="btn_submit">
                    <button 
                      type="submit"
                      className={`${
                        isNoteName && "active"
                      } btn btn-primary mx-3 post-btn`}
                      disabled={
                        !isNoteName || getSingleTrustDetail?.isEdit === 0 || isNoteName === " "
                      }
                    >
                      Post
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNotes;
