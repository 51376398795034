import React from "react";
import MainCenterBox from "../MainCenterBox";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ResetPasswordLinkService } from "../../redux/features/AuthService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "*Please enter your email address to reset your password";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const ResetPassword = () => {
  document.title = "National Trust Registry | Password Reset";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    // enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      const response = await dispatch(ResetPasswordLinkService(values));
      if (response.type === "auths/ResetPasswordLinkService/fulfilled") {
        toast.success(response?.payload?.message, { autoClose: 3000 });
        navigate("/password-reset-requested");
      } else {
        toast.error(response?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <MainCenterBox
      title={"Password Reset"}
      className="col-xl-4 col-lg-6 col-md-8 m-auto"
    >
      <p className="pb-4">Access your National Trust Registry account</p>
      <form onSubmit={formik.handleSubmit}>
        <div className="main_form_box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z"
              fill="#1F1F1F"
            />
          </svg>
          <div className="form-group px-2 w-100">
            <input
              className="input-field w-100"
              type="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
            />
          </div>
        </div>
        {formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}
        <div className="d-flex align-items-center justify-content-between mb-md-5 mb-sm-4 mt-4">
          <div
            className="link_pasword SiteBtn2"
            onClick={() => navigate("/sign-in")}
          >
            Back
          </div>
          <Button type="submit" className="btn-thm SiteBtn" text="Continue" />
        </div>
      </form>
    </MainCenterBox>
  );
};

export default ResetPassword;
