import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const storedAccessToken = Cookies.get("ntr-token");
  const [authStatus, setAuthStatus] = useState(storedAccessToken ? 1 : 0);

  const updateAuthStatus = (status) => {
    setAuthStatus(status);
    if (storedAccessToken) {
      setAuthStatus(1);
    } else {
      setAuthStatus(status);
    }
  };

  useEffect(() => {
    const initializeAuthStatus = async () => {
      if (storedAccessToken) {
        updateAuthStatus(1);
      } else {
        updateAuthStatus(0);
      }
    };

    initializeAuthStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedAccessToken]);

  return (
    <AuthContext.Provider value={{ authStatus, updateAuthStatus }}>
      {props.children}
    </AuthContext.Provider>
  );
};
