import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UploadMedia } from "../../redux/features/CommonService";
import moment from "moment";
import {
  UpdateUserDetail,
  LogoutService,
  GetUserDetailsService,
  SignUpServiceSpouse,
} from "../../redux/features/AuthService";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Structure from "../Common/Structure/Structure";
import Loader from "../Common/Loader";
import SuccessorProfilePopup from "./SuccessorProfilePopup";
import ModalCustom from "../Common/ModalCustom";
import AskSpousePopup from "./AskSpousePopup";
import ProfileDetailCard from "./ProfileDetailCard";
import ProfileDetailForm from "./ProfileDetailForm";
import SubscribedPlanDetails from "./SubscribedPlanDetails";

const allowedRolesForSpouse = [2, 4];

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spouseLoading, setSpouseLoading] = useState(false);
  const media_url = process.env.REACT_APP_MEDIA_URL;
  const [value, setValue] = useState("");
  const [spouseValue, setSpouseValue] = useState("");
  const [ssnValue, setSsnValue] = useState("");
  const [spouseSsnValue, setSpouseSsnValue] = useState("");
  const [isSetRemoveSSNNumber, setRemoveSSNNumber] = useState(false);
  const { getUserDetail } = useSelector((state) => state.auths);
  const [showPopup, setShowPopup] = useState(false);
  const [showAskSpousePopup, setAskSpousePopup] = useState(false);
  const [isEditSpouse, setIsEditSpouse] = useState(false);
  const [removeSpouseSSNNumber, setRemoveSpouseSSNNumber] = useState(false);
  const currentUnixTimestamp = moment().unix();

  const handleUploadImage = async (file) => {
    if (file) {
      let data = file;
      if (file?.type === "image/svg+xml") {
        data = new Blob([file], { type: "image/svg" });
        data.name = file.name;
      }
      const formData = new FormData();
      formData.append("files", data, file.name);
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "commons/UploadMedia/fulfilled") {
        return res.payload?.files[0];
      }
      return null;
    }
  };

  const handleLogout = async () => {
    const response = await dispatch(LogoutService());
    if (response.type === "auths/LogoutService/fulfilled") {
      Cookies.remove("ntr-token");
      Cookies.remove("ntr-verify-token");
      Cookies.remove("ntr-email-login");
      window.location.reload();
      toast.success(response.payload?.message, { autoClose: 3000 });
    } else {
      toast.error(response.payload?.message, { autoClose: 3000 });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setRemoveSSNNumber(false);
    setIsEdit(true);
  };
  const handleEditSpouse = (e) => {
    e.preventDefault();
    setRemoveSpouseSSNNumber(false);
    setIsEditSpouse(true);
  };
  const maskify = (cc) => {
    if (cc && cc.length > 4) {
      const digits = cc.substr(cc.length - 4);
      return digits;
    } else {
      return cc;
    }
  };
  const downloadImageFront = (imageUrl) => {
    let imgURL = media_url + "/" + imageUrl;
    saveAs(imgURL, "drivingLicenseFront");
  };

  const downloadImageBack = (imageUrl) => {
    let imgURL = media_url + "/" + imageUrl;
    saveAs(imgURL, "drivingLicenseFront");
  };

  const handleResetModal = () => {
    navigate("/forget-password");
  };

  const validate = (values) => {
    let errors = {};
    if (!values?.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!values?.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!values?.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    }
    if (!values?.email) {
      errors.email = "Email is required";
    }
    if (getUserDetail?.role !== 3) {
    }
    if (!values?.address1) {
      errors.address1 = "Address is required";
    }
    if (!values?.city) {
      errors.city = "City is required";
    }
    if (!values?.state) {
      errors.state = "State is required";
    }
    if (!values?.zipCode) {
      errors.zipCode = "Zip code is required";
    }
    if (!values?.dob) {
      errors.dob = "Date of birth is required";
    }
    if (getUserDetail?.role === 2 || getUserDetail?.role === 4) {
      if (!values.ssnNumber) {
        errors.ssnNumber = "SSN/ITIN Number is required";
      } else if (values.ssnNumber?.length < 9) {
        errors.ssnNumber = "SSN/ITIN number must be 9 digits long";
      }
    }
    if (getUserDetail?.role === 3) {
      if (!values.barNumber) {
        errors.barNumber = "Bar Number is required";
      }
      if (!values.stateLicensed) {
        errors.stateLicensed = "State Licensed is required";
      }
    }
    return errors;
  };

  const transformDisplay = (val) => {
    console.log("val", val);
    let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3);
    }

    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6);
      const lastFour = displayVal.slice(7);
      displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour;
    } else {
      displayVal = displayVal.replace(/\d/g, "*");
    }
    console.log("displayVal", displayVal);
    return displayVal;
  };

  const replaceAt = (str, index, character) => {
    return (
      str.substr(0, index) + character + str.substr(index + character.length)
    );
  };

  const transformValue = (val, setFieldValue, isChildInfo) => {
    let cleanVal = val.replace(/[^0-9|\\*]/g, "");
    cleanVal = cleanVal.substr(0, 9);
    let updatedValue = (isChildInfo ? spouseValue : value) || "";

    for (let i = 0, l = cleanVal.length; i < l; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        updatedValue = replaceAt(updatedValue, i, cleanVal[i]);
      }
    }
    updatedValue = updatedValue.substr(0, cleanVal.length);
    setFieldValue("ssnNumber", updatedValue);
    if (isChildInfo) setValue(updatedValue);
    else setSpouseValue(updatedValue);
  };

  const syncInput = (event, setFieldValue) => {
    const val = event.target.value;
    if (isSetRemoveSSNNumber === false && getUserDetail?.ssnNumber) {
      setRemoveSSNNumber(true);
      setFieldValue("ssnValue", "");
      setFieldValue("ssnNumber", "");
      setSsnValue("");
      setValue("");
    } else {
      const displayVal = transformDisplay(val);
      setFieldValue("ssnValue", displayVal);
      transformValue(val, setFieldValue, false);
      event.target.setSelectionRange(val.length, val.length);
      setSsnValue(value);
    }
  };

  const syncSpouseInput = (event, setFieldValue) => {
    const val = event.target.value;
    if (
      removeSpouseSSNNumber === false &&
      getUserDetail?.childInfo?.ssnNumber
    ) {
      setRemoveSpouseSSNNumber(true);
      setFieldValue("ssnValue", "");
      setFieldValue("ssnNumber", "");
      setSpouseSsnValue("");
      setSpouseValue("");
    } else {
      const displayVal = transformDisplay(val);
      setFieldValue("ssnValue", displayVal);
      transformValue(val, setFieldValue, true);
      event.target.setSelectionRange(val.length, val.length);
      setSpouseSsnValue(value);
    }
  };

  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  const onSubmit = async (values) => {
    setLoading(true);
    delete values.ssnValue;
    const data = { ...values };
    if (getUserDetail?.ssnNumber === values?.ssnNumber) {
      delete data.ssnNumber;
    }
    setRemoveSSNNumber(false);
    const res = await dispatch(
      UpdateUserDetail({ id: getUserDetail?._id, data: data })
    );
    if (res?.payload?.status === 1) {
      setRemoveSSNNumber(false);
      setIsEdit(false);
      setSsnValue("");
      setValue("");
      setLoading(false);
      toast.success(res.payload.message);
    } else if (res?.payload?.status === 0) {
      await dispatch(GetUserDetailsService());
      toast.error(res.payload.message);
      setLoading(false);
      setSsnValue("");
      setValue("");
    }
  };

  const onSubmitSpouseInfo = async (values) => {
    setSpouseLoading(true);
    delete values.ssnValue;
    const data = { ...values };
    setRemoveSpouseSSNNumber(false);
    const res = await dispatch(
      SignUpServiceSpouse({ ...data, spouseId: getUserDetail?.childInfo?._id })
    );
    if (res?.payload?.status === 1) {
      await dispatch(GetUserDetailsService());
      setRemoveSpouseSSNNumber(false);
      setIsEditSpouse(false);
      setSpouseSsnValue("");
      setSpouseValue("");
      setSpouseLoading(false);
      toast.success(res.payload.message);
    } else if (res?.payload?.status === 0) {
      await dispatch(GetUserDetailsService());
      toast.error(res.payload.message);
      setSpouseLoading(false);
      setSpouseSsnValue("");
      setSpouseValue("");
    }
  };

  useEffect(() => {
    localStorage.clear();
  });

  return (
    <>
      <Structure activeText="">
        {(loading || spouseLoading) && <Loader />}
        <div className="w-100 center-chat chat_Section_main ">
          <div className="mb-4 header-title">
            <div
              className="p-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 className="pt-2">My Account</h2>
              <div className="d-flex">
                <button className="btn btn-thm" onClick={handleLogout}>
                  Log Out
                </button>
              </div>
            </div>
          </div>
          {isEdit ? (
            <>
              <ProfileDetailForm
                {...{
                  getUserDetail,
                  validate,
                  onSubmit,
                  handleKeyPress,
                  handleUploadImage,
                  syncInput,
                  isSetRemoveSSNNumber,
                  transformDisplay,
                  value,
                  ssnValue,
                  handleResetModal,
                  setAskSpousePopup,
                  allowedRolesForSpouse,
                  currentUnixTimestamp,
                }}
              />
            </>
          ) : (
            <div className="profile_main_part_section">
              <div className="btm_details_sec">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="Title mt-1 mb-3">
                    {`${getUserDetail?.firstName ?? ""}`}{" "}
                    {`${getUserDetail?.lastName ?? ""}`}
                  </h2>
                </div>
                <ProfileDetailCard
                  {...{
                    getUserDetail,
                    handleClick,
                    downloadImageFront,
                    downloadImageBack,
                    setShowPopup,
                    currentUnixTimestamp,
                    maskify,
                  }}
                />
              </div>
            </div>
          )}
          {getUserDetail?.childId ? (
            <>
              {isEditSpouse ? (
                <ProfileDetailForm
                  {...{
                    getUserDetail: getUserDetail?.childInfo,
                    validate,
                    onSubmit: onSubmitSpouseInfo,
                    handleKeyPress,
                    handleUploadImage,
                    syncInput: syncSpouseInput,
                    isSetRemoveSSNNumber: removeSpouseSSNNumber,
                    transformDisplay,
                    value: spouseValue,
                    ssnValue: spouseSsnValue,
                    isChildInfo: true,
                  }}
                />
              ) : (
                <>
                  <div className="profile_main_part_section">
                    <div className="btm_details_sec">
                      <div className="d-flex align-items-center justify-content-between mt-4">
                        <h2 className="Title mt-1 mb-3">
                          {`${getUserDetail?.childInfo?.firstName ?? ""}`}{" "}
                          {`${getUserDetail?.childInfo?.lastName ?? ""}`}
                        </h2>
                      </div>
                      <ProfileDetailCard
                        {...{
                          isChildInfo: true,
                          getUserDetail: getUserDetail?.childInfo,
                          handleClick: handleEditSpouse,
                          downloadImageFront,
                          downloadImageBack,
                          maskify,
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : null}
          {([2,4].includes(getUserDetail?.role)) ? <SubscribedPlanDetails /> : null }
        </div>
      </Structure>
      <ModalCustom
        size="lg"
        handleClose="grantorModal"
        show={showPopup}
        className={"CongratulationsPopup"}
      >
        <SuccessorProfilePopup setSuccessorPopup={setShowPopup} />
      </ModalCustom>

      <ModalCustom
        size="lg"
        handleClose="grantorModal"
        show={showAskSpousePopup}
        className={"CongratulationsPopup"}
      >
        <AskSpousePopup setAskSpousePopup={setAskSpousePopup} />
      </ModalCustom>
    </>
  );
};

export default Profile;
