import React, { useState } from "react";
import _ from "lodash";
import ModalCustom from "../../../../Common/ModalCustom";
import AddAttoreyPopup from "./add-attorney-popup";
import {
  AddTrustsData,
} from "../../../../../redux/features/TrustsService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import Loader from "../../../../Common/Loader";
import AttorneyInvitation from "../AttorneyInvitation";

const AddAttorney = ({
  isAddEditData,
  handleRemoveData,
  setAddEditData,
  id,
}) => {
  const { getSingleTrustDetail } = useSelector((state) => state.trusts);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [invitePopup, setInvitePopup] = useState(false);
  const [isEmail, setEmail] = useState("");
  const [isIndex, setIndex] = useState("");
  const dispatch = useDispatch();

  const enabled =
    isAddEditData?.trustName &&
    (isAddEditData?.attorneyData?.length === undefined ||
      isAddEditData?.attorneyData?.length <= 1);

  const handleSave = async () => {
    const attorneyData = {
      trustId: id,
      userList: isAddEditData?.attorneyData,
      role: 3,
    };
    try {
      const res = await dispatch(AddTrustsData(attorneyData));
      if (res?.type === "trusts/AddTrustsData/fulfilled") {
        setIsEdit(true);
      } else if (res?.type === "trusts/AddTrustsData/rejected") {
        toast.error(res?.payload?.response?.data?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const sortedAttorneyData = _.sortBy(isAddEditData?.attorneyData, "position");

  const validate = (values) => {
    const errors = {};
    values.userList.forEach((error, index) => {
      if (!error.firstName) {
        errors[`userList.${index}.firstName`] = "*First Name is required";
      }
      if (!error.lastName) {
        errors[`userList.${index}.lastName`] = "*Last Name is required";
      }
      if (!error.email) {
        errors[`userList.${index}.email`] = "*Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(error.email)
      ) {
        errors[`userList.${index}.email`] = "Invalid email address";
      }
      if (!error.phoneNumber) {
        errors[`userList.${index}.phoneNumber`] = "*Phone Number is required";
      } else if (!/\d{3,10}$/.test(error.phoneNumber)) {
        errors[`userList.${index}.phoneNumber`] = "Invalid phone number";
      }
      if (!error.address1) {
        errors[`userList.${index}.address1`] = "*Address 1 is required";
      }
      if (!error.state) {
        errors[`userList.${index}.state`] = "*State is required";
      }
      if (!error.city) {
        errors[`userList.${index}.city`] = "*City is required";
      }
      if (!error.zipcode) {
        errors[`userList.${index}.zipcode`] = "*Zip code is required";
      }
      if (!error.barNumber) {
        errors[`userList.${index}.barNumber`] = "*Bar Number is required";
      }
      if (!error.stateLicensed) {
        errors[`userList.${index}.stateLicensed`] =
          "*State Licensed is required";
      }
    });
    return errors;
  };

  const isMarkAsDeceased = (getSingleTrustDetail?.holderInfo?.deceased === 1)

  return (
    <>
      {loading && <Loader />}

      <Formik
        initialValues={{
          userList: _.map(sortedAttorneyData, (value) => {
            return {
              firstName: value?.firstName,
              lastName: value?.lastName,
              phoneNumber: value?.phoneNumber,
              email: value?.email,
              address1: value?.address1,
              address2: value?.address2,
              city: value?.city,
              state: value?.state,
              zipcode: value?.zipcode,
              barNumber: value?.barNumber,
              stateLicensed: value?.stateLicensed,
              firm: value?.firm,
              position: value?.position,
              isInvite: value?.isInvite,
            };
          }),
        }}
        enableReinitialize={true}
        validate={validate}
        validateOnBlur={false}
        onSubmit={async (values) => {
          setAddEditData({ ...isAddEditData, attorneyData: values?.userList });
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          handleChange,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className={`${isEdit && id && "aaaaaaa"} mian_part_sec`}>
                  <div className="col-xl-12 d-flex align-items-center title_row justify-content-between">
                    <p>
                      {id
                        ? isEdit
                          ? "Attorney(s)"
                          : "Edit Attorney(s) Details"
                        : "Add Attorney(s)"}
                    </p>
                    <div className="bttn_continue mb-0">
                      <button
                        type="button"
                        className={` btn btn_continue mx-2
              ${!enabled ? "btn btn_continue" : "active"}`}
                        onClick={(e) => {
                          e.preventDefault();
                          isAddEditData?.trustName && setShowPopup(true);
                        }}
                        disabled={
                          !enabled || getSingleTrustDetail?.isEdit === 0
                        }
                      >
                        Add Attorney
                      </button>
                      {id ? (
                        isEdit ? (
                          <button
                            type="button"
                            className="btn btn_continue"
                            onClick={() => setIsEdit(false)}
                            disabled={getSingleTrustDetail?.isEdit === 0}
                          >
                            Edit
                          </button>
                        ) : (
                          <div className="btn_back mt-3">
                            <button
                              type="button"
                              className="btn"
                              onClick={() => handleSave()}
                            >
                              Save
                            </button>
                          </div>
                        )
                      ) : (
                        <button type="submit" className="btn btn_continue">
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    {_.map(values?.userList, (field, index) => (
                      <div
                        className="top_grid_sec"
                        key={`${index}-trusts-grantors`}
                      >
                        <div className="title_grid d-flex align-items-baseline">
                          <p className={index === 0 ? "mr-5" : "mr-4 pt-3"}>
                            {index === 0 ? "Primary" : "Secondary"}
                          </p>
                          {!isMarkAsDeceased ? field?.isInvite === 0 && isEdit && id && (
                            <div className="btn_sec pt-2" key={index}>
                              <button
                                type="button"
                                className="btn add_trust px-4"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEmail(field?.email);
                                  setIndex(index)
                                  setInvitePopup(true)
                                }}
                                disabled={getSingleTrustDetail?.isEdit === 0}
                              >
                                Invite
                              </button>
                            </div>
                          ) : <></>}{" "}
                          <div className="position-relative">
                            <p
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveData(
                                  index,
                                  isAddEditData?.attorneyData,
                                  "attorneyData"
                                );
                              }}
                            >
                              {!isMarkAsDeceased ? id ? (
                                !isEdit &&
                                isAddEditData?.attorneyData?.length === 2 && (
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M15.5 3.5741V4.5741H20.5V6.5741H19.5V19.5741C19.5 20.6741 18.6 21.5741 17.5 21.5741H7.5C6.4 21.5741 5.5 20.6741 5.5 19.5741V6.5741H4.5V4.5741H9.5V3.5741H15.5ZM7.5 19.5741H17.5V6.5741H7.5V19.5741ZM9.5 8.5741H11.5V17.5741H9.5V8.5741ZM15.5 8.5741H13.5V17.5741H15.5V8.5741Z"
                                      fill="#0059C7"
                                    />
                                  </svg>
                                )
                              ) : (
                                <svg
                                  width="25"
                                  height="25"
                                  viewBox="0 0 25 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.5 3.5741V4.5741H20.5V6.5741H19.5V19.5741C19.5 20.6741 18.6 21.5741 17.5 21.5741H7.5C6.4 21.5741 5.5 20.6741 5.5 19.5741V6.5741H4.5V4.5741H9.5V3.5741H15.5ZM7.5 19.5741H17.5V6.5741H7.5V19.5741ZM9.5 8.5741H11.5V17.5741H9.5V8.5741ZM15.5 8.5741H13.5V17.5741H15.5V8.5741Z"
                                    fill="#0059C7"
                                  />
                                </svg>
                              ) : <></>}
                              <p className="hover-message delete-content">
                                Are you sure you want to delete this item? This action cannot be undone.
                              </p>
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <div className="mian_grid_top grid-container edit_grid_sec w-100">
                            <div className="row m-0 w-100">
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.firstName`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    First Name
                                  </label>
                                  <Field
                                    type="text"
                                    name={`userList.${index}.firstName`}
                                    value={field["firstName"]}
                                    readOnly={id}
                                    id={`userList.${index}.firstName`}
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  />
                                </div>

                                {errors[`userList.${index}.firstName`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.firstName`]}
                                  </p>
                                )}
                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.lastName`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    Last Name
                                  </label>
                                  <Field
                                    type="text"
                                    name={`userList.${index}.lastName`}
                                    value={field["lastName"]}
                                    readOnly={id}
                                    id={`userList.${index}.lastName`}
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  />
                                </div>

                                {errors[`userList.${index}.lastName`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.lastName`]}
                                  </p>
                                )}
                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.phoneNumber`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    Phone Number
                                  </label>
                                  <Field
                                    type="text"
                                    name={`userList.${index}.phoneNumber`}
                                    value={field["phoneNumber"]}
                                    readOnly={id}
                                    id={`userList.${index}.phoneNumber`}
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  />
                                </div>

                                {errors[`userList.${index}.phoneNumber`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.phoneNumber`]}
                                  </p>
                                )}
                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.email`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    Email Address
                                  </label>
                                  <Field
                                    type="email"
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                    id={`userList.${index}.email`}
                                    name={`userList.${index}.email`}
                                    value={field["email"]}
                                    readOnly={id}
                                  />
                                </div>

                                {errors[`userList.${index}.email`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.email`]}
                                  </p>
                                )}
                              </div>

                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.stateLicensed`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    State Licensed
                                  </label>
                                  <Field
                                    type="text"
                                    name={`userList.${index}.stateLicensed`}
                                    value={field["stateLicensed"]}
                                    readOnly={id}
                                    id={`userList.${index}.stateLicensed`}
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  />
                                </div>

                                {errors[`userList.${index}.barNumber`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.barNumber`]}
                                  </p>
                                )}
                              </div>

                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.stateLicensed`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    State Licensed
                                  </label>
                                  <Field
                                    type="text"
                                    name={`userList.${index}.stateLicensed`}
                                    value={field["stateLicensed"]}
                                    readOnly={id}
                                    id={`userList.${index}.stateLicensed`}
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  />
                                </div>

                                {errors[`userList.${index}.stateLicensed`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.stateLicensed`]}
                                  </p>
                                )}
                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.firm`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    Firm (if applicable)
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                    id={`userList.${index}.firm`}
                                    name={`userList.${index}.firm`}
                                    value={field["firm"]}
                                    readOnly={id}
                                  />
                                </div>

                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.address1`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    Address Line 1
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                    id={`userList.${index}.address1`}
                                    name={`userList.${index}.address1`}
                                    value={field["address1"]}
                                    readOnly={id}
                                  />
                                </div>

                                {errors[`userList.${index}.address1`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.address1`]}
                                  </p>
                                )}
                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.address2`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    Address 2
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                    id={`userList.${index}.address2`}
                                    name={`userList.${index}.address2`}
                                    value={field["address2"]}
                                    readOnly={id}
                                  />
                                </div>

                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.city`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    City
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                    id={`userList.${index}.city`}
                                    name={`userList.${index}.city`}
                                    value={field["city"]}
                                    readOnly={id}
                                  />
                                </div>

                                {errors[`userList.${index}.city`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.city`]}
                                  </p>
                                )}
                              </div>

                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.state`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    State
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                    id={`userList.${index}.state`}
                                    name={`userList.${index}.state`}
                                    value={field["state"]}
                                    readOnly={id}
                                  />
                                </div>

                                {errors[`userList.${index}.state`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.state`]}
                                  </p>
                                )}
                              </div>
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className={`form-group ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}>
                                  <label
                                    htmlFor={`userList.${index}.zipcode`}
                                    className={`${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                  >
                                    ZIP Code
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${isEdit && id ? "" : (id ? "not-allowed" : "aa")}`}
                                    id={`userList.${index}.zipcode`}
                                    name={`userList.${index}.zipcode`}
                                    value={field["zipcode"]}
                                    readOnly={id}
                                  />
                                </div>

                                {errors[`userList.${index}.zipcode`] && (
                                  <p className="error">
                                    {errors[`userList.${index}.zipcode`]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>

      <ModalCustom size="xl" handleClose="grantorModal" show={showPopup}>
        <AddAttoreyPopup
          setShowAttornyPopup={setShowPopup}
          setAddEditData={setAddEditData}
          isAddEditData={isAddEditData}
          attorneyData={isAddEditData?.attorneyData}
          id={id}
        />
      </ModalCustom>
      <ModalCustom size="xl" handleClose="grantorModal" show={invitePopup} className={"CongratulationsPopup"}>
        <AttorneyInvitation
          setInvitePopup={setInvitePopup}
          isEmail={isEmail}
          isIndex={isIndex}
          trustName={isAddEditData?.trustName}
          id={id}
        />
      </ModalCustom>
    </>
  );
};

export default AddAttorney;
