import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/AuthService";
import trustReducer from "./features/TrustsService";
import commonReducer from "./features/CommonService";
import UserReducer from "./features/UsersService";
import adminReducer from "./features/AdminService";
import attorneyReducer from "./features/AttorneyService";
import grantorReducer from "./features/GrantorService";
import activityReducer from "./features/ActivityService";
import successorReducer from "./features/SuccessorService";
import subscriptionReducer from "./features/SubscriptionService";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  auths: authReducer,
  trusts: trustReducer,
  commons: commonReducer,
  users: UserReducer,
  attorney: attorneyReducer,
  admin: adminReducer,
  successor: successorReducer,
  grantors: grantorReducer,
  activity: activityReducer,
  subscription: subscriptionReducer,
});

export { rootPersistConfig, rootReducer };
