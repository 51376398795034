import { Modal } from "react-bootstrap";
import React from "react";

const ModalCustom = ({ size, children, show, className }) => {
  return (
    <>
      <Modal
        show={show}
        size={size ? size : "md"}
        className={`${className && className}`}
        centered
      >
        <Modal.Body className={className}>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCustom;
