import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UserSetPassword } from "../../redux/features/CommonService";
import { toast } from "react-toastify";
import MainCenterBox from "../MainCenterBox";
import Button from "../Common/Button";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "*Please enter your password";
  } else if (
    !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(values.password) ||
    values.password.length < 8
  ) {
    errors.password =
      "*Please make sure the password meets all of the criteria below";
  }
  return errors;
};

const SetPassword = () => {
  document.title = "National Trust Registry | Set Password";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { id } = useParams();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const formik = useFormik({
    initialValues: {
      userId: id,
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      const response = await dispatch(
        UserSetPassword({ data: values, id: id })
      );
      if (
        response.type === "commons/UserSetPassword/fulfilled" &&
        response?.payload?.status === 1
      ) {
        toast.success(response?.payload?.message, { autoClose: 3000 });
        navigate("/sign-in");
      } else {
        toast.error(response?.payload?.message, { autoClose: 3000 });
      }
    },
  });
  return (
    <MainCenterBox
      title={"Set Password"}
      className="col-xl-4 col-lg-6 col-md-8 m-auto"
    >
      <p className="pb-4 mb-0">Pick something creative yet memorable.</p>
      <form onSubmit={formik.handleSubmit}>
        <div className="main_form_box d-flex align-items-center">
          <svg
            width="22"
            height="12"
            viewBox="0 0 22 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 9.225C6.9 9.225 7.6625 8.9125 8.2875 8.2875C8.9125 7.6625 9.225 6.9 9.225 6C9.225 5.1 8.9125 4.3375 8.2875 3.7125C7.6625 3.0875 6.9 2.775 6 2.775C5.1 2.775 4.3375 3.0875 3.7125 3.7125C3.0875 4.3375 2.775 5.1 2.775 6C2.775 6.9 3.0875 7.6625 3.7125 8.2875C4.3375 8.9125 5.1 9.225 6 9.225ZM6 12C4.33333 12 2.91667 11.4167 1.75 10.25C0.583333 9.08333 0 7.66667 0 6C0 4.33333 0.583333 2.91667 1.75 1.75C2.91667 0.583333 4.33333 0 6 0C7.41667 0 8.60417 0.3875 9.5625 1.1625C10.5208 1.9375 11.1917 2.925 11.575 4.125H19.875C19.975 4.125 20.0667 4.14167 20.15 4.175C20.2333 4.20833 20.3167 4.26667 20.4 4.35L21.475 5.425C21.5583 5.50833 21.6167 5.59167 21.65 5.675C21.6833 5.75833 21.7 5.85 21.7 5.95C21.7 6.05 21.6833 6.14167 21.65 6.225C21.6167 6.30833 21.5583 6.39167 21.475 6.475L18.875 9.075C18.7917 9.15833 18.7083 9.21667 18.625 9.25C18.5417 9.28333 18.45 9.3 18.35 9.3C18.25 9.3 18.1583 9.28333 18.075 9.25C17.9917 9.21667 17.9083 9.15833 17.825 9.075L16.25 7.5L14.675 9.075C14.5917 9.15833 14.5083 9.21667 14.425 9.25C14.3417 9.28333 14.25 9.3 14.15 9.3C14.05 9.3 13.9583 9.28333 13.875 9.25C13.7917 9.21667 13.7083 9.15833 13.625 9.075L12.375 7.825H11.575C11.2583 9.075 10.5875 10.0833 9.5625 10.85C8.5375 11.6167 7.35 12 6 12Z"
              fill="black"
            />
          </svg>

          <div className="form-group d-flex align-items-center">
            <input
              type={passwordVisible ? "text" : "password"}
              className="input-field"
              name="password"
              id="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {passwordVisible ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={togglePasswordVisibility}
              >
                <path
                  d="M11.825 16.75C13.0083 16.75 14.0125 16.3375 14.8375 15.5125C15.6625 14.6875 16.075 13.6833 16.075 12.5C16.075 11.3167 15.6625 10.3125 14.8375 9.4875C14.0125 8.6625 13.0083 8.25 11.825 8.25C10.6417 8.25 9.6375 8.6625 8.8125 9.4875C7.9875 10.3125 7.575 11.3167 7.575 12.5C7.575 13.6833 7.9875 14.6875 8.8125 15.5125C9.6375 16.3375 10.6417 16.75 11.825 16.75ZM11.825 15.3C11.0417 15.3 10.3792 15.0292 9.8375 14.4875C9.29583 13.9458 9.025 13.2833 9.025 12.5C9.025 11.7167 9.29583 11.0542 9.8375 10.5125C10.3792 9.97083 11.0417 9.7 11.825 9.7C12.6083 9.7 13.2708 9.97083 13.8125 10.5125C14.3542 11.0542 14.625 11.7167 14.625 12.5C14.625 13.2833 14.3542 13.9458 13.8125 14.4875C13.2708 15.0292 12.6083 15.3 11.825 15.3ZM11.825 20C9.525 20 7.42917 19.375 5.5375 18.125C3.64583 16.875 2.18333 15.225 1.15 13.175C1.1 13.0917 1.0625 12.9917 1.0375 12.875C1.0125 12.7583 1 12.6333 1 12.5C1 12.3667 1.0125 12.2417 1.0375 12.125C1.0625 12.0083 1.1 11.9083 1.15 11.825C2.18333 9.775 3.64583 8.125 5.5375 6.875C7.42917 5.625 9.525 5 11.825 5C14.125 5 16.2208 5.625 18.1125 6.875C20.0042 8.125 21.4667 9.775 22.5 11.825C22.55 11.9083 22.5875 12.0083 22.6125 12.125C22.6375 12.2417 22.65 12.3667 22.65 12.5C22.65 12.6333 22.6375 12.7583 22.6125 12.875C22.5875 12.9917 22.55 13.0917 22.5 13.175C21.4667 15.225 20.0042 16.875 18.1125 18.125C16.2208 19.375 14.125 20 11.825 20Z"
                  fill="#44464E"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
                onClick={togglePasswordVisibility}
              >
                <path
                  d="M10.825 12.75C12.0083 12.75 13.0125 12.3375 13.8375 11.5125C14.6625 10.6875 15.075 9.68333 15.075 8.5C15.075 7.31667 14.6625 6.3125 13.8375 5.4875C13.0125 4.6625 12.0083 4.25 10.825 4.25C9.64167 4.25 8.6375 4.6625 7.8125 5.4875C6.9875 6.3125 6.575 7.31667 6.575 8.5C6.575 9.68333 6.9875 10.6875 7.8125 11.5125C8.6375 12.3375 9.64167 12.75 10.825 12.75ZM10.825 11.3C10.0417 11.3 9.37917 11.0292 8.8375 10.4875C8.29583 9.94583 8.025 9.28333 8.025 8.5C8.025 7.71667 8.29583 7.05417 8.8375 6.5125C9.37917 5.97083 10.0417 5.7 10.825 5.7C11.6083 5.7 12.2708 5.97083 12.8125 6.5125C13.3542 7.05417 13.625 7.71667 13.625 8.5C13.625 9.28333 13.3542 9.94583 12.8125 10.4875C12.2708 11.0292 11.6083 11.3 10.825 11.3ZM10.825 16C8.525 16 6.42917 15.375 4.5375 14.125C2.64583 12.875 1.18333 11.225 0.15 9.175C0.1 9.09167 0.0625 8.99167 0.0375 8.875C0.0125 8.75833 0 8.63333 0 8.5C0 8.36667 0.0125 8.24167 0.0375 8.125C0.0625 8.00833 0.1 7.90833 0.15 7.825C1.18333 5.775 2.64583 4.125 4.5375 2.875C6.42917 1.625 8.525 1 10.825 1C13.125 1 15.2208 1.625 17.1125 2.875C19.0042 4.125 20.4667 5.775 21.5 7.825C21.55 7.90833 21.5875 8.00833 21.6125 8.125C21.6375 8.24167 21.65 8.36667 21.65 8.5C21.65 8.63333 21.6375 8.75833 21.6125 8.875C21.5875 8.99167 21.55 9.09167 21.5 9.175C20.4667 11.225 19.0042 12.875 17.1125 14.125C15.2208 15.375 13.125 16 10.825 16Z"
                  fill="#44464E"
                />
                <path
                  d="M3 16L19 1"
                  stroke="#44464E"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            )}
          </div>
        </div>
        {formik.errors.password ? (
          <div className="error">{formik.errors.password}</div>
        ) : null}
        <div className="pwdreqments mt-3">At least 1 lowercase letter</div>
        <div className="pwdreqments ">At least 1 UPPERCASE letter</div>
        <div className="pwdreqments ">At least 1 number</div>
        <div className="pwdreqments   ">At least 6 characters</div>
        <div className="pt-4 btn-div w-100 d-flex justify-content-end align-items-center">
          <Button type="submit" className="btn-thm SiteBtn" text="Continue" />
        </div>
      </form>
    </MainCenterBox>
  );
};

export default SetPassword;
