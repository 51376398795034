import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";

const url = process.env.REACT_APP_API_URL;

export const GetUserList = createAsyncThunk(
  "users/GetUserList",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/get_user_list/`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const getUserDetailsWithToken = createAsyncThunk(
  "users/getUserDetailsWithToken",
  async (args, { rejectWithValue }) => {
    try {
      let token = (args?.tokenName) ? args?.tokenName : 'ntr-verify-token';
      const resp = await axios.get(
        `${url}/user/get_user_details_with_token`,
        {
          headers: getAuthenticationHeader(token),
          mode: "cors",
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    error: false,
    loading: false,
    getUsersList: null,
  },
  reducers: {
    setUsersPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserList.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.getUsersList = action.payload;
      })
      .addCase(GetUserList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserDetailsWithToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetailsWithToken.fulfilled, (action, state) => {
        state.loading = false;
        state.getUsersList = action.payload;
      })
      .addCase(getUserDetailsWithToken.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setUsersPage } = usersSlice.actions;

export default usersSlice.reducer;
