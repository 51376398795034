import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Structure from "../../../Common/Structure/Structure";
import { Field, Form, Formik } from "formik";
import {
  GetSingleAttorneyDetail,
  UpdateAttorneyDetail,
} from "../../../../redux/features/AttorneyService";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";

const validate = (values) => {
  let errors = {};
  if (!values?.firstName) {
    errors.firstName = "This field is required";
  }
  if (!values?.lastName) {
    errors.lastName = "This field is required";
  }
  if (!values?.phoneNumber) {
    errors.phoneNumber = "This field is required";
  }
  if (!values?.email) {
    errors.email = "This field is required";
  }
  if (!values?.address1) {
    errors.address1 = "This field is required";
  }
  if (!values?.city) {
    errors.city = "This field is required";
  }
  if (!values?.state) {
    errors.state = "This field is required";
  }
  if (!values?.zipCode) {
    errors.zipCode = "This field is required";
  }
  if (!values?.barNumber) {
    errors.barNumber = "This field is required";
  }
  if (!values?.stateLicensed) {
    errors.stateLicensed = "This field is required";
  }
  return errors;
};

const EditAttorney = () => {
  const params = useParams();
  const userId = params.id;
  const { getSingleAttorneyDetail } = useSelector((state) => state.attorney);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const id = getSingleAttorneyDetail?._id;

  useEffect(() => {
    if (userId) {
      setLoading(true);
      dispatch(GetSingleAttorneyDetail({ userId })).finally(() =>
        setLoading(false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleClick = () => {
    setIsEdit(true);
  };

  const handleChangeStatus = async (status) => {
    const values = { isStatus: status, userId: userId };
    const res = await dispatch(UpdateAttorneyDetail({ values }));
    if (res?.type === "attorney/UpdateAttorneyDetailStatus/fulfilled") {
      await dispatch(GetSingleAttorneyDetail({ userId }));
    } else if (res?.type === "attorney/UpdateAttorneyDetailStatus/rejected") {
      toast.error(res?.payload?.response?.data?.message);
    }
  };
  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Structure activeText="Attorney Users">
        <div className="w-100 center-chat chat_Section_main ">

          <div className="title_bar d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  onClick={() => navigate(-1)}
                  className="cursor-pointer"
                >
                  <path
                    d="M26.6663 14.6667H10.4397L17.893 7.21334L15.9997 5.33334L5.33301 16L15.9997 26.6667L17.8797 24.7867L10.4397 17.3333H26.6663V14.6667Z"
                    fill="#1F1F1F"
                  />
                </svg>
              </div>
              <div>
                <h5>
                  {id
                    ? `Attorney Users / ${getSingleAttorneyDetail?.firstName +
                    " " +
                    getSingleAttorneyDetail?.lastName
                    }`
                    : "Create New Trust"}
                </h5>
                {isEdit ? (
                  <p className="mb-0 HeaderTitle2">Edit Details</p>
                ) : (
                  <p className="mb-0 HeaderTitle2">View Details</p>
                )}
              </div>
            </div>
          </div>

          <div className="btm_details_sec">
            {isEdit ? (
              <div className="conten-div">
                <div className="profile_main_part_section">
                  <div className="btm_details_sec">
                    <div className="">
                      <h2 className="Title mt-1 mb-3">
                        {`${getSingleAttorneyDetail?.firstName ?? ""}`}{" "}
                        {`${getSingleAttorneyDetail?.lastName ?? ""}`}
                      </h2>
                    </div>
                    <div className="profile_main_sec_detail">
                      <Formik
                        initialValues={{
                          userId: getSingleAttorneyDetail?._id || "",
                          firstName: getSingleAttorneyDetail?.firstName || "",
                          lastName: getSingleAttorneyDetail?.lastName || "",
                          phoneNumber:
                            getSingleAttorneyDetail?.phoneNumber || "",
                          email: getSingleAttorneyDetail?.email || "",
                          barNumber: getSingleAttorneyDetail?.barNumber || "",
                          address1: getSingleAttorneyDetail?.address1 || "",
                          address2: getSingleAttorneyDetail?.address2 || "",
                          city: getSingleAttorneyDetail?.city || "",
                          state: getSingleAttorneyDetail?.state || "",
                          zipCode: getSingleAttorneyDetail?.zipCode || "",
                          firm: getSingleAttorneyDetail?.firm || "",
                          stateLicensed:
                            getSingleAttorneyDetail?.stateLicensed || "",
                          dob: getSingleAttorneyDetail?.dob || "",
                        }}
                        enableReinitialize={true}
                        validate={validate}
                        onSubmit={async (values) => {
                          setLoading(true);
                          const res = await dispatch(
                            UpdateAttorneyDetail({ values })
                          );
                          if (
                            res?.type ===
                            "attorney/updateAttorneyDetail/fulfilled"
                          ) {
                            await dispatch(GetSingleAttorneyDetail({ userId }));
                            setLoading(false);
                            setIsEdit(false);
                          } else if (res?.type === "attorney/updateAttorneyDetail/rejected") {
                            toast.error(res?.payload?.response?.data?.message);
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleChange,
                        }) => (
                          <Form>
                            <div className="profile_title_grid d-flex align-items-center justify-content-between pb-3">
                              <div>
                                <p className="m-0 p-0 fs-5 fw-medium">
                                  {isEdit ? "Edit Details" : "Details"}
                                </p>
                              </div>
                              <div className="btn_sec_right color_change">
                                <button className="profile_save_btn rounded-pill text-primary fw-medium">
                                  Save
                                </button>
                              </div>
                            </div>
                            <div className="">
                              <div className="main_grid_top grid-container gap-0 pt-2">
                                <div className="row m-0 w-100">
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="firstName"
                                          className="profile_section_label"
                                        >
                                          First Name
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control profile_main_part_user_detail_profile"
                                          name="firstName"
                                          id="firstName"
                                          value={values?.firstName}
                                          placeholder="John"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    {errors.firstName && touched.firstName ? (
                                      <div>{errors.firstName}</div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="lastName"
                                          className="profile_section_label"
                                        >
                                          Last Name
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control profile_main_part_user_detail_profile"
                                          name="lastName"
                                          id="lastName"
                                          value={values?.lastName}
                                          placeholder="Smith"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    {errors.lastName && touched.lastName ? (
                                      <div className="error">
                                        {errors.lastName}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="phoneNumber"
                                          className="profile_section_label"
                                        >
                                          Phone Number
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="phoneNumber"
                                          id="phoneNumber"
                                          value={values?.phoneNumber}
                                          placeholder="+1 (123) 123-1234"
                                          onChange={handleChange}
                                          onKeyPress={handleKeyPress}
                                        />
                                      </div>
                                      {errors.phoneNumber &&
                                        touched.phoneNumber ? (
                                        <div className="error">
                                          {errors.phoneNumber}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="email"
                                          className="profile_section_label"
                                        >
                                          Email Address
                                        </label>
                                        <Field
                                          type="email"
                                          className="form-control"
                                          name="email"
                                          id="email"
                                          value={values?.email}
                                          placeholder="Enter Email"
                                          onChange={handleChange}
                                          disabled
                                        />
                                      </div>
                                      {errors.email && touched.email ? (
                                        <div className="error">
                                          {errors.email}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="barNumber"
                                          className="profile_section_label"
                                        >
                                          Bar number
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="barNumber"
                                          id="barNumber"
                                          value={values?.barNumber}
                                          placeholder="Enter BarNumber"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    {errors.barNumber && touched.barNumber ? (
                                      <div className="error">
                                        {errors.barNumber}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="email"
                                          className="main_group1 profile_section_label"
                                        >
                                          State Licensed
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="stateLicensed"
                                          id="stateLicensed"
                                          value={values?.stateLicensed}
                                          placeholder="Enter StateLicensed"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    {errors.stateLicensed &&
                                      touched.stateLicensed ? (
                                      <div className="error">
                                        {errors.stateLicensed}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="email"
                                          className="profile_section_label"
                                        >
                                          Firm (if applicable)
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="firm"
                                          id="firm"
                                          value={values?.firm}
                                          placeholder="Enter Firm"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="email"
                                          className="profile_section_label"
                                        >
                                          Address 1
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="address1"
                                          id="address1"
                                          value={values?.address1}
                                          placeholder="Enter Address1"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.address1 && touched.address1 ? (
                                    <div className="error">
                                      {errors.address1}
                                    </div>
                                  ) : null}

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div className="main_group1 form-group">
                                      <label
                                        htmlFor="email"
                                        className="profile_section_label"
                                      >
                                        Address 2
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="address2"
                                        id="address2"
                                        value={values?.address2}
                                        placeholder="Enter Address2"
                                        onChange={handleChange}
                                      />
                                    </div>

                                  </div>

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">


                                    <div className="main_group1 form-group">
                                      <label
                                        htmlFor="email"
                                        className="profile_section_label"
                                      >
                                        City
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="city"
                                        id="city"
                                        value={values?.city}
                                        placeholder="Enter City"
                                        onChange={handleChange}
                                      />
                                    </div>

                                  </div>
                                  {errors.city && touched.city ? (
                                    <div className="error">{errors.city}</div>
                                  ) : null}
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="email"
                                          className="profile_section_label"
                                        >
                                          State
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="state"
                                          id="state"
                                          value={values?.state}
                                          placeholder="Enter State"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.state && touched.state ? (
                                    <div className="error">{errors.state}</div>
                                  ) : null}

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">

                                    <div>
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="email"
                                          className="profile_section_label"
                                        >
                                          Zip Code
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="zipCode"
                                          id="zipCode"
                                          value={values?.zipCode}
                                          placeholder="Enter Zipcode"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    {errors.zipCode && touched.zipCode ? (
                                      <div className="error">
                                        {errors.zipCode}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="StatusPart mt-3">
                                    <p className="Title3">Status</p>
                                    <div className="d-flex">
                                      <div
                                        className={`profile_tab_link fw-medium ${parseInt(
                                          getSingleAttorneyDetail?.status
                                        ) === 1
                                          ? "active"
                                          : "active0"
                                          }`}
                                        onClick={() => {
                                          handleChangeStatus("1");
                                        }}
                                      >
                                        Active
                                      </div>
                                      <div
                                        className={`profile_tab_link fw-medium ${parseInt(
                                          getSingleAttorneyDetail?.status
                                        ) === 1
                                          ? "Inactive"
                                          : "Inactive0"
                                          }`}
                                        onClick={() => {
                                          handleChangeStatus("0");
                                        }}
                                      >
                                        Inactive
                                      </div>
                                    </div>
                                  </div>


                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="conten-div">
                <div className="profile_main_part_section">
                  <div className="btm_details_sec">
                    <div className="">
                      <h2 className="Title mt-1 mb-3">
                        {" "}
                        {`${getSingleAttorneyDetail?.firstName ?? ""}`}{" "}
                        {`${getSingleAttorneyDetail?.lastName ?? ""}`}
                      </h2>
                    </div>
                    <div className="profile_main_sec_detail">
                      <div className="profile_title_grid d-flex align-items-center justify-content-between pb-3">
                        <div>
                          <p className="m-0 p-0 fs-5 fw-medium">Details</p>
                        </div>
                        <div className="btn_sec_right color_change">
                          <button
                            className="profile_edit_btn rounded-pill text-white  "
                            onClick={handleClick}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="">
                        <div className="main_grid_top grid-container gap-0 pt-2">
                          <div className="row m-0 w-100">
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="firstName"
                                  className="profile_section_label"
                                >
                                  First Name
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.firstName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="lastName"
                                  className="profile_section_label"
                                >
                                  Last Name
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.lastName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="phoneNumber"
                                  className="profile_section_label"
                                >
                                  Phone Number
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.phoneNumber}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  Email
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.email}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  Bar Number
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.barNumber}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  State Licensed
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.stateLicensed}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  Firm (If applicable)
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.firm}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  Address 1
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.address1}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  Address 2
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.address2}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  City
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.city}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  State
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.state}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  Zip Code
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {getSingleAttorneyDetail?.zipCode}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="StatusPart">
                      <p className="Title3">Status</p>
                      <div className="d-flex">
                        <div
                          className={`profile_tab_link fw-medium ${parseInt(getSingleAttorneyDetail?.status) === 1
                            ? "active"
                            : "active0"
                            }`}
                        >
                          Active
                        </div>
                        <div
                          className={`profile_tab_link fw-medium ${parseInt(getSingleAttorneyDetail?.status) === 1
                            ? "Inactive"
                            : "Inactive0"
                            }`}
                        >
                          Inactive
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Structure >
    </>
  );
};

export default EditAttorney;
