import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import _ from "loadsh";
import { useDispatch, useSelector } from "react-redux";
import ActiveAdminUsers from "./ActiveAdminUsers";
import ArchivedAdminUsers from "./ArchivedAdminUsers";
import { GetAdminListing } from "../../../redux/features/AdminService";
import moment from "moment-timezone";
import AddAdminUserPopup from "./Create/AddAdminUserPopup";
import ModalCustom from "../../Common/ModalCustom";
import SearchBox from "../../Common/SearchBox";
import Loader from "../../Common/Loader";

const AdminUser = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const tabs = ["Active", "Inactive"];
  const { getAdminListing } = useSelector((state) => state.admin);
  const [showPopup, setShowPopup] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [search, setSearch] = useState(""); 
  const [filteredData, setFilteredData] = useState(null); 
  const [isCsvDataShow, setCsvDataShow] = useState(false);

  const handleSearch = (searchText) => {
    setSearch(searchText);
  };

  const csvLink = useRef();
  const handleCsvDownload = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        GetAdminListing({
          search: search,
          role: 1,
          csv: true,
          status: activeTab === 1 ? "0" : "1",
        })
      );

      const appendedData = _.map(response?.payload?.data, (data) => {
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          createdAt: moment(data.createdAt).format("MM/DD/yyyy @ HH:mm PT"),
          updatedAt: moment(data.updatedAt).format("MM/DD/yyyy @ HH:mm PT"),
        };
      });
      if (Array.isArray(appendedData)) {
        const sortedData = _.sortBy(appendedData, "firstName");
        setCsvData(sortedData);
        setCsvDataShow(true);
      } else {
        console.error("Invalid data format for CSV export");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (csvData?.length > 0 && isCsvDataShow) {
      csvLink.current.link.click();
      setCsvDataShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  const headers = [
    { label: "First Name", key: `firstName` },
    { label: "Last Name", key: `lastName` },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Last Session", key: "createdAt" },
    { label: "Last Updated", key: "updatedAt" },
  ];

  // handle search
  useEffect(() => {
    if (search.trim() === "") {
      setFilteredData(getAdminListing?.data);
    } else {
      const filtered = _.filter(
        getAdminListing?.data,
        (item) =>
          item?.firstName
            ?.trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase()) ||
          item?.lastName
            ?.trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase()) ||
          (item?.email &&
            item?.email
              .trim()
              .toLowerCase()
              .includes(search.trim().toLowerCase())) ||
          (item?.phoneNumber &&
            item?.phoneNumber.trim().includes(search.trim()))
      );

      setFilteredData(filtered);
    }
  }, [search, getAdminListing]);

  return (
    <>
      {loading && <Loader />}
      <div className="w-100">
        <div className="title_bar d-flex align-items-center justify-content-between">
          <div className="text-style">Admin Users</div>
          <div className="d-flex">
            {getAdminListing.data && (
              <>
                <button
                  className="btn btn-transparant mx-2"
                  onClick={handleCsvDownload}
                >
                  Export CSV
                </button>
                <CSVLink
                  data={csvData}
                  filename="Admin-user"
                  ref={csvLink}
                  headers={headers}
                />
              </>
            )}
            <button
              className="btn btn-thm"
              onClick={(e) => {
                e.preventDefault();
                setShowPopup(true);
              }}
            >
              Add Admin User
            </button>
          </div>
        </div>

        <div className="conten-div">
          <div className="d-flex justify-content-between">
            <SearchBox handleSearch={handleSearch} search={search} />
          </div>
          <div className="d-flex mt-4">
            {_.map(tabs, (tab, tabIndex) => (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setSearch("");
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                  setActiveTab(tabIndex);
                }}
                className={`tab-link ${activeTab === tabIndex ? "active" : ""}`}
                key={`${tab}-${tabIndex}-AdminUser`}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="TableData mt-4">
            {activeTab === 0 && (
              <ActiveAdminUsers filteredData={filteredData} />
            )}
            {activeTab === 1 && (
              <ArchivedAdminUsers filteredData={filteredData} />
            )}
          </div>
        </div>
      </div>
      <ModalCustom size="xs" show={showPopup}>
        <AddAdminUserPopup setShowAdminUserPopup={setShowPopup} />
      </ModalCustom>
    </>
  );
};

export default AdminUser;
