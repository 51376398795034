import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalCustom from "../Common/ModalCustom";
import AddSpouseFormPopup from "./AddSpouseFormPopup";

const AskSpousePopup = ({ setAskSpousePopup }) => {
  const navigate = useNavigate();
  const [showFormSpousePopup, setFormSpousePopup] = useState(false);
  const handlePopup = (e) => {
    console.log("DFASDASD");
    e.preventDefault();
    // setAskSpousePopup(false);
    setFormSpousePopup(true);
  };

  return (
    <>
      <div className="mian_center_box">
        <h5 className="my-2" style={{ color: "black", fontSize: "24px" }}>
          Do you wish to add this spouse to your account?
        </h5>
        <h5
          className="my-2"
          style={{ color: "rgba(68, 70, 78, 1)", fontSize: "24px" }}
        >
          If you would like to proceed with adding your spouse,
          <br /> please confirm by clicking the "Yes, Add Spouse" <br /> button
          below. If you do not wish to proceed, you can
          <br /> cancel this action.
        </h5>

        <div className="d-flex align-items-center justify-content-between mb-0 mt-2">
          <div className="btn_back mr-2">
            <button
              type="button"
              className="btn cursor-pointer"
              onClick={() => {
                setAskSpousePopup(false);
                setFormSpousePopup(false);
              }}
            >
              No Thanks
            </button>
          </div>
          <div className="bttn_continue mb-0">
            <button
              className="btn btn_continue cursor-pointer"
              onClick={(e) => {
                handlePopup(e);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <ModalCustom
        size="xl"
        handleClose="grantorModal"
        show={showFormSpousePopup}
        className={""}
      >
        <AddSpouseFormPopup
          setFormSpousePopup={setFormSpousePopup}
          setAskSpousePopup={setAskSpousePopup}
        />
      </ModalCustom>
    </>
  );
};

export default AskSpousePopup;
