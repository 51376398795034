import React, { useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import { useDispatch } from "react-redux";
import { AddInvitation } from "../../../../redux/features/TrustsService";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";

const Invitation = ({
  setShowInvitation,
  id,
  trustName,
  isAddEditData,
  setAddEditData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const validate = (values) => {
    let errors = {};
    if (!values?.trustName) {
      errors.trustName = "Trust Name is required";
    }
    
    return errors;
  };

  return (
    <>
      <div className="col-xl-11 mx-auto invitation-header">
        <h4 className="mt-3 mb-1 popupTitle">
          Your trust application is complete — Thank you for entrusting us with
          this important step.
        </h4>
        <p className="mb-0 text-center popupTitle2">
          Share the peace of mind by inviting your trusted successors and
          attorneys to join your trust.
        </p>
        <p className="text-center popupTitle2">
          Together, we build a foundation for a secure and thriving future
        </p>
      </div>
      <Formik
        initialValues={{
          trustId: id,
          trustName: trustName,
          grantorList: [],
          attorneyList: [],
          successorList: [],
        }}
        enableReinitialize={true}
        validate={validate}
        validateOnBlur={false}
        onSubmit={async (values) => {
          setLoading(true);
          const { trustName, grantorList, attorneyList, successorList } =
            values;
          const trustId = id;
          const grantor =
            grantorList?.flatMap((item) =>
              item.split(",").map((str) => str.trim())
            ) || [];
          const attorney =
            attorneyList?.flatMap((item) =>
              item.split(",").map((str) => str.trim())
            ) || [];
          const successor =
            successorList?.flatMap((item) =>
              item.split(",").map((str) => str.trim())
            ) || [];

          const res = await dispatch(
            AddInvitation({
              trustName: trustName,
              trustId,
              grantorList: grantor,
              attorneyList: attorney,
              successorList: successor,
            })
          );
          if (res?.type === "trusts/AddInvitation/fullfiled") {
            setLoading(false);
          } else if (res?.type === "trusts/AddInvitation/rejected") {
            toast.error(res?.payload?.response?.data?.message);
          }
          setShowInvitation(false);
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          handleBlur,
        }) => (
          <form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            formgroup="editform"
          >
            {loading && <Loader />}
            <FieldArray
              render={(arrayHelpers) => (
                <>
                  <div className="mt-5">
                    <div className="main_form1 col-xl-12 mb-0">
                      <div className="form-group ">
                        <label htmlFor="exampleInputPassword1">
                          Trust Name
                        </label>
                        <Field
                          type="text"
                          name="trustName"
                          placeholder="Enter Your Trust Name"
                          id="trustName"
                          className="form-control"
                          value={values?.trustName}
                          readOnly
                        />
                      </div>
                    </div>
                    <small id="emailHelp" className="form-text ">
                      Add one or more email addresses, separated by a comma.
                      Press enter to add.{" "}
                    </small>
                    {errors?.trustName && touched?.trustName && (
                      <span className="error">{errors?.trustName}</span>
                    )}
                  </div>
                  <div className="main_form1 col-xl-12 mb-0">
                    <div className="form-group ">
                      <label htmlFor="exampleInputPassword1">Grantors</label>

                      <Field
                        type="text"
                        name="grantorList[0]"
                        placeholder="Enter Your Grantors"
                        id="grantorList[0]"
                        className="form-control"
                        disabled={
                          isAddEditData?.grantorData.length > 1 ||
                          setAddEditData?.grantorData?.length > 1
                        }
                      />
                    </div>
                  </div>
                  <small id="emailHelp" className="form-text ">
                    Add one or more email addresses, separated by a comma. Press
                    enter to add.{" "}
                  </small>
                  <div className="main_form1 col-xl-12 mb-0">
                    <div className="form-group ">
                      <label htmlFor="exampleInputPassword1">Successors</label>
                      <Field
                        type="text"
                        name="successorList[0]"
                        placeholder="Enter Your Successors"
                        id="successorList[0]"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <small id="emailHelp" className="form-text ">
                    Add one or more email addresses, separated by a comma. Press
                    enter to add.{" "}
                  </small>
                  <div className="main_form1 col-xl-12 mb-0">
                    <div className="form-group ">
                      <label htmlFor="exampleInputPassword1">Attorneys</label>
                      <Field
                        type="text"
                        name="attorneyList[0]"
                        placeholder="Enter Your Attorneys"
                        id="attorneyList[0]"
                        className="form-control"
                        disabled={
                          isAddEditData?.attorneyData.length > 1 ||
                          setAddEditData?.attorneyData?.length > 1
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            />
            <div className="d-flex align-items-center justify-content-between btn_bottm">
              <div className="btn_back mr-2">
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    setShowInvitation(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="bttn_continue mb-0">
                <button type="submit" className="btn btn_continue">
                  Invite
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Invitation;
