import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LoginVerificationOtpService, SetPasswordSpouseUser } from "../../redux/features/AuthService";
import Cookies from "js-cookie";
import MainCenterBox from "../MainCenterBox";
import Button from "../Common/Button";
import { AuthContext } from "../../contexts/AuthContexts";
import SetPasswordSpouse from "./SetPasswordSpouse";

const SpouseVerification = ({ text }) => {
  document.title = "National Trust Registry | Verify";

  const navigate = useNavigate();
  const [code, setCode] = useState();
  const { updateAuthStatus } = useContext(AuthContext);

  const dispatch = useDispatch();

  const handleSetPasswordSpouse = async () => {
    if (code?.length !== 6) {
      toast.error("*Please enter a valid verification code");
    } else {
      const data = {
        userId: localStorage.getItem('spouse-id'),
        otp: code,
        password: localStorage.getItem('spouse-verify-pwd'),
      };
      const response = await dispatch(SetPasswordSpouseUser(data));
      if (response.type === "auths/SetPasswordSpouseUser/fulfilled") {
        console.log(response?.payload?.message);
        if (response?.payload?.status === 1) {
          navigate("/");
        } else {
          toast.error(response?.payload?.message, {
            autoClose: 3000,
          });
        }
      } else {
        toast.error(response?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <MainCenterBox
      title="Verification"
      className="col-xl-4 col-lg-6 col-md-8 m-auto"
    >
      <p className="mb-0">
        {text === "signIn"
          ? "Input the verification code to verify your email address."
          : "Verify your email address to access your account."}
      </p>
      <form>
        <div className=" d-flex align-items-center verification_sec justify-content-center pt-4">
          <div className="key_word">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 15.225C7.9 15.225 8.6625 14.9125 9.2875 14.2875C9.9125 13.6625 10.225 12.9 10.225 12C10.225 11.1 9.9125 10.3375 9.2875 9.7125C8.6625 9.0875 7.9 8.775 7 8.775C6.1 8.775 5.3375 9.0875 4.7125 9.7125C4.0875 10.3375 3.775 11.1 3.775 12C3.775 12.9 4.0875 13.6625 4.7125 14.2875C5.3375 14.9125 6.1 15.225 7 15.225ZM7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.41667 6 9.60417 6.3875 10.5625 7.1625C11.5208 7.9375 12.1917 8.925 12.575 10.125H20.875C20.975 10.125 21.0667 10.1417 21.15 10.175C21.2333 10.2083 21.3167 10.2667 21.4 10.35L22.475 11.425C22.5583 11.5083 22.6167 11.5917 22.65 11.675C22.6833 11.7583 22.7 11.85 22.7 11.95C22.7 12.05 22.6833 12.1417 22.65 12.225C22.6167 12.3083 22.5583 12.3917 22.475 12.475L19.875 15.075C19.7917 15.1583 19.7083 15.2167 19.625 15.25C19.5417 15.2833 19.45 15.3 19.35 15.3C19.25 15.3 19.1583 15.2833 19.075 15.25C18.9917 15.2167 18.9083 15.1583 18.825 15.075L17.25 13.5L15.675 15.075C15.5917 15.1583 15.5083 15.2167 15.425 15.25C15.3417 15.2833 15.25 15.3 15.15 15.3C15.05 15.3 14.9583 15.2833 14.875 15.25C14.7917 15.2167 14.7083 15.1583 14.625 15.075L13.375 13.825H12.575C12.2583 15.075 11.5875 16.0833 10.5625 16.85C9.5375 17.6167 8.35 18 7 18Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="verify-input">
            <OtpInput
              value={code}
              renderInput={(props) => <input {...props} />}
              onChange={(value) => setCode(value)}
              numInputs={6}
              separator={<span style={{ width: "0px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid #75777F",
                maxWidth: "50px",
                maxHeight: "50px",
                width: "100%",
                height: "100%",
                fontSize: "20px",
                color: "#000",
                fontWeight: "400",
                margin: "0",
                gap: "0",
                padding: "30px 0",
                background: "#EFF4FA",
                borderLeft: 0,
              }}
              inputType="password"
              focusStyle={{
                border: "1px solid #550059",
              }}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-md-5 mb-sm-4 mt-3">
          <div
            className="link_pasword SiteBtn2"
            onClick={() =>
              navigate("/sign-in", {
                state: {
                  passwordTab: true,
                },
              })
            }
          >
            Back
          </div>
          <Button
            type="button"
            className="btn-thm SiteBtn"
            text="Continue"
            onClick={handleSetPasswordSpouse}
          />
        </div>
      </form>
    </MainCenterBox>
  );
};

export default SpouseVerification;
