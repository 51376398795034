import React from 'react';
import Button from '../Common/Button';
import { useNavigate } from 'react-router-dom';



const SpouseUser = () => {
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate("/subscription");

    }
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center vh-100">
                <div className='main-box col-md-8'>
                    <div className='maingrantorverify col-md-8'>
                        <div className='text-center mt-4'>
                            <p className='thm-black f4bold'>Are you sure you wish to add this user?  </p>
                            <p className='thm-gray f4bold mb-5'>Select continue to send an invite</p>
                            <div className='d-flex justify-content-center'>
                                <Button type="submit" className="Spouse-add me-2" text="No, Cancel" />
                                <Button type="submit" className="Spouse-btn" text="Yes, Continue to Payment" onClick={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpouseUser
