import React, { useState } from "react";
import Button from "../Common/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { GiScales, GiQuillInk } from "react-icons/gi";
import { SlKey } from "react-icons/sl";

const RegAccountType = (props) => {
  const [selectedOption, setSelectedOption] = useState("3");
  const { title, className, children } = props;
  const navigate = useNavigate();
  const handleSubmit = async () => {
    if (selectedOption === "3") {
      navigate("/sign-up-account-attorney");
    } else if (selectedOption === "2") {
      navigate("/sign-up-account-grantor");
    } else if (selectedOption === "4") {
      navigate("/sign-up-account-successor");
    }
  };

  function onValueChange(event) {
    setSelectedOption(event.target.value);
  }
  const handleClick = (type) => {
    setSelectedOption(type);
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-xl-4 col-md-8">
            <div className="main_center_box">
              <h4 className="">Account Type</h4>
              <p className="mt-4">Please select your account type.</p>
              <div className="row d-flex">
                <div className="col-sm-4 text-center">
                  <p className="mb-0 ">Attorney</p>
                  <div className="text-center cursor-pointer" onClick={() => handleClick("3")}>
                    <GiScales class="img-fluid sign-up-checkbox-icon" />
                  </div>
                  <div class="my-3 cursor-pointer">
                    <input type="checkbox" class="form-check-input position-relative ml-0 cursor-pointer" id="check1"
                      value="3"
                      name="account"
                      checked={selectedOption === "3"}
                      onChange={onValueChange}
                      onClick={() => handleClick("3")} />
                  </div>
                </div>
                <div className="text-center col-sm-4">
                  <p className="mb-0">Grantor</p>
                  <div className="cursor-pointer" onClick={() => handleClick("2")}>
                    <GiQuillInk class="img-fluid sign-up-checkbox-icon" />
                  </div>
                  <div class="my-3 sign-up-checkbox-icon cursor-pointer">
                    <input type="checkbox" class="form-check-input position-relative ml-0 cursor-pointer" id="check1" name="account"
                      value="2"
                      checked={selectedOption === "2"}
                      onChange={onValueChange}
                      onClick={() => handleClick("2")} />
                  </div>
                </div>
                <div className="text-center col-sm-4">
                  <p className="mb-0">Successor</p>
                  <div className="cursor-pointer" onClick={() => handleClick("4")}>
                    <SlKey class="img-fluid sign-up-checkbox-icon" />
                  </div>
                  <div class="my-3 cursor-pointer">
                    <input
                      type="checkbox"
                      value="4"
                      name="account"
                      class="form-check-input position-relative ml-0 cursor-pointer"
                      checked={selectedOption === "4"}
                      onChange={onValueChange}
                      onClick={() => handleClick("4")}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-md-5 mb-sm-4 mt-3">
                <div
                  className="link_pasword SiteBtn2"
                  onClick={() => navigate("/sign-in")}
                >
                  Sign in
                </div>
                <Button
                  type="button"
                  className="btn-thm SiteBtn"
                  text="Continue"
                  onClick={handleSubmit}
                />
              </div>
              <div className="d-flex justify-content-center gap-5 mt-5 pt-2">
                <div>
                  <Link to={"/supports"} className="link-text-color">
                    Help
                  </Link>
                </div>
                <div>
                  <Link
                    to={"https://www.mytntr.com/privacy/"}
                    className="link-text-color"
                  >
                    Privacy
                  </Link>
                </div>
                <div>
                  <Link
                    to={"https://www.mytntr.com/terms-of-use/"}
                    className="link-text-color"
                  >
                    Terms
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="d-flex w-100 align-items-center mb-3 AccountType"
        onClick={() => handleClick("2")}
      >
        <div className="d-flex justify-content-center" style={{ flex: 0.1 }}>
          <input
            type="radio"
            name="account"
            value="2"
            checked={selectedOption === "2"}
            onChange={onValueChange}
          />
        </div>
        <div
          className="rounded-1"
          style={{ flex: 0.9, border: "1px solid #75777F" }}
        >
          <button
            style={{ background: "none" }}
            className="p-3 w-100 fw-medium fs-4"
          >
            Grantor
          </button>
        </div>
      </div>
      <div
        className="d-flex w-100 align-items-center mt-3 AccountType"
        onClick={() => handleClick("3")}
      >
        <div className="d-flex justify-content-center" style={{ flex: 0.1 }}>
          <input
            type="radio"
            value="3"
            name="account"
            checked={selectedOption === "3"}
            onChange={onValueChange}
          />
        </div>
        <div
          className="  rounded-1"
          style={{ flex: 0.9, border: "1px solid #75777F" }}
        >
          <button
            style={{ background: "none" }}
            className="p-3 w-100 fw-medium fs-4"
          >
            Attorney
          </button>
        </div>
      </div>
      <div
        className="d-flex w-100 align-items-center mb-md-5 mb-sm-4 mt-3 AccountType"
        onClick={() => handleClick("4")}
      >
        <div className="d-flex justify-content-center" style={{ flex: 0.1 }}>
          <input
            type="radio"
            value="4"
            name="account"
            checked={selectedOption === "4"}
            onChange={onValueChange}
          />
        </div>
        <div
          className="  rounded-1"
          style={{ flex: 0.9, border: "1px solid #75777F" }}
        >
          <button
            style={{ background: "none" }}
            className="p-3 w-100 fw-medium fs-4"
          >
            Successor
          </button>
        </div>
      </div> */}

    </>
  );
};

export default RegAccountType;
