import React, { useEffect, useRef, useState } from "react";
import _ from "loadsh";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import Structure from "../../Common/Structure/Structure";
import { GetActiveGrantorList } from "../../../redux/features/GrantorService";
import moment from "moment-timezone";
import AddGrantor from "./AddGrantor";
import ModalCustom from "../../Common/ModalCustom";
import ActiveGrantor from "./ActiveGrantor";
import ArchivedGrantor from "./ArchivedGrantor";
import SearchBox from "../../Common/SearchBox";
import Loader from "../../Common/Loader";

const GrantorsList = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const tabs = ["Active", "Inactive"];
  const { getGrantorListing } = useSelector((state) => state.grantors);
  const [csvData, setCsvData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [search, setSearch] = useState("");
  const [isCsvDataShow, setCsvDataShow] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const csvLink = useRef();
  const handleCsvDownload = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        GetActiveGrantorList({
          search: search,
          role: 2,
          csv: true,
          status: activeTab === 1 ? "0" : "1",
        })
      );

      const appendedData = _.map(response?.payload?.data, (data) => {
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          createdAt: moment
            (data.createdAt)
            .format("MM/DD/yyyy @ HH:mm PT"),
          updatedAt: moment
            (data.updatedAt)
            .format("MM/DD/yyyy @ HH:mm PT"),
        };
      });
      if (Array.isArray(appendedData)) {
        const sortedData = _.sortBy(appendedData, "firstName");
        setCsvData(sortedData);
        setCsvDataShow(true);
      } else {
        console.error("Invalid data format for CSV export");
      }
    } catch (e) {
      console.error(e);
    }
    finally{
      setLoading(false);
    }
  };

  const headers = [
    { label: "First Name", key: `firstName` },
    { label: "Last Name", key: `lastName` },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Last Session", key: "createdAt" },
    { label: "Last Updated", key: "updatedAt" },
  ];

  useEffect(() => {
    if (csvData?.length > 0 && isCsvDataShow) {
      csvLink.current.link.click();
      setCsvDataShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  const handleSearch = (searchText) => {
    setSearch(searchText);
  };

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredData(getGrantorListing?.data);
    } else {
      const filtered = _.filter(
        getGrantorListing?.data,
        (item) =>
        (item?.firstName?.trim().toLowerCase().includes(search.trim().toLowerCase())) ||
        (item?.lastName?.trim().toLowerCase().includes(search.trim().toLowerCase())) ||
        ((item?.email && item?.email.trim().toLowerCase().includes(search.trim().toLowerCase()))) ||
        ((item?.phoneNumber && item?.phoneNumber.trim().includes(search.trim())))
      );
      setFilteredData(filtered);
    }
  }, [search, getGrantorListing]);

  return (
    <>
      {loading && <Loader />}
      <Structure activeText="Grantor Users">
        <div className="w-100">
          <div className="title_bar d-flex align-items-center justify-content-between">
            <div className="text-style">
              Grantor Users
            </div>
            <div className="d-flex">
              {getGrantorListing.data && (
                <>
                  <button
                    className="btn btn-transparant mx-2"
                    onClick={handleCsvDownload}
                  >
                    Export CSV
                  </button>
                  <CSVLink
                    data={csvData}
                    filename="Grantor-list"
                    ref={csvLink}
                    headers={headers}
                  />
                </>
              )}
              <button
                className="btn btn-thm"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPopup(true);
                }}
              >
                Add Grantor User
              </button>
            </div>
          </div>

          <div className="conten-div">
            <div className="d-flex justify-content-between">
              <div className="flex-1">
                <SearchBox handleSearch={handleSearch} search={search} />
              </div>
            </div>
            <div className="d-flex mt-4">
              {_.map(tabs, (tab, tabIndex) => (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setSearch("");
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1000);
                    setActiveTab(tabIndex);
                  }}
                  className={`tab-link ${activeTab === tabIndex ? "active" : ""
                    }`}
                  key={`${tab}-${tabIndex}-trusts`}
                >
                  {tab}
                </div>
              ))}
            </div>
            <div className="TableData mt-4">
              {activeTab === 0 && <ActiveGrantor filteredData={filteredData} />}
              {activeTab === 1 && (
                <ArchivedGrantor filteredData={filteredData} />
              )}
            </div>
          </div>
        </div>
      </Structure>
      <ModalCustom size="xs" handleClose="grantorModal" show={showPopup}>
        <AddGrantor setShowGrantorPopup={setShowPopup} />
      </ModalCustom>
    </>
  );
};

export default GrantorsList;
