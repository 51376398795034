import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Button from "../Common/Button";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { UploadMedia } from "../../redux/features/CommonService";
import { toast } from "react-toastify";
import { SignUpService } from "../../redux/features/AuthService";
import Cookies from "js-cookie";
import ConfirmSpousePopup from "./ConfirmSpousePopup";
import ModalCustom from "../Common/ModalCustom";

const validate = (values) => {
  const errors = {};
  if (!values.ssnNumber) {
    errors.ssnNumber = "*SSN/ITIN Number is required to Sign Up";
  }
  if (!values.ssnConfirmNumber) {
    errors.ssnConfirmNumber = "*Confirm SSN/ITIN Number is required to Sign Up";
  } else if (values.ssnNumber !== values.ssnConfirmNumber) {
    errors.ssnConfirmNumber = "*SSN/ITIN and confirm SSN/ITIN do not match";
  }
  if (!values.ssnNumber) {
    errors.ssnNumber = "*SSN/ITIN Number is required to Sign Up";
  } else if (values.ssnNumber?.length < 9) {
    errors.ssnNumber = "SSN/ITIN number must be 9 digits long";
  }
  return errors;
};
const SpouseSignUp2 = ({ handleBack, data1, handleSubmit }) => {
  document.title = "National Trust Registry | Sign Up";
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [ssnValue, setSsnValue] = useState("");
  const [valueCnf, setValueCnf] = useState("");
  const [ssnValueCnf, setSsnValueCnf] = useState("");
  const dispatch = useDispatch();
  const fileFrontRef = useRef(null);
  const fileBackRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const handleSvgFrontClick = () => {
    fileFrontRef.current.click();
  };

  const handleSvgBackClick = () => {
    fileBackRef.current.click();
  };

  let initialValuesWithoutEmail = {
    timezone: "Asia/Kolkata",
    role: 2,
    ssnConfirmNumberValue: "",
    ssnConfirmNumber: "",
    ssnNumber: "",
    ssnValue: "",
    drivingLicenseFront: "",
    drivingLicenseBack: "",
    drivingLicenseFrontShow: "",
    drivingLicenseBackShow: "",
  };
  if (id) {
    initialValuesWithoutEmail.email = id;
  }
  const formik = useFormik({
    initialValues: initialValuesWithoutEmail,
    validate,
    onSubmit: async (values) => {
      const data = {
        ...values,
        ...data1,
      };
      console.log(data);
      localStorage.setItem('spouse-data', JSON.stringify(data));
      setShowPopup(true)
      // const response = await dispatch(SignUpService(data));
      // if (response.type === "auths/SignUpService/fulfilled") {
      //   Cookies.set("ntr-email-register", data.email, { expires: 36400 });
      //   if (response?.payload?.status === 3) {
      //     navigate("/sign-up-verify", { state: response?.payload?.data?._id });
      //     toast.success(response?.payload?.message, { autoClose: 3000 });
      //     // navigate("/grantor-verify");
      //   } else {
      //     toast.error(response?.payload?.message, {
      //       autoClose: 3000,
      //     });
      //   }
      // } else {
      //   toast.error(response?.payload?.response?.data?.message, {
      //     autoClose: 3000,
      //   });
      // }
      localStorage.setItem("userTitle", formik.values.firstName);
    },
  });

  const replaceAt = (str, index, character) => {
    return (
      str.substr(0, index) + character + str.substr(index + character.length)
    );
  };

  const transformDisplay = (val) => {
    let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3);
    }

    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6);
      const lastFour = displayVal.slice(7);
      displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour;
    } else {
      displayVal = displayVal.replace(/\d/g, "*");
    }

    return displayVal;
  };

  const transformValue = (val) => {
    let cleanVal = val.replace(/[^0-9|\\*]/g, "");
    cleanVal = cleanVal.substr(0, 9);
    let updatedValue = value ? value : "";

    for (let i = 0, l = cleanVal.length; i < l; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        updatedValue = replaceAt(updatedValue, i, cleanVal[i]);
      }
    }
    updatedValue = updatedValue.substr(0, cleanVal.length);
    formik.setFieldValue("ssnNumber", updatedValue);
    setValue(updatedValue);
  };

  const syncInput = (event) => {
    const val = event.target.value;
    formik.setFieldValue("ssnValue", val);
    transformDisplay(val);
    transformValue(val);
    event.target.setSelectionRange(val.length, val.length);
    setSsnValue(value);
  };

  const replaceAtCnf = (str, index, character) => {
    return (
      str.substr(0, index) + character + str.substr(index + character.length)
    );
  };

  const transformDisplayCnf = (val) => {
    let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3);
    }

    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6);
      const lastFour = displayVal.slice(7);
      displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour;
    } else {
      displayVal = displayVal.replace(/\d/g, "*");
    }

    return displayVal;
  };

  const transformValueCnf = (val) => {
    let cleanVal = val.replace(/[^0-9|\\*]/g, "");
    cleanVal = cleanVal.substr(0, 9);
    let updatedValue = valueCnf ? valueCnf : "";

    for (let i = 0, l = cleanVal.length; i < l; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        updatedValue = replaceAtCnf(updatedValue, i, cleanVal[i]);
      }
    }
    updatedValue = updatedValue.substr(0, cleanVal.length);
    formik.setFieldValue("ssnConfirmNumber", updatedValue);
    setValueCnf(updatedValue);
  };

  const syncInputCnf = (event) => {
    const val = event.target.value;
    formik.setFieldValue("ssnConfirmNumber", val);
    transformDisplayCnf(val);
    transformValueCnf(val);
    event.target.setSelectionRange(val.length, val.length);
    setSsnValueCnf(valueCnf);
  };

  const handleUploadImage = async (file) => {
    if (file) {
      let data = file;
      if (file?.type === "image/svg+xml") {
        data = new Blob([file], { type: "image/svg" });
        data.name = file.name;
      }
      const formData = new FormData();
      formData.append("files", data, file.name);
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "commons/UploadMedia/fulfilled") {
        return res.payload?.files[0];
      }
    }
  };

  const drivingLicenseFront = async (e) => {
    const file = e.target.files[0];
    const uploadData = await handleUploadImage(file);
    formik.setFieldValue("drivingLicenseFront", uploadData.mediaName);
    formik.setFieldValue("drivingLicenseFrontShow", file.name);
  };

  const drivingLicenseBack = async (e) => {
    const file = e.target.files[0];
    const uploadData = await handleUploadImage(file);
    formik.setFieldValue("drivingLicenseBack", uploadData?.mediaName);
    formik.setFieldValue("drivingLicenseBackShow", file?.name);
  };

  // const handleSubmit = () => {
  //   navigate("/grantor-verify");
  // }

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mt-0 mb-0">
            <span>Security Information</span>
          </div>
          <div className="col-md-12">
            <div className="col-md-6 p-0">
              <div className="main_form_box d-flex align-items-center mt-1">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="#808080"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
                    fill="#323232"
                  />
                </svg>

                <div className="form-group d-flex align-items-center">
                  <input
                    className="number input-field"
                    style={{ position: "absolute" }}
                    onChange={syncInput}
                    value={transformDisplay(value)}
                    maxLength={11}
                    placeholder="SSN/ ITIN"
                  />
                  <input
                    className="value input-field"
                    style={{
                      position: "absolute",
                      top: 0,
                      background: "transparent",
                      color: "transparent",
                      border: "none",
                      visibility: "hidden",
                    }}
                    value={ssnValue}
                    maxLength={9}
                    readOnly
                    disabled
                    type="hidden"
                  />
                </div>
              </div>
              <p className="SSN-input-message">
                *Please enter your SSN/ITIN carefully.
              </p>
            </div>
            {formik.touched.ssnNumber && formik.errors.ssnNumber ? (
              <div className="error sstn-error pt-2">
                {formik.errors.ssnNumber}
              </div>
            ) : null}
          </div>
          <div className="col-md-12">
            <div className="col-md-6 p-0">
              <div className="main_form_box d-flex align-items-center mt-4  ">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="#808080"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
                    fill="#323232"
                  />
                </svg>
                <div className="form-group d-flex align-items-center">
                  <input
                    className="number1 input-field"
                    style={{ position: "absolute" }}
                    onChange={syncInputCnf}
                    maxLength={11}
                    value={transformDisplayCnf(valueCnf)}
                    placeholder="Confirm SSN / ITIN"
                  />
                  <input
                    className="value1 input-field"
                    style={{
                      position: "absolute",
                      top: 0,
                      background: "transparent",
                      color: "transparent",
                      border: "none",
                      visibility: "hidden",
                    }}
                    value={ssnValueCnf}
                    maxLength={9}
                    readOnly
                    disabled
                    type="hidden"
                  />
                </div>
              </div>
              {formik.touched.ssnConfirmNumber &&
                formik.errors.ssnConfirmNumber ? (
                <div className="error">{formik.errors.ssnConfirmNumber}</div>
              ) : null}
            </div>
          </div>
          <div className="col-12 mt-2 mb-0">
            <span>Identity Verification</span>
          </div>
          <div className="col-md-12">
            <div className="main_form_box d-flex align-items-center mt-2">
              <svg
                width="20"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 0H3C2.45 0 2 0.45 2 1C2 1.55 2.45 2 3 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0ZM3 24H17C17.55 24 18 23.55 18 23C18 22.45 17.55 22 17 22H3C2.45 22 2 22.45 2 23C2 23.55 2.45 24 3 24ZM18 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4ZM10 6.75C11.24 6.75 12.25 7.76 12.25 9C12.25 10.24 11.24 11.25 10 11.25C8.76 11.25 7.75 10.24 7.75 9C7.75 7.76 8.76 6.75 10 6.75ZM15 17H5V15.5C5 13.83 8.33 13 10 13C11.67 13 15 13.83 15 15.5V17Z"
                  fill="#42474E"
                />
              </svg>

              <div className="form-group d-flex justify-content-between">
                <label
                  htmlFor="drivingLicenseFrontShow"
                  className="cursor-pointer w-100"
                >
                  {formik.values.drivingLicenseFrontShow
                    ? formik.values.drivingLicenseFrontShow.length > 25
                      ? formik.values.drivingLicenseFrontShow.substr(0, 25) +
                      "..."
                      : formik.values.drivingLicenseFrontShow
                    : "Driver's License Front"}
                </label>
                <div style={{ position: "relative" }}>
                  <svg
                    width="26"
                    height="32"
                    viewBox="0 0 26 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={handleSvgFrontClick}
                  >
                    <path
                      d="M16.1666 0.166504H3.49992C1.75825 0.166504 0.349086 1.5915 0.349086 3.33317L0.333252 28.6665C0.333252 30.4082 1.74242 31.8332 3.48408 31.8332H22.4999C24.2416 31.8332 25.6666 30.4082 25.6666 28.6665V9.6665L16.1666 0.166504ZM22.4999 28.6665H3.49992V3.33317H14.5833V11.2498H22.4999V28.6665ZM6.66658 20.7657L8.89909 22.9982L11.4166 20.4965V27.0832H14.5833V20.4965L17.1008 23.014L19.3333 20.7657L13.0158 14.4165L6.66658 20.7657Z"
                      fill="#42474E"
                    />
                  </svg>
                  <input
                    type="file"
                    id="drivingLicenseFrontShow"
                    name="drivingLicenseFrontShow"
                    placeholder="Upload File"
                    onChange={drivingLicenseFront}
                    style={{ display: "none" }}
                    ref={fileFrontRef}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="main_form_box d-flex align-items-center mt-2">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM17 14H3C2.45 14 2 13.55 2 13V8H18V13C18 13.55 17.55 14 17 14ZM18 4H2V2H18V4Z"
                  fill="#42474E"
                />
              </svg>

              <div className="form-group d-flex justify-content-between">
                <label
                  htmlFor="drivingLicenseBackShow"
                  className="cursor-pointer"
                >
                  {formik.values.drivingLicenseBackShow
                    ? formik.values.drivingLicenseBackShow.length > 25
                      ? formik.values.drivingLicenseBackShow.substr(0, 25) +
                      "..."
                      : formik.values.drivingLicenseBackShow
                    : "Driver's License  Back"}
                </label>
                <div style={{ position: "relative" }}>
                  <svg
                    width="26"
                    height="32"
                    viewBox="0 0 26 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={handleSvgBackClick}
                  >
                    <path
                      d="M16.1666 0.166504H3.49992C1.75825 0.166504 0.349086 1.5915 0.349086 3.33317L0.333252 28.6665C0.333252 30.4082 1.74242 31.8332 3.48408 31.8332H22.4999C24.2416 31.8332 25.6666 30.4082 25.6666 28.6665V9.6665L16.1666 0.166504ZM22.4999 28.6665H3.49992V3.33317H14.5833V11.2498H22.4999V28.6665ZM6.66658 20.7657L8.89909 22.9982L11.4166 20.4965V27.0832H14.5833V20.4965L17.1008 23.014L19.3333 20.7657L13.0158 14.4165L6.66658 20.7657Z"
                      fill="#42474E"
                    />
                  </svg>
                  <input
                    type="file"

                    id="drivingLicenseBackShow"
                    name="drivingLicenseBackShow"
                    placeholder="Upload File"
                    onChange={drivingLicenseBack}
                    style={{ display: "none" }}
                    ref={fileBackRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1 btn-div d-flex justify-content-between align-items-center">
          <div type="submit" className="SiteBtn2" onClick={handleBack}>
            Back
          </div>
          <Button type="submit" className="btn-thm SiteBtn" text="Continue" onClick={handleSubmit} />
        </div>
      </Form>
      <ModalCustom size="ml" handleClose="grantorModal" show={showPopup}>
        <ConfirmSpousePopup setSuccessorPopup={setShowPopup} />
      </ModalCustom>

    </>
  );
};

export default SpouseSignUp2;
