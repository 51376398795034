import React, { useEffect, useState } from "react";
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ModalCustom from "../../../Common/ModalCustom";
import Invitation from "./Invitation";
import _ from "lodash";
import { AddTrustsName } from "../../../../redux/features/TrustsService";
import Loader from "../../../Common/Loader";
import { GetGrantorListTrust } from "../../../../redux/features/GrantorService";

const validate = (values) => {
  let errors = {};
  if (!values?.trustName) {
    errors.trustName = "Trust Name field is required";
  }
  return errors;
};

const AddTrustsForm = ({
  children,
  setAddEditData,
  isAddEditData,
  setTrustId,
  id,
  trustName,
  author,
}) => {
  const { getSingleTrustDetail } = useSelector((state) => state.trusts);
  const { getUserDetail } = useSelector((state) => state.auths);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTrustHolder, setTrustHolder] = useState([]);
  const dispatch = useDispatch();
  const { getGrantorListTrust } = useSelector((state) => state.grantors);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      trustName: isAddEditData?.trustName,
      authorId: isAddEditData?.authorId,
      trustId: id,
    };
    const res = await dispatch(AddTrustsName(data));
    if (res?.type === "trusts/AddTrustsName/fulfilled") {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(GetGrantorListTrust());
  }, [])

  const handleHolderChange = (value) => {
    setAddEditData({ ...isAddEditData, authorId: value?._id });
  };

  const authorInfo = {
    _id: getSingleTrustDetail?.holderInfo?._id || "",
    firstName: getSingleTrustDetail?.holderInfo?.firstName || "",
    lastName: getSingleTrustDetail?.holderInfo?.lastName || "",
  };

  useEffect(() => {
    if (getUserDetail) {
      const mergedOptions = [
        authorInfo || "",
        getUserDetail || "",
        ...(getSingleTrustDetail?.successorList || []),
        ...(getSingleTrustDetail?.grantorList || []),
      ].reduce((acc, option) => {
        if (!_.find(acc, { _id: option?._id })) {
          acc.push(option);
        }
        return acc;
      }, []);
      setTrustHolder(mergedOptions);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserDetail, getSingleTrustDetail]);



// console.log(getGrantorListTrust);
  const GetGrantorData = async () => {
    // debugger;
    if([1,3,5].includes(parseInt(getUserDetail?.role)) || ((getUserDetail?.role === 2 || getUserDetail?.role === 4) && getUserDetail?.parentInfo?.graceDurationWillEndAt > Date.now()) || (getUserDetail?.role === 2 || getUserDetail?.role === 4) && getUserDetail?.graceDurationWillEndAt > Date.now()){
      const data = await {
        trustName: isAddEditData?.trustName,
        authorId: getUserDetail?._id,
      };
      // console.log(data);

      const res = await dispatch(AddTrustsName(data));
      if (res?.type === "trusts/AddTrustsName/fulfilled") {
          if(getGrantorListTrust?.length) {
            setAddEditData((prevData) => ({
              ...prevData,
              grantorData: getGrantorListTrust,
            }))
          } else {
            setAddEditData((prevData) => ({
              ...prevData,
              grantorData: [],
            }))
          }

        setTrustId(res?.payload?.data?._id)
        localStorage.setItem('trust-id-grantor-flow', res?.payload?.data?._id)
        setLoading(false);
        if(!getGrantorListTrust){
          // console.log('no data');
        }else{
          localStorage.setItem('grantor-data', JSON.stringify(getGrantorListTrust));
        }
      }else{
        // console.log('api issue');
      }
    }else{
      // console.log('else');
    }
  }
  // console.log(isAddEditData, 'GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG')

  const handleSetTrustName = (e) => {
    setAddEditData({ ...isAddEditData, ...{trustName: e.target.value} })
  }

  return (
    <>
      {loading && <Loader />}
      <div className="">
        <div className="d-flex align-items-center justify-content-between py-3 flex-wrap">
          {!id && (
            <div className="">
              <Formik
                initialValues={{
                  trustName: isAddEditData?.trustName ?? "",
                }}
                validate={validate}
                onSubmit={async (values) => {
                  setLoading(true)
                 await setAddEditData({ ...isAddEditData, ...values });
                 setLoading(false)

                }}
              >
                {({ values, handleSubmit, errors, touched, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <FieldArray
                      name="trustName"
                      render={(arrayHelpers) => (
                        <>
                          <div className="d-flex align-items-center ">
                            <div className="edit_Sec">
                              <div className="form-group mb-1">
                                <label htmlFor="trustName" className="mb-0">
                                  Trust Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control trustnameinput"
                                  id="trustName"
                                  name="trustName"
                                  placeholder="Enter Trust Name"
                                  onChange={(e) => {handleChange(e);handleSetTrustName(e)}}
                                  value={values?.trustName}
                                />
                              </div>
                            </div>
                            <div className="edit_btnn mt-4 mx-4">
                              <button type="submit" onClick={GetGrantorData} className="btn btn_edit">
                                Save
                              </button>
                            </div>
                          </div>
                          {errors?.trustName && touched?.trustName && (
                            <span className="error">{errors?.trustName}</span>
                          )}
                        </>
                      )}
                    />
                  </form>
                )}
              </Formik>
            </div>
          )}
          {id ? (
            <>
              <div className="col-xl-4 col-md-6 col-12 mt-md-0 mt-4">
                <div className="row justify-content-end align-items-center">
                  <div className="col-md-8">
                    <div className="right_top_ststus d-fle">
                      <div className="form-group">
                        <label htmlFor="trust_holder">
                          Current Trust Holder
                        </label>

                        <Select
                          selected
                          options={isTrustHolder}
                          getOptionValue={(option) => option._id}
                          getOptionLabel={(option) =>
                            `${option?.firstName} ${option?.lastName ?? ""}`
                          }
                          onChange={handleHolderChange}
                          value={_.find(
                            isTrustHolder,
                            (option) => option?._id === isAddEditData?.authorId
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="btn_sec pt-1">
                      <button
                        type="button"
                        disabled={getSingleTrustDetail?.isEdit === 0}
                        className="btn add_trust"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFormSubmit(e);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            !id &&
            getUserDetail && (
              <div className="col-xl-4 col-md-6 col-12 mt-md-0 mt-4">
                <div className="row justify-content-end align-items-center">
                  <div className="col-md-8">
                    <div className="right_top_ststus d-fle">
                      <div className="form-group">
                        <label htmlFor="trust_holder">
                          Current Trust Holder
                        </label>
                        <Select
                          getOptionValue={(option) => option._id}
                          getOptionLabel={(option) =>
                            `${option?.firstName} ${option?.lastName ?? ""}`
                          }
                          options={[getUserDetail]}
                          onChange={handleHolderChange}
                          value={
                            getUserDetail && String(getUserDetail._id)
                              ? getUserDetail
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <ModalCustom size="xl" handleClose="grantorModal" show={showDialog}>
        <Invitation
          setShowInvitation={setShowDialog}
          setAddEditData={setAddEditData}
          trustName={trustName}
          id={id}
          isAddEditData={isAddEditData}
        />
      </ModalCustom>
      {children}
    </>
  );
};

export default AddTrustsForm;
