import axios from "axios";
import { getAuthenticationHeader, getHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

export const UploadMedia = createAsyncThunk(
  "commons/UploadMedia",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/common/common_file_upload`, args, {
        headers: getAuthenticationHeader("", "multipart/form-data"),
        mode: "cors",
      });

      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UserSetPassword = createAsyncThunk(
  "commons/UserSetPassword",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = args;
      const resp = await axios.post(`${url}/user/set_password`, data, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const CommonSlice = createSlice({
  name: "commons",
  initialState: {
    error: false,
    loading: false,
    UploadMedia: null,
  },
  reducers: {
    setCommonPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UploadMedia.pending, (state) => {
        state.loading = true;
      })
      .addCase(UploadMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.UploadMedia = action.payload;
      })
      .addCase(UploadMedia.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCommonPage } = CommonSlice.actions;

export default CommonSlice.reducer;
