import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadMedia } from "../../../../../redux/features/CommonService";
import _ from "lodash";
import {
  AddDocumentsTrust,
  GetSingleTrustDetail,
} from "../../../../../redux/features/TrustsService";
import VerifyCertificates from "./verify-death-certificates";
import ModalCustom from "../../../../Common/ModalCustom";
import moment from "moment";
import Loader from "../../../../Common/Loader";
import { saveAs } from "file-saver";

const AddDocuments = ({ isDocuments, setDocuments, id, setAddDocumets, isAddEditData, getUserDetail, grantorList }) => {
  const dispatch = useDispatch();
  const { getSingleTrustDetail } = useSelector((state) => state.trusts);
  const [isDocTypeListData, setDocTypeListData] = useState([]);
  const [isMedia, setMedia] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trustTimestamps, setTrustTimestamps] = useState([]);
  const [willsTimestamps, setWillsTimestamps] = useState([]);
  const [advancedtamps, setadvancedTimestamps] = useState([]);
  const [hipaaNametamps, sethipaaNameTimestamps] = useState([]);
  const [powerOfNametamps, setpowerOfNameTimestamps] = useState([]);
  const [deathNametamps, setdeathNameTimestamps] = useState([]);
  const [miscellaneoustamps, setmiscellaneousTimestamps] = useState([]);
  const [isUpdalodDocImageName, setUpdalodDocImageName] = useState("");
  const [isVerifyButtonVisible, setIsVerifyButtonVisible] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const media_url = process.env.REACT_APP_MEDIA_URL;

  const [isNamefile, setNamefiles] = useState({
    trustName: "",
    willsName: "",
    advancedName: "",
    hipaaName: "",
    powerOfName: "",
    deathName: "",
    miscellaneousName: "",
  });

  const downloadImageFront = (imageUrl) => {
    let imgURL = media_url + "/" + imageUrl;
    saveAs(imgURL);
  };

  useEffect(() => {
    if (getSingleTrustDetail?.dcDocInfo?.length) {
      setDocTypeListData(getSingleTrustDetail?.dcDocInfo);
    } else {
      setDocTypeListData([]);
    }
  }, [getSingleTrustDetail?.dcDocInfo]);

  const handleUploadImage = async (textKey, number) => {
    if(isNotAllowToUploadFiles()) { return; }

    if (isMedia) {
      let data = isMedia;
      if (isMedia?.type === "image/svg+xml") {
        data = new Blob([isMedia], { type: "image/svg" });
        data.name = isMedia.name;
      }
      const formData = new FormData();
      formData.append("files", data, isMedia.name);
      formData.append("trustId", getSingleTrustDetail._id || id)
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "commons/UploadMedia/fulfilled") {
        if (textKey) {
          const uploadData = res.payload?.files[0]?.mediaName;
          const createdAt = res.payload.files[0]?.createdAt;
          if (id) {
            const requestData = {
              trustId: id,
              trustDoc: uploadData,
              docType: number,
            };
            try {
              setLoading(true);
              if (number === 6) {
                setDocTypeListData([
                  ...isDocTypeListData,
                  {
                    imgName: uploadData,
                    oldImgName: isMedia?.name,
                    createdAt: createdAt,
                  },
                ]);
                setSubmit(true)
                setUpdalodDocImageName(uploadData);
              } else {
                const res = await dispatch(AddDocumentsTrust(requestData));
                if (res?.type === "trusts/AddDocumentsTrust/fulfilled") {
                  setTimeout(async () => {
                    try {
                      await dispatch(GetSingleTrustDetail({ trustId: id }));
                    } catch (error) {
                      console.error(
                        "Error fetching updated trust details:",
                        error
                      );
                      setLoading(false);
                    } finally {
                      setLoading(false);
                    }
                  }, 1000);
                }
              }
            } catch (error) {
              console.error("Error adding documents to trust:", error);
              setLoading(false);
            } finally {
              setLoading(false);
            }
          } else {
            setDocuments((prevData) => ({
              ...prevData,
              [textKey]: Array.isArray(prevData && prevData[textKey])
                ? [...(prevData[textKey] || []), uploadData]
                : [uploadData],
            }));
            const forAddDocument = {
              docName: uploadData,
              docType: number,
            };
            setAddDocumets((prevData) => [...prevData, forAddDocument]);
            switch (textKey) {
              case "trustDoc":
                setTrustTimestamps((prevTimestamps) => [
                  ...prevTimestamps,
                  createdAt,
                ]);
                break;
              case "willsDoc":
                setWillsTimestamps((prevTimestamps) => [
                  ...prevTimestamps,
                  createdAt,
                ]);
                break;
              case "ahcdDoc":
                setadvancedTimestamps((prevTimestamps) => [
                  ...prevTimestamps,
                  createdAt,
                ]);
                break;
              case "hipaaDoc":
                sethipaaNameTimestamps((prevTimestamps) => [
                  ...prevTimestamps,
                  createdAt,
                ]);
                break;
              case "pfaDoc":
                setpowerOfNameTimestamps((prevTimestamps) => [
                  ...prevTimestamps,
                  createdAt,
                ]);
                break;
              case "dcDoc":
                setdeathNameTimestamps((prevTimestamps) => [
                  ...prevTimestamps,
                  createdAt,
                ]);
                break;
              case "miscellaneousDoc":
                setmiscellaneousTimestamps((prevTimestamps) => [
                  ...prevTimestamps,
                  createdAt,
                ]);
                break;
              default:
                break;
            }
          }
        }
        setNamefiles({
          trustName: "",
          willsName: "",
          advancedName: "",
          hipaaName: "",
          powerOfName: "",
          deathName: "",
          miscellaneousName: "",
        });
      }
    }
  };

  const handleRemoveImage = async (e, fileName, textKey, number) => {
    const removeData = {
      trustId: getSingleTrustDetail?._id,
      trustDoc: fileName,
      docType: number,
      isRemove: 1,
    };
    const successorResponse = await dispatch(AddDocumentsTrust(removeData));
    if (successorResponse?.type === "trusts/AddDocumentsTrust/fulfilled") {
      const filterData = isDocuments[textKey]?.filter(
        (data) => data !== fileName
      );
      setDocuments((prevData) => ({
        ...prevData,
        [textKey]: filterData,
      }));
      if (id || getSingleTrustDetail?.isDocument === 1) {
        setLoading(true);
        await dispatch(
          GetSingleTrustDetail({ trustId: getSingleTrustDetail?._id })
        ).finally(() => setLoading(false));
      }
      setNamefiles({
        trustName: "",
        willsName: "",
        advancedName: "",
        hipaaName: "",
        powerOfName: "",
        deathName: "",
        miscellaneousName: "",
      });
    }
  };

  function removeRandomNumber(filename) {
    const pattern = /_\d+(?=\.\w+)/;
    const modifiedFilename = filename.replace(pattern, "");
    return modifiedFilename;
  }

  const isNotAllowToUploadFiles = () => {
    if (!getUserDetail || !grantorList) return true;
    const currentDate = Date.now();

    if (
      (getUserDetail.role !== 3) ||
      (getUserDetail.role === 3 &&
      grantorList &&
      (
        (grantorList[0] && grantorList[0].isInvite === 1 && grantorList[0].graceDurationWillEndAt >= currentDate) ||
        (grantorList[1] && grantorList[1].isInvite === 1 && grantorList[1].graceDurationWillEndAt >= currentDate)
      ))
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      {loading && <Loader />}
      <div className="mian_part_sec">
        <div className="title_acordian">
          <h5>{id ? "Document Log" : "Add Document(s)"}</h5>
        </div>
        <div id="accordion" className="mt-3">
          <div className="docCard">
            <div className="docCard-header p-0" id="headingZero">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseZero"
                  aria-expanded="false"
                  aria-controls="collapseZero"
                >
                  Trusts
                </button>
              </h5>
            </div>
            <div
              id="collapseZero"
              className="collapse"
              aria-labelledby="headingZero"
              data-bs-parent="accordion"
            >
              <div className="docCard-body">
                <form>
                  <div className="mian_check_box_sec col-xl-6 px-1">
                    {id
                      ? _.map(
                          getSingleTrustDetail?.trustDocInfo,
                          (trustMediaDetail, trustMediaIndex) => (
                            <label
                              className="container"
                              key={`${trustMediaIndex}-trusts`}
                            >
                              <p className="mb-0">
                                {moment(trustMediaDetail?.createdAt).format(
                                  "LL @ h:mm "
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {trustMediaDetail?.imgName && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(
                                          trustMediaDetail.imgName
                                        );
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {trustMediaDetail?.oldImgName}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          trustMediaDetail?.imgName,
                                          "trustDoc",
                                          1
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>
                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )
                      : _.map(
                          isDocuments?.trustDoc,
                          (mediaDetail, mediaIndex) => (
                            <label
                              className="container"
                            >
                              <p className="mb-0">
                                {moment(trustTimestamps[mediaIndex]).format(
                                  "LL @ h:mm "
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {mediaDetail && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(mediaDetail);
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {removeRandomNumber(mediaDetail)}
                                    </button>
                                  )}

                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          mediaDetail,
                                          "trustDoc",
                                          1
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>

                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )}
                  </div>
                  <div className="submit d-flex align-items-center">
                    <div className={`upload_file upload_file_trust w-25 mt-1 ${isNotAllowToUploadFiles() && 'disabled-ele'}`}>
                      <div className="files-wr" data-count-files="1">
                        <div className="one-file d-flex align-items-center">
                          <label
                            htmlFor="trusts"
                            className="profile_section_label w-100 mb-0"
                          >
                            <span className="w-100">
                              {isNamefile?.trustName.length > 40
                                ? isNamefile?.trustName.slice(0, 33) + "..."
                                : isNamefile?.trustName
                                ? isNamefile?.trustName
                                : "Upload File(s)"}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="trusts"
                            value=""
                            name="trusts"
                            placeholder=""
                            onChange={async (e) => {
                              const file = e.currentTarget.files[0];
                              setMedia(file);
                              setNamefiles((prevNames) => ({
                                ...prevNames,
                                trustName: file?.name || "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn_submit">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadImage("trustDoc", 1);
                        }}
                        className={`${
                          isNamefile?.trustName ? "active" : "btn btn-primary"
                        } ${isNotAllowToUploadFiles() && 'disabled-ele'} btn btn-primary disb-btn`}
                        disabled={
                          !isNamefile?.trustName ||
                          getSingleTrustDetail?.isDocument === 0
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="docCard">
            <div className="docCard-header p-0" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Wills
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              className="collapse"
              aria-labelledby="headingOne"
              data-bs-parent="accordion"
            >
              <div className="docCard-body">
                <form>
                  <div className="mian_check_box_sec col-xl-6 px-1">
                    {id
                      ? _.map(
                          getSingleTrustDetail?.willsDocInfo,
                          (willMediaDetail, trustMediaIndex) => (
                            <label
                              className="container"
                              key={`${trustMediaIndex}-wills`}
                            >
                              <p className="mb-0">
                                {moment(willMediaDetail?.createdAt).format(
                                  "LL @ h:mm "
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {willMediaDetail?.imgName && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(
                                          willMediaDetail.imgName
                                        );
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {willMediaDetail?.oldImgName}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          willMediaDetail?.imgName,
                                          "willsDoc",
                                          2
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>

                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )
                      : _.map(
                          isDocuments?.willsDoc,
                          (mediaDetail, mediaIndex) => (
                            <label
                              className="container"
                              key={`${mediaIndex}-wills`}
                            >
                              <p className="mb-0">
                                {moment(willsTimestamps[mediaIndex]).format(
                                  "LL @ h:mm"
                                )}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {mediaDetail && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(mediaDetail);
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {removeRandomNumber(mediaDetail)}
                                    </button>
                                  )}

                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          mediaDetail,
                                          "willsDoc",
                                          2
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>
                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )}
                  </div>
                  <div className="submit d-flex align-items-center">
                    <div className={`upload_file upload_file_trust w-25 mt-1 ${isNotAllowToUploadFiles() && 'disabled-ele'}`}>
                      <div className="files-wr" data-count-files="1">
                        <div className="one-file d-flex align-items-center">
                          <label
                            htmlFor="wills"
                            className="profile_section_label w-100 mb-0"
                          >
                            <span className="w-100">
                              {isNamefile?.willsName.length > 40
                                ? isNamefile?.willsName.slice(0, 33) + "..."
                                : isNamefile?.willsName
                                ? isNamefile?.willsName
                                : "Upload File(s)"}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="wills"
                            value=""
                            name="wills"
                            placeholder=""
                            onChange={async (e) => {
                              const file = e.currentTarget.files[0];
                              setMedia(file);
                              setNamefiles((prevNames) => ({
                                ...prevNames,
                                willsName: file?.name || "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn_submit">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadImage("willsDoc", 2);
                        }}
                        className={`${
                          isNamefile?.willsName ? "active" : "btn btn-primary"
                        } ${isNotAllowToUploadFiles() && 'disabled-ele'} btn btn-primary disb-btn`}
                        disabled={
                          !isNamefile?.willsName ||
                          getSingleTrustDetail?.isDocument === 0
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="docCard">
            <div className="docCard-header p-0" id="headingTwo">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Advance Health Care Directive
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="accordion"
            >
              <div className="docCard-body">
                <form>
                  <div className="mian_check_box_sec col-xl-6 px-1">
                    {id
                      ? _.map(
                          getSingleTrustDetail?.ahcdDocInfo,
                          (ahcdMediaDetail, ahcdMediaIndex) => (
                            <label
                              className="container"
                              key={`${ahcdMediaIndex}-advance`}
                            >
                              <p className="mb-0">
                                {moment(ahcdMediaDetail?.createdAt).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {ahcdMediaDetail?.imgName && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(
                                          ahcdMediaDetail.imgName
                                        );
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {ahcdMediaDetail?.oldImgName}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          ahcdMediaDetail?.imgName,
                                          "ahcdDoc",
                                          3
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>
                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )
                      : _.map(
                          isDocuments?.ahcdDoc,
                          (mediaDetail, mediaIndex) => (
                            <label
                              className="container"
                              key={`${mediaIndex}-advance`}
                            >
                              <p className="mb-0">
                                {moment(advancedtamps[mediaIndex]).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {mediaDetail && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(mediaDetail);
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {removeRandomNumber(mediaDetail)}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          mediaDetail,
                                          "ahcdDoc",
                                          3
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>
                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )}
                  </div>
                  <div className="submit d-flex align-items-center">
                    <div className={`upload_file upload_file_trust w-25 mt-1 ${isNotAllowToUploadFiles() && 'disabled-ele'}`}>
                      <div className="files-wr" data-count-files="1">
                        <div className="one-file d-flex align-items-center">
                          <label
                            htmlFor="advanceHealth"
                            className="profile_section_label w-100 mb-0"
                          >
                            <span className="w-100">
                              {isNamefile?.advancedName.length > 40
                                ? isNamefile?.advancedName.slice(0, 33) + "..."
                                : isNamefile?.advancedName
                                ? isNamefile?.advancedName
                                : "Upload File(s)"}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="advanceHealth"
                            value=""
                            name="advanceHealth"
                            placeholder=""
                            onChange={async (e) => {
                              const file = e.currentTarget.files[0];
                              setMedia(file);
                              setNamefiles((prevNames) => ({
                                ...prevNames,
                                advancedName: file?.name || "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn_submit">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadImage("ahcdDoc", 3);
                        }}
                        className={`${
                          isNamefile?.advancedName && "active"
                        } ${isNotAllowToUploadFiles() && 'disabled-ele'} btn btn-primary disb-btn`}
                        disabled={
                          !isNamefile?.advancedName ||
                          getSingleTrustDetail?.isDocument === 0
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="docCard">
            <div className="docCard-header p-0" id="headingThree">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  HIPAA
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-bs-parent="accordion"
            >
              <div className="docCard-body">
                <form>
                  <div className="mian_check_box_sec col-xl-6 px-1">
                    {id
                      ? _.map(
                          getSingleTrustDetail?.hipaaDocInfo,
                          (hipaaMediaDetail, hipaaMediaIndex) => (
                            <label
                              className="container"
                              key={`${hipaaMediaIndex}-hipaa`}
                            >
                              <p className="mb-0">
                                {moment(hipaaMediaDetail?.createdAt).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {hipaaMediaDetail?.imgName && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(
                                          hipaaMediaDetail.imgName
                                        );
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {hipaaMediaDetail?.oldImgName}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          hipaaMediaDetail?.imgName,
                                          "hipaaDoc",
                                          4
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>
                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )
                      : _.map(
                          isDocuments?.hipaaDoc,
                          (mediaDetail, mediaIndex) => (
                            <label
                              className="container"
                              key={`${mediaIndex}-hipaa`}
                            >
                              <p className="mb-0">
                                {moment(hipaaNametamps[mediaIndex]).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {mediaDetail && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(mediaDetail);
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {removeRandomNumber(mediaDetail)}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          mediaDetail,
                                          "hipaaDoc",
                                          4
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>

                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )}
                  </div>
                  <div className="submit d-flex align-items-center">
                    <div className={`upload_file upload_file_trust w-25 mt-1 ${isNotAllowToUploadFiles() && 'disabled-ele'}`}>
                      <div className="files-wr" data-count-files="1">
                        <div className="one-file d-flex align-items-center">
                          <label
                            htmlFor="HIPAA"
                            className="profile_section_label w-100 mb-0"
                          >
                            <span className="w-100">
                              {isNamefile?.hipaaName.length > 40
                                ? isNamefile?.hipaaName.slice(0, 33) + "..."
                                : isNamefile?.hipaaName
                                ? isNamefile?.hipaaName
                                : "Upload File(s)"}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="HIPAA"
                            value=""
                            name="HIPAA"
                            placeholder=""
                            onChange={async (e) => {
                              const file = e.currentTarget.files[0];
                              setMedia(file);
                              setNamefiles((prevNames) => ({
                                ...prevNames,
                                hipaaName: file?.name || "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn_submit">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadImage("hipaaDoc", 4);
                        }}
                        className={`${
                          isNamefile?.hipaaName && "active"
                        } ${isNotAllowToUploadFiles() && 'disabled-ele'} btn btn-primary disb-btn`}
                        disabled={
                          !isNamefile?.hipaaName ||
                          getSingleTrustDetail?.isDocument === 0
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="docCard">
            <div className="docCard-header p-0" id="headingFour">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Power of Attorney
                </button>
              </h5>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              aria-labelledby="headingFour"
              data-bs-parent="accordion"
            >
              <div className="docCard-body">
                <form>
                  <div className="mian_check_box_sec col-xl-6 px-1">
                    {id
                      ? _.map(
                          getSingleTrustDetail?.pfaDocInfo,
                          (pfaMediaDetail, pfaMediaIndex) => (
                            <label
                              className="container"
                              key={`${pfaMediaIndex}-power`}
                            >
                              <p className="mb-0">
                                {moment(pfaMediaDetail?.createdAt).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {pfaMediaDetail?.imgName && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(
                                          pfaMediaDetail.imgName
                                        );
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {pfaMediaDetail?.oldImgName}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          pfaMediaDetail?.imgName,
                                          "pfaDoc",
                                          5
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>
                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )
                      : _.map(
                          isDocuments?.pfaDoc,
                          (mediaDetail, mediaIndex) => (
                            <label
                              className="container"
                              key={`${mediaIndex}-power`}
                            >
                              <p className="mb-0">
                                {moment(powerOfNametamps[mediaIndex]).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>

                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {mediaDetail && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(mediaDetail);
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {removeRandomNumber(mediaDetail)}
                                    </button>
                                  )}

                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          mediaDetail,
                                          "pfaDoc",
                                          5
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>

                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )}
                  </div>
                  <div className="submit d-flex align-items-center">
                    <div className={`upload_file upload_file_trust w-25 mt-1 ${isNotAllowToUploadFiles() && 'disabled-ele'}`}>
                      <div className="files-wr" data-count-files="1">
                        <div className="one-file d-flex align-items-center">
                          <label
                            htmlFor="PowerofAttorney"
                            className="profile_section_label w-100 mb-0"
                          >
                            <span className="w-100">
                              {isNamefile?.powerOfName.length > 40
                                ? isNamefile?.powerOfName.slice(0, 33) + "..."
                                : isNamefile?.powerOfName
                                ? isNamefile?.powerOfName
                                : "Upload File(s)"}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="PowerofAttorney"
                            value=""
                            name="PowerofAttorney"
                            placeholder=""
                            onChange={async (e) => {
                              const file = e.currentTarget.files[0];
                              setMedia(file);
                              setNamefiles((prevNames) => ({
                                ...prevNames,
                                powerOfName: file?.name || "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn_submit">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadImage("pfaDoc", 5);
                        }}
                        className={`${
                          isNamefile?.powerOfName && "active"
                        } ${isNotAllowToUploadFiles() && 'disabled-ele'} btn btn-primary disb-btn`}
                        disabled={
                          !isNamefile?.powerOfName ||
                          getSingleTrustDetail?.isDocument === 0
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="docCard">
            <div className="docCard-header p-0" id="headingFive">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Death Certificate
                </button>
              </h5>
            </div>
            <div
              id="collapseFive"
              className="collapse"
              aria-labelledby="headingFive"
              data-bs-parent="accordion"
            >
              <div className="docCard-body">
                <form>
                  <div className="mian_check_box_sec col-xl-6 px-1">
                    {id
                      ? _.map(
                          isDocTypeListData,
                          (dcDocMediaDetail, dcDocMediaIndex) => (
                            <span
                              className="container"
                              key={`${dcDocMediaIndex}-death`}
                            >
                              <p className="mb-0">
                                {moment(dcDocMediaDetail?.createdAt).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="upload_box">
                                  <h5 className="mb-0">
                                    {dcDocMediaDetail?.imgName && (
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          downloadImageFront(
                                            dcDocMediaDetail.imgName
                                          );
                                        }}
                                        className="bg-transparent file-upload-text"
                                      >
                                        {dcDocMediaDetail?.oldImgName}
                                      </button>
                                    )}
                                    <span>
                                      <a
                                        href={`void(0)`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleRemoveImage(
                                            e,
                                            dcDocMediaDetail?.imgName,
                                            "dcDoc",
                                            6
                                          );
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                        >
                                          <path
                                            d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                            fill="#44464E"
                                          />
                                        </svg>
                                      </a>
                                    </span>
                                  </h5>
                                </div>
                                <div className="bttn_continue align-items-center">
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPopup(true);
                                    }}
                                    disabled={
                                      id
                                        ? !dcDocMediaDetail?._id
                                          ? false
                                          : true
                                        : true
                                    }
                                    className="btn btn_continue"
                                  >
                                    Verify
                                  </button>
                                  <div className="ml-3 certificate-success">
                                    {(
                                      id
                                        ? !dcDocMediaDetail?._id
                                          ? false
                                          : true
                                        : true
                                    )
                                      ? "success"
                                      : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="HighlightDot"></div>
                            </span>
                          )
                        )
                      : _.map(isDocuments?.dcDoc, (mediaDetail, mediaIndex) => (
                          <span
                            className="container"
                            key={`${mediaIndex}-death`}
                          >
                            <p className="mb-0">
                              {moment(deathNametamps[mediaIndex]).format(
                                "LL @ h:mm"
                              )}{" "}
                            </p>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {mediaDetail && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(mediaDetail);
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {removeRandomNumber(mediaDetail)}
                                    </button>
                                  )}

                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          mediaDetail,
                                          "dcDoc",
                                          6
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>
                              {isVerifyButtonVisible && (
                                <div className="bttn_continue">
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPopup(true);
                                    }}
                                    disabled
                                    className="btn btn_continue"
                                  >
                                    Verify
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="HighlightDot"></div>
                          </span>
                        ))}
                  </div>
                  <div className="submit d-flex align-items-center">
                    <div className={`upload_file upload_file_trust w-25 mt-1 ${isNotAllowToUploadFiles() && 'disabled-ele'}`}>
                      <div className="files-wr" data-count-files="1">
                        <div className="one-file d-flex align-items-center">
                          <label
                            htmlFor="DeathCertificate"
                            className="profile_section_label w-100 mb-0"
                          >
                            <span className="w-100">
                              {isNamefile?.deathName.length > 40
                                ? isNamefile?.deathName.slice(0, 33) + "..."
                                : isNamefile?.deathName
                                ? isNamefile?.deathName
                                : "Upload File(s)"}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="DeathCertificate"
                            value=""
                            name="DeathCertificate"
                            placeholder=""
                            onChange={async (e) => {
                              const file = e.currentTarget.files[0];
                              setMedia(file);
                              setNamefiles((prevNames) => ({
                                ...prevNames,
                                deathName: file?.name || "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="btn_submit">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadImage("dcDoc", 6);
                          setIsVerifyButtonVisible(true);
                        }}
                        className={`${
                          id && isNamefile?.deathName && "active"
                        } ${isNotAllowToUploadFiles() && 'disabled-ele'} btn btn-primary disb-btn`}
                        disabled={!id || !isNamefile?.deathName || isSubmit}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="docCard">
            <div className="docCard-header p-0" id="headingSix">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseMiscellanous"
                  aria-expanded="false"
                  aria-controls="collapseMiscellanous"
                >
                  Miscellaneous
                </button>
              </h5>
            </div>
            <div
              id="collapseMiscellanous"
              className="collapse"
              aria-labelledby="headingSix"
              data-bs-parent="accordion"
            >
              <div className="docCard-body">
                <form>
                  <div className="mian_check_box_sec col-xl-6 px-1">
                    {id
                      ? _.map(
                          getSingleTrustDetail?.miscellaneousDocInfo,
                          (miscMediaDetail, miscMediaIndex) => (
                            <label
                              className="container"
                              key={`${miscMediaIndex}-miscell`}
                            >
                              <p className="mb-0">
                                {moment(miscMediaDetail?.createdAt).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {miscMediaDetail?.imgName && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(
                                          miscMediaDetail.imgName
                                        );
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {miscMediaDetail?.oldImgName}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          miscMediaDetail?.imgName,
                                          "miscellaneousDoc",
                                          7
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>

                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )
                      : _.map(
                          isDocuments?.miscellaneousDoc,
                          (mediaDetail, mediaIndex) => (
                            <label
                              className="container"
                              key={`${mediaIndex}-miscell`}
                            >
                              <p className="mb-0">
                                {moment(miscellaneoustamps[mediaIndex]).format(
                                  "LL @ h:mm"
                                )}{" "}
                              </p>
                              <div className="upload_box">
                                <h5 className="mb-0">
                                  {mediaDetail && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadImageFront(mediaDetail);
                                      }}
                                      className="bg-transparent file-upload-text"
                                    >
                                      {removeRandomNumber(mediaDetail)}
                                    </button>
                                  )}
                                  <span>
                                    <a
                                      href={`void(0)`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveImage(
                                          e,
                                          mediaDetail,
                                          "miscellaneousDoc",
                                          7
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                                          fill="#44464E"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </h5>
                              </div>

                              <div className="HighlightDot"></div>
                            </label>
                          )
                        )}
                  </div>
                  <div className="submit d-flex align-items-center">
                    <div className={`upload_file upload_file_trust w-25 mt-1 ${isNotAllowToUploadFiles() && 'disabled-ele'}`}>
                      <div className="files-wr" data-count-files="1">
                        <div className="one-file d-flex align-items-center">
                          <label
                            htmlFor="Miscellaneous"
                            className="profile_section_label w-100 mb-0"
                          >
                            <span className="w-100">
                              {isNamefile?.miscellaneousName.length > 40
                                ? isNamefile?.miscellaneousName.slice(0, 33) +
                                  "..."
                                : isNamefile?.miscellaneousName
                                ? isNamefile?.miscellaneousName
                                : "Upload File(s)"}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="Miscellaneous"
                            value=""
                            name="Miscellaneous"
                            placeholder=""
                            onChange={async (e) => {
                              const file = e.currentTarget.files[0];
                              setMedia(file);
                              setNamefiles((prevNames) => ({
                                ...prevNames,
                                miscellaneousName: file?.name || "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn_submit">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadImage("miscellaneousDoc", 7);
                        }}
                        className={`${
                          isNamefile?.miscellaneousName && "active"
                        } ${isNotAllowToUploadFiles() && 'disabled-ele'} btn btn-primary disb-btn`}
                        disabled={
                          !isNamefile?.miscellaneousName ||
                          getSingleTrustDetail?.isDocument === 0
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCustom size="xl" handleClose="grantorModal" show={showPopup}>
        <VerifyCertificates
          setShowVerifyPopup={setShowPopup}
          id={id}
          setUpdalodDocImageName={setUpdalodDocImageName}
          isUpdalodDocImageName={isUpdalodDocImageName}
          isDocTypeListData={isDocTypeListData}
          type={6}
          isSubmit={setSubmit}
        />
      </ModalCustom>
    </>
  );
};

export default AddDocuments;
