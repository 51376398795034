import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserDetailsWithToken } from "../../redux/features/UsersService";
import { AuthContext } from "../../contexts/AuthContexts";
import Loader from "../Common/Loader";

const ThankYou = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("ntr-token");
  const [loading, setLoading] = useState(false);
  const { updateAuthStatus } = useContext(AuthContext);

  const handleClick = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const response = await dispatch(getUserDetailsWithToken()).unwrap();
      if (response?.data?.role === 2 && response?.data?.paymentStatus === 1) {
        const newToken = response?.data?.token;
        if (newToken !== token) {
          Cookies.set("ntr-token", newToken, { expires: 36400 });
          Cookies.set("ntr-verify-token", newToken, { expires: 36400 });
          updateAuthStatus(1);
        }
      } else {
        Cookies.remove("ntr-token");
        Cookies.remove("ntr-verify-token");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false); // Ensure loading state is reset
      navigate("/");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container vh-100 d-flex justify-content-center align-items-center">
        <div className="d-flex w-100 h-100 justify-content-center align-items-center rounded thank-you-main">
          <div>
            <div className="text-center fw-medium fs-4">Thank You!</div>
            <div className="py-4 fs-4">
                Your account has been successfully upgraded.
            </div>
            <div className="pt-3 text-center">
              <button className="SiteBtn" onClick={handleClick}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
