import Cookies from "js-cookie";
import React from "react";

const SuccessfullyReset = () => {
  const handleSignIn = () => {
    Cookies.remove("ntr-token");
    window.location.reload();
  };

  return (
    <div className="container d-flex vh-100 align-items-center justify-content-center">
      <div className="reset_password_success_modal">
        <p className="m-0 p-0 fw-medium fs-4 mb-3 px-5">
          Successful password reset!
        </p>
        <p className="text-center" style={{ color: "#44464E" }}>
          You can now use your new password
          <br />
          to sign in to your account.
        </p>
        <div className="pt-4 text-center">
          <button
            className="px-4 bg-primary text-white rounded-pill py-2 fw-medium"
            onClick={handleSignIn}
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessfullyReset;
