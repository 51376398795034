import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalCustom from "../components/Common/ModalCustom";
import { GetUserDetailsService } from "../redux/features/AuthService";
import SuccessorProfilePopup from "../components/Admin/SuccessorProfilePopup";

const Notice = () => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const { getUserDetail } = useSelector((state) => state.auths);

  const handleLogout = () => {
    Cookies.remove("ntr-token");
    Cookies.remove("ntr-verify-token");
    window.location.reload();
  };

  useEffect(() => {
    const cachedUserDetail = Cookies.get("user-detail");

    if (!getUserDetail && !cachedUserDetail) {
      dispatch(GetUserDetailsService()).then((response) => {
        if (response && !cachedUserDetail) {
          Cookies.set("user-detail", JSON.stringify(response));
        }
      });
    }
  }, [getUserDetail, dispatch]);

  return (
    <>
      <div className="container vh-100 d-flex justify-content-center align-items-center">
        <div className="d-flex w-100 h-100 justify-content-center align-items-center rounded thank-you-main">
          <div>
            <div className="text-center fw-medium fs-4">Alert!</div>
            <div className="p-4 fs-4">
              The grantor account has been deactivated. To manage your trusts, please upgrade your account.
            </div>
            <div className="pt-3 text-center">
              {getUserDetail?.role === 4 ? (
                <button
                  className="btn mr-2 upgrade-successor-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPopup(true);
                  }}
                >
                  Upgrade Account
                </button>
              ) : null}
              <button className="SiteBtn" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalCustom
        size="lg"
        handleClose="grantorModal"
        show={showPopup}
        className={"CongratulationsPopup"}
      >
        <SuccessorProfilePopup setSuccessorPopup={setShowPopup} />
      </ModalCustom>
    </>
  );
};

export default Notice;
