import axios from "axios";
import { getAuthenticationHeader, getHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";

const url = process.env.REACT_APP_API_URL;

export const GetActiveTrustsList = createAsyncThunk(
  "trusts/GetActiveTrustsList",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/trust/get_trust/`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const AddTrustsName = createAsyncThunk(
  "trusts/AddTrustsName",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/trust/set_trust`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const GetTrustsListing = createAsyncThunk(
  "trusts/GetTrustsListing",
  async (args, { rejectWithValue }) => {
    try {
      const { csv } = args;
      const resp = await axios.post(`${url}/trust/get_trust_list`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });
      return { ...resp.data, ...{ csv: csv } };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const AddTrustsData = createAsyncThunk(
  "trusts/AddTrustsData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_user`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const AddDocumentsTrust = createAsyncThunk(
  "trusts/AddDocumentsTrust",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/trust/set_trust_document`, args, {
        headers: getAuthenticationHeader(
          "",
          "application/x-www-form-urlencoded"
        ),
        mode: "cors",
      });

      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const AddNoteData = createAsyncThunk(
  "trusts/AddNoteData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/trust/set_note`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });

      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const AddVerifyCertificate = createAsyncThunk(
  "trusts/AddVerifyCertificate",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${url}/trust/verify_death_certificate`,
        args,
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );

      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const GetSingleTrustDetail = createAsyncThunk(
  "trusts/GetSingleTrustDetail",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/trust/get_trust_info`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const CheckMailService = createAsyncThunk(
  "trusts/CheckMailService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/check_email`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });

      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const AddInvitation = createAsyncThunk(
  "trusts/AddInvitation",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/trust/send_trust_invite`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });

      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const SetPermission = createAsyncThunk(
  "trusts/SetPermission",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/trust/set_trust_permission`, args, {
        headers: getAuthenticationHeader(
          "ntr-token",
          "application/x-www-form-urlencoded"
        ),
        mode: "cors",
      });

      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const AddAllDocumentsTrust = createAsyncThunk(
  "trusts/AddAllDocumentsTrust",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${url}/trust/set_multi_trust_document`,
        args,
        {
          headers: getAuthenticationHeader(
            "",
            "application/x-www-form-urlencoded"
          ),
          mode: "cors",
        }
      );

      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

const TrustsSlice = createSlice({
  name: "trusts",
  initialState: {
    error: false,
    loading: false,
    getActiveTrustsList: null,
    addTrustsDetail: null,
    addInvitation: null,
    setPermission: null,
    getTrustsListing: [],
    getSingleTrustDetail: null,
    getCsvData: [],
  },
  reducers: {
    setTrustsPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetActiveTrustsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetActiveTrustsList.fulfilled, (state, action) => {
        state.loading = false;
        state.getActiveTrustsList = action.payload;
      })
      .addCase(GetActiveTrustsList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddTrustsName.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddTrustsName.fulfilled, (state, action) => {
        state.loading = false;
        state.addTrustData = action.payload?.data;
      })
      .addCase(AddTrustsName.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetTrustsListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTrustsListing.fulfilled, (state, action) => {
        state.loading = false;
        if (!action?.payload?.csv) {
          state.getTrustsListing = action.payload;
          state.getCsvData = [];
        } else {
          state.getCsvData = action?.payload?.data;
        }
      })
      .addCase(GetTrustsListing.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddTrustsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddTrustsData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddTrustsData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddDocumentsTrust.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddDocumentsTrust.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddDocumentsTrust.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddInvitation.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddInvitation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddInvitation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(SetPermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(SetPermission.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(SetPermission.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddNoteData.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddNoteData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddNoteData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddVerifyCertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddVerifyCertificate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddVerifyCertificate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetSingleTrustDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSingleTrustDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleTrustDetail = action.payload?.data;
      })
      .addCase(GetSingleTrustDetail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(CheckMailService.pending, (state) => {
        state.loading = true;
      })
      .addCase(CheckMailService.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(CheckMailService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddAllDocumentsTrust.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddAllDocumentsTrust.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddAllDocumentsTrust.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setTrustsPage } = TrustsSlice.actions;

export default TrustsSlice.reducer;
