import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PaymentService } from "../../redux/features/AuthService";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import _ from "lodash";

const PaymentPlan = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [cardType, setCardType] = useState([0, 1]);

  const handleClick = () => {
    if (cardType[0] === 0) {
      setCardType([1, 0]);
    } else {
      setCardType([0, 1]);
    }
  };

  const handlePaymentCardEdit = (e) => {
    e.preventDefault();
    navigate("/payment", {
      state: {
        subsctiptionSelectedData: location?.state?.subscriptionData,
        formData: location?.state?.formData,
      },
    });
  };

  const handleClickToPayment = async () => {
    setLoading(true);
    try {
      const subscriData = location?.state?.subscriptionData;
      const data = {
        ...location?.state?.formData,
        ...{ planId: subscriData?._id },
      };

      const response = await dispatch(PaymentService(data));
      if (response.type === "payment/submitPayment/fulfilled") {
        toast.success(response?.payload?.message, { autoClose: 3000 });

        if (response?.payload?.data?.profileStatus === 3) {
          navigate("/thank-you", {
            state: response?.payload?.data?.profileStatus,
          });
        }
      } else {
        toast.error(response?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
      setLoading(false);
    } catch (error) {
      toast.error("Payment failed:" + error, {
        autoClose: 3000,
      });
    }
  };
  return (
    <div className="container-fluid ">
      {loading && <Loader />}
      <div className="row v-100">
        <div className="col-md-6 col-lg-6 col-xl-4 my-5 ml-auto">
          <div className="p-4 pt-5 mx-auto sign-up-card-container position-relative">
            <div className="d-flex justify-content-between align-items-center px-2 mb-3 pt-5">
              <div className="px-4 TitleTxt1">
                {location?.state?.subscriptionData?.title}
              </div>
              <div className="px-4">
                <span className="fw-bold fs-5">
                  ${location?.state?.subscriptionData?.price}
                </span>
                <br />
                <span className="fw-normal text-black fs-5">
                  /{location?.state?.subscriptionData?.subTitle}
                </span>
              </div>
            </div>
            {_.map(
              location?.state?.subscriptionData?.description,
              (descPlanDetail, descPlanIndex) => {
                return (
                  <div
                    className="d-flex gap-1 align-items-center mb-3"
                    key={`${descPlanIndex}-plan-selected`}
                  >
                    <div className="me-2">
                      <img
                        src="/assets/images/check.png"
                        alt="check"
                        width="20px"
                      />
                    </div>
                    <p className="mb-0 card-check-text">{descPlanDetail}</p>
                  </div>
                );
              }
            )}
            <div className="text-center pt-md-5 pb-md-4 change-plan-text w-100">
              <Link
                to={"/subscription"}
                className="fw-medium text-decoration-underline text-black fs-4 text-center"
              >
                Change Plan?
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1 my-5 mr-auto">
          <div className="p-4 sign-up-card-container">
            <div className="d-flex justify-content-between mb-5 pt-2">
              <div className="fs-4 text-black">Payment Plan</div>
              <div className="d-flex gap-2 card-type-container">
                <div className="p-1">
                  <button
                    onClick={handleClick}
                    className={
                      cardType[0] === 0
                        ? "fw-bold bg-white px-3"
                        : "fw-bold px-3"
                    }
                  >
                    Credit{" "}
                  </button>
                </div>
                <div className="p-1">
                  <button
                    onClick={handleClick}
                    className={
                      cardType[0] === 1
                        ? "fw-bold bg-white px-3"
                        : "fw-bold px-3"
                    }
                  >
                    Debit
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex mb-2 justify-content-between align-items-center pl-4">
              <div className="d-flex gap-3 align-items-center">
                <div className="d-flex justify-content-center align-items-center me-2 payment-card-circle">
                  <span className="rounded-circle bg-white"></span>
                </div>
                <div>
                  <span className="pe-2 fw-bold">****</span>
                  <span className="fw-bold fs-5">
                    {parseInt(
                      (location?.state?.formData?.cardNumber + "").slice(-4)
                    )}
                  </span>
                  <br />
                  <p className="fw-medium m-0">
                    <span className="pe-2">Visa card</span>
                    <span
                      onClick={(e) => handlePaymentCardEdit(e)}
                      className="text-decoration-underline text-black cursor-pointer"
                    >
                      Edit
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <img src="/assets/images/payment-methods.png" alt="Visa Card" />
              </div>
            </div>
            <div className="pt-3 TitleTxt2">Summary:</div>
            <div className="d-flex justify-content-between py-2 fw-normal fs-5">
              <p className="m-0 TitleTxt3">Amount : </p>
              <p className="m-0 TitleTxt3">
                $ {location?.state?.subscriptionData?.price}
              </p>
            </div>
            <div className="d-flex justify-content-between py-2 fw-normal fs-5">
              <p className="m-0 TitleTxt3">Tax :</p>
              <p className="m-0 TitleTxt3">$ 0.00</p>
            </div>
            <hr className="my-2" />
            <div className="d-flex justify-content-between pt-1 fw-normal fs-5">
              <p className="m-0 TitleTxt3">Amount to pay:</p>
              <p className="m-0 TitleTxt3">
                {" "}
                $ {location?.state?.subscriptionData?.price}
              </p>
            </div>
            <div className="text-center px-5 pt-4 mt-3">
              <button className="SiteBtn4 w-100" onClick={handleClickToPayment}>
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
