import { useFormik } from 'formik';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from "react-bootstrap";
import Button from "../Common/Button";
import { toast } from 'react-toastify';
import MainCenterBox from "../MainCenterBox";
import { SpouseSetPasswordVerify } from '../../redux/features/AuthService';

const validate = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = "*Password is required";
    } else if (values.password.length < 6 || values.password.length > 20) {
        errors.password = "*Password must be between 6 to 20 characters";
    } else if (!/(?=.*[a-z])/.test(values.password)) {
        errors.password = "*Password must contain at least one lowercase letter";
    } else if (!/(?=.*[A-Z])/.test(values.password)) {
        errors.password = "*Password must contain at least one uppercase letter";
    } else if (!/(?=.*\d)/.test(values.password)) {
        errors.password = "*Password must contain at least one digit";
    } else if (!/(?=.*[!@#$%&])/.test(values.password)) {
        errors.password = "*Password must contain at least one special character (@#$%&!)";
    }
    if (!values.cPassword) {
        errors.cPassword = "*Confirm Password is required";
    } else if (values.password !== values.cPassword) {
        errors.cPassword = "*Password and confirm Password do not match";
    }
    return errors;
};

const SetPasswordSpouse = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const id = params.id;
    const formik = useFormik({
        initialValues: {
            password: "",
            cPassword: "",
        },
        validate,
        onSubmit: async (values) => {
            const response = await dispatch(SpouseSetPasswordVerify({userId : id}));
            if (response?.payload?.status === 3) {
                console.log(response);
                toast.success(response?.payload?.message, {
                    autoClose: 3000,
                    });
                navigate('/spouse-verify');
                localStorage.setItem('spouse-id', id);
                localStorage.setItem('spouse-verify-pwd', values.password);

            } else {
                toast.success(response?.payload?.response?.data?.message, {
                    autoClose: 3000,
                });
            }
            // localStorage.setItem("userTitle", formik.values.firstName);
        },
    });

    const handleBack = () => {

    }

    return (
        <>
            <MainCenterBox
                title={"Create an Account"}
                className="col-xl-8 col-lg-10 col-md-12"
            >
                <p className="text-center">Peace of mind is a few easy clicks away.</p>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row">

                        <div className="col-12 mt-2 mb-0">
                            <span>Password Setup</span>
                        </div>
                        <div className="col-md-12">
                            <div className="main_form_box d-flex align-items-center mt-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M7 15.225C7.9 15.225 8.6625 14.9125 9.2875 14.2875C9.9125 13.6625 10.225 12.9 10.225 12C10.225 11.1 9.9125 10.3375 9.2875 9.7125C8.6625 9.0875 7.9 8.775 7 8.775C6.1 8.775 5.3375 9.0875 4.7125 9.7125C4.0875 10.3375 3.775 11.1 3.775 12C3.775 12.9 4.0875 13.6625 4.7125 14.2875C5.3375 14.9125 6.1 15.225 7 15.225ZM7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.41667 6 9.60417 6.3875 10.5625 7.1625C11.5208 7.9375 12.1917 8.925 12.575 10.125H20.875C20.975 10.125 21.0667 10.1417 21.15 10.175C21.2333 10.2083 21.3167 10.2667 21.4 10.35L22.475 11.425C22.5583 11.5083 22.6167 11.5917 22.65 11.675C22.6833 11.7583 22.7 11.85 22.7 11.95C22.7 12.05 22.6833 12.1417 22.65 12.225C22.6167 12.3083 22.5583 12.3917 22.475 12.475L19.875 15.075C19.7917 15.1583 19.7083 15.2167 19.625 15.25C19.5417 15.2833 19.45 15.3 19.35 15.3C19.25 15.3 19.1583 15.2833 19.075 15.25C18.9917 15.2167 18.9083 15.1583 18.825 15.075L17.25 13.5L15.675 15.075C15.5917 15.1583 15.5083 15.2167 15.425 15.25C15.3417 15.2833 15.25 15.3 15.15 15.3C15.05 15.3 14.9583 15.2833 14.875 15.25C14.7917 15.2167 14.7083 15.1583 14.625 15.075L13.375 13.825H12.575C12.2583 15.075 11.5875 16.0833 10.5625 16.85C9.5375 17.6167 8.35 18 7 18Z"
                                        fill="black"
                                    />
                                </svg>
                                <div className="form-group d-flex align-items-center">
                                    <input
                                        type={"password"}
                                        placeholder="Password"
                                        className="input-field"
                                        name="password"
                                        id="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                </div>
                            </div>

                            {formik.touched.password && formik.errors.password ? (
                                <div className="error">{formik.errors.password}</div>
                            ) : null}
                        </div>
                        <div className="col-md-12">
                            <div className="main_form_box d-flex align-items-center mt-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M7 15.225C7.9 15.225 8.6625 14.9125 9.2875 14.2875C9.9125 13.6625 10.225 12.9 10.225 12C10.225 11.1 9.9125 10.3375 9.2875 9.7125C8.6625 9.0875 7.9 8.775 7 8.775C6.1 8.775 5.3375 9.0875 4.7125 9.7125C4.0875 10.3375 3.775 11.1 3.775 12C3.775 12.9 4.0875 13.6625 4.7125 14.2875C5.3375 14.9125 6.1 15.225 7 15.225ZM7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.41667 6 9.60417 6.3875 10.5625 7.1625C11.5208 7.9375 12.1917 8.925 12.575 10.125H20.875C20.975 10.125 21.0667 10.1417 21.15 10.175C21.2333 10.2083 21.3167 10.2667 21.4 10.35L22.475 11.425C22.5583 11.5083 22.6167 11.5917 22.65 11.675C22.6833 11.7583 22.7 11.85 22.7 11.95C22.7 12.05 22.6833 12.1417 22.65 12.225C22.6167 12.3083 22.5583 12.3917 22.475 12.475L19.875 15.075C19.7917 15.1583 19.7083 15.2167 19.625 15.25C19.5417 15.2833 19.45 15.3 19.35 15.3C19.25 15.3 19.1583 15.2833 19.075 15.25C18.9917 15.2167 18.9083 15.1583 18.825 15.075L17.25 13.5L15.675 15.075C15.5917 15.1583 15.5083 15.2167 15.425 15.25C15.3417 15.2833 15.25 15.3 15.15 15.3C15.05 15.3 14.9583 15.2833 14.875 15.25C14.7917 15.2167 14.7083 15.1583 14.625 15.075L13.375 13.825H12.575C12.2583 15.075 11.5875 16.0833 10.5625 16.85C9.5375 17.6167 8.35 18 7 18Z"
                                        fill="black"
                                    />
                                </svg>
                                <div className="form-group d-flex align-items-center">
                                    <input
                                        type={"password"}
                                        placeholder="Confirm Password"
                                        className="input-field"
                                        name="cPassword"
                                        id="cPassword"
                                        onChange={formik.handleChange}
                                        value={formik.values.cPassword}
                                    />
                                </div>
                            </div>
                            {formik.touched.cPassword && formik.errors.cPassword ? (
                                <div className="error mt-1">{formik.errors.cPassword}</div>
                            ) : null}
                            <div className="pwdreqments mt-1">Password Criteria:</div>
                            <div className="pwdreqments ">At least 1 lowercase letter</div>
                            <div className="pwdreqments ">At least 1 UPPERCASE letter</div>
                            <div className="pwdreqments ">At least 1 number</div>
                            <div className="pwdreqments   ">At least 6 characters</div>
                        </div>
                    </div>

                    <div className="mt-1 btn-div d-flex  justify-content-end align-items-center">
                        <Button type="submit" className="btn-thm SiteBtn" text="Continue" />
                    </div>
                </Form>
            </MainCenterBox>
        </>
    )
}

export default SetPasswordSpouse
