import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UploadMedia } from "../../../redux/features/CommonService";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { saveAs } from "file-saver";
import {
  GetSingleGrantorService,
  UpdateSingleGrantor,
} from "../../../redux/features/GrantorService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Structure from "../../Common/Structure/Structure";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import SubscribedPlanDetails from "../SubscribedPlanDetails";
import Switch from "react-switch";

const SingleGrantor = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getUserDetail } = useSelector((state) => state.auths);
  const params = useParams();
  const userId = params.id;
  const { getSingleGrantor } = useSelector((state) => state.grantors);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ssnValue, setSsnValue] = useState("");
  const [value, setValue] = useState("");
  const [isSetRemoveSSNNumber, setRemoveSSNNumber] = useState(false);

  const media_url = process.env.REACT_APP_MEDIA_URL;

  const isNotAllowToEditStatus = useCallback(() => {
    const currentTime = Date.now();

    if(getSingleGrantor && !getSingleGrantor?.planExpiryDate && !getSingleGrantor?.graceDurationWillEndAt) {
      return false;
    } else {
      return getSingleGrantor && (
        parseInt(getSingleGrantor?.planExpiryDate) > currentTime &&
        parseInt(getSingleGrantor?.graceDurationWillEndAt) > currentTime
      )
    }
  }, [getSingleGrantor])

  const validate = (values) => {
    let errors = {};

    if (!values?.firstName) {
      errors.firstName = "This field is required";
    }
    if (!values?.lastName) {
      errors.lastName = "This field is required";
    }
    if (!values?.phoneNumber) {
      errors.phoneNumber = "This field is required";
    }
    if (!values?.email) {
      errors.email = "This field is required";
    }
    if (!values?.address1) {
      errors.address1 = "This field is required";
    }
    if (!values?.city) {
      errors.city = "This field is required";
    }
    if (!values?.dob) {
      errors.dob = "This field is required";
    }
    if (!values?.state) {
      errors.state = "This field is required";
    }
    if (!values?.zipCode) {
      errors.zipCode = "This field is required";
    }

    if (getUserDetail?.role !== 3) {
      if (!values.ssnNumber) {
        errors.ssnNumber = "SSN/ITIN Number is required";
      } else if (values.ssnNumber?.length < 9) {
        errors.ssnNumber = "SSN/ITIN number must be 9 digits long";
      }
    }

    return errors;
  };
  useEffect(() => {
    if (userId) {
      setLoading(true);
      dispatch(GetSingleGrantorService({ userId })).finally(() =>
        setLoading(false)
      );
    }

  }, [userId]);

  const handleUploadImage = async (file) => {
    if (file) {
      let data = file;
      if (file?.type === "image/svg+xml") {
        data = new Blob([file], { type: "image/svg" });
        data.name = file.name;
      }
      const formData = new FormData();
      formData.append("files", data, file.name);
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "commons/UploadMedia/fulfilled") {
        return res.payload?.files[0];
      }
    }
  };
  const maskify = (cc) => {
    if (cc && cc.length > 4) {
      const digits = cc.substr(cc.length - 4);
      return digits;
    } else {
      return cc;
    }
  };

  const downloadImageFront = (imageUrl) => {
    let imgURL = media_url + "/" + imageUrl;
    saveAs(imgURL, "drivingLicenseFront");
  };

  const downloadImageBack = (imageUrl) => {
    let imgURL = media_url + "/" + imageUrl;
    saveAs(imgURL, "drivingLicenseFront");
  };

  const handleClick = (e) => {
    e.preventDefault();
    setRemoveSSNNumber(false);
    setIsEdit(true);
  };

  const handleChangeStatus = async (status) => {
    if(isNotAllowToEditStatus()) { return; }

    setLoading(true);
    const data = { isStatus: status, userId: userId };
    const res = await dispatch(UpdateSingleGrantor({ data }));
    if (res?.type === "grantors/updateSingleGrantor/fulfilled") {
      setLoading(false);
      await dispatch(GetSingleGrantorService({ userId }));
    } else {
      setLoading(false);
    }
  };

  const handleChangeDeceased = async (isDeceased, isEditAble) => {
    if (!isEditAble) { return; }

    setLoading(true);
    const data = { isDeceased: isDeceased ? '1' : '0', userId: userId };
    const res = await dispatch(UpdateSingleGrantor({ data }));
    if (res?.type === "grantors/updateSingleGrantor/fulfilled") {
      setLoading(false);
      await dispatch(GetSingleGrantorService({ userId }));
    }
  };

  const transformDisplay = (val) => {
    let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3);
    }

    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6);
      const lastFour = displayVal.slice(7);
      displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour;
    } else {
      displayVal = displayVal.replace(/\d/g, "*");
    }

    return displayVal;
  };

  const replaceAt = (str, index, character) => {
    return (
      str.substr(0, index) + character + str.substr(index + character.length)
    );
  };
  const transformValue = (val, setFieldValue) => {
    let cleanVal = val.replace(/[^0-9|\\*]/g, "");
    cleanVal = cleanVal.substr(0, 9);
    let updatedValue = value ? value : "";

    for (let i = 0, l = cleanVal.length; i < l; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        updatedValue = replaceAt(updatedValue, i, cleanVal[i]);
      }
    }
    updatedValue = updatedValue.substr(0, cleanVal.length);
    setFieldValue("ssnNumber", updatedValue);
    setValue(updatedValue);
  };

  const syncInput = (event, setFieldValue) => {
    const val = event.target.value;
    if (isSetRemoveSSNNumber === false && getSingleGrantor?.ssnNumber) {
      setRemoveSSNNumber(true);
      setFieldValue("ssnValue", "");
      setFieldValue("ssnNumber", "");
      setSsnValue("");
      setValue("");
    } else {
      const displayVal = transformDisplay(val);
      setFieldValue("ssnValue", displayVal);
      transformValue(val, setFieldValue);
      event.target.setSelectionRange(val.length, val.length);
      setSsnValue(value);
    }
  };
  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Structure activeText="Grantor Users" data-sort-id="your-specific-value">
        <div className="w-100">
          <div className="title_bar d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  onClick={() => navigate(-1)}
                  className="cursor-pointer"
                >
                  <path
                    d="M26.6663 14.6667H10.4397L17.893 7.21334L15.9997 5.33334L5.33301 16L15.9997 26.6667L17.8797 24.7867L10.4397 17.3333H26.6663V14.6667Z"
                    fill="#1F1F1F"
                  />
                </svg>
              </div>
              <div>
                <h5>
                  Grantor Users / {`${getSingleGrantor?.firstName ?? ""}`}{" "}{`${getSingleGrantor?.lastName ?? ""}`}
                  {getSingleGrantor?.mainGrantor && ` (Spouse of: ${getSingleGrantor?.mainGrantor?.firstName} ${getSingleGrantor?.mainGrantor?.lastName})` }
                </h5>
                {isEdit ? (
                  <p className="mb-0 HeaderTitle2">Edit Details</p>
                ) : (
                  <p className="mb-0 HeaderTitle2">View Details</p>
                )}
              </div>
            </div>
          </div>
          {isEdit ? (
            <div className="conten-div">
              <div className="profile_main_part_section">
                <div className="btm_details_sec">
                  <div className="">
                    <h2 className="Title mt-1 mb-3">
                      {`${getSingleGrantor?.firstName ?? ""}`}{" "}
                      {`${getSingleGrantor?.lastName ?? ""}`}
                    </h2>
                    <div className="profile_main_sec_detail">
                      <Formik
                        initialValues={{
                          userId: getSingleGrantor?._id || "",

                          firstName: getSingleGrantor?.firstName || "",
                          lastName: getSingleGrantor?.lastName || "",
                          phoneNumber: getSingleGrantor?.phoneNumber || "",
                          email: getSingleGrantor?.email || "",
                          address1: getSingleGrantor?.address1 || "",
                          address2: getSingleGrantor?.address2 || "",
                          city: getSingleGrantor?.city || "",
                          state: getSingleGrantor?.state || "",
                          zipCode: getSingleGrantor?.zipCode || "",
                          dob: getSingleGrantor?.dob
                            ? getSingleGrantor.dob
                            : "",
                          drivingLicenseFront:
                            getSingleGrantor?.drivingLicenseFront || "",
                          drivingLicenseBack:
                            getSingleGrantor?.drivingLicenseBack || "",
                          drivingLicenseFrontShow:
                            getSingleGrantor?.drivingLicenseFrontInfo
                              ?.oldImgName || "",
                          drivingLicenseBackShow:
                            getSingleGrantor?.drivingLicenseBackInfo
                              ?.oldImgName || "",
                          ssnNumber: getSingleGrantor?.ssnNumber || "",
                          ssnValue: getSingleGrantor?.ssnNumber || "",
                        }}
                        enableReinitialize={true}
                        validate={validate}
                        onSubmit={async (values) => {
                          delete values.ssnValue;
                          const data = { ...values };
                          if (
                            getSingleGrantor?.ssnNumber === values?.ssnNumber
                          ) {
                            delete data.ssnNumber;
                          }

                          const res = await dispatch(
                            UpdateSingleGrantor({ data })
                          );
                          setRemoveSSNNumber(false);
                          if (
                            res?.type ===
                            "grantors/updateSingleGrantor/fulfilled"
                          ) {
                            if (res?.payload?.status === 0) {
                              toast.error(res?.payload?.message);
                              await dispatch(
                                GetSingleGrantorService({ userId })
                              );



                            } else {

                              await dispatch(
                                GetSingleGrantorService({ userId })
                              );
                              setRemoveSSNNumber(false);
                              setIsEdit(false);
                            }
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleChange,
                          handleBlur,
                        }) => (
                          <>
                            <Form>
                              <div className="profile_title_grid d-flex justify-content-between">
                                <div>
                                  <p className="m-0 p-0 fs-5 fw-medium">
                                    Details
                                  </p>
                                </div>
                                <div className="btn_sec_right color_change">
                                  <button className="profile_save_btn rounded-pill text-primary fw-medium">
                                    Save
                                  </button>
                                </div>
                              </div>
                              <div className="">
                                <div className="main_grid_top grid-container gap-0 pt-2">
                                  <div className="row m-0 w-100">
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="firstName"
                                            className="profile_section_label"
                                          >
                                            Fist Name
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control profile_main_part_user_detail_profile"
                                            name="firstName"
                                            id="firstName"
                                            value={values?.firstName || ""}
                                            placeholder="John"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      {errors.firstName && touched.firstName ? (
                                        <div className="error">
                                          {errors.firstName}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="lastName"
                                            className="profile_section_label"
                                          >
                                            Last Name
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control profile_main_part_user_detail_profile"
                                            name="lastName"
                                            id="lastName"
                                            value={values?.lastName || ""}
                                            placeholder="Smith"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      {errors.lastName && touched.lastName ? (
                                        <div className="error">
                                          {errors?.lastName}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-3 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="phoneNumber"
                                            className="profile_section_label"
                                          >
                                            Phone Number
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control"
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            value={values?.phoneNumber || ""}
                                            placeholder="+1 (123) 123-1234"
                                            onChange={handleChange}
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </div>
                                      {errors.phoneNumber &&
                                        touched.phoneNumber ? (
                                        <div className="error">
                                          {errors.phoneNumber}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-3 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="email"
                                            className="profile_section_label"
                                          >
                                            Email Address
                                          </label>
                                          <Field
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            value={values?.email}
                                            placeholder="Enter Email"
                                            disabled

                                          />
                                        </div>
                                      </div>
                                      {errors.email && touched.email ? (
                                        <div className="error">
                                          {errors.email}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="exampleInputPassword1"
                                            className="profile_section_label"
                                          >
                                            Drivers License - Front
                                          </label>
                                          <div
                                            className="files-wr file_sub_group"
                                            data-count-files="1"
                                          >
                                            <div className="one-file d-flex align-items-center">
                                              <label
                                                htmlFor="drivingLicenseFrontShow"
                                                className="profile_section_label"
                                              >
                                                {values?.drivingLicenseFrontShow
                                                  ? values
                                                    ?.drivingLicenseFrontShow
                                                    .length > 9
                                                    ? values.drivingLicenseFrontShow.substring(
                                                      0,
                                                      9
                                                    ) + "..."
                                                    : values.drivingLicenseFrontShow
                                                  : "Upload File"}
                                              </label>
                                              <Field
                                                type="file"
                                                className="form-control"
                                                id="drivingLicenseFrontShow"
                                                name="drivingLicenseFrontShow"
                                                placeholder="Upload File"
                                                value={""}
                                                onChange={async (e) => {
                                                  try {
                                                    const file =
                                                      e.currentTarget.files[0];
                                                    const uploadData =
                                                      await handleUploadImage(
                                                        file
                                                      );

                                                    setFieldValue(
                                                      "drivingLicenseFront",
                                                      uploadData?.mediaName
                                                    );
                                                    setFieldValue(
                                                      "drivingLicenseFrontShow",
                                                      file?.name
                                                    );
                                                  } catch (error) { }
                                                }}
                                              />
                                              <div className="file-item hide-btn"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {errors.drivingLicenseFrontShow &&
                                        touched.drivingLicenseFrontShow ? (
                                        <p className="error">
                                          {errors.drivingLicenseFrontShow}
                                        </p>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="exampleInputPassword1"
                                            className="profile_section_label"
                                          >
                                            Drivers License - Back
                                          </label>
                                          <div
                                            className="files-wr file_sub_group"
                                            data-count-files="1"
                                          >
                                            <div className="one-file d-flex align-items-center">
                                              <label
                                                htmlFor="drivingLicenseBackShow"
                                                className="profile_section_label"
                                              >
                                                {values?.drivingLicenseBackShow
                                                  ? values
                                                    ?.drivingLicenseBackShow
                                                    .length > 9
                                                    ? values.drivingLicenseBackShow.substring(
                                                      0,
                                                      9
                                                    ) + "..."
                                                    : values.drivingLicenseBackShow
                                                  : "Upload File"}
                                              </label>
                                              <input
                                                type="file"
                                                className="form-control"
                                                id="drivingLicenseBackShow"
                                                name="drivingLicenseBackShow"
                                                placeholder="Upload File"
                                                onChange={async (e) => {
                                                  try {
                                                    const file =
                                                      e.currentTarget.files[0];
                                                    const uploadData =
                                                      await handleUploadImage(
                                                        file
                                                      );
                                                    setFieldValue(
                                                      "drivingLicenseBack",
                                                      uploadData?.mediaName
                                                    );
                                                    setFieldValue(
                                                      "drivingLicenseBackShow",
                                                      file?.name
                                                    );
                                                  } catch (error) { }
                                                }}
                                                onBlur={handleBlur}
                                              />
                                              <div className="file-item hide-btn"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {errors.drivingLicenseBackShow &&
                                        touched.drivingLicenseBackShow ? (
                                        <div className="error">
                                          {errors.drivingLicenseBackShow}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="address1"
                                            className="profile_section_label"
                                          >
                                            Address Line 1
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control"
                                            id="address1"
                                            value={values?.address1 || ""}
                                            name="address1"
                                            placeholder="Enter Your Address Line 1"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      {errors.address1 && touched.address1 ? (
                                        <div className="error">
                                          {errors.address1}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-3 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="address2"
                                            className="profile_section_label"
                                          >
                                            Address Line 2
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control"
                                            id="address2"
                                            name="address2"
                                            placeholder="Enter Your Address Line 2"
                                            onChange={handleChange}
                                            value={values?.address2 || ""}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-3 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="city"
                                            className="profile_section_label"
                                          >
                                            City
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control"
                                            id="city"
                                            name="city"
                                            placeholder="Enter Your City"
                                            onChange={handleChange}
                                            value={values?.city || ""}
                                          />
                                        </div>
                                      </div>
                                      {errors.city && touched.city ? (
                                        <div className="error">
                                          {errors.city}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="state"
                                            className="profile_section_label"
                                          >
                                            State
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            value={values?.state || ""}
                                            name="state"
                                            placeholder="Enter Your State"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      {errors.state && touched.state ? (
                                        <div className="error">
                                          {errors.state}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="zipCode"
                                            className="profile_section_label"
                                          >
                                            Zip Code
                                          </label>
                                          <div>
                                            <Field
                                              type="text"
                                              name="zipCode"
                                              id="zipCode"
                                              placeholder="Enter Your Zip Code"
                                              value={values?.zipCode || ""}
                                              className="form-control"
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {errors.zipCode && touched.zipCode ? (
                                        <div className="error">
                                          {errors.zipCode}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                      <div>
                                        <div className="main_group1 form-group">
                                          <label
                                            htmlFor="zipCode"
                                            className="profile_section_label"
                                          >
                                            Date Of Birth
                                          </label>
                                          <div>
                                            <DatePicker
                                              selected={values?.dob}

                                              onChange={(date) =>
                                                setFieldValue("dob", date)
                                              }
                                              className="form-control"
                                              placeholderText="Date Of Birth"
                                              showYearDropdown
                                              scrollableYearDropdown
                                              showMonthDropdown
                                              useShortMonthInDropdown
                                              dateFormatCalendar="MMMM"
                                              yearDropdownItemNumber={1000}
                                              maxDate={new Date()}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {errors.dob && touched.dob ? (
                                        <div className="error">
                                          {errors.dob}
                                        </div>
                                      ) : null}
                                    </div>
                                    {getUserDetail?.role === 3 ? (
                                      <></>
                                    ) : (
                                      <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                        <div>
                                          <div className="main_group1 form-group">
                                            <label
                                              htmlFor="ssnNumber"
                                              className="profile_section_label"
                                            >
                                              SSN / ITIN
                                            </label>
                                            <div>
                                              <input
                                                className="form-control border-0 ps-0"
                                                onChange={(e) => {
                                                  syncInput(e, setFieldValue);
                                                }}
                                                value={
                                                  getSingleGrantor?.ssnNumber
                                                    ? isSetRemoveSSNNumber ===
                                                      false
                                                      ? values?.ssnValue
                                                        ? values?.ssnValue
                                                        : transformDisplay(
                                                          value
                                                        )
                                                      : transformDisplay(value)
                                                    : transformDisplay(value)
                                                }
                                                maxLength={11}
                                                placeholder="Enter SSN/ITIN Number"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {errors.ssnNumber &&
                                          touched.ssnNumber ? (
                                          <div className="error">
                                            {errors.ssnNumber}
                                          </div>
                                        ) : null}
                                        <input
                                          className="form-control border-0 ps-0"
                                          style={{

                                            top: 0,
                                            background: "transparent",
                                            color: "transparent",
                                            border: "none",
                                            visibility: "hidden",
                                          }}
                                          value={ssnValue}
                                          maxLength={9}
                                          readOnly
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </div>
                    <div className="d-flex">
                      <div className="StatusPart">
                        <p className="Title3">Status</p>
                        <div className={`d-flex ${isNotAllowToEditStatus() && 'not-allowed'}`}>
                          <div
                            className={`profile_tab_link fw-medium ${isNotAllowToEditStatus() && 'not-allowed'} ${parseInt(getSingleGrantor?.status) === 1
                              ? "active"
                              : "active0"
                              }`}
                            onClick={() => {
                              handleChangeStatus("1");
                            }}
                          >
                            Active
                          </div>
                          <div
                            className={`profile_tab_link fw-medium ${isNotAllowToEditStatus() && 'not-allowed'} ${parseInt(getSingleGrantor?.status) === 1
                              ? "Inactive"
                              : "Inactive0"
                              }`}
                            onClick={() => {
                              handleChangeStatus("0");
                            }}
                          >
                            Inactive
                          </div>
                        </div>
                      </div>

                      <div className="StatusPart ml-3">
                        <p className="Title3">Mark as deceased</p>
                        <Switch
                          onChange={(checked) => handleChangeDeceased(checked, true)}
                          checked={parseInt(getSingleGrantor?.deceased) === 1}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onColor="#00639B"
                          offColor="#DEE3EB"
                          height={29}
                          width={70}
                          onHandleColor="#FFFFFF"
                          offHandleColor="#DEE3EB"
                          handleDiameter={22}
                          activeBoxShadow="null"
                          className={`${parseInt(getSingleGrantor?.deceased) === 1 ? "" : "inactive-border"}`}
                          uncheckedHandleIcon={
                            <div
                              style={{
                                width: "16px",
                                height: "16px",
                                background: "#72777F",
                                borderRadius: "50px",
                                margin: "3.5px 3px 3px 3px",
                              }}
                            ></div>
                          }
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="conten-div">
              <div className="profile_main_part_section">
                <div className="btm_details_sec">
                  <div className="">
                    <h2 className="Title mt-1 mb-3">
                      {`${getSingleGrantor?.firstName ?? ""}`}{" "}
                      {`${getSingleGrantor?.lastName ?? ""}`}
                    </h2>
                  </div>

                  <div className="profile_main_sec_detail">
                    <div className="profile_title_grid d-flex justify-content-between">
                      <div>
                        <p className="m-0 p-0 fs-5 fw-medium">Details</p>
                      </div>
                      <div className="btn_sec_right color_change">
                        <button
                          className="profile_edit_btn rounded-pill text-white"
                          onClick={handleClick}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <div className="main_grid_top grid-container gap-0 pt-2">
                        <div className="row m-0 w-100">
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="firstName"
                                className="profile_section_label"
                              >
                                First Name
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.firstName}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="lastName"
                                className="profile_section_label"
                              >
                                Last Name
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.lastName}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="phoneNumber"
                                className="profile_section_label"
                              >
                                Phone Number
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.phoneNumber}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className="profile_section_label"
                              >
                                Email Address
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.email}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="profile_section_label"
                              >
                                Drivers License - Front
                              </label>
                              <div className="files-wr" data-count-files="1">
                                <button
                                  onClick={
                                    getSingleGrantor?.drivingLicenseFront ===
                                      undefined
                                      ? () => { }
                                      : () =>
                                        downloadImageFront(
                                          getSingleGrantor?.drivingLicenseFront
                                        )
                                  }
                                  className="bg-white"
                                >
                                  <div className="one-file d-flex align-items-center">
                                    <div className="d-flex">
                                      {getSingleGrantor?.drivingLicenseFront ===
                                        undefined ||
                                        getSingleGrantor?.drivingLicenseFront ===
                                        "" ? (
                                        <>{""}</>
                                      ) : (
                                        <>
                                          <p className="profile_section_label see-attachment-text">
                                            See Attachment
                                          </p>
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M17.8019 15.1533V18.0196H6.33666V15.1533H4.42578V18.0196C4.42578 19.0706 5.28567 19.9305 6.33666 19.9305H17.8019C18.8529 19.9305 19.7128 19.0706 19.7128 18.0196V15.1533H17.8019ZM16.8465 11.3316L15.4993 9.98439L13.0247 12.4494V4.64349H11.1138V12.4494L8.63926 9.98439L7.29209 11.3316L12.0693 16.1087L16.8465 11.3316Z"
                                              fill="#3F76C8"
                                            />
                                          </svg>
                                        </>
                                      )}
                                    </div>
                                    <div className="file-item hide-btn"></div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="profile_section_label"
                              >
                                Drivers License - Back
                              </label>
                              <div className="files-wr" data-count-files="1">
                                <button
                                  onClick={
                                    getSingleGrantor?.drivingLicenseBack ===
                                      undefined
                                      ? () => { }
                                      : () =>
                                        downloadImageBack(
                                          getSingleGrantor?.drivingLicenseBack
                                        )
                                  }
                                  className="bg-white"
                                >
                                  <div className="one-file d-flex align-items-center">
                                    <div className="d-flex">
                                      {getSingleGrantor?.drivingLicenseBack ===
                                        undefined ||
                                        getSingleGrantor?.drivingLicenseBack ===
                                        "" ? (
                                        <>{""}</>
                                      ) : (
                                        <>
                                          <p className="profile_section_label see-attachment-text">
                                            See Attachment
                                          </p>
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M17.8019 15.1533V18.0196H6.33666V15.1533H4.42578V18.0196C4.42578 19.0706 5.28567 19.9305 6.33666 19.9305H17.8019C18.8529 19.9305 19.7128 19.0706 19.7128 18.0196V15.1533H17.8019ZM16.8465 11.3316L15.4993 9.98439L13.0247 12.4494V4.64349H11.1138V12.4494L8.63926 9.98439L7.29209 11.3316L12.0693 16.1087L16.8465 11.3316Z"
                                              fill="#3F76C8"
                                            />
                                          </svg>
                                        </>
                                      )}
                                    </div>
                                    <input
                                      name="drivingLicenseBack"
                                      id="drivingLicenseBack"
                                    />
                                    <div className="file-item hide-btn"></div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="address1"
                                className="profile_section_label"
                              >
                                Address Line 1
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.address1}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="address2"
                                className="profile_section_label"
                              >
                                Address Line 2
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.address2}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="city"
                                className="profile_section_label"
                              >
                                City
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.city}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="state"
                                className="profile_section_label"
                              >
                                State
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.state}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="zipCode"
                                className="profile_section_label"
                              >
                                Zip Code
                              </label>
                              <p className="profile_main_part_user_detail_profile">
                                {getSingleGrantor?.zipCode}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                            <div className="form-group">
                              <label
                                htmlFor="dob"
                                className="profile_section_label"
                              >
                                Date Of Birth
                              </label>
                              <p>
                                {getSingleGrantor?.dob
                                  ? moment(getSingleGrantor.dob).format(
                                    "MM/DD/YYYY"
                                  )
                                  : ""}
                              </p>
                            </div>
                          </div>

                          {getUserDetail?.role === 3 ? (
                            <></>
                          ) : (
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="ssnNumber"
                                  className="profile_section_label"
                                >
                                  SSN / ITIN
                                </label>
                                <p className="profile_main_part_user_detail_profile">
                                  {`${getSingleGrantor?.ssnNumber
                                    ? "***-**-" +
                                    maskify(getSingleGrantor?.ssnNumber)
                                    : ""
                                    }`}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="StatusPart =">
                      <p className="Title3">Status</p>
                      <div className="d-flex">
                        <div
                          className={`profile_tab_link fw-medium ${parseInt(getSingleGrantor?.status) === 1
                            ? "active"
                            : "active0"
                            }`}
                        >
                          Active
                        </div>
                        <div
                          className={`profile_tab_link fw-medium ${parseInt(getSingleGrantor?.status) === 1
                            ? "Inactive"
                            : "Inactive0"
                            }`}
                        >
                          Inactive
                        </div>
                      </div>
                    </div>

                    <div className="StatusPart ml-3">
                      <p className="Title3">Mark as deceased</p>
                      <Switch
                        onChange={(checked) => handleChangeDeceased(checked, false)}
                        checked={parseInt(getSingleGrantor?.deceased) === 1}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#00639B"
                        offColor="#DEE3EB"
                        height={29}
                        width={70}
                        onHandleColor="#FFFFFF"
                        offHandleColor="#DEE3EB"
                        handleDiameter={22}
                        activeBoxShadow="null"
                        className={`${parseInt(getSingleGrantor?.deceased) === 1 ? "" : "inactive-border"}`}
                        uncheckedHandleIcon={
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              background: "#72777F",
                              borderRadius: "50px",
                              margin: "3.5px 3px 3px 3px",
                            }}
                          ></div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {([1,5].includes(getUserDetail?.role)) ? (
            <SubscribedPlanDetails userId={userId} isAdminUser={true} />
          ): null }
        </div>
      </Structure>
    </>
  );
};

export default SingleGrantor;
