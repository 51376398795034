import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";

const url = process.env.REACT_APP_API_URL;
export const AddAdminService = createAsyncThunk(
  "admin/AddAdminService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_common_user`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const GetAdminListing = createAsyncThunk(
  "admin/GetAdminListing",
  async (args, { rejectWithValue }) => {
    try {
      const { role, status, search, csv } = args;
      const resp = await axios.post(
        `${url}/user/get_user_list_common`,
        {
          role: role,
          status: status,
          search: search,
        },
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );
      return { ...resp.data, ...{ csv: csv } };
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const UpdateAdminDetail = createAsyncThunk(
  "admin/UpdateAdminDetail",
  async (args, { rejectWithValue }) => {
    try {
      const { values } = args;
      const resp = await axios.post(`${url}/user/common_user_update`, values, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const GetSingleAdminDetail = createAsyncThunk(
  "admin/GetSingleAdminDetail",
  async (args, { rejectWithValue }) => {
    try {
      const { userId } = args;
      const resp = await axios.post(
        `${url}/user/get_user_info`,
        {
          userId: userId,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const UpdateAdminDetailStatus = createAsyncThunk(
  "admin/UpdateAdminDetailStatus",
  async (args, { rejectWithValue }) => {
    try {
      const { userId, isStatus } = args;
      const resp = await axios.post(
        `${url}/user/common_user_update`,
        {
          userId: userId,
          isStatus: isStatus,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    error: false,
    loading: false,
    addAdmin: null,
    updateAdmin: null,
    getAdminListing: [],
    getSingleAdminDetail: null,
  },
  reducers: {
    setAdminPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddAdminService.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddAdminService.fulfilled, (state, action) => {
        state.loading = false;
        state.addAdmin = action?.payload?.data;
      })
      .addCase(AddAdminService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetAdminListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAdminListing.fulfilled, (state, action) => {
        state.loading = false;
        if (!action?.payload?.csv) {
          state.getAdminListing = action?.payload;
        }
      })
      .addCase(GetAdminListing.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateAdminDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateAdminDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleAdminDetail = action?.payload?.data;
      })
      .addCase(UpdateAdminDetail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateAdminDetailStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateAdminDetailStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleAdminDetail = action?.payload?.data;
      })
      .addCase(UpdateAdminDetailStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetSingleAdminDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSingleAdminDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleAdminDetail = action.payload?.data;
      })
      .addCase(GetSingleAdminDetail.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setAdminPage: setAuthPage } = AdminSlice.actions;

export default AdminSlice.reducer;
