import axios from "axios";
import {
  getAuthenticationHeader,
  getHeader,
  getVerifyTokenHeader,
} from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_API_URL;

export const SignInService = createAsyncThunk(
  "auths/SignInService",
  async (args, { rejectWithValue }) => {
    try {

      const resp = await axios.post(`${url}/sign-in`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SignUpService = createAsyncThunk(
  "auths/SignUpService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/sign-up`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SignUpServiceSpouse = createAsyncThunk(
  "auths/SignUpServiceSpouse",
  async (args, { rejectWithValue }) => {
    try {
      const token = "ntr-verify-token";
      const resp = await axios.post(`${url}/user/set_spouse`, args, {
        headers: getAuthenticationHeader(
          token ? token : Cookies.get("ntr-token")
        ),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const LoginVerificationService = createAsyncThunk(
  "auths/LoginVerificationService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/verify`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const LoginVerificationOtpService = createAsyncThunk(
  "auths/LoginVerificationOtpService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/sign_in_verify`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SpouseSetPasswordVerify = createAsyncThunk(
  "auths/SpouseSetPasswordVerify",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/send_password_otp`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SetPasswordSpouseUser = createAsyncThunk(
  "auths/SetPasswordSpouseUser",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_spouse_password`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ResetPasswordLinkService = createAsyncThunk(
  "auths/ResetPasswordLinkService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/send-password-reset-link`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ResetPasswordService = createAsyncThunk(
  "auths/ResetPasswordService",
  async (args, { rejectWithValue }) => {
    try {
      const { data, token } = args;
      const resp = await axios.post(`${url}/password-reset/${token}`, data, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const AdminResetPasswordService = createAsyncThunk(
  "auths/AdminResetPasswordService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/change_password`, args, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SignUpAccount = createAsyncThunk(
  "auths/SignUpAccount",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_account_type`, args, {
        headers: getAuthenticationHeader("ntr-verify-token"),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SignUpPlan = createAsyncThunk(
  "auths/SignUpPlan",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/set_account_type`, args, {
        headers: getAuthenticationHeader("ntr-verify-token"),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SkipPayment = createAsyncThunk(
  "auths/SkipPayment",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/skip_payment`, args, {
        headers: getAuthenticationHeader("ntr-verify-token"),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


export const GetAllPlans = createAsyncThunk(
  "payment/GetAllPlans",
  async (_, { rejectWithValue }) => {
    try {
      const token = "ntr-verify-token";
      const resp = await axios.get(`${url}/payment/get_all_plan`, {
        headers: getAuthenticationHeader(
          token ? token : Cookies.get("ntr-token")
        ),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const CreateCheckoutSession = createAsyncThunk(
  "payment/CreateCheckoutSession",
  async (planId, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${url}/payment/create_checkout_session`,
        { planId: planId },
        {
          headers: getAuthenticationHeader("ntr-verify-token"),
          mode: "cors",
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const PaymentService = createAsyncThunk(
  "payment/submitPayment",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${url}/payment/create_checkout_session`,
        args,
        {
          headers: getAuthenticationHeader("ntr-verify-token"),
          mode: "cors",
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const LogoutService = createAsyncThunk(
  "auths/LogoutService",
  async (_, { rejectWithValue }) => {
    try {

      const resp = await axios.post(
        `${url}/logout`,
        {},
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );

      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const CreateSupportService = createAsyncThunk(
  "auths/CreateSupportService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/common/create_support`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetAllTrustService = createAsyncThunk(
  "auths/GetAllTrustService",
  async (params, { rejectWithValue }) => {
    try {



      const resp = await axios.get(
        `${url}/trust/get_all_trust?filter=${params.filter}&page=${params.page}&limit=${params.limit}&search=${params.search}`,
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );


      return resp.data;
    } catch (err) {

      return rejectWithValue(err);
    }
  }
);

export const GetAllAttorneyService = createAsyncThunk(
  "auths/GetAllAttorneyService",
  async (params, { rejectWithValue }) => {
    try {

      const resp = await axios.get(
        `${url}/admin/get_all_attorney?filter=${params.filter}&page=${params.page}&limit=${params.limit}&search=${params.search}`,
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );


      return resp.data;
    } catch (err) {

      return rejectWithValue(err);
    }
  }
);

export const GetAllGrantorService = createAsyncThunk(
  "auths/GetAllGrantorService",
  async (args, { rejectWithValue }) => {
    try {

      const { filter, search, page, limit } = args;
      const resp = await axios.get(
        `${url}sdfsd/admin/get_all_grantorsdfsdf?filter=${filter}&search=${search}&page=${page}&limit=${limit}`,
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetTokenUserInfo = createAsyncThunk(
  "auths/GetTokenUserInfo",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/get_token_user_info`, args, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const SetUserInfo = createAsyncThunk(
  "auths/SetUserInfo",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_user_info`, args, {
        headers: getHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const PaymentPurchaseService = createAsyncThunk(
  "auths/PaymentPurchaseService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${url}/payment/purchase_stripe_plan`,
        args,
        {
          headers: getVerifyTokenHeader(),
          mode: "cors",
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ApplyCoupon = createAsyncThunk(
  "auths/ApplyCoupon",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${url}/payment/apply_stripe_coupon`,
        args,
        {
          headers: getVerifyTokenHeader(),
          mode: "cors",
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetUserDetailsService = createAsyncThunk(
  "auths/GetUserDetailsService",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/user/get_user_details`, {
        headers: getAuthenticationHeader(),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const UpdateUserDetail = createAsyncThunk(
  "user/UpdateUserDetail",
  async (args, { rejectWithValue }) => {
    try {
      const { id, data } = args;
      const resp = await axios.patch(
        `${url}/user/update_user_details/${id}`,
        data,
        {
          headers: getAuthenticationHeader("ntr-verify-token"),
          mode: "cors",
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const AuthSlice = createSlice({
  name: "auths",
  initialState: {
    error: false,
    loading: false,
    aboutUsData: null,
    mainGrantor: null,
    getAllPlansList: [],
    getLogoutDetails: [],
    getUserDetail: null,
    getTokenUserInfo: [],
    paymentServiceStripe: [],
    skipPayment:[],
    coupenDetails: {}, //
    successorGrantor: null
  },
  reducers: {
    setAuthPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignInService.pending, (state) => {
        state.loading = true;
      })
      .addCase(SignInService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(SignInService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(SetUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(SetUserInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(SetUserInfo.rejected, (state) => {
        state.loading = false;
      })

      .addCase(PaymentPurchaseService.pending, (state) => {
        state.loading = true;
      })
      .addCase(PaymentPurchaseService.fulfilled, (action, state) => {
        state.loading = false;
        state.paymentServiceStripe = action?.payload;
      })
      .addCase(PaymentPurchaseService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(ApplyCoupon.pending, (state) => {
        state.loading = true;
      })
      .addCase(ApplyCoupon.fulfilled, (action, state) => {
        state.loading = false;
        state.coupenDetails = action?.payload;
      })
      .addCase(ApplyCoupon.rejected, (state) => {
        state.loading = false;
      })
      .addCase(SkipPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(SkipPayment.fulfilled, (action, state) => {
        state.loading = false;
        state.skipPayment = action?.payload;
      })
      .addCase(SkipPayment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(LogoutService.pending, (state) => {
        state.loading = true;
      })
      .addCase(LogoutService.fulfilled, (state, action) => {
        state.loading = false;
        state.getLogoutDetails = action?.payload;
      })
      .addCase(LogoutService.rejected, (state) => {
        state.loading = false;
      })

      .addCase(LoginVerificationService.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginVerificationService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(LoginVerificationService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(LoginVerificationOtpService.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginVerificationOtpService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(LoginVerificationOtpService.rejected, (state) => {
        state.loading = false;
      })

      .addCase(ResetPasswordLinkService.pending, (state) => {
        state.loading = true;
      })
      .addCase(ResetPasswordLinkService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(ResetPasswordLinkService.rejected, (state) => {
        state.loading = false;
      })

      .addCase(GetAllPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllPlansList = action?.payload?.data;
      })
      .addCase(GetAllPlans.rejected, (state) => {
        state.loading = false;
      })

      .addCase(GetUserDetailsService.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetUserDetailsService.fulfilled, (state, action) => {
        state.loading = false;
        state.getUserDetail = action?.payload?.data;
        state.mainGrantor = action?.payload?.mainGrantor;
        state.successorGrantor = action?.payload?.successorGrantor;
      })
      .addCase(GetUserDetailsService.rejected, (state) => {
        state.loading = false;
      })

      .addCase(GetTokenUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTokenUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.getTokenUserInfo = action?.payload?.data;
      })
      .addCase(GetTokenUserInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateUserDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateUserDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getUserDetail = action?.payload?.data;
      })
      .addCase(UpdateUserDetail.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setAuthPage } = AuthSlice.actions;

export default AuthSlice.reducer;
