import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_API_URL;

export const getSubscriptionDetail = createAsyncThunk(
  "subscription/getSubscriptionDetail",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/payment/get_stripe_plan`, {
        params: args,
        headers: getAuthenticationHeader('ntr-token'),
        mode: "cors"
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/payment/cancel_stripe_plan`, args, {
        headers: getAuthenticationHeader('ntr-token'),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const getCurrentProduct = createAsyncThunk(
  "subscription/getCurrentProduct",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/payment/get_current_product`, args, {
        headers: getAuthenticationHeader('ntr-token'),
        mode: "cors",
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    error: false,
    loading: false,
    subscriptionData: null,
    successorGrantor: null,
    success: false,
  },
  reducers: {
    setSubscriptionPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionDetail.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(getSubscriptionDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.subscriptionData = action.payload.subscriptionData;
      })
      .addCase(getSubscriptionDetail.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.loading = false;
        state.subscriptionData = null;
        state.success = true;
        state.error = false;
      })
      .addCase(cancelSubscription.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(getCurrentProduct.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(getCurrentProduct.fulfilled, (action, state) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(getCurrentProduct.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { setSubscriptionPage } = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
