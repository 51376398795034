import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetUserDetailsService } from "../../../redux/features/AuthService";

const Header = ({ handleHideShowClass }) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  var userChar = userName?.charAt(0).toUpperCase();
  const userDetail = async () => {
    const response = await dispatch(GetUserDetailsService());
    setUserName(response?.payload?.data?.firstName);
    localStorage.setItem(
      "profileInfo",
      JSON.stringify(response?.payload?.data)
    );
  };

  useEffect(() => {
    userDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="main_navigation_Sec">
        <div className="container-fluid">
          <nav className="navbar navbar-light  justify-content-between">
            <a href="/" className="navbar-brand d-flex align-items-center">
              <img
                src="/assets/images/main_logo.png"
                className="img-fluid mr-2"
                alt="logo"
              />
              <h5 className="mt-2">The National Trust Registry</h5>
            </a>
            <a href="/profile">
              <div className="login_icon">
                <b>{userChar}</b>
              </div>
            </a>
          </nav>
        </div>
      </div>

      <div className="main_chat hide-left-chat">
        <div className="open_chat_uer_menu">
          <div><button id="chat-menu" onClick={(e) => { e.preventDefault(); handleHideShowClass(true) }}><i className="fa fa-align-left" aria-hidden="true"></i></button> </div>
        </div>
      </div>
    </>
  );
};

export default Header;


