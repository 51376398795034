import React, { useState } from "react";
import _ from "lodash";
import AddSuccessorPopup from "./add-successor-popup";
import ModalCustom from "../../../../Common/ModalCustom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddTrustsData,
  GetSingleTrustDetail,
} from "../../../../../redux/features/TrustsService";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { UploadMedia } from "../../../../../redux/features/CommonService";
import Loader from "../../../../Common/Loader";
import SuccessorInvitation from "../SuccessorInvitation";

const validate = (values) => {
  const errors = {};
  values.userList.forEach((error, index) => {
    if (!error.firstName) {
      errors[`userList.${index}.firstName`] = "First Name is required";
    }
    if (!error.lastName) {
      errors[`userList.${index}.lastName`] = "Last Name is required";
    }
    if (!error.phoneNumber) {
      errors[`userList.${index}.phoneNumber`] = "Phone number is required";
    }
    if (!error.email) {
      errors[`userList.${index}.email`] = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(error.email)) {
      errors[`userList.${index}.email`] = "Invalid email address";
    }
    if (!error.address1) {
      errors[`userList.${index}.address1`] = "Address is required";
    }
    if (!error.city) {
      errors[`userList.${index}.city`] = "City is required";
    }
    if (!error.state) {
      errors[`userList.${index}.state`] = "State is required";
    }
    if (!error.zipcode) {
      errors[`userList.${index}.zipcode`] = "Zipcode is required";
    }
  });
  return errors;
};

const AddSuccessor = ({
  isAddEditData,
  handleRemoveData,
  id,
  setAddEditData,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [displayCount, setDisplayCount] = useState(2);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [invitePopup, setInvitePopup] = useState(false);
  const [isEmail, setEmail] = useState("");
  const [isIndex, setIndex] = useState("");
  const dispatch = useDispatch();
  const { getSingleTrustDetail } = useSelector((state) => state.trusts);
  const { getUserDetail } = useSelector((state) => state.auths);
  const media_url = process.env.REACT_APP_MEDIA_URL;

  const handleSave = async () => {
    const successorData = {
      trustId: id,
      userList: isAddEditData?.successorData,
      role: 4,
    };
    try {
      const res = await dispatch(AddTrustsData(successorData));
      if (res?.type === "trusts/AddTrustsData/fulfilled") {
        setIsEdit(true);
        dispatch(GetSingleTrustDetail({ trustId: id }));
      } else if (res?.type === "trusts/AddTrustsData/rejected") {
        toast.error(res?.payload?.response?.data?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const enabled =
    isAddEditData?.trustName &&
    (isAddEditData?.successorData?.length === undefined ||
      isAddEditData.successorData?.length <= 14);

  const downloadImageFront = (imageUrl) => {
    let imgURL = media_url + "/" + imageUrl;
    saveAs(imgURL, "drivingLicenseFront");
  };

  const downloadImageBack = (imageUrl) => {
    let imgURL = media_url + "/" + imageUrl;
    saveAs(imgURL, "drivingLicenseBack");
  };

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
    setDisplayCount(2);
  };
  const sortedSuccessorData = _.sortBy(
    isAddEditData?.successorData,
    "position"
  );

  const handleUploadImage = async (file) => {
    if (file) {
      let data = file;
      if (file?.type === "image/svg+xml") {
        data = new Blob([file], { type: "image/svg" });
        data.name = file.name;
      }
      const formData = new FormData();
      formData.append("files", data, file.name);
      formData.append("trustId", id);
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "commons/UploadMedia/fulfilled") {
        return res.payload?.files[0];
      }
      return null;
    }
  };

  const isMarkAsDeceased = (getSingleTrustDetail?.holderInfo?.deceased === 1)

  return (
    <>
      {loading && <Loader />}
      <Formik
        initialValues={{
          userList: _.map(sortedSuccessorData, (value) => {
            return {
              userId: value?.userId,
              firstName: value?.firstName,
              lastName: value?.lastName,
              phoneNumber: value?.phoneNumber,
              email: value?.email,
              address1: value?.address1,
              address2: value?.address2,
              city: value?.city,
              state: value?.state,
              zipcode: value?.zipcode,
              drivingLicenseFront: value?.drivingLicenseFront,
              drivingLicenseBack: value?.drivingLicenseBack,
              drivingLicenseFrontInfo: value?.drivingLicenseFrontInfo,
              drivingLicenseBackInfo: value?.drivingLicenseBackInfo,
              drivingLicenseFrontShow: value?.drivingLicenseFrontShow,
              drivingLicenseBackShow: value?.drivingLicenseBackShow,
              position: value?.position,
              isInvite: value?.isInvite,
              deceased: value?.deceased
            };
          }),
        }}
        enableReinitialize={true}
        validate={validate}
        validateOnBlur={false}
        onSubmit={async (values) => {
          setAddEditData({ ...isAddEditData, successorData: values?.userList });
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          handleChange,
        }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className={`${isEdit && id && "aaaaaaa"} mian_part_sec`}>
                  <div className="col-xl-12 d-flex align-items-center title_row justify-content-between">
                    <p>
                      {id
                        ? isEdit
                          ? "Successor Trustee(s)"
                          : "Edit Successor(s) Details"
                        : "Add Successor Trustee(s)"}
                    </p>
                    <div className="bttn_continue mb-0">
                      <button
                        type="button"
                        className={`btn btn_continue mx-2
              ${!enabled ? "btn btn_continue" : "active"}`}
                        onClick={(e) => {
                          e.preventDefault();
                          isAddEditData?.trustName && setShowPopup(true);
                        }}
                        disabled={
                          !enabled || getSingleTrustDetail?.isEdit === 0
                        }

                      >
                        Add Successor
                      </button>
                      {id ? (
                        isEdit ? (
                          <button
                            type="submit"
                            className="btn btn_continue"
                            onClick={() => setIsEdit(false)}
                            disabled={
                              getUserDetail?.role === 4
                                ? false
                                : !(getSingleTrustDetail?.isEdit === 1)
                            }
                          >
                            Edit
                          </button>
                        ) : (
                          <div className="btn_back mt-3">
                            <button
                              type="submit"
                              className="btn"
                              onClick={() => handleSave()}
                            >
                              Save
                            </button>
                          </div>
                        )
                      ) : (
                        <button type="submit" className="btn btn_continue">
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    {_.map(
                      values?.userList?.slice(
                        0,
                        showAll ? undefined : displayCount
                      ),
                      (field, index) => {
                        return (
                          <div
                            className="top_grid_sec"
                            key={`${index}-trusts-grantors`}
                          >
                            <div className="title_grid d-flex align-items-baseline">
                              <p className={index === 0 ? "mr-5" : "mr-4 pt-3"}>
                                {index === 0
                                  ? "Primary"
                                  : index === 1
                                    ? "Secondary"
                                    : `Successor ${index + 1}`}
                              </p>
                              {(!isMarkAsDeceased) ? (field?.deceased == 0) && field?.isInvite === 0 && isEdit && id && (
                                <div className="btn_sec pt-2" key={index}>
                                  <button
                                    type="button"
                                    className="btn add_trust px-4"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      setEmail(field?.email);
                                      setIndex(index)
                                      setInvitePopup(true)
                                    }}
                                    disabled={
                                      getSingleTrustDetail?.isEdit === 0
                                    }
                                  >
                                    Invite
                                  </button>
                                </div>
                              ) : <></>}
                              <div className="position-relative">
                                <p
                                  className="cursor-pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveData(
                                      index,
                                      isAddEditData?.successorData,
                                      "successorData"
                                    );
                                  }}
                                >
                                  {!isMarkAsDeceased ? id ? (
                                    !isEdit && (
                                      <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.5 3.5741V4.5741H20.5V6.5741H19.5V19.5741C19.5 20.6741 18.6 21.5741 17.5 21.5741H7.5C6.4 21.5741 5.5 20.6741 5.5 19.5741V6.5741H4.5V4.5741H9.5V3.5741H15.5ZM7.5 19.5741H17.5V6.5741H7.5V19.5741ZM9.5 8.5741H11.5V17.5741H9.5V8.5741ZM15.5 8.5741H13.5V17.5741H15.5V8.5741Z"
                                          fill="#0059C7"
                                        />
                                      </svg>
                                    )
                                  ) : (
                                    <svg
                                      width="25"
                                      height="25"
                                      viewBox="0 0 25 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.5 3.5741V4.5741H20.5V6.5741H19.5V19.5741C19.5 20.6741 18.6 21.5741 17.5 21.5741H7.5C6.4 21.5741 5.5 20.6741 5.5 19.5741V6.5741H4.5V4.5741H9.5V3.5741H15.5ZM7.5 19.5741H17.5V6.5741H7.5V19.5741ZM9.5 8.5741H11.5V17.5741H9.5V8.5741ZM15.5 8.5741H13.5V17.5741H15.5V8.5741Z"
                                        fill="#0059C7"
                                      />
                                    </svg>
                                  ) : <></>}
                                  <p className="hover-message delete-content">
                                    Are you sure you want to delete this item? This action cannot be undone.
                                  </p>
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <div className="mian_grid_top grid-container edit_grid_sec">
                                <div className="row d-flex m-0 w-100">
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.firstName`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        First Name
                                      </label>
                                      <Field
                                        type="text"
                                        name={`userList.${index}.firstName`}
                                        value={field["firstName"]}

                                        id={`userList.${index}.firstName`}
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                      />
                                    </div>
                                    {errors[`userList.${index}.firstName`] && (
                                      <p className="error">
                                        {errors[`userList.${index}.firstName`]}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.lastName`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        Last Name
                                      </label>
                                      <Field
                                        type="text"
                                        name={`userList.${index}.lastName`}
                                        value={field["lastName"]}

                                        id={`userList.${index}.lastName`}
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                      />
                                    </div>
                                    {errors[`userList.${index}.lastName`] && (
                                      <p className="error">
                                        {errors[`userList.${index}.lastName`]}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.phoneNumber`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        Phone Number
                                      </label>
                                      <Field
                                        type="text"
                                        name={`userList.${index}.phoneNumber`}
                                        value={field["phoneNumber"]}

                                        id={`userList.${index}.phoneNumber`}
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                      />
                                    </div>
                                    {errors[
                                      `userList.${index}.phoneNumber`
                                    ] && (
                                        <p className="error">
                                          {
                                            errors[
                                            `userList.${index}.phoneNumber`
                                            ]
                                          }
                                        </p>
                                      )}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.email`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        Email Address
                                      </label>
                                      <Field
                                        type="email"
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                        id={`userList.${index}.email`}
                                        name={`userList.${index}.email`}
                                        value={field["email"]}

                                      />
                                    </div>
                                    {errors[`userList.${index}.email`] && (
                                      <p className="error">
                                        {errors[`userList.${index}.email`]}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    {id ? (
                                      <div
                                        className={`form-group ${isEdit && id ? "" : "not-allowed"
                                          }`}
                                      >
                                        <label
                                          htmlFor={`userList.${index}.drivingLicenseFrontInfo`}
                                          className={`profile_section_label ${isEdit && id ? "" : "not-allowed"
                                            }`}
                                        >
                                          Drivers License - Front
                                        </label>

                                        {!isEdit && (
                                          <>
                                            <div>
                                              {field?.drivingLicenseFrontInfo
                                                ? field?.drivingLicenseFrontInfo
                                                  ?.length > 20
                                                  ? field?.drivingLicenseFrontInfo.substr(
                                                    0,
                                                    20
                                                  ) + "..."
                                                  : field?.drivingLicenseFrontInfo
                                                : "Upload Attachment"}
                                            </div>
                                          </>
                                        )}

                                        {isEdit && (
                                          <div
                                            className="files-wr"
                                            data-count-files="1"
                                          >
                                            <button
                                              onClick={() =>
                                                field?.drivingLicenseFront
                                                  ? downloadImageFront(
                                                    field?.drivingLicenseFront
                                                  )
                                                  : () => { }
                                              }
                                              className={` ${isEdit && id
                                                ? "bg-white"
                                                : "file-upload-attche"
                                                }`}
                                            >
                                              <div className="one-file d-flex align-items-center">
                                                <div className="d-flex">
                                                  <p className="profile_section_label see-attachment-text">
                                                    {field?.drivingLicenseFront ===
                                                      undefined ||
                                                      field?.drivingLicenseFront ===
                                                      null ||
                                                      field?.drivingLicenseFront ===
                                                      ""
                                                      ? ""
                                                      : "See Attachment"}
                                                  </p>
                                                  {getUserDetail?.drivingLicenseFront ===
                                                    "" &&
                                                    getUserDetail?.drivingLicenseFront ===
                                                    undefined && (
                                                      <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M17.8019 15.1533V18.0196H6.33666V15.1533H4.42578V18.0196C4.42578 19.0706 5.28567 19.9305 6.33666 19.9305H17.8019C18.8529 19.9305 19.7128 19.0706 19.7128 18.0196V15.1533H17.8019ZM16.8465 11.3316L15.4993 9.98439L13.0247 12.4494V4.64349H11.1138V12.4494L8.63926 9.98439L7.29209 11.3316L12.0693 16.1087L16.8465 11.3316Z"
                                                          fill="#3F76C8"
                                                        />
                                                      </svg>
                                                    )}
                                                </div>
                                                <input
                                                  name={`userList.${index}.drivingLicenseFront`}
                                                  id={`userList.${index}.drivingLicenseFront`}
                                                  value={
                                                    field?.drivingLicenseFront
                                                  }
                                                  className={`${isEdit && id
                                                    ? ""
                                                    : "not-allowed"
                                                    }`}
                                                  readOnly
                                                />
                                                <div className="file-item hide-btn"></div>
                                              </div>
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="form-group">
                                        <label
                                          htmlFor="drivingLicenseFront"
                                          className="profile_section_label"
                                        >
                                          Drivers License - Front
                                        </label>
                                        <div
                                          className="files-wr"
                                          data-count-files="1"
                                        >
                                          <div className="pt-2">
                                            <label
                                              htmlFor={`userList.${index}.drivingLicenseFrontSuccessor`}
                                              className="bi bi-upload w-50 mb-0"
                                            //className="modal-media-upload-label w-100 mb-0"
                                            >
                                              {field?.drivingLicenseFrontInfo ||
                                                field?.drivingLicenseFrontShow
                                                ? field?.drivingLicenseFrontInfo ||
                                                  field?.drivingLicenseFrontShow
                                                    ?.length > 9
                                                  ? (
                                                    field?.drivingLicenseFrontInfo ||
                                                    field?.drivingLicenseFrontShow
                                                  ).substr(0, 9) + "..."
                                                  : field?.drivingLicenseFrontInfo ||
                                                  field?.drivingLicenseFrontShow
                                                : "Upload File"}
                                            </label>

                                            <Field
                                              type="file"
                                              className="form-control"
                                              id={`userList.${index}.drivingLicenseFrontSuccessor`}
                                              name={`userList.${index}.drivingLicenseFront`}
                                              placeholder="Upload File"
                                              value={""}
                                              onChange={async (e) => {
                                                try {
                                                  const file =
                                                    e.currentTarget.files[0];
                                                  const uploadData =
                                                    await handleUploadImage(
                                                      file
                                                    );
                                                  setFieldValue(
                                                    `userList.${index}.drivingLicenseFront`,
                                                    uploadData?.mediaName
                                                  );
                                                  setFieldValue(
                                                    `userList.${index}.drivingLicenseFrontInfo`,
                                                    file?.name
                                                  );
                                                } catch (error) { }
                                              }}
                                              readOnly={id}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    {id ? (
                                      <div
                                        className={`form-group ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        <label
                                          htmlFor={`userList.${index}.drivingLicenseBackInfo`}
                                          className={`profile_section_label  ${isEdit && id
                                            ? ""
                                            : "aa" && id && "not-allowed"
                                            }`}
                                        >
                                          Drivers License - Back
                                        </label>

                                        {!isEdit && (
                                          <>
                                            <div>
                                              {field?.drivingLicenseBackInfo
                                                ? field?.drivingLicenseBackInfo
                                                  ?.length > 20
                                                  ? field?.drivingLicenseBackInfo.substr(
                                                    0,
                                                    20
                                                  ) + "..."
                                                  : field?.drivingLicenseBackInfo
                                                : "Upload Attachment"}
                                            </div>
                                          </>
                                        )}

                                        {isEdit && (
                                          <div
                                            className="files-wr"
                                            data-count-files="1"
                                          >
                                            <button
                                              onClick={() =>
                                                field?.drivingLicenseBack
                                                  ? downloadImageBack(
                                                    field?.drivingLicenseBack
                                                  )
                                                  : () => { }
                                              }
                                              className={`form-control ${isEdit && id
                                                ? "bg-white"
                                                : "file-upload-attche"
                                                }`}
                                            >
                                              {field?.drivingLicenseBack && (
                                                <div className="one-file d-flex align-items-center">
                                                  <div className="d-flex">
                                                    {field?.drivingLicenseBack ===
                                                      undefined ||
                                                      field?.drivingLicenseBack ===
                                                      "" ||
                                                      field?.drivingLicenseBack ===
                                                      null ? (
                                                      <>{""}</>
                                                    ) : (
                                                      <>
                                                        <p className="profile_section_label see-attachment-text">
                                                          See Attachment
                                                        </p>
                                                        {getUserDetail?.drivingLicenseBack ===
                                                          "" &&
                                                          getUserDetail?.drivingLicenseBack ===
                                                          undefined && (
                                                            <svg
                                                              width="24"
                                                              height="24"
                                                              viewBox="0 0 24 24"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M17.8019 15.1533V18.0196H6.33666V15.1533H4.42578V18.0196C4.42578 19.0706 5.28567 19.9305 6.33666 19.9305H17.8019C18.8529 19.9305 19.7128 19.0706 19.7128 18.0196V15.1533H17.8019ZM16.8465 11.3316L15.4993 9.98439L13.0247 12.4494V4.64349H11.1138V12.4494L8.63926 9.98439L7.29209 11.3316L12.0693 16.1087L16.8465 11.3316Z"
                                                                fill="#3F76C8"
                                                              />
                                                            </svg>
                                                          )}
                                                      </>
                                                    )}
                                                  </div>
                                                  <input
                                                    name={`userList.${index}.drivingLicenseBack`}
                                                    id={`userList.${index}.drivingLicenseBack`}
                                                    value={
                                                      field?.drivingLicenseBack
                                                    }
                                                    readOnly={id}
                                                    className={`${isEdit && id
                                                      ? ""
                                                      : "not-allowed"
                                                      }`}
                                                  />
                                                  <div className="file-item"></div>
                                                </div>
                                              )}
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor="drivingLicenseBacklable"
                                          className="profile_section_label"
                                        >
                                          Drivers License - Back
                                        </label>
                                        <div
                                          className="files-wr"
                                          data-count-files="1"
                                        >
                                          <div className="pt-2">
                                            <label
                                              htmlFor={`userList.${index}.drivingLicenseBackSuccessor`}
                                              className="bi bi-upload w-50 mb-0"
                                            //className="modal-media-upload-label w-100 mb-0"
                                            >
                                              {field?.drivingLicenseBackInfo ||
                                                field?.drivingLicenseBackShow
                                                ? field?.drivingLicenseBackInfo ||
                                                  field?.drivingLicenseBackShow
                                                    ?.length > 8
                                                  ? (
                                                    field?.drivingLicenseBackInfo ||
                                                    field?.drivingLicenseBackShow
                                                  ).substr(0, 8) + "..."
                                                  : field?.drivingLicenseBackInfo ||
                                                  field?.drivingLicenseBackShow
                                                : "Upload File"}
                                            </label>
                                            <input
                                              type="file"
                                              className="form-control"
                                              id={`userList.${index}.drivingLicenseBackSuccessor`}
                                              name={`userList.${index}.drivingLicenseBack`}
                                              placeholder="Upload File"
                                              onChange={async (e) => {
                                                try {
                                                  const file =
                                                    e.currentTarget.files[0];
                                                  const uploadData =
                                                    await handleUploadImage(
                                                      file
                                                    );
                                                  setFieldValue(
                                                    `userList.${index}.drivingLicenseBack`,
                                                    uploadData?.mediaName
                                                  );
                                                  setFieldValue(
                                                    `userList.${index}.drivingLicenseBackInfo`,
                                                    file?.name
                                                  );
                                                } catch (error) { }
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            <div className="file-item hide-btn">
                                              <span className="file-name"></span>
                                              <span className="btn btn-del-file">
                                                x
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        {errors?.drivingLicenseBackShow &&
                                          touched?.drivingLicenseBackShow ? (
                                          <p className="error">
                                            {errors?.drivingLicenseBackShow}
                                          </p>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.address1`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        Address Line 1
                                      </label>
                                      <Field
                                        type="text"
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                        id={`userList.${index}.address1`}
                                        name={`userList.${index}.address1`}
                                        value={field["address1"]}

                                      />
                                    </div>
                                    {errors[`userList.${index}.address1`] && (
                                      <p className="error">
                                        {errors[`userList.${index}.address1`]}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.address2`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        Address 2
                                      </label>
                                      <Field
                                        type="text"
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                        id={`userList.${index}.address2`}
                                        name={`userList.${index}.address2`}
                                        value={field["address2"]}

                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.city`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        City
                                      </label>
                                      <Field
                                        type="text"
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                        id={`userList.${index}.city`}
                                        name={`userList.${index}.city`}
                                        value={field["city"]}

                                      />
                                    </div>
                                    {errors[`userList.${index}.city`] && (
                                      <p className="error">
                                        {errors[`userList.${index}.city`]}
                                      </p>
                                    )}
                                  </div>

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.state`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        State
                                      </label>
                                      <Field
                                        type="text"
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                        id={`userList.${index}.state`}
                                        name={`userList.${index}.state`}
                                        value={field["state"]}

                                      />
                                    </div>
                                    {errors[`userList.${index}.state`] && (
                                      <p className="error">
                                        {errors[`userList.${index}.state`]}
                                      </p>
                                    )}
                                  </div>

                                  <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                    <div
                                      className={`form-group ${isEdit && id
                                        ? ""
                                        : "aa" && id && "not-allowed"
                                        }`}
                                    >
                                      <label
                                        htmlFor={`userList.${index}.zipcode`}
                                        className={`${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                      >
                                        ZIP Code
                                      </label>
                                      <Field
                                        type="text"
                                        className={`form-control ${isEdit && id
                                          ? ""
                                          : "aa" && id && "not-allowed"
                                          }`}
                                        readOnly={id}
                                        id={`userList.${index}.zipcode`}
                                        name={`userList.${index}.zipcode`}
                                        value={field["zipcode"]}

                                      />
                                    </div>
                                    {errors[`userList.${index}.zipcode`] && (
                                      <p className="error">
                                        {errors[`userList.${index}.zipcode`]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="text-center mt-5">
                    {isAddEditData?.successorData?.length > 2 && (
                      <div>
                        {showAll ? (
                          <Link onClick={handleShowLess} className="text-color">
                            Show Less
                          </Link>
                        ) : (
                          <Link onClick={handleShowMore} className="text-color">
                            Show More
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <ModalCustom size="xl" handleClose="grantorModal" show={showPopup}>
        <AddSuccessorPopup
          setShowSuccessorPopup={setShowPopup}
          setAddEditData={setAddEditData}
          id={id}
          successorData={isAddEditData?.successorData}
        />
      </ModalCustom>
      <ModalCustom size="xl" handleClose="grantorModal" show={invitePopup} className={"CongratulationsPopup"}>
        <SuccessorInvitation
          setInvitePopup={setInvitePopup}
          isEmail={isEmail}
          isIndex={isIndex}
          trustName={isAddEditData?.trustName}
          id={id}
        />
      </ModalCustom>
    </>
  );
};

export default AddSuccessor;
