import React from 'react'
import { useNavigate } from 'react-router-dom';

const GrantorVerify = ({ setSuccessorPopup }) => {

    const navigate = useNavigate();

    const handleSubmit = async (value) => {
        navigate('/spouse-sign-up')
    };


    return (
        <div className="text-center">
            <h5 className="my-2 mt-5 pb-5" style={{ color: 'black', fontSize: '24px' }}>Would you like to add your spouse?</h5>
            {/* <h5 className="my-2" style={{ color: 'rgba(68, 70, 78, 1)', fontSize: '24px' }}>
                Select continue to upgrade your account and <h5 style={{ color: 'rgba(68, 70, 78, 1)', fontSize: '24px' }}> create trusts!</h5>
            </h5> */}
            <div className="d-flex align-items-center justify-content-between mb-0 mt-2">
                <div className="btn_back">
                    <button
                        type="button"
                        className="btn cursor-pointer mx-4"
                        onClick={() => {
                            navigate('/subscription')
                        }}
                    >
                        No, Continue to Payment
                    </button>
                </div>
                <div className="bttn_continue mb-0">
                    <button
                        className="btn btn_continue cursor-pointer mx-0 pe-4"
                        onClick={() => {
                            handleSubmit(true);
                            // handleSubscription();
                        }}
                    >
                        Yes, Add My Spouse
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GrantorVerify