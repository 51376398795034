import React, { useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  AddDocumentsTrust,
  AddVerifyCertificate,
  GetSingleTrustDetail,
} from "../../../../../redux/features/TrustsService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalCustom from "../../../../Common/ModalCustom";
import ThankYouCertificate from "../../../../Common/ThankYouCertificate";
import { toast } from "react-toastify";
import Loader from "../../../../Common/Loader";

const VerifyCertificates = ({
  setShowVerifyPopup,
  id,
  setShow,
  isUpdalodDocImageName,
  setUpdalodDocImageName,
  type,
  isSubmit
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [isSetRemoveSSNNumber, setRemoveSSNNumber] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const validate = (values) => {
    let errors = {};
    if (!values?.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!values?.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!values.ssn) {
      errors.ssn = "SSN/ITIN Number is required";
    } else if (values.ssn?.length < 9) {
      errors.ssn = "SSN/ITIN number must be 9 digits long";
    }
    if (!values?.dob) {
      errors.dob = "Date of Birth is required";
    }
    return errors;
  };
  const transformDisplay = (val) => {
    let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3); 
    }

    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6); 
      const lastFour = displayVal.slice(7); 
      displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour; 
    } else {
      displayVal = displayVal.replace(/\d/g, "*"); 
    }

    return displayVal;
  };

  const replaceAt = (str, index, character) => {
    return (
      str.substr(0, index) + character + str.substr(index + character.length)
    );
  };

  const transformValue = (val, setFieldValue) => {
    let cleanVal = val.replace(/[^0-9|\\*]/g, "");
    cleanVal = cleanVal.substr(0, 9);
    let updatedValue = value ? value : "";

    for (let i = 0, l = cleanVal.length; i < l; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        updatedValue = replaceAt(updatedValue, i, cleanVal[i]);
      }
    }
    updatedValue = updatedValue.substr(0, cleanVal.length);
    setFieldValue("ssn", updatedValue);
    setValue(updatedValue);
  };

  const syncInput = (event, setFieldValue) => {
    const val = event.target.value;
    if (isSetRemoveSSNNumber === false) {
      setRemoveSSNNumber(true);
      setFieldValue("ssnValue", "");
      setFieldValue("ssn", "");
      setValue("");
    } else {
      const displayVal = transformDisplay(val);
      setFieldValue("ssnValue", displayVal);
      transformValue(val, setFieldValue);
      event.target.setSelectionRange(val.length, val.length);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container">
        <div className="row justify-content-center">
          <div className="p-0">
            <Formik
              initialValues={{
                trustId: id,
                certificateNumber: "",
                dod: "",
                deathPlace: "",
                firstName: "",
                middleName: "",
                lastName: "",
                address: "",
                city: "",
                state: "",
                ssn: "",
                dob: "",
                maritalStatus: "",
                survivingSpouse: "",
                militaryStatus: "",
                employerName: "",
                job: "",
                deathCertificateId: "",
              }}
              enableReinitialize={true}
              validate={validate}
              validateOnBlur={false}
              onSubmit={async (values) => {
                try {
                  const res = await dispatch(AddVerifyCertificate(values));
                  if (res?.payload?.status) {
                    if (res.type === "trusts/AddVerifyCertificate/fulfilled") {
                      if (type === 6) {
                        const requestData = {
                          trustId: id,
                          trustDoc: isUpdalodDocImageName,
                          docType: 6,
                        };
                        const res = await dispatch(
                          AddDocumentsTrust(requestData)
                        );
                        if (
                          res?.type === "trusts/AddDocumentsTrust/fulfilled"
                        ) {
                          setSaving(true);
                          setLoading(true);
                          setTimeout(async () => {
                            try {
                              await dispatch(
                                GetSingleTrustDetail({ trustId: id })
                              );
                              setShowModal(true);
                              setLoading(false);
                            } catch (error) {
                              console.error(
                                "Error fetching updated trust details:",
                                error
                              );
                            }
                          }, 1000);
                        }
                      }
                      setShow(true);
                      if (isUpdalodDocImageName) {
                        setUpdalodDocImageName("");
                      }
                      isSubmit(true);
                    }
                  } else if (
                    res.type === "trusts/AddVerifyCertificate/rejected"
                  ) {
                    toast.error(res?.payload?.response?.data?.message, {
                      autoClose: 3000,
                    });
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
                handleBlur,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  formgroup="editform"
                >
                  <FieldArray
                    name="userList"
                    render={(arrayHelpers) => (
                      <>
                        <div className="d-flex profile_title_grid">
                          <div className="col-xl-5 p-0">
                            <h4 className="my-3">Verify Death Certificate</h4>
                          </div>
                          <div className="d-flex col-xl-7 align-items-center justify-content-end  btn_bottm">
                            <div className="mr-2">
                              <div className="bttn_continue mb-0">
                                <button
                                  type="submit"
                                  className="btn btn_continue"
                                  disabled={isSaving}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div className="btn_back mr-2">
                              <button
                                type="button"
                                className="btn"
                                onClick={() => {
                                  setShowVerifyPopup(false);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 pl-0">
                          <div className="col-xl-4 mx-1  ">
                            <div className="main_form mb-0">
                              <Field
                                type="text"
                                name="certificateNumber"
                                placeholder="Certificate Number"
                                id="certificateNumber"
                                className="form-control-certificate"
                              />
                            </div>
                            {errors?.certificateNumber &&
                              touched?.certificateNumber && (
                                <span className="error">
                                  {errors?.certificateNumber}
                                </span>
                              )}
                          </div>

                          <div className="mx-1 form-group   col-xl-4">
                            <div className="main_form mb-0">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_4168_94865)">
                                    <path
                                      d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
                                      fill="#323232"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_4168_94865">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <DatePicker
                                  selected={values?.dod}
                                  placeholderText="Date of Death"
                                  onChange={(date) =>
                                    setFieldValue("dod", date)
                                  }
                                  showYearDropdown
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  className="border-0 px-3 form-control-certificate"
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={1000}
                                  maxDate={new Date()}
                                  minDate={
                                    values?.dob ? new Date(values.dob) : null
                                  }
                                />
                              </div>
                            </div>
                            {errors?.dod && touched?.dod && (
                              <span className="error">{errors?.dod}</span>
                            )}
                          </div>

                          <div className="col-xl-4 mx-1  ">
                            <div className="main_form mb-0">
                              <Field
                                type="text"
                                name="deathPlace"
                                placeholder="Place of Death"
                                id="deathPlace"
                                className="form-control-certificate"
                              />
                            </div>
                            {errors?.deathPlace && touched?.deathPlace && (
                              <span className="error">
                                {errors?.deathPlace}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="d-flex col-xl-12 pl-0">
                          <div className="mx-1 form-group   col-xl-4">
                            <div className="main_form mb-0">
                              <div className="d-flex align-items-center">
                                <i className="fa fa-user"></i>
                                <Field
                                  type="text"
                                  name="firstName"
                                  placeholder="First Name"
                                  id="firstName"
                                  className="form-control-certificate px-3"
                                />
                              </div>
                            </div>
                            {errors?.firstName && touched?.firstName && (
                              <span className="error">{errors?.firstName}</span>
                            )}
                          </div>

                          <div className="mx-1 form-group  col-xl-4">
                            <div className="main_form mb-0">
                              <div className="d-flex align-items-center">
                                <i className="fa fa-user"></i>
                                <Field
                                  type="text"
                                  name="middleName"
                                  placeholder="Middle Name (Optional)"
                                  id="middleName"
                                  className="form-control-certificate px-3"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mx-1 form-group col-xl-4">
                            <div className="main_form mb-0">
                              <div className="d-flex align-items-center">
                                <i className="fa fa-user"></i>
                                <Field
                                  type="text"
                                  name="lastName"
                                  placeholder="Last Name"
                                  id="lastName"
                                  className="form-control-certificate px-3"
                                />
                              </div>
                            </div>
                            {errors?.lastName && touched?.lastName && (
                              <span className="error">{errors?.lastName}</span>
                            )}
                          </div>
                        </div>

                        <div className="d-flex col-xl-12 pl-0">
                          <div className="mx-1 form-group   col-xl-4">
                            <div className="main_form mb-0">
                              <div className="d-flex align-items-center">
                                <i className="fa fa-map-marker"></i>
                                <Field
                                  type="text"
                                  name="address"
                                  placeholder="Address of Current Residence"
                                  id="address"
                                  className="form-control-certificate px-3"
                                />
                              </div>
                            </div>
                            {errors?.address && touched?.address && (
                              <span className="error">{errors?.address}</span>
                            )}
                          </div>

                          <div className="mx-1 form-group col-xl-4 d-flex justify-content-between">
                            <div className="form-group set-content-height">
                              <div className="main_form mb-0">
                                <Field
                                  type="text"
                                  name="city"
                                  placeholder="City"
                                  id="city"
                                  className="form-control form-control-city"
                                />
                              </div>
                              {errors?.city && touched?.city ? (
                                <div className="error">{errors.city}</div>
                              ) : null}
                            </div>
                            <div className="form-group ml-5 set-content-height ">
                              <div className="main_form mb-0">
                                <Field
                                  type="text"
                                  name="state"
                                  placeholder="State"
                                  id="state"
                                  className="form-control form-control-city"
                                />
                              </div>
                              {errors?.state && touched?.state ? (
                                <div className="error">{errors.state}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="mx-2 mb-0 col-xl-4">
                            <div className="main_form mb-0 ">
                              <div>
                                <input
                                  className="form-control-certificate border-0 ps-0"
                                  onChange={(e) => {
                                    syncInput(e, setFieldValue);
                                  }}
                                  value={
                                    isSetRemoveSSNNumber === false
                                      ? values?.ssn
                                        ? values?.ssn
                                        : transformDisplay(value)
                                      : transformDisplay(value)
                                  }
                                  maxLength={11}
                                  placeholder="Enter SSN/ITIN Number"
                                />
                              </div>
                            </div>
                            {errors?.ssn && touched?.ssn ? (
                              <p className="error">{errors?.ssn}</p>
                            ) : null}
                          </div>
                        </div>

                        <div className="d-flex col-xl-12 pl-0">
                          <div className="mx-1 mb-0   col-xl-4">
                            <div className="main_form m-0 form-group">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_4168_94865)">
                                    <path
                                      d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
                                      fill="#323232"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_4168_94865">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <DatePicker
                                  placeholderText="Date Of Birth"
                                  selected={values?.dob}
                                  onChange={(date) =>
                                    setFieldValue("dob", date)
                                  }
                                  showYearDropdown
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  className="border-0 px-3 form-control-certificate"
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={1000}
                                  maxDate={new Date()}
                                />
                              </div>
                            </div>
                            <div>
                              {errors?.dob && touched?.dob && (
                                <span className="error">{errors?.dob}</span>
                              )}
                            </div>
                          </div>

                          <div className="mx-1 mb-0   col-xl-4">
                            <div className="main_form m-0 form-group">
                              <Field
                                type="text"
                                name="maritalStatus"
                                placeholder="Marital Status"
                                id="maritalStatus"
                                className="form-control-certificate"
                              />
                            </div>
                            <div>
                              {errors?.maritalStatus &&
                                touched?.maritalStatus && (
                                  <span className="error">
                                    {errors?.maritalStatus}
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mx-1 mb-0   col-xl-4">
                            <div className="main_form m-0 form-group">
                              <Field
                                type="text"
                                name="survivingSpouse"
                                placeholder="Surviving Spouse (Optional)"
                                id="survivingSpouse"
                                className="form-control-certificate w-100"
                              />
                            </div>
                            <div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 pl-0">
                          <div className=" mx-1 my-4 col-xl-4">
                            <div className="main_form m-0 form-group">
                              <Field
                                type="text"
                                name="militaryStatus"
                                placeholder="Military Status"
                                id="militaryStatus"
                                className="form-control-certificate"
                              />
                            </div>
                            <div>
                              {errors?.militaryStatus &&
                                touched?.militaryStatus && (
                                  <span className="error">
                                    {errors?.militaryStatus}
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className=" mx-1 my-4 col-xl-4">
                            <div className="main_form m-0 form-group">
                              <Field
                                type="text"
                                name="employerName"
                                placeholder="Employer Name"
                                id="employerName"
                                className="form-control-certificate"
                              />
                            </div>
                            <div>
                              {errors?.employerName &&
                                touched?.employerName && (
                                  <span className="error">
                                    {errors?.employerName}
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mx-1 my-4 col-xl-4">
                            <div className="main_form m-0 form-group">
                              <Field
                                type="text"
                                name="job"
                                placeholder="Job"
                                id="job"
                                className="form-control-certificate"
                              />
                            </div>
                            <div>
                              {errors?.job && touched?.job && (
                                <span className="error">{errors?.job}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
        <ModalCustom size="lg" className="thank-you-modal" show={showModal}>
          <ThankYouCertificate
            setShowModal={setShowModal}
            setShowVerifyPopup={setShowVerifyPopup}
            isSubmit={isSubmit}
          />
        </ModalCustom>
      </div>
    </>
  );
};

export default VerifyCertificates;
