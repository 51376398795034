import React, { useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  AddTrustsData,
  CheckMailService,
  GetSingleTrustDetail,
} from "../../../../../redux/features/TrustsService";
import Loader from "../../../../Common/Loader";
import { toast } from "react-toastify";
import _ from "lodash";

const validate = (values) => {
  let errors = {};
  if (!values?.firstName) {
    errors.firstName = "This field is required";
  }
  if (!values?.lastName) {
    errors.lastName = "This field is required";
  }
  if (!values?.phoneNumber) {
    errors.phoneNumber = "This field is required";
  }
  if (!values?.email) {
    errors.email = "This field is required";
  }
  if (!values?.address1) {
    errors.address1 = "This field is required";
  }
  if (!values?.city) {
    errors.city = "This field is required";
  }
  if (!values?.state) {
    errors.state = "This field is required";
  }
  if (!values?.zipcode) {
    errors.zipcode = "This field is required";
  }
  if (!values?.barNumber) {
    errors.barNumber = "This field is required";
  }
  if (!values?.stateLicensed) {
    errors.stateLicensed = "This field is required";
  }
  return errors;
};

const AddAttoreyPopup = ({
  setAddEditData,
  setShowAttornyPopup,
  attorneyData,
  id,
  isAddEditData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="p-0">
            <Formik
              initialValues={{
                userId: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipcode: "",
                barNumber: "",
                stateLicensed: "",
                firm: "",
                position: 0,
              }}
              enableReinitialize={true}
              validate={validate}
              validateOnBlur={false}
              onSubmit={async (values) => {
                try {
                  setSaving(true);
                  const data = {
                    email: values.email,
                  };
                  const res = await dispatch(CheckMailService(data));
                  if (res?.type === "trusts/CheckMailService/fulfilled") {
                    if (id) {
                      setLoading(true);
                      const AttorneyData = {
                        trustId: id,
                        userList: [...attorneyData, values],
                        role: 3,
                      };
                      const resp = await dispatch(AddTrustsData(AttorneyData));
                      if (resp?.type === "trusts/AddTrustsData/fulfilled") {
                        setLoading(false);
                        setShowAttornyPopup(false);
                        dispatch(GetSingleTrustDetail({ trustId: id }));
                      } else if (
                        resp?.type === "trusts/AddTrustsData/rejected"
                      ) {
                        setLoading(false);
                        setShowAttornyPopup(true);
                        toast.error(resp?.payload?.response?.data?.message);
                      }
                    } else {
                      if (isAddEditData?.attorneyData?.length) {
                        if (
                          _.some(
                            isAddEditData?.attorneyData,
                            (entry) => entry.phoneNumber === values?.phoneNumber
                          )
                        ) {
                          toast.error("Phone number is already used");
                        } else if (
                          _.some(
                            isAddEditData?.attorneyData,
                            (entry) => entry.email === values?.email
                          )
                        ) {
                          toast.error("Email is already used");
                        } else {
                          setAddEditData((prevData) => ({
                            ...prevData,
                            attorneyData: Array.isArray(prevData.attorneyData)
                              ? [...prevData.attorneyData, values]
                              : [values],
                          }));
                          setShowAttornyPopup(false);
                        }
                      } else {
                        setAddEditData((prevData) => ({
                          ...prevData,
                          attorneyData: Array.isArray(prevData.attorneyData)
                            ? [...prevData.attorneyData, values]
                            : [values],
                        }));
                        setShowAttornyPopup(false);
                      }
                    }
                  }
                } catch (error) {
                } finally {
                  setSaving(false);
                }
              }}
            >
              {({
                values,
                setFieldValue,
                handleSubmit,
                errors,
                touched,
                handleBlur,
                handleChange,
              }) => (
                <>
                  {loading && <Loader />}
                  <form
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    formgroup="editform"
                  >
                    <FieldArray
                      name="userList"
                      render={(arrayHelpers) => (
                        <>
                          <div className="d-flex profile_title_grid">
                            <div className="col-xl-2 p-0">
                              <h4 className="my-3">Add Attorney</h4>
                            </div>
                            <div className="d-flex col-xl-10 align-items-center justify-content-end  btn_bottm">
                              <div className="btn_back mr-2">
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowAttornyPopup(false);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>

                              <div className="bttn_continue mb-0">
                                <button
                                  type="submit"
                                  className="btn btn_continue"
                                  disabled={saving}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="col-xl-2 p-0">
                              <h5 className="my-3 text-muted">Add Attorney</h5>
                            </div>
                            <div className="main_grid_top grid-container pt-2 ">
                              <div className="row m-0 w-100">
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="firstName"
                                        className="bg-white"
                                      >
                                        First Name
                                      </label>
                                      <Field
                                        type="text"
                                        name="firstName"
                                        placeholder="Bill"
                                        id="firstName"
                                        className="form-control"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "position",
                                            !id
                                              ? !attorneyData?.length
                                                ? 1
                                                : attorneyData.length + 1
                                              : attorneyData
                                                ?.map(
                                                  (attorney) =>
                                                    parseInt(attorney?.position) +
                                                    1
                                                )
                                                .join(", ")
                                          );
                                          handleChange(e);
                                        }}
                                      />
                                    </div>

                                    {errors?.firstName && touched?.firstName && (
                                      <span className="error">
                                        {errors?.firstName}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="lastName"
                                        className="bg-white"
                                      >
                                        Last Name
                                      </label>
                                      <Field
                                        type="text"
                                        name="lastName"
                                        placeholder="Smith"
                                        id="lastName"
                                        className="form-control"
                                      />
                                    </div>

                                    {errors?.lastName && touched?.lastName && (
                                      <span className="error">
                                        {errors?.lastName}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="phoneNumber"
                                        className="bg-white"
                                      >
                                        Phone Number
                                      </label>
                                      <Field
                                        type="text"
                                        name="phoneNumber"
                                        placeholder="+1 (123) 123-1233"
                                        id="phoneNumber"
                                        className="form-control"
                                        onKeyPress={handleKeyPress}
                                      />
                                    </div>
                                    {errors?.phoneNumber &&
                                      touched?.phoneNumber && (
                                        <span className="error">
                                          {errors?.phoneNumber}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="email" className="bg-white">
                                        Email Address
                                      </label>
                                      <Field
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Bsmith"
                                      />
                                    </div>
                                    {errors?.email && touched?.email && (
                                      <span className="error">{errors?.email}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="barNumber"
                                        className="bg-white"
                                      >
                                        Bar Number
                                      </label>
                                      <Field
                                        type="barNumber"
                                        className="form-control"
                                        id="barNumber"
                                        name="barNumber"
                                        placeholder="6986562356"
                                      />
                                    </div>
                                    {errors?.barNumber && touched?.barNumber && (
                                      <span className="error">
                                        {errors?.barNumber}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="stateLicensed"
                                        className="bg-white"
                                      >
                                        State Licensed
                                      </label>
                                      <Field
                                        type="stateLicensed"
                                        className="form-control"
                                        id="stateLicensed"
                                        name="stateLicensed"
                                        placeholder="California"
                                      />
                                    </div>
                                    {errors?.stateLicensed &&
                                      touched?.stateLicensed && (
                                        <span className="error">
                                          {errors?.stateLicensed}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="firm" className="bg-white">
                                        Firm (if applicable)
                                      </label>
                                      <Field
                                        type="firm"
                                        className="form-control"
                                        id="firm"
                                        name="firm"
                                        placeholder="Mc Law Firms"
                                      />
                                      {errors?.firm && touched?.firm && (
                                        <span className="error">
                                          {errors?.firm}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="address1"
                                        className="bg-white"
                                      >
                                        Address Line 1
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="address1"
                                        name="address1"
                                        placeholder="Address 1"
                                      />
                                    </div>
                                    {errors?.address1 && touched?.address1 && (
                                      <span className="error">
                                        {errors?.address1}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="address2"
                                        className="bg-white"
                                      >
                                        Address Line 2
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="address2"
                                        name="address2"
                                        placeholder="Address 2"
                                      />
                                    </div>
                                    {errors?.address2 && touched?.address2 && (
                                      <span className="error">
                                        {errors?.address2}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="city" className="bg-white">
                                        City
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        name="city"
                                        placeholder="Austin"
                                      />
                                    </div>
                                    {errors?.city && touched?.city && (
                                      <span className="error">{errors?.city}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="state">State</label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        name="state"
                                        placeholder="Texas"
                                      />
                                    </div>
                                    {errors?.state && touched?.state && (
                                      <span className="error">{errors?.state}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="zipcode" className="bg-white">
                                        ZIP Code
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="zipcode"
                                        name="zipcode"
                                        placeholder="78711"
                                      />
                                    </div>
                                    {errors?.zipcode && touched?.zipcode && (
                                      <span className="error">
                                        {errors?.zipcode}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </>
                      )}
                    />
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAttoreyPopup;
