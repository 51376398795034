import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SignUpService } from "../../redux/features/AuthService";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import MainCenterBox from "../MainCenterBox";
import Loader from "../Common/Loader";
import Button from "../Common/Button";
import "react-datepicker/dist/react-datepicker.css";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "*Email is required to Sign Up";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.firstName) {
    errors.firstName = "*First Name is required to Sign Up";
  }
  if (!values.lastName) {
    errors.lastName = "*Last Name is required to Sign Up";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "*Phone Number is required to Sign Up";
  } else if (!/\d{3,10}$/.test(values.phoneNumber)) {
    errors.phoneNumber = "Invalid phone number";
  }
  if (!values.password) {
    errors.password = "*Password is required to Sign Up";
  } else if (values.password.length < 6 || values.password.length > 20) {
    errors.password = "*Password must be between 6 to 20 characters";
  } else if (!/(?=.*[a-z])/.test(values.password)) {
    errors.password = "*Password must contain at least one lowercase letter";
  } else if (!/(?=.*[A-Z])/.test(values.password)) {
    errors.password = "*Password must contain at least one uppercase letter";
  } else if (!/(?=.*\d)/.test(values.password)) {
    errors.password = "*Password must contain at least one digit";
  } else if (!/(?=.*[!@#$%&])/.test(values.password)) {
    errors.password =
      "*Password must contain at least one special character (@#$%&!)";
  }
  if (!values.address1) {
    errors.address1 = "*Address 1 is required to Sign Up";
  }
  if (!values.state) {
    errors.state = "*State is required to Sign Up";
  }
  if (!values.city) {
    errors.city = "*City is required to Sign Up";
  }
  if (!values.zipCode) {
    errors.zipCode = "*Zip code is required to Sign Up";
  }
  if (!values.barNumber) {
    errors.barNumber = "*Bar Number is required to Sign Up";
  }
  if (!values.stateLicensed) {
    errors.stateLicensed = "*State Licensed is required to Sign Up";
  }
  if (!values.cPassword) {
    errors.cPassword = "*Confirm Password is required to Sign Up";
  } else if (values.password !== values.cPassword) {
    errors.cPassword = "*Password and confirm Password do not match";
  }
  return errors;
};

const AttorneySignUp = () => {
  document.title = "National Trust Registry | Sign Up";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  let initialValuesWithoutEmail = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    dob: "",
    ssnNumber: "",
    ssnConfirmNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    barNumber: "",
    firm: "",
    stateLicensed: "",
    timezone: "Asia/Kolkata",
    cPassword: "",
    role: 3,
    isEmail:  "",
    userId:  "",
  };
  const path = window.location.pathname;
  const parts = path.split("/").filter((part) => part !== "");
  if (parts.length >= 3) {
    const email = parts[1];
    const inviteId = parts[2];
    const userId = parts[3];

    if (id) {
      initialValuesWithoutEmail.email = email;
      initialValuesWithoutEmail.isEmail = inviteId;
      initialValuesWithoutEmail.userId = userId;
    }
  }

  const formik = useFormik({
    initialValues: initialValuesWithoutEmail,
    validate,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await dispatch(SignUpService(values));
      if (response.type === "auths/SignUpService/fulfilled") {
        Cookies.set("ntr-email-register", values.email, { expires: 36400 });
        if (response?.payload?.status === 3) {
          navigate("/sign-up-verify", { state: response?.payload?.data?._id });
          toast.success(response?.payload?.message, { autoClose: 3000 });
        } else {
          navigate("/sign-up");
        }
      } else {
        toast.error(response?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
      setLoading(false);
      localStorage.setItem("userTitle", formik.values.firstName);
    },
  });
  return (
    <>
      {loading && <Loader />}
      <MainCenterBox
        title={"Create an Account"}
        className="col-xl-8 col-lg-10 col-md-12"
      >
        <p className="text-center pb-4">
          Peace of mind is a few easy clicks away.
        </p>

        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="pt-2 sign-up-form-text">
                Personal Information
              </div>
            </div>
            <div className="col-md-6">
              <div className="main_form_box  ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4487_48705)">
                    <path
                      d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
                      fill="#323232"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4487_48705">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    id="firstName"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="error">{formik.errors.firstName}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <div className="main_form_box ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4487_48705)">
                    <path
                      d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
                      fill="#323232"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4487_48705">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    id="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="error">{formik.errors.lastName}</div>
              ) : null}
            </div>
            <div className="col-md-12">
              <div className="main_form_box mt-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1659_28499)">
                    <path
                      d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                      fill="#42474E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1659_28499">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="form-group">
                  {id ? (
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      id="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className="input-field"
                      readOnly
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      id="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className="input-field"
                    />
                  )}
                </div>
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <div className="main_form_box mt-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_2348_29835)">
                    <path
                      d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.05999 13.63 6.61999 10.79L8.47 8.94001C8.89999 8.51001 9.10999 7.91001 9.03999 7.30001L8.74999 4.78001C8.62999 3.77001 7.77999 3.01001 6.76 3.01001H5.03C3.9 3.01001 2.95999 3.95001 3.02999 5.08001C3.55999 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
                      fill="#42474E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2348_29835">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="form-group">
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    id="phoneNumber"
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber}
                    className="input-field"
                    maxLength={13}
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </div>
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="error">{formik.errors.phoneNumber}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <div className="main_form_box mt-4">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Firm (if applicable)"
                    name="firm"
                    id="firm"
                    onChange={formik.handleChange}
                    value={formik.values.firm}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.firm && formik.errors.firm ? (
                <div className="error">{formik.errors.firm}</div>
              ) : null}
            </div>

            <div className="col-md-6">
              <div className="main_form_box mt-4">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM9 6C10.66 6 12 7.34 12 9C12 10.66 10.66 12 9 12C7.34 12 6 10.66 6 9C6 7.34 7.34 6 9 6ZM15 18H3V16.6C3 14.6 7 13.5 9 13.5C11 13.5 15 14.6 15 16.6V18Z" fill="#42474E" />
                </svg>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Bar Number"
                    name="barNumber"
                    id="barNumber"
                    onChange={formik.handleChange}
                    value={formik.values.barNumber}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.barNumber && formik.errors.barNumber ? (
                <div className="error">{formik.errors.barNumber}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <div className="main_form_box mt-4">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="State Licensed"
                    name="stateLicensed"
                    id="stateLicensed"
                    onChange={formik.handleChange}
                    value={formik.values.stateLicensed}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.stateLicensed && formik.errors.stateLicensed ? (
                <div className="error">{formik.errors.stateLicensed}</div>
              ) : null}
            </div>
            <div className="pt-2 sign-up-form-text mt-2">
              Address Information
            </div>
            <div className="col-md-12">
              <div className="main_form_box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4487_48813)">
                    <path
                      d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                      fill="#323232"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4487_48813">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Address Line 1"
                    name="address1"
                    id="address1"
                    onChange={formik.handleChange}
                    value={formik.values.address1}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.address1 && formik.errors.address1 ? (
                <div className="error">{formik.errors.address1}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <div className="main_form_box mt-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4487_48813)">
                    <path
                      d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                      fill="#323232"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4487_48813">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Address Line 2 (Optional)"
                    name="address2"
                    id="address2"
                    onChange={formik.handleChange}
                    value={formik.values.address2}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.address2 && formik.errors.address2 ? (
                <div className="error">{formik.errors.address2}</div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="main_form_box mt-4">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    id="city"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.city && formik.errors.city ? (
                <div className="error">{formik.errors.city}</div>
              ) : null}
            </div>
            <div className="col-md-2">
              <div className="main_form_box mt-4">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="State"
                    name="state"
                    id="state"
                    onChange={formik.handleChange}
                    value={formik.values.state}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.state && formik.errors.state ? (
                <div className="error">{formik.errors.state}</div>
              ) : null}
            </div>
            <div className="col-md-2">
              <div className="main_form_box mt-4">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Zip Code"
                    name="zipCode"
                    id="zipCode"
                    onChange={formik.handleChange}
                    value={formik.values.zipCode}
                    className="input-field"
                  />
                </div>
              </div>
              {formik.touched.zipCode && formik.errors.zipCode ? (
                <div className="error">{formik.errors.zipCode}</div>
              ) : null}
            </div>
            <div className="pt-2 sign-up-form-text mt-2">
              Password Setup
            </div>
            <div className="col-md-12">
              <div className="main_form_box d-flex align-items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 15.225C7.9 15.225 8.6625 14.9125 9.2875 14.2875C9.9125 13.6625 10.225 12.9 10.225 12C10.225 11.1 9.9125 10.3375 9.2875 9.7125C8.6625 9.0875 7.9 8.775 7 8.775C6.1 8.775 5.3375 9.0875 4.7125 9.7125C4.0875 10.3375 3.775 11.1 3.775 12C3.775 12.9 4.0875 13.6625 4.7125 14.2875C5.3375 14.9125 6.1 15.225 7 15.225ZM7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.41667 6 9.60417 6.3875 10.5625 7.1625C11.5208 7.9375 12.1917 8.925 12.575 10.125H20.875C20.975 10.125 21.0667 10.1417 21.15 10.175C21.2333 10.2083 21.3167 10.2667 21.4 10.35L22.475 11.425C22.5583 11.5083 22.6167 11.5917 22.65 11.675C22.6833 11.7583 22.7 11.85 22.7 11.95C22.7 12.05 22.6833 12.1417 22.65 12.225C22.6167 12.3083 22.5583 12.3917 22.475 12.475L19.875 15.075C19.7917 15.1583 19.7083 15.2167 19.625 15.25C19.5417 15.2833 19.45 15.3 19.35 15.3C19.25 15.3 19.1583 15.2833 19.075 15.25C18.9917 15.2167 18.9083 15.1583 18.825 15.075L17.25 13.5L15.675 15.075C15.5917 15.1583 15.5083 15.2167 15.425 15.25C15.3417 15.2833 15.25 15.3 15.15 15.3C15.05 15.3 14.9583 15.2833 14.875 15.25C14.7917 15.2167 14.7083 15.1583 14.625 15.075L13.375 13.825H12.575C12.2583 15.075 11.5875 16.0833 10.5625 16.85C9.5375 17.6167 8.35 18 7 18Z"
                    fill="black"
                  />
                </svg>
                <div className="form-group d-flex align-items-center">
                  <input
                    type={"password"}
                    placeholder="Password"
                    className="input-field"
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                </div>
              </div>

              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="col-md-12">
              <div className="main_form_box d-flex align-items-center mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 15.225C7.9 15.225 8.6625 14.9125 9.2875 14.2875C9.9125 13.6625 10.225 12.9 10.225 12C10.225 11.1 9.9125 10.3375 9.2875 9.7125C8.6625 9.0875 7.9 8.775 7 8.775C6.1 8.775 5.3375 9.0875 4.7125 9.7125C4.0875 10.3375 3.775 11.1 3.775 12C3.775 12.9 4.0875 13.6625 4.7125 14.2875C5.3375 14.9125 6.1 15.225 7 15.225ZM7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.41667 6 9.60417 6.3875 10.5625 7.1625C11.5208 7.9375 12.1917 8.925 12.575 10.125H20.875C20.975 10.125 21.0667 10.1417 21.15 10.175C21.2333 10.2083 21.3167 10.2667 21.4 10.35L22.475 11.425C22.5583 11.5083 22.6167 11.5917 22.65 11.675C22.6833 11.7583 22.7 11.85 22.7 11.95C22.7 12.05 22.6833 12.1417 22.65 12.225C22.6167 12.3083 22.5583 12.3917 22.475 12.475L19.875 15.075C19.7917 15.1583 19.7083 15.2167 19.625 15.25C19.5417 15.2833 19.45 15.3 19.35 15.3C19.25 15.3 19.1583 15.2833 19.075 15.25C18.9917 15.2167 18.9083 15.1583 18.825 15.075L17.25 13.5L15.675 15.075C15.5917 15.1583 15.5083 15.2167 15.425 15.25C15.3417 15.2833 15.25 15.3 15.15 15.3C15.05 15.3 14.9583 15.2833 14.875 15.25C14.7917 15.2167 14.7083 15.1583 14.625 15.075L13.375 13.825H12.575C12.2583 15.075 11.5875 16.0833 10.5625 16.85C9.5375 17.6167 8.35 18 7 18Z"
                    fill="black"
                  />
                </svg>
                <div className="form-group d-flex align-items-center">
                  <input
                    type={"password"}
                    placeholder="Confirm Password"
                    className="input-field"
                    name="cPassword"
                    id="cPassword"
                    onChange={formik.handleChange}
                    value={formik.values.cPassword}
                  />
                </div>
              </div>
              {formik.touched.cPassword && formik.errors.cPassword ? (
                <div className="error">{formik.errors.cPassword}</div>
              ) : null}
            </div>
            <div className="signup-form-pwd-text">
              <div className="pwdreqments mt-2">Password Criteria:</div>
              <div className="pwdreqments ">At least 1 lowercase letter</div>
              <div className="pwdreqments ">At least 1 UPPERCASE letter</div>
              <div className="pwdreqments ">At least 1 number</div>
              <div className="pwdreqments   ">At least 6 characters</div>
            </div>
          </div>

          <div className="mt-4 btn-div d-flex justify-content-between align-items-center">
            <div
              type="submit"
              className="SiteBtn2"
              onClick={() => navigate("/")}
            >
              Sign In
            </div>
            <Button type="submit" className="btn-thm SiteBtn" text="Continue" />
          </div>
        </Form>
      </MainCenterBox>
    </>
  );
};

export default AttorneySignUp;
