import moment from "moment";
import React from "react";

export default function ProfileDetailCard({
  getUserDetail,
  handleClick,
  downloadImageFront,
  downloadImageBack,
  setShowPopup,
  currentUnixTimestamp,
  maskify,
  isChildInfo,
}) {
  return (
    <>
      <div className="profile_main_sec_detail">
        <div className="profile_title_grid d-flex justify-content-between align-items-center pb-3">
          <div>
            <p className="m-0 p-0 fs-5 fw-medium">Details</p>
            <p className="lastUpdatedText">
              Last Updated{" "}
              {moment(getUserDetail?.updatedAt).format("MM/DD/YYYY h:mm a ")}
            </p>
          </div>
          <div className="btn_sec_right color_change">
            <button
              className="profile_edit_btn rounded-pill text-white"
              onClick={handleClick}
            >
              Edit
            </button>
          </div>
        </div>
        <div className="">
          <div className="main_grid_top grid-container gap-0 pt-2">
            <div className="row m-0 w-100">
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">First Name</p>
                  <p className="profile_main_part_user_detail_profile">
                    {getUserDetail?.firstName}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">Last Name</p>
                  <p className="profile_main_part_user_detail_profile">
                    {getUserDetail?.lastName}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">Phone Number</p>
                  <p className="profile_main_part_user_detail_profile ">
                    {getUserDetail?.phoneNumber}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">Email address</p>
                  <p className="profile_main_part_user_detail_profile ">
                    {getUserDetail?.email}
                  </p>
                </div>
              </div>
              {getUserDetail?.role === 3 && (
                <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                  <div className="form-group">
                    <p className="profile_section_label">Bar Number</p>
                    <p className="profile_main_part_user_detail_profile">
                      {getUserDetail?.barNumber}
                    </p>
                  </div>
                </div>
              )}
              {getUserDetail?.role === 3 && (
                <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                  <div className="form-group">
                    <p className="profile_section_label">State Licensed</p>
                    <p className="profile_main_part_user_detail_profile">
                      {getUserDetail?.stateLicensed}
                    </p>
                  </div>
                </div>
              )}
              {getUserDetail?.role === 3 && (
                <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                  <div className="form-group col-xl-">
                    <p className="profile_section_label">
                      {" "}
                      Firm (if applicable)
                    </p>
                    <p className="profile_main_part_user_detail_profile">
                      {getUserDetail?.firm}
                    </p>
                  </div>
                </div>
              )}
              {getUserDetail?.role !== 3 && (
                <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                  <div className="form-group">
                    <p className="profile_section_label">
                      Drivers License - Front
                    </p>
                    <div className="files-wr" data-count-files="1">
                      <button
                        onClick={
                          getUserDetail?.drivingLicenseFront === undefined
                            ? () => {}
                            : () =>
                                downloadImageFront(
                                  getUserDetail?.drivingLicenseFront
                                )
                        }
                        className="bg-white"
                      >
                        <div className="one-file d-flex align-items-center">
                          <div className="d-flex">
                            {getUserDetail?.drivingLicenseFront === undefined ||
                            getUserDetail?.drivingLicenseFront === "" ? (
                              <>{""}</>
                            ) : (
                              <>
                                <p className="profile_section_label see-attachment-text">
                                  See Attachment
                                </p>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.8019 15.1533V18.0196H6.33666V15.1533H4.42578V18.0196C4.42578 19.0706 5.28567 19.9305 6.33666 19.9305H17.8019C18.8529 19.9305 19.7128 19.0706 19.7128 18.0196V15.1533H17.8019ZM16.8465 11.3316L15.4993 9.98439L13.0247 12.4494V4.64349H11.1138V12.4494L8.63926 9.98439L7.29209 11.3316L12.0693 16.1087L16.8465 11.3316Z"
                                    fill="#3F76C8"
                                  />
                                </svg>
                              </>
                            )}
                          </div>
                          <input
                            name="drivingLicenseFront"
                            id="drivingLicenseFront"
                          />
                          <div className="file-item hide-btn"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {getUserDetail?.role !== 3 && (
                <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                  <div className="form-group">
                    <p className="profile_section_label">
                      Drivers License - Back
                    </p>
                    <div className="files-wr" data-count-files="1">
                      <button
                        onClick={
                          getUserDetail?.drivingLicenseBack === undefined
                            ? () => {}
                            : () =>
                                downloadImageBack(
                                  getUserDetail?.drivingLicenseBack
                                )
                        }
                        className="bg-white"
                      >
                        <div className="one-file d-flex align-items-center">
                          <div className="d-flex">
                            {getUserDetail?.drivingLicenseBack === undefined ||
                            getUserDetail?.drivingLicenseBack === "" ? (
                              <>{""}</>
                            ) : (
                              <>
                                <p className="profile_section_label see-attachment-text">
                                  See Attachment
                                </p>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.8019 15.1533V18.0196H6.33666V15.1533H4.42578V18.0196C4.42578 19.0706 5.28567 19.9305 6.33666 19.9305H17.8019C18.8529 19.9305 19.7128 19.0706 19.7128 18.0196V15.1533H17.8019ZM16.8465 11.3316L15.4993 9.98439L13.0247 12.4494V4.64349H11.1138V12.4494L8.63926 9.98439L7.29209 11.3316L12.0693 16.1087L16.8465 11.3316Z"
                                    fill="#3F76C8"
                                  />
                                </svg>
                              </>
                            )}
                          </div>
                          <input
                            name="drivingLicenseBack"
                            id="drivingLicenseBack"
                          />
                          <div className="file-item hide-btn"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">Address Line 1</p>
                  <p className="profile_main_part_user_detail_profile">
                    {getUserDetail?.address1}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">Address Line 2</p>
                  <p className="profile_main_part_user_detail_profile">
                    {getUserDetail?.address2}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">City</p>
                  <p className="profile_main_part_user_detail_profile">
                    {getUserDetail?.city}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">State</p>
                  <p className="profile_main_part_user_detail_profile">
                    {getUserDetail?.state}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">Zip Code</p>
                  <p className="profile_main_part_user_detail_profile">
                    {getUserDetail?.zipCode}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                <div className="form-group">
                  <p className="profile_section_label">Date of Birth</p>
                  <p>
                    {getUserDetail?.dob
                      ? moment(getUserDetail.dob).format("MM/DD/YYYY")
                      : ""}
                  </p>
                </div>
              </div>

              {(getUserDetail?.role === 2 || getUserDetail?.role === 4) && (
                <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                  <div className="form-group">
                    <p htmlFor="ssnNumber" className="profile_section_label">
                      SSN / ITIN
                    </p>
                    <p className="profile_main_part_user_detail_profile">
                      {`${
                        getUserDetail?.ssnNumber
                          ? "***-**-" + maskify(getUserDetail?.ssnNumber)
                          : ""
                      }`}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isChildInfo && getUserDetail?.role === 4 ? (
        <button
          className="btn mt-3 mx-2 upgrade-successor-btn"
          onClick={(e) => {
            e.preventDefault();
            setShowPopup(true);
          }}
        >
          Upgrade Account
        </button>
      ) : (
        <></>
      )}
    </>
  );
}
