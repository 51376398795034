import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { GetAdminListing } from "../../../redux/features/AdminService";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ArchivedAdminUsers = ({ filteredData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAdminListing } = useSelector((state) => state.admin);

  const columns = [
    {
      id: "firstName",
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      id: "lastName",
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => (row.email ? row.email : "-"),
      sortable: true,
    },
    {
      id: "phoneNumber",
      name: "Phone Number",
      selector: (row) => (row.phoneNumber ? row.phoneNumber : "-"),
      sortable: true,
    },
    {
      id: "createdAt",
      name: "Last Session",
      selector: (row) =>
        moment(row.createdAt).format(
          "MM/DD/yyyy @ HH:mm PT"
        ),
      sortable: true,
    },
    {
      id: "updatedAt",
      name: "Last Updated",
      selector: (row) =>
        moment(row.updatedAt).format(
          "MM/DD/yyyy @ HH:mm PT"
        ),
      sortable: true,
    },
  ];

  useEffect(() => {
    dispatch(GetAdminListing({ search: "", role: 1, status: "0" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (row) => {
    navigate(`/admin-users/${row?._id}`);
  };

  return (
    <DataTable
      title=""
      columns={columns}
      data={filteredData || getAdminListing.data}
      pagination
      onRowClicked={handleRowClick}
      highlightOnHover
      defaultSortFieldId="firstName"
      persistTableHead

    />
  );
};

export default ArchivedAdminUsers;
