import Cookies from "js-cookie";
import React, { useEffect, useContext } from "react";
import SubscriptionBox from "../Common/SubscriptionBox";
import { useDispatch, useSelector } from "react-redux";
import { GetAllPlans } from "../../redux/features/AuthService";
import { AuthContext } from "../../contexts/AuthContexts";

const Subscription = () => {
  const dispatch = useDispatch();
  const { authStatus } = useContext(AuthContext);

  document.title = "National Trust Registry | Verify";
  const { getAllPlansList } = useSelector((state) => state.auths);

  useEffect(() => {
    dispatch(GetAllPlans());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    Cookies.remove("ntr-token");
    Cookies.remove("ntr-verify-token");
    window.location.reload();
  };

  return (
    <>
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <div className="container-fulid h-100">
          {((authStatus == 1) && (window.location.pathname == '/purchase_subscription')) && <button className="SiteBtn subscriptionLogout" onClick={handleLogout}>Logout</button>}
          <div className="row pt-5">
            <div>
              <div className="subscriptionMainTitle position-relative">
                <h1>Choose a Subscription Plan</h1>
              </div>
              <div className="subscriptionMainTitle2">
                <p>Choose a plan that suites your needs best.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-5 card-sub ">
            <SubscriptionBox
              subscriptionDataList={getAllPlansList} />
          </div>
        </div>
      </div>



    </>
  );
};

export default Subscription;
