import React from "react";

const Button = ({ className, text, onClick, type }) => {
  return (
    <div className="text-end">
      {onClick ? (
        <button
          className={`${className}`}
          onClick={onClick}
          type={type ? type : "submit"}
        >
          {text}
        </button>
      ) : (
        <button className={`${className}`} type={type ? type : "submit"}>
          {text}
        </button>
      )}
    </div>
  );
};

export default Button;
