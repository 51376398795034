import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
  validateCreditCardExpiry,
} from "../../utils/payment";
import { toast } from "react-toastify";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "*Name on card is required";
  }
  if (!values.cardNumber) {
    errors.cardNumber = "*Card information is required";
  }
  if (!values.expirationDate) {
    errors.expirationDate = "*Expiration date is required";
  } else if (!validateCreditCardExpiry(values.expirationDate)) {
    errors.expirationDate = "Invalid expiration date";
  }
  if (!values.country) {
    errors.country = "*Country is required";
  }

  if (!values.cvc) {
    errors.cvc = "*CVC is required";
  }

  return errors;
};

const PaymentMethod = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      name: location?.state?.formData ? location?.state?.formData?.name : "",
      cardNumber: location?.state?.formData
        ? location?.state?.formData?.cardNumberWithSpace
        : "",
      expirationDate: location?.state?.formData
        ? location?.state?.formData?.expirationDate
        : "",
      country: location?.state?.formData
        ? location?.state?.formData?.country
        : "",
      cvc: location?.state?.formData ? location?.state?.formData?.cvc : "",
    },
    validate: validate,
    onSubmit: (values) => {
      const data = {
        cardNumber: values?.cardNumber.replace(/\s/g, ""),
        cardNumberWithSpace: values?.cardNumber,
        expMonth: values?.expirationDate?.split("/")[0],
        expYear: values?.expirationDate?.split("/")[1],
        expirationDate: values?.expirationDate,
        cvc: values?.cvc,
        name: values?.name,
        country: values?.country,
        planId: values?._id,
      };
      const isEmptyField = Object.values(values).some((value) => !value.trim());

      if (isEmptyField) {
      } else if (!validateCreditCardExpiry(values.expirationDate)) {
        toast.error("please enter valid expiration date", {
          autoClose: 3000,
        });
      } else {
        localStorage.setItem(
          "id",
          JSON.stringify(
            values === undefined
              ? {
                  nameOnCard: "",
                  cardInformation: "",
                  expirationDate: "",
                  country: "",
                  cvc: "",
                }
              : values
          )
        );

        navigate("/payment-plan", {
          state: {
            subscriptionData: location?.state?.subsctiptionSelectedData,
            formData: data,
          },
        });
      }
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "cardNumber") {
      e.target.value = formatCreditCardNumber(value);
    } else if (name === "expirationDate") {
      e.target.value = formatExpirationDate(value);
    } else if (name === "cvc") {
      e.target.value = formatCVC(value);
    }
    formik.handleChange(e);
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-md-6">
          <div className="main_center_box">
            <div className="text-center">
              <img
                src="/assets/images/ntr_logo.png"
                className="img-fluid"
                alt="logo_box"
              />
            </div>
            <h6 className="pt-4 pb-1">Payment Method</h6>
            <p className="pb-4">Quality made easy is a few clicks away.</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <div className="payment_main_form col-xl-12">
                  <label htmlFor="exampleInputPassword1">Name on Card</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="payment_form_control w-100"
                    placeholder="John Doe "
                    onChange={handleInputChange}
                    value={formik.values.name}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="error">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="payment_main_form col-xl-12">
                <div className="mb-0 d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 141.732 141.732"
                  >
                    <g fill="#2566af">
                      <path d="M62.935 89.571h-9.733l6.083-37.384h9.734zM45.014 52.187L35.735 77.9l-1.098-5.537.001.002-3.275-16.812s-.396-3.366-4.617-3.366h-15.34l-.18.633s4.691.976 10.181 4.273l8.456 32.479h10.141l15.485-37.385H45.014zM121.569 89.571h8.937l-7.792-37.385h-7.824c-3.613 0-4.493 2.786-4.493 2.786L95.881 89.571h10.146l2.029-5.553h12.373l1.14 5.553zm-10.71-13.224l5.114-13.99 2.877 13.99h-7.991zM96.642 61.177l1.389-8.028s-4.286-1.63-8.754-1.63c-4.83 0-16.3 2.111-16.3 12.376 0 9.658 13.462 9.778 13.462 14.851s-12.075 4.164-16.06.965l-1.447 8.394s4.346 2.111 10.986 2.111c6.642 0 16.662-3.439 16.662-12.799 0-9.72-13.583-10.625-13.583-14.851.001-4.227 9.48-3.684 13.645-1.389z" />
                    </g>
                    <path
                      d="M34.638 72.364l-3.275-16.812s-.396-3.366-4.617-3.366h-15.34l-.18.633s7.373 1.528 14.445 7.253c6.762 5.472 8.967 12.292 8.967 12.292z"
                      fill="#e6a540"
                    />
                    <path fill="none" d="M0 0h141.732v141.732H0z" />
                  </svg>
                  <label htmlFor="exampleInputPassword1">
                    Card Information
                  </label>
                  <input
                    type="tel"
                    id="cardNumber"
                    name="cardNumber"
                    placeholder="0000 0000 0000 0000 "
                    pattern="[\d| ]{16,16}"
                    onChange={handleInputChange}
                    value={formik.values.cardNumber}
                    className="payment_form_control w-100"
                    maxLength={19}
                    minLength={19}
                  />{" "}
                </div>
              </div>

              {formik.touched.cardNumber && formik.errors.cardNumber ? (
                <div className="error">{formik.errors.cardNumber}</div>
              ) : null}
              <div className="d-flex justify-content-between w-100 gap-3 mb-3">
                <div className="custom-input-container">
                  <div className="payment_main_form col-xl-12">
                    <div className="mb-0 ">
                      <label htmlFor="exampleInputPassword1">
                        Expiration Date
                      </label>
                      <input
                        type="tel"
                        id="expirationDate"
                        name="expirationDate"
                        className="payment_form_control w-100"
                        placeholder="MM / YY "
                        onChange={handleInputChange}
                        value={formik.values.expirationDate}
                        pattern="\d\d/\d\d"
                        maxLength="5"
                      />
                    </div>
                  </div>
                  {formik.touched.expirationDate &&
                  formik.errors.expirationDate ? (
                    <div className="error">{formik.errors.expirationDate}</div>
                  ) : null}
                </div>
                <div className="">
                  <div className="payment_main_form col-xl-12">
                    <div className="mb-0 ">
                      <label htmlFor="exampleInputPassword1">Country</label>{" "}
                      <input
                        id="country"
                        name="country"
                        className="payment_form_control w-100"
                        placeholder="United States "
                        onChange={handleInputChange}
                        value={formik.values.country}
                      />
                    </div>
                  </div>
                  {formik.touched.country && formik.errors.country ? (
                    <div className="error">{formik.errors.country}</div>
                  ) : null}
                </div>
                <div className="">
                  <div className="payment_main_form col-xl-12">
                    <label htmlFor="exampleInputPassword1">CVC / CVV</label>
                    <input
                      type="password"
                      id="cvc"
                      name="cvc"
                      pattern="\d{3}"
                      className="payment_form_control w-100"
                      placeholder="3 or 4 Digits "
                      onChange={handleInputChange}
                      value={formik.values.cvc}
                    />
                  </div>
                  {formik.touched.cvc && formik.errors.cvc ? (
                    <div className="error">{formik.errors.cvc}</div>
                  ) : null}
                </div>
              </div>
              <p className="mt-5">
                <button type="submit" className="SiteBtn4">
                  Create Account
                </button>
              </p>
              <p className="m-0 p-0 text-center fw-normal fs-6 text-black">
                Would you like to cancel the payment?
              </p>
              <p className="m-0 mb-5 text-center p-0 mt-1">
                <Link
                  to={"/subscription"}
                  className="text-decoration-underline cursor-pointeer"
                >
                  Yes, please
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
