import React from "react";
import { Link } from "react-router-dom";

const PasswordResetRequest = () => {
  document.title = "National Trust Registry | Password Reset";
  return (
    <div className="container vh-100 d-flex justify-content-center align-items-center">
      <div className="row justify-content-center">
        <div className="col-xl-5 col-lg-6 col-md-8 m-auto">
          <div className="main_center_box">
            <h5>National Trust Registry</h5>
            <h4>Password Reset</h4>
            <p className="my-3 mx-0">Your request is being processed.</p>
            <p>
              Please check your email for instructions. If you do not see an
              email from us then please check your spam folder. Failing that
              please contact support.
            </p>
            <div className="d-flex align-items-center justify-content-center my-md-5 my-4">
              <Link to={"/supports"} className="link_pasword">
                Contact Support
              </Link>
            </div>
            <div className="d-flex justify-content-center gap-5 mt-5 pt-2">
              <div>
                <Link to={"/supports"} className="link-text-color">
                  Help
                </Link>
              </div>
              <div>
                <Link
                  to={"https://www.mytntr.com/privacy/"}
                  className="link-text-color"
                >
                  Privacy
                </Link>
              </div>
              <div>
                <Link
                  to={"https://www.mytntr.com/terms-of-use/"}
                  className="link-text-color"
                >
                  Terms
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
