import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="container ">
      <div className="row vh-100  justify-content-center align-items-center ">
        <div className="col-md-6 text-center">
          <h1 className="display-4">404 Not Found</h1>
          <p className="lead">The page you are looking for does not exist.</p>
          <Link to="/">
            <button type="button" className="btn btn-primary">
              Return To Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
