import React from "react";
import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Structure = ({ children, activeText }) => {  
  const [isRemoveClass, setRemoveClass] = useState(false);
  const handleHideShowClass = (value) => {
    setRemoveClass(value);
  }
  return (
    <>
      <Header handleHideShowClass={handleHideShowClass} />
      <div className={`${!isRemoveClass ? 'hide-left-chat' : ""} table_sec col-xl-12 d-flex main_chat support_main px-0`}>
        <Sidebar activeText={activeText} handleHideShowClass={handleHideShowClass} />
        {children}
      </div>
    </>
  );
};

export default Structure;
