import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { GetTrustsListing } from "../../../redux/features/TrustsService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";

const columns = [
  {
    id: "trustName",
    name: "Trust Name",
    selector: (row) => row.trustName,
    sortable: true,
  },
  {
    id: "grantorNameList",
    name: "Grantor",
    selector: (row) => (row.grantorNameList ? row.grantorNameList : "-"),
    sortable: true,
  },
  {
    id: "attorneyNameList",
    name: "Attorney",
    selector: (row) => (row.attorneyNameList ? row.attorneyNameList : "-"),
    sortable: true,
  },
  {
    id: "successorNameList",
    name: "Successor",
    selector: (row) => (row.successorNameList ? row.successorNameList : "-"),
    sortable: true,
  },
  {
    id: "holderName",
    name: "Current Trust Holder",
    selector: (row) => (row.holderName ? row.holderName : "-"),
    sortable: true,
  },
];

const ArchivedTrusts = ({filteredData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { getTrustsListing } = useSelector((state) => state.trusts);

  useEffect(() => {
    dispatch(
      GetTrustsListing({
        search: "",
        status: "0",
      })
    ).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (row) => {
    if (row?.trustPermission?.isRead === 1) {
      navigate(`/trusts/edit/${row?._id}`);
    }
  };

  return (
    <main className="w-100">
      {loading && <Loader />}
      <DataTable
        columns={columns}
        data={filteredData || getTrustsListing?.data}
        pagination
        onRowClicked={handleRowClick}
        highlightOnHover
        defaultSortFieldId="trustName"
        persistTableHead
      />
    </main>
  );
};

export default ArchivedTrusts;
