import React from "react";
import { Link } from "react-router-dom";

const MainCenterBox = (props) => {
  const { title, className, children } = props;
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100">
        <div className={className}>
          <div className="main_center_box">
            <div className="text-center">
              <img
                src="/assets/images/ntr_logo.png"
                className="img-fluid"
                alt="logo_box"
              />
            </div>
            <h6 className="pt-4 pb-1">{title}</h6>
            <div>{children}</div>

            <div className="d-flex justify-content-center gap-5 mt-5 pt-2">
              <div>
                <Link to={"/supports"} className="link-text-color">
                  Help
                </Link>
              </div>
              <div>
                <Link
                  to={"https://www.mytntr.com/privacy/"}
                  className="link-text-color"
                >
                  Privacy
                </Link>
              </div>
              <div>
                <Link
                  to={"https://www.mytntr.com/terms-of-use/"}
                  className="link-text-color"
                >
                  Terms
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCenterBox;
