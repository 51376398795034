import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import SearchBox from "../../Common/SearchBox";
import _ from "loadsh";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActiveTrusts from "./ActiveTrusts";
import { GetTrustsListing } from "../../../redux/features/TrustsService";
import ArchivedTrusts from "./ArchivedTrusts";
import Loader from "../../Common/Loader";
import moment from "moment";
import ModalCustom from "../../Common/ModalCustom";
import Confirmation from "./Create/Confirmation";

const Trusts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState("");
  const tabs = ["Active", "Archived"];
  const [isCsvDataShow, setCsvDataShow] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const { getTrustsListing } = useSelector((state) => state.trusts);
  const { getUserDetail } = useSelector((state) => state.auths);
  const [loading, setLoading] = useState(false);
  const [isCurrent, setCurrent] = useState("");
  const [isFormated, setFormated] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const csvLink = useRef();

  const handleCsvDownload = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        GetTrustsListing({
          search: search,
          page: 1,
          limit: getTrustsListing?.totalRecord,
          csv: true,
          status: activeTab === 1 ? "0" : "1",
        })
      );

      const appendedData = _?.map(response?.payload?.data, (data) => {
        return {
          trustName: data?.trustName,
          grantorNameList: data?.grantorNameList,
          attorneyNameList: data?.attorneyNameList,
          successorNameList: data?.successorNameList,
          holderName: data?.holderName,
        };
      });
      if (Array.isArray(appendedData)) {
        const sortedData = _.sortBy(appendedData, "trustName");
        setCsvData(sortedData);
        setCsvDataShow(true);
      } else {
        console.error("Invalid data format for CSV export");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (csvData?.length > 0 && isCsvDataShow) {
      csvLink.current.link.click();
      setCsvDataShow(false);
    }

  }, [csvData]);

  const handleSearch = (searchText) => {
    setSearch(searchText);
  };

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredData(getTrustsListing?.data);
    } else {
      const filtered = _.filter(
        getTrustsListing?.data,
        (item) =>
          item?.trustName
            ?.trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase()) ||
          item?.grantorNameList
            ?.trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase()) ||
          (item?.attorneyNameList &&
            item?.attorneyNameList
              .trim()
              .toLowerCase()
              .includes(search.trim().toLowerCase())) ||
          (item?.successorNameList &&
            item?.successorNameList
              .trim()
              .toLowerCase()
              .includes(search.trim().toLowerCase())) ||
          (item?.holderName &&
            item?.holderName
              .trim()
              .toLowerCase()
              .includes(search.trim().toLowerCase()))
      );
      setFilteredData(filtered);
    }
  }, [search, getTrustsListing]);

  const headers = [
    { label: "Trust Name", key: `trustName` },
    { label: "Grantor", key: "grantorNameList" },
    { label: "Attorney", key: "attorneyNameList" },
    { label: "Successor", key: "successorNameList" },
    { label: "Current Trust Holder", key: "holderName" },
  ];

  useEffect(() => {
    if (getUserDetail && getUserDetail?.graceDurationWillEndAt) {
      const planExpiryTimestamp = getUserDetail?.graceDurationWillEndAt;
      const momentDate = moment.unix(planExpiryTimestamp / 1000);
      const formattedDate = momentDate.add(30, 'days').toDate();
      const currentDate = new Date();
      setFormated(formattedDate);
      setCurrent(currentDate);
    }
  }, [getUserDetail]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const AddGrantorDataToTrust = (e) => {
    e.preventDefault();
    navigate("/trusts/add");
  }

  return (
    <>
      {loading && <Loader />}
      <div className="w-100">
        <div className="title_bar d-flex align-items-center justify-content-between">
          <div className="text-style">Trusts</div>
          <div className="d-flex">
            {getTrustsListing?.data && (
              <>
                <button
                  className="btn btn-transparant mx-2"
                  onClick={handleCsvDownload}
                >
                  Export CSV
                </button>
                <CSVLink
                  data={csvData}
                  filename="Trust-list"
                  ref={csvLink}
                  headers={headers}
                />
              </>
            )}

            {/* ((getUserDetail?.graceDurationWillEndAt && !getUserDetail.graceDurationWillEndAt && getUserDetail.graceDurationWillEndAt != '')  ||  (getUserDetail?.parentInfo?.graceDurationWillEndAt && !getUserDetail.parentInfo.graceDurationWillEndAt  && getUserDetail.graceDurationWillEndAt != '')) && getUserDetail?.role === 2) || (!getUserDetail?.graceDurationWillEndAt && getUserDetail?.role === 4) */}
            {/* {(getUserDetail?.role == 2 && ((!getUserDetail.graceDurationWillEndAt || (getUserDetail?.graceDurationWillEndAt && getUserDetail.graceDurationWillEndAt == '' || getUserDetail.graceDurationWillEndAt < isCurrent))))? ( */}
            {((getUserDetail?.role == 2 || getUserDetail?.role == 4) && (getUserDetail?.parentInfo
              ? (!getUserDetail?.parentInfo.graceDurationWillEndAt || (getUserDetail?.graceDurationWillEndAt && getUserDetail?.parentInfo.graceDurationWillEndAt == '' || getUserDetail?.parentInfo.graceDurationWillEndAt < Date.now()))
              : (!getUserDetail?.graceDurationWillEndAt || (getUserDetail?.graceDurationWillEndAt && getUserDetail?.graceDurationWillEndAt == '' || getUserDetail?.graceDurationWillEndAt < Date.now())))) ? (
              <button
                className="btn btn-thm"
                onClick={(e) => {
                  AddGrantorDataToTrust(e);
                }}
                disabled
              >
                Add Trust
              </button>
            ) : (
              <button
                className="btn btn-thm"
                onClick={(e) => {
                  AddGrantorDataToTrust(e);
                }}
              >
                Add Trust
              </button>
            )}
          </div>
        </div>

        <div className="conten-div mx-1">
          <div className="d-flex justify-content-between">
            <SearchBox handleSearch={handleSearch} search={search} />
          </div>
          <div className="d-flex mt-4">
            {_.map(tabs, (tab, tabIndex) => (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setSearch("");
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                  setActiveTab(tabIndex);
                }}
                className={`tab-link ${activeTab === tabIndex ? "active" : ""}`}
                key={`${tab}-${tabIndex}-trusts`}
              >
                {tab}
              </div>
            ))}
          </div>

          <div className="TableData mt-4">
            {activeTab === 0 && <ActiveTrusts filteredData={filteredData} />}
            {activeTab === 1 && <ArchivedTrusts filteredData={filteredData} />}
          </div>
        </div>
      </div>
      {showModal &&
        <ModalCustom size="lg" handleClose="grantorModal" show={toggleModal} >
          <Confirmation
            setShowModal={setShowModal}
            formattedDate={isCurrent}
            currentDateFormated={isFormated}
          />
        </ModalCustom>}
    </>
  );
};

export default Trusts;
