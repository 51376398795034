import React, { useState } from "react";
import MainCenterBox from "../MainCenterBox";
import "react-datepicker/dist/react-datepicker.css";
import SignupGrantorForm1 from "./SignupGrantorForm1";
import SignupGrantorForm2 from "./SignupGrantorForm2";


const SignUp = () => {
  document.title = "National Trust Registry | Sign Up";
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(null); 

  const handleContinue = (formData) => {
    setFormData(formData);
    setCurrentStep(currentStep + 1);
  };


  const handleBack = () => {
    setCurrentStep(currentStep - 1);
};
  return (
    <>
      <MainCenterBox
        title={"Create an Account"}
        className="col-xl-8 col-lg-10 col-md-12"
      >
        <p className="text-center">Peace of mind is a few easy clicks away.</p>
        {currentStep === 1 && <SignupGrantorForm1 handleContinue={handleContinue} formData={formData} />}
        {currentStep === 2 && <SignupGrantorForm2 data1={formData}  handleBack={handleBack}/>}
      </MainCenterBox>
    </>
  );
};

export default SignUp;
