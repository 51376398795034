import React from "react";

const ThankYouCertificate = ({
  setShowModal,
  setShowVerifyPopup,
  isSubmit,
}) => {
  const handleClick = async () => {
    setShowModal(false);
    setShowVerifyPopup(false);
    isSubmit(false);
  };
  return (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
      <div>
        <div className="text-center fw-medium fs-4">Thank You!</div>
        <div className="py-4 fs-4 text-thanks">
          We will inform you when the death certificate <br />
          verification will be completed.
        </div>
        <div className="pt-2 text-center">
          <button className="SiteBtnThanks" onClick={handleClick}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouCertificate;
