import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CreateSupportService } from "../../redux/features/AuthService";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import ModalCustom from "../Common/ModalCustom";
import SupportSubmitPopup from "./SupportSubmitPopup";

const RequestSupport = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const accessToken = Cookies.get("ntr-token");
  const formik = useFormik({
    initialValues: {
      email: accessToken ? Cookies.get("ntr-email-login") : "",
      subject: "",
      message: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "*Please enter an email address.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        errors.email = "Invalid email address";
      }

      if (!values.subject) {
        errors.subject = "*Please enter subject";
      }

      if (!values.message) {
        errors.message = "*Please enter a message.";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const response = await dispatch(CreateSupportService(values));
      if (response.type === "auths/CreateSupportService/fulfilled") {
        toast.success(response?.payload?.message, { autoClose: 3000 });
        setShowModal(true);
      } else {
        toast.error(response?.payload?.response?.data?.error, {
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100">
        <div className={"col-xl-4 col-md-8"}>
          <div className="main-req-supp-div bg-white h-auto pb-4">
            <p className="support-head">National Trust Registry</p>
            <h6 className="text-center fw-medium py-2 fs-5 m-0 p-0  ">
              {"Request Support"}
            </h6>
            <p className="m-0 p-0 mb-2 ">
              Have a question or problem? Please let us know!
            </p>
            <div className="form-support">
              <form onSubmit={formik.handleSubmit}>
                {!accessToken && (
                  <div className="mb-2">
                    <div className="support-input-main ">
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        id="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        className="input-field py-3 bg-transparent "
                      />
                    </div>
                    {formik.errors.email && (
                      <div className="error">{formik.errors.email}</div>
                    )}
                  </div>
                )}

                <div className="mb-2">
                  <div className="support-input-main ">
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      id="subject"
                      onChange={formik.handleChange}
                      value={formik.values.subject}
                      className="input-field py-3 bg-white "
                    />
                  </div>
                  {formik.errors.subject && (
                    <div className="error">{formik.errors.subject}</div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="form-group mb-0">
                    <textarea
                      rows="4"
                      placeholder="Message"
                      name="message"
                      id="message"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      className="w-100 textarea-support"
                    />
                  </div>
                  {formik.errors.message && (
                    <div className="error">{formik.errors.message}</div>
                  )}
                </div>
                <p className="text-end mt-3 mb-0 ">
                  <button
                    style={{ background: "#0059C7" }}
                    type="submit"
                    className="px-4 py-2 text-white rounded-pill "
                  >
                    Request Support
                  </button>
                </p>
              </form>
            </div>

            <div className="d-flex justify-content-center gap-5 mt-3 pt-2">
              <Link to={"/supports"} className="link-text-color">
                Help
              </Link>
              <Link
                to={"https://www.mytntr.com/privacy/"}
                className="link-text-color"
              >
                Privacy
              </Link>
              <Link
                to={"https://www.mytntr.com/terms-of-use/"}
                className="link-text-color"
              >
                Terms
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ModalCustom size="lg" className="support-modal" show={showModal}>
        <SupportSubmitPopup setShowModal={setShowModal} />
      </ModalCustom>
    </div>
  );
};

export default RequestSupport;
