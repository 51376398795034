import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";

export default function ProfileDetailForm({
  getUserDetail,
  validate,
  onSubmit,
  handleKeyPress,
  handleUploadImage,
  syncInput,
  isSetRemoveSSNNumber,
  transformDisplay,
  value,
  ssnValue,
  handleResetModal,
  allowedRolesForSpouse,
  setAskSpousePopup,
  currentUnixTimestamp,
  isChildInfo,
}) {
  return (
    <>
      <div className="">
        <div className="col-xl-12 btm_details_sec">
          <div className="col-xl-12 d-flex align-items-center justify-content-between mt-4">
            <p className="fw-semibold fs-large fs-4 ">
              {`${getUserDetail?.firstName ?? ""}`}{" "}
              {`${getUserDetail?.lastName ?? ""}`}
            </p>
          </div>
          <div className="profile_main_sec_detail mx-2  mb-3">
            <Formik
              initialValues={{
                firstName: getUserDetail?.firstName || "",
                lastName: getUserDetail?.lastName || "",
                phoneNumber: getUserDetail?.phoneNumber,
                email: getUserDetail?.email,
                barNumber: getUserDetail?.barNumber || "",
                address1: getUserDetail?.address1,
                address2: getUserDetail?.address2,
                city: getUserDetail?.city,
                state: getUserDetail?.state,
                zipCode: getUserDetail?.zipCode,
                dob: getUserDetail?.dob || "",
                drivingLicenseFront: getUserDetail?.drivingLicenseFront || "",
                drivingLicenseBack: getUserDetail?.drivingLicenseBack || "",
                drivingLicenseFrontShow:
                  getUserDetail?.drivingLicenseFrontInfo?.oldImgName || "",
                drivingLicenseBackShow:
                  getUserDetail?.drivingLicenseBackInfo?.oldImgName || "",
                ssnNumber: getUserDetail?.ssnNumber || "",
                ssnValue: getUserDetail?.ssnNumber || "",
                firm: getUserDetail?.firm || "",
                stateLicensed: getUserDetail?.stateLicensed || "",
              }}
              enableReinitialize={true}
              validate={validate}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => {
                return (
                  <>
                    <Form>
                      <div className="profile_title_grid d-flex justify-content-between">
                        <div>
                          <p className="m-0 p-0 fs-5 fw-medium">Details</p>
                          <p className="lastUpdatedText">
                            Last Updated{" "}
                            {moment(getUserDetail?.updatedAt).format(
                              "MM/DD/YYYY h:mm a"
                            )}
                          </p>
                        </div>
                        <div className="btn_sec_right color_change">
                          <button
                            type="submit"
                            className="profile_save_btn rounded-pill text-primary fw-medium"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="">
                        <div className="main_grid_top grid-container gap-0 pt-2">
                          <div className="row m-0 w-100">
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="firstName"
                                  className="profile_section_label"
                                >
                                  Fist Name
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0 ps-0"
                                  name="firstName"
                                  id="firstName"
                                  value={values?.firstName}
                                  placeholder="John"
                                  onChange={handleChange}
                                />
                                {errors?.firstName && touched?.firstName ? (
                                  <p className="error">{errors?.firstName}</p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="lastName"
                                  className="profile_section_label"
                                >
                                  Last Name
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0 ps-0"
                                  name="lastName"
                                  id="lastName"
                                  value={values?.lastName}
                                  placeholder="John"
                                  onChange={handleChange}
                                />
                                {errors?.lastName && touched?.lastName ? (
                                  <p className="error">{errors?.lastName}</p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="phoneNumber"
                                  className="profile_section_label"
                                >
                                  Phone Number
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0 ps-0"
                                  name="phoneNumber"
                                  id="phoneNumber"
                                  value={values?.phoneNumber}
                                  placeholder="+1 (123) 123-1234"
                                  onChange={handleChange}
                                  onKeyPress={handleKeyPress}
                                />
                                {errors?.phoneNumber && touched?.phoneNumber ? (
                                  <p className="error">{errors?.phoneNumber}</p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="email"
                                  className="profile_section_label"
                                >
                                  Email Address
                                </label>
                                <Field
                                  type="email"
                                  className="form-control border-0 bg-white ps-0"
                                  name="email"
                                  id="email"
                                  value={values?.email}
                                  placeholder="Enter Email"
                                  disabled
                                />
                              </div>
                            </div>
                            {getUserDetail?.role === 3 && (
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className="form-group">
                                  <label
                                    htmlFor="barNumber"
                                    className="profile_section_label"
                                  >
                                    Bar Number
                                  </label>
                                  <Field
                                    type="barNumber"
                                    className="form-control border-0 bg-white ps-0"
                                    name="barNumber"
                                    id="barNumber"
                                    value={values?.barNumber}
                                    placeholder="Enter Bar Number"
                                    onChange={handleChange}
                                  />
                                  {errors?.barNumber && touched?.barNumber ? (
                                    <p className="error">{errors?.barNumber}</p>
                                  ) : null}
                                </div>
                              </div>
                            )}
                            {getUserDetail?.role === 3 && (
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className="form-group">
                                  <label
                                    htmlFor="stateLicensed"
                                    className="profile_section_label"
                                  >
                                    State Licensed
                                  </label>
                                  <Field
                                    type="stateLicensed"
                                    className="form-control border-0 bg-white ps-0"
                                    name="stateLicensed"
                                    id="stateLicensed"
                                    value={values?.stateLicensed}
                                    placeholder="Enter State Licensed"
                                    onChange={handleChange}
                                  />
                                  {errors?.stateLicensed &&
                                  touched?.stateLicensed ? (
                                    <p className="error">
                                      {errors?.stateLicensed}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            )}
                            {getUserDetail?.role === 3 && (
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className="form-group">
                                  <label
                                    htmlFor="firm"
                                    className="profile_section_label"
                                  >
                                    Firm (if applicable)
                                  </label>
                                  <Field
                                    type="firm"
                                    className="form-control border-0 bg-white ps-0"
                                    name="firm"
                                    id="firm"
                                    value={values?.firm}
                                    placeholder="Enter Firm"
                                    onChange={handleChange}
                                  />
                                  {errors?.firm && touched?.firm ? (
                                    <p className="error">{errors?.firm}</p>
                                  ) : null}
                                </div>
                              </div>
                            )}
                            {getUserDetail?.role !== 3 && (
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className="form-group">
                                  <label
                                    htmlFor="drivingLicenseFrontShow"
                                    className="bg-white profile_section_label"
                                  >
                                    Drivers License - Front
                                  </label>
                                  <div
                                    className="files-wr"
                                    data-count-files="1"
                                  >
                                    <div className="pt-2">
                                      <label
                                        htmlFor="drivingLicenseFrontShow"
                                        className="bi bi-upload w-50 mb-0"
                                        //className="modal-media-upload-label w-100 mb-0"
                                      >
                                        {values?.drivingLicenseFrontShow
                                          ? values?.drivingLicenseFrontShow
                                              .length > 9
                                            ? values.drivingLicenseFrontShow.substring(
                                                0,
                                                9
                                              ) + "..."
                                            : values.drivingLicenseFrontShow
                                          : "Upload File"}
                                      </label>
                                      <Field
                                        type="file"
                                        className="form-control"
                                        id="drivingLicenseFrontShow"
                                        name="drivingLicenseFrontShow"
                                        placeholder="Upload File"
                                        value={""}
                                        onChange={async (e) => {
                                          try {
                                            const file =
                                              e.currentTarget.files[0];
                                            const uploadData =
                                              await handleUploadImage(file);
                                            setFieldValue(
                                              "drivingLicenseFront",
                                              uploadData?.mediaName
                                            );
                                            setFieldValue(
                                              "drivingLicenseFrontShow",
                                              file?.name
                                            );
                                          } catch (error) {}
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {errors?.drivingLicenseFrontShow &&
                                    touched?.drivingLicenseFrontShow && (
                                      <span className="error">
                                        {errors?.drivingLicenseFrontShow}
                                      </span>
                                    )}
                                </div>
                              </div>
                            )}
                            {getUserDetail?.role !== 3 && (
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className="form-group mb-0">
                                  <label
                                    htmlFor="drivingLicenseBacklable"
                                    className="bg-white profile_section_label"
                                  >
                                    Drivers License - Back
                                  </label>
                                  <div
                                    className="files-wr"
                                    data-count-files="1"
                                  >
                                    <div className="pt-2">
                                      <label
                                        htmlFor="drivingLicenseBackShow"
                                        className="bi bi-upload w-50 mb-0"
                                        //className="modal-media-upload-label w-100 mb-0"
                                      >
                                        {values?.drivingLicenseBackShow
                                          ? values?.drivingLicenseBackShow
                                              .length > 9
                                            ? values.drivingLicenseBackShow.substring(
                                                0,
                                                9
                                              ) + "..."
                                            : values.drivingLicenseBackShow
                                          : "Upload File"}
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        id="drivingLicenseBackShow"
                                        name="drivingLicenseBackShow"
                                        placeholder="Upload File"
                                        onChange={async (e) => {
                                          try {
                                            const file =
                                              e.currentTarget.files[0];
                                            const uploadData =
                                              await handleUploadImage(file);
                                            setFieldValue(
                                              "drivingLicenseBack",
                                              uploadData?.mediaName
                                            );
                                            setFieldValue(
                                              "drivingLicenseBackShow",
                                              file?.name
                                            );
                                          } catch (error) {}
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      <div className="file-item hide-btn">
                                        <span className="file-name"></span>
                                        <span className="btn btn-del-file">
                                          x
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {errors?.drivingLicenseBackShow &&
                                  touched?.drivingLicenseBackShow ? (
                                    <p className="error">
                                      {errors?.drivingLicenseBackShow}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            )}
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="address1"
                                  className="profile_section_label"
                                >
                                  Address Line 1
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0 ps-0"
                                  id="address1"
                                  value={values?.address1}
                                  name="address1"
                                  placeholder="Enter Your Address Line 1"
                                  onChange={handleChange}
                                />
                                {errors?.address1 && touched?.address1 ? (
                                  <p className="error">{errors?.address1}</p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="address2"
                                  className="profile_section_label"
                                >
                                  Address Line 2
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0  ps-0"
                                  id="address2"
                                  name="address2"
                                  placeholder="Enter Your Address Line 2"
                                  onChange={handleChange}
                                  value={values?.address2}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="city"
                                  className="profile_section_label"
                                >
                                  City
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0  ps-0"
                                  id="city"
                                  name="city"
                                  placeholder="Enter Your City"
                                  onChange={handleChange}
                                  value={values?.city}
                                />
                                {errors?.city && touched?.city ? (
                                  <p className="error">{errors?.city}</p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="state"
                                  className="profile_section_label"
                                >
                                  State
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0 ps-0"
                                  id="state"
                                  value={values?.state}
                                  name="state"
                                  placeholder="Enter Your State"
                                  onChange={handleChange}
                                />
                                {errors?.state && touched?.state ? (
                                  <p className="error">{errors?.state}</p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="zipCode"
                                  className="profile_section_label"
                                >
                                  Zip Code
                                </label>
                                <div>
                                  <Field
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    placeholder="Enter Your Zip Code"
                                    value={values?.zipCode}
                                    className="form-control border-0 ps-0"
                                    onChange={handleChange}
                                  />
                                </div>
                                {errors?.zipCode && touched?.zipCode ? (
                                  <p className="error">{errors?.zipCode}</p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                              <div className="form-group">
                                <label
                                  htmlFor="zipCode"
                                  className="profile_section_label"
                                >
                                  Date Of Birth
                                </label>
                                <div>
                                  <DatePicker
                                    selected={values?.dob}
                                    onChange={(date) =>
                                      setFieldValue("dob", date)
                                    }
                                    showYearDropdown
                                    scrollableYearDropdown
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    className="form-control border-0 ps-0"
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={1000}
                                    maxDate={new Date()}
                                    placeholderText="Enter Date of Birth"
                                  />
                                </div>
                                {errors?.dob && touched?.dob ? (
                                  <p className="error">{errors?.dob}</p>
                                ) : null}
                              </div>
                            </div>
                            {getUserDetail?.role === 2 && (
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className="form-group">
                                  <label
                                    htmlFor="ssnNumber"
                                    className="profile_section_label"
                                  >
                                    SSN Number
                                  </label>
                                  <div>
                                    {console.log(
                                      "transformDisplay(value)>>>>2",
                                      value,
                                      transformDisplay(value)
                                    )}
                                    <input
                                      className="border-0 ps-0"
                                      onChange={(e) => {
                                        syncInput(e, setFieldValue);
                                      }}
                                      value={
                                        getUserDetail?.ssnNumber
                                          ? isSetRemoveSSNNumber === false
                                            ? values?.ssnValue
                                              ? values?.ssnValue
                                              : transformDisplay(value)
                                            : transformDisplay(value)
                                          : transformDisplay(value)
                                      }
                                      disabled
                                      maxLength={11}
                                      placeholder="Enter SSN/ITIN Number"
                                    />
                                    {errors?.ssnNumber && touched?.ssnNumber ? (
                                      <p className="error">
                                        {errors?.ssnNumber}
                                      </p>
                                    ) : null}
                                    <input
                                      className="form-control border-0 ps-0"
                                      style={{
                                        top: 0,
                                        background: "transparent",
                                        color: "transparent",
                                        border: "none",
                                        visibility: "hidden",
                                      }}
                                      value={ssnValue}
                                      maxLength={9}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {getUserDetail?.role === 4 && (
                              <div className="col-md-4 col-lg-3 col-xl-2 pt-2">
                                <div className="form-group">
                                  <label
                                    htmlFor="ssnNumber"
                                    className="profile_section_label"
                                  >
                                    SSN Number
                                  </label>
                                  <div>
                                    {console.log(
                                      "transformDisplay(value)",
                                      transformDisplay(value)
                                    )}
                                    <input
                                      className="form-control border-0 ps-0"
                                      onChange={(e) => {
                                        syncInput(e, setFieldValue);
                                      }}
                                      value={
                                        getUserDetail?.ssnNumber
                                          ? isSetRemoveSSNNumber === false
                                            ? values?.ssnValue
                                              ? values?.ssnValue
                                              : transformDisplay(value)
                                            : transformDisplay(value)
                                          : transformDisplay(value)
                                      }
                                      maxLength={11}
                                      placeholder="Enter SSN/ITIN Number"
                                    />

                                    {errors?.ssnNumber && touched?.ssnNumber ? (
                                      <p className="error">
                                        {errors?.ssnNumber}
                                      </p>
                                    ) : null}
                                    <input
                                      className="form-control border-0 ps-0"
                                      style={{
                                        top: 0,
                                        background: "transparent",
                                        color: "transparent",
                                        border: "none",
                                        visibility: "hidden",
                                      }}
                                      value={ssnValue}
                                      maxLength={9}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
          <div>
            {!isChildInfo && (
              <button
                className="px-4 my-3 bg-primary text-white rounded-pill py-2 mt-3"
                onClick={handleResetModal}
              >
                Reset Password
              </button>
            )}
            {!isChildInfo &&
            allowedRolesForSpouse?.includes(getUserDetail?.role) &&
            getUserDetail?.graceDurationWillEndAt >= currentUnixTimestamp &&
            (!(getUserDetail?.childId || getUserDetail?.parentId)) ? (
              <>
                <button
                  className="btn mb-3 mt-3 mx-2 upgrade-successor-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setAskSpousePopup(true);
                  }}
                >
                  Add Spouse
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
