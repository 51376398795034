import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { GetAttorneyListing } from "../../../redux/features/AttorneyService";
import Loader from "../../Common/Loader";
const columns = [
  {
    id: "firstName",
    name: "First Name",
    selector: (row) => row.firstName,
    sortable: true,
  },
  {
    id: "lastName",
    name: "Last Name",
    selector: (row) => row.lastName,
    sortable: true,
  },
  {
    id: "email",
    name: "Email",
    selector: (row) => (row.email ? row.email : "-"),
    sortable: true,
  },
  {
    id: "phoneNumber",
    name: "Phone Number",
    selector: (row) => (row.phoneNumber ? row.phoneNumber : "-"),
    sortable: true,
  },
  {
    id: "lastSession",
    name: "Last Session",
    selector: (row) =>
      moment
        (row.createdAt)
        .format("MM/DD/yyyy @ HH:mm PT"),
    sortable: true,
  },
  {
    id: "lastUpdated",
    name: "Last Updated",
    selector: (row) =>
      moment
      (row.updatedAt)
        .format("MM/DD/yyyy @ HH:mm PT"),
    sortable: true,
  },
];

const ActiveAttorney = ({filteredData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { getAttorneyListing } = useSelector((state) => state.attorney);

  useEffect(() => {
    setLoading(true);
    dispatch(
      GetAttorneyListing({
        search: "",
        role: 3,
        status: "1",
      })
    ).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleRowClick = (row) => {
    navigate(`/attorney/${row?._id}`);
  };

  return (
    <>
      {loading && <Loader />}
      <DataTable
        columns={columns}
        data={filteredData || getAttorneyListing?.data}
        pagination
        onRowClicked={handleRowClick}
        highlightOnHover
        defaultSortFieldId="firstName"
        persistTableHead
      />
    </>
  );
};

export default ActiveAttorney;
