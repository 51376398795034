import Cookies from "js-cookie";

export const getHeader = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  headers.mode = "cors";

  return headers;
};

export const getAuthenticationHeader = (token, type) => {
  const headers = {
    "Content-Type": type ? type : "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: token ? "Bearer " + Cookies.get(token) : "Bearer " + Cookies.get("ntr-token"),
  };
  headers.mode = "cors";
  return headers;
};

export const getVerifyTokenHeader = (token, type) => {
  const headers = {
    "Content-Type": type ? type : "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + Cookies.get(token ? token : "ntr-verify-token"),
  };
  headers.mode = "cors";
  return headers;
};
