import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Button from "../Common/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "*Email is required to Sign Up";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.firstName) {
    errors.firstName = "*First Name is required to Sign Up";
  }
  if (!values.lastName) {
    errors.lastName = "*Last Name is required to Sign Up";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "*Phone Number is required to Sign Up";
  } else if (!/\d{3,10}$/.test(values.phoneNumber)) {
    errors.phoneNumber = "Invalid phone number";
  }
  if (!values.dob) {
    errors.dob = "*Date Of Birth is required to Sign Up";
  }
  if (!values.state) {
    errors.state = "*State is required to Sign Up";
  }
  if (!values.city) {
    errors.city = "*City is required to Sign Up";
  }
  if (!values.zipCode) {
    errors.zipCode = "*Zip code is required to Sign Up";
  }
  if (!values.address1) {
    errors.address1 = "*Address 1 is required to Sign Up";
  }
  return errors;
};

const SpouseSignUp1 = ({ handleContinue, formData }) => {
  document.title = "National Trust Registry | Sign Up";
  const navigate = useNavigate();
  const { id } = useParams();

  let initialValuesWithoutEmail = {
    firstName: formData ? formData?.firstName : "",
    lastName: formData ? formData?.lastName : "",
    phoneNumber: formData ? formData?.phoneNumber : "",
    email: formData ? formData?.email : "",
    dob: formData ? formData?.dob : "",
    address1: formData ? formData?.address1 : "",
    address2: formData ? formData?.address2 : "",
    city: formData ? formData?.city : "",
    state: formData ? formData?.state : "",
    zipCode: formData ? formData?.zipCode : "",
    isEmail: formData ? formData?.isEmail : "",
  };

  const path = window.location.pathname;
  const parts = path.split("/").filter((part) => part !== "");
  if (parts.length >= 3) {
    const email = parts[1];
    const inviteId = parts[2];
    const userId = parts[3];

    if (id) {
      initialValuesWithoutEmail.email = email;
      initialValuesWithoutEmail.isEmail = inviteId;
      initialValuesWithoutEmail.userId = userId;
    }
  }

  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  const formik = useFormik({
    initialValues: initialValuesWithoutEmail,
    validate,
    onSubmit: async (values) => {
      const data1 = {
        ...values,
      };
      handleContinue(data1);
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mt-0 mb-0">
            <span>Personal Information</span>
          </div>
          <div className="col-md-6">
            <div className="main_form_box mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_4487_48705)">
                  <path
                    d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4487_48705">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  id="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  className="input-field"
                />
              </div>
            </div>
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <div className="main_form_box mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_4487_48705)">
                  <path
                    d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4487_48705">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  id="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  className="input-field"
                />
              </div>
            </div>
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="col-md-12">
            <div className="main_form_box mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_1659_28499)">
                  <path
                    d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                    fill="#42474E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1659_28499">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="form-group">
                {id ? (
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="input-field"
                    readOnly
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="input-field"
                  />
                )}
              </div>
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <div className="main_form_box mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_2348_29835)">
                  <path
                    d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.05999 13.63 6.61999 10.79L8.47 8.94001C8.89999 8.51001 9.10999 7.91001 9.03999 7.30001L8.74999 4.78001C8.62999 3.77001 7.77999 3.01001 6.76 3.01001H5.03C3.9 3.01001 2.95999 3.95001 3.02999 5.08001C3.55999 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
                    fill="#42474E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2348_29835">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  className="input-field"
                  maxLength={13}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <div className="main_form_box mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_4487_48747)">
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4487_48747">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="form-group">
                <DatePicker
                  selected={formik?.values?.dob}
                  onChange={(date) => formik.setFieldValue("dob", date)}
                  className="border-0 bg-transparent"
                  placeholderText="Date Of Birth"
                  showYearDropdown
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={1000}
                  maxDate={new Date()}
                />
              </div>
            </div>
            {formik.touched.dob && formik.errors.dob ? (
              <div className="error">{formik.errors.dob}</div>
            ) : null}
          </div>

          <div className="col-12 mt-4 mb-0">
            <span>Address Information</span>
          </div>

          <div className="col-md-12">
            <div className="main_form_box mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_4487_48813)">
                  <path
                    d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4487_48813">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Address Line 1"
                  name="address1"
                  id="address1"
                  onChange={formik.handleChange}
                  value={formik.values.address1}
                  className="input-field"
                />
              </div>
            </div>
            {formik.touched.address1 && formik.errors.address1 ? (
              <div className="error">{formik.errors.address1}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <div className="main_form_box mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_4487_48813)">
                  <path
                    d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4487_48813">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Address Line 2 (Optional)"
                  name="address2"
                  id="address2"
                  onChange={formik.handleChange}
                  value={formik.values.address2}
                  className="input-field"
                />
              </div>
            </div>
            {formik.touched.address2 && formik.errors.address2 ? (
              <div className="error">{formik.errors.address2}</div>
            ) : null}
          </div>
          <div className="col-md-2">
            <div className="main_form_box mt-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  id="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  className="input-field"
                />
              </div>
            </div>
            {formik.touched.city && formik.errors.city ? (
              <div className="error">{formik.errors.city}</div>
            ) : null}
          </div>
          <div className="col-md-2">
            <div className="main_form_box mt-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="State"
                  name="state"
                  id="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  className="input-field"
                />
              </div>
            </div>
            {formik.touched.state && formik.errors.state ? (
              <div className="error">{formik.errors.state}</div>
            ) : null}
          </div>
          <div className="col-md-2">
            <div className="main_form_box mt-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Zip Code"
                  name="zipCode"
                  id="zipCode"
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    formik.handleChange({
                      target: {
                        name: "zipCode",
                        value: numericValue,
                      },
                    });
                  }}
                  value={formik.values.zipCode}
                  className="input-field"
                />
              </div>
            </div>
            {formik.touched.zipCode && formik.errors.zipCode ? (
              <div className="error">{formik.errors.zipCode}</div>
            ) : null}
          </div>
        </div>

        <div className="mt-3 btn-div d-flex justify-content-between align-items-center">
          <div type="submit" className="SiteBtn2" onClick={() => navigate("/")}>
            Sign In
          </div>
          <Button type="submit" className="btn-thm SiteBtn" text="Continue" />
        </div>
      </Form>
    </div>
  );
};

export default SpouseSignUp1;
