import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";

const url = process.env.REACT_APP_API_URL;

export const AddGrantorService = createAsyncThunk(
  "grantors/AddGrantorService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_common_user`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const GetGrantorListTrust = createAsyncThunk(
  "grantors/GetGrantorListTrust",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/get_current_grantor_list`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const GetActiveGrantorList = createAsyncThunk(
  "grantors/GetActiveGrantorList",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const { role, status, search, csv } = args;
      const resp = await axios.post(
        `${url}/user/get_user_list_common`,
        {
          role: role,
          status: status,
          search: search,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return { ...resp.data, ...{ csv: csv } };
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const GetSingleGrantorService = createAsyncThunk(
  "grantors/GetSingleGrantor",
  async (args, { rejectWithValue }) => {
    try {
      const { userId } = args;
      const resp = await axios.post(
        `${url}/user/get_user_info`,
        {
          userId: userId,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const UpdateSingleGrantor = createAsyncThunk(
  "grantors/updateSingleGrantor",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = args;
      const resp = await axios.post(`${url}/user/common_user_update`, data, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const UpdateSingleGrantorStatus = createAsyncThunk(
  "grantors/updateSingleGrantorStatus",
  async (args, { rejectWithValue }) => {
    try {
      const { userId, isStatus } = args;
      const resp = await axios.post(
        `${url}/user/common_user_update`,
        {
          userId: userId,
          isStatus: isStatus,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

const GrantorSlice = createSlice({
  name: "grantors",
  initialState: {
    error: false,
    loading: false,
    getGrantorListing: [],
    getSingleGrantor: [],
    addGrantor: [],
    getGrantorListTrust : []
  },
  reducers: {
    setGrantorPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddGrantorService.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddGrantorService.fulfilled, (state, action) => {
        state.loading = false;
        state.addGrantor = action?.payload?.data;
      })
      .addCase(AddGrantorService.rejected, (state) => {
        state.loading = false;
      })

      .addCase(GetGrantorListTrust.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetGrantorListTrust.fulfilled, (state, action) => {
        state.loading = false;
        state.getGrantorListTrust = action?.payload?.data;
      })
      .addCase(GetGrantorListTrust.rejected, (state) => {
        state.loading = false;
      })

      .addCase(GetActiveGrantorList.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetActiveGrantorList.fulfilled, (state, action) => {
        state.loading = false;
        if (!action?.payload?.csv) {
          state.getGrantorListing = action?.payload;
        }
      })
      .addCase(GetActiveGrantorList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(UpdateSingleGrantorStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateSingleGrantorStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpdateSingleGrantorStatus.rejected, (state) => {
        state.loading = false;
      })

      .addCase(GetSingleGrantorService.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSingleGrantorService.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleGrantor = action?.payload?.data;
      })
      .addCase(GetSingleGrantorService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateSingleGrantor.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateSingleGrantor.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.getSingleGrantor = action?.payload?.data;
        }
      })
      .addCase(UpdateSingleGrantor.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setGrantorPage } = GrantorSlice.actions;

export default GrantorSlice.reducer;
