import React, { useContext, useEffect, useCallback, useRef } from "react";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../contexts/AuthContexts";
import RenewSubscriptionAlert from "../Common/RenewSubscriptionAlert";
import { GetUserDetailsService } from "../../redux/features/AuthService";

const PrivateWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authStatus } = useContext(AuthContext);
  const { getUserDetail, successorGrantor, mainGrantor } = useSelector((state) => state.auths);
  const fetched = useRef(false);

  useEffect(() => {
    if (!fetched.current && !getUserDetail) {
      fetched.current = true;
      dispatch(GetUserDetailsService());
    }
  }, [dispatch, getUserDetail]);

  const isGraceDurationCompleted = useCallback((userDetail) => {
    if (!userDetail) return false;
    const currentTime = Date.now();
    return userDetail.planExpiryDate < currentTime && userDetail.graceDurationWillEndAt < currentTime;
  }, []);

  const grantorShouldRedirectForSubscription = useCallback(() => {
    return (
      (isGraceDurationCompleted(getUserDetail) && getUserDetail.activateByAdmin == 1 && getUserDetail.role === 2) ||
      (mainGrantor && isGraceDurationCompleted(mainGrantor) && getUserDetail.role === 2)
    );
  }, [getUserDetail, mainGrantor, isGraceDurationCompleted]);

  const successorShouldRedirectForSubscription = useCallback(() => {
    return isGraceDurationCompleted(getUserDetail) && !successorGrantor && getUserDetail.role === 4;
  }, [getUserDetail, isGraceDurationCompleted]);

  const redirectToNoticePage = useCallback(() => {
    return (
      successorGrantor &&
      isGraceDurationCompleted(successorGrantor) &&
      !getUserDetail.planExpiryDate &&
      !getUserDetail.graceDurationWillEndAt &&
      location.pathname !== '/subscription-successor'
    );
  }, [getUserDetail, successorGrantor, location.pathname, isGraceDurationCompleted]);

  const notAllowedPagesForActiveUser = useCallback(() => {
    return (
      (getUserDetail && !isGraceDurationCompleted(getUserDetail) && location.pathname === '/purchase_subscription') || (successorGrantor && !isGraceDurationCompleted(successorGrantor) && location.pathname === '/notice')
    );
  }, [getUserDetail, location.pathname, isGraceDurationCompleted]);

  const handleRedirection = useCallback(() => {
    if (grantorShouldRedirectForSubscription() || (successorShouldRedirectForSubscription() && !redirectToNoticePage())) {
      if (location.pathname !== '/purchase_subscription') {
        navigate('/purchase_subscription');
        return null;
      }
    } else if (redirectToNoticePage() && location.pathname !== '/notice') {
      navigate('/notice');
      return null;
    } else if (notAllowedPagesForActiveUser()) {
      navigate('/');
      return null;
    }

    return authStatus === 1 ? <Outlet /> : <Navigate to="/sign-in" />;
  }, [
    grantorShouldRedirectForSubscription,
    successorShouldRedirectForSubscription,
    redirectToNoticePage,
    notAllowedPagesForActiveUser,
    location.pathname,
    navigate,
    authStatus,
  ]);

  const currentTime = Date.now();
  const isGraceDuration = getUserDetail?.isSubscriptionDeactive == 1 && getUserDetail?.planExpiryDate < currentTime && getUserDetail.graceDurationWillEndAt >= currentTime;
  const showRenewAlert = isGraceDuration && [2, 4].includes(getUserDetail.role);

  return (
    <>
      {showRenewAlert ? (
        <RenewSubscriptionAlert getUserDetail={getUserDetail} mainGrantor={mainGrantor} />
      ) : null}
      {handleRedirection()}
    </>
  );
};

export default PrivateWrapper;
