import React, { useEffect, useState } from "react";
import { GetSuccessorListing } from '../../../redux/features/SuccessorService';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Loader from "../../Common/Loader";

const ActiveSuccessor = ({ filteredData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSuccessorListing } = useSelector((state) => state.successor);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      id: "firstName",
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
      sortAsc: true,
    },
    {
      id: "lastName",
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => (row.email ? row.email : "-"),
      sortable: true,
    },
    {
      id: "phoneNumber",
      name: "Phone Number",
      selector: (row) => (row.phoneNumber ? row.phoneNumber : "-"),
      sortable: true,
    },
    {
      id: "createdAt",
      name: "Last Session",
      selector: (row) =>
        moment(row.createdAt).format(
          "MM/DD/yyyy @ HH:mm PT"
        ),
      sortable: true,
    },
    {
      id: "updatedAt",
      name: "Last Updated",
      selector: (row) =>
        moment(row.updatedAt).format(
          "MM/DD/yyyy @ HH:mm PT"
        ),
      sortable: true,
    },
  ];
  useEffect(() => {
    setLoading(true);
    dispatch(
      GetSuccessorListing({
        search: "",
        role: 4,
        status: "1",
      })
    ).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (row) => {
    navigate(`/successor/${row?._id}`);
  };

  return (
    <>
    {loading && <Loader />}
    <DataTable
      columns={columns}
      data={filteredData || getSuccessorListing?.data}
      pagination
      onRowClicked={handleRowClick}
      highlightOnHover
      defaultSortFieldId="firstName"
      persistTableHead
    />
  </>
  )
}

export default ActiveSuccessor
