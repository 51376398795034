import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AdminResetPasswordService } from "../../redux/features/AuthService";

const ResetPasswordModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    cPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "*Please make sure the password meets all of the criteria below")
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
        "*Please make sure the password meets all of the criteria below"
      )
      .required("*Please Enter your password"),
    cPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "password and confirm password do not match"
      )
      .required("Please Enter Confirm Password"),
  });

  const handleSubmit = (values) => {
    dispatch(AdminResetPasswordService(values));
    navigate("/reset-success");
  };

  return (
    <div className="container">
      <div className="row d-flex vh-100 align-items-center justify-content-center">
        <div className="col-md-8 col-lg-5 mx-auto forgot-box">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <p className="m-0 p-0 fw-medium fs-5 mb-3 text-center">
                  Reset your password
                </p>
                <div>
                  <div className="w-100 mb-3">
                    <label htmlFor="password" className="fw-medium">
                      New Password:
                    </label>
                    <div className="mt-1">
                      <Field
                        type="password"
                        className="bg-white p-2 border-0 w-100 border-2"
                        id="password"
                        name="password"
                        placeholder=""
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="w-100 mt-3">
                    <label htmlFor="cPassword" className="fw-medium">
                      Confirm New Password:
                    </label>
                    <div className="mt-1">
                      <Field
                        type="password"
                        className="bg-white p-2 border-0 w-100"
                        id="cPassword"
                        name="cPassword"
                        placeholder=""
                      />
                      <ErrorMessage
                        name="cPassword"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3 row">
                  <div className="col-md-6">
                    <div className="pwdreqments ">
                      At least 1 lowercase letter
                    </div>
                    <div className="pwdreqments ">
                      At least 1 UPPERCASE letter
                    </div>
                    <div className="pwdreqments ">At least 1 number</div>
                    <div className="pwdreqments   ">At least 6 characters</div>
                  </div>
                  <div className="col-md-6 text-align-right position-relative">
                    <button
                      type="submit"
                      className="px-4 bg-primary text-white rounded-pill py-2 fw-medium d-flex position-absolute bottom-0 end-0"
                      disabled={isSubmitting}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
