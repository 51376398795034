import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalCustom from "../Common/ModalCustom";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getCurrentProduct } from "../../redux/features/SubscriptionService";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_URL);

const RenewSubscriptionAlert = ({ getUserDetail, mainGrantor }) => {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState();
  const [showStripeCheckoutForm, setShowStripeCheckoutForm] = useState(false);

  const handleStripeCheckoutForm = () => {
    setShowStripeCheckoutForm(true);
  };

  const getCurrentlyUseProduct = async (stripeSubscriptionId) => {
    if (stripeSubscriptionId) {
      const res = await dispatch(getCurrentProduct({ stripeSubscriptionId }));
      if (res.type === "subscription/getCurrentProduct/fulfilled") {
        setSelectedProduct(res.payload.paymentPlanInfo);
      }
    }
  };

  const formatDate = (milliseconds) => {
    if (!milliseconds) return;
    const date = new Date(milliseconds);
    const day = date.getUTCDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short', timeZone: 'UTC' }).format(date);
    const year = date.getUTCFullYear();
    return `${month} ${day}, ${year}`;
  };

  useEffect(() => {
    if (getUserDetail?.stripeSubscriptionId) {
      getCurrentlyUseProduct(getUserDetail.stripeSubscriptionId);
    }
  }, [getUserDetail]);

  const currentTime = Date.now();

  if ([1, 3, 5].includes(getUserDetail?.planExpiryDate) || mainGrantor) return null;

  const isGraceDuration = getUserDetail?.isSubscriptionDeactive == 1 && getUserDetail?.planExpiryDate < currentTime && getUserDetail.graceDurationWillEndAt >= currentTime;
  const showRenewAlert = isGraceDuration && [2, 4].includes(getUserDetail?.role);

  return showRenewAlert ? (
    <>
      <div className="alert alert-warning mb-0 text-center" role="alert">
        Your subscription is currently inactive. Please <span className="cursor-pointer text-primary" onClick={handleStripeCheckoutForm}>renew</span> before the grace period ends on {formatDate(parseInt(getUserDetail?.planExpiryDate))}.
      </div>

      <ModalCustom size="xs" show={showStripeCheckoutForm}>
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm
            checkoutFormType="renew_subscription"
            selectedProduct={selectedProduct}
            handleStripeCheckoutFormPopup={setShowStripeCheckoutForm}
          />
        </Elements>
      </ModalCustom>
    </>
  ) : null;
};

export default RenewSubscriptionAlert;
