import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import _ from "loadsh";
import moment from "moment-timezone";
import { GetActivityListing } from "../../../redux/features/ActivityService";
import { CSVLink } from "react-csv";
import Loader from "../../Common/Loader";

const columns = [
  {
    id: "firstName",
    name: "First Name",
    selector: (row) => (row.userInfo ? row.userInfo[0]?.firstName : "-"),
    sortable: true,
    reorder: true,
  },
  {
    id: "lastName",
    name: "Last Name",
    selector: (row) => (row.userInfo ? row.userInfo[0]?.lastName : "-"),
    sortable: true,
    reorder: true,
  },
  {
    id: "email",
    name: "Email",
    selector: (row) => (row.email ? row.email : "-"),
    sortable: true,
    reorder: true,
  },
  {
    id: "trustName",
    name: "Trust Name",
    selector: (row) => (row.trustName ? row.trustName : "-"),
    sortable: true,
    reorder: true,
  },
  {
    id: "fileName",
    name: "File Name",
    selector: (row) => (row.fileName ? row.fileName : "-"),
    sortable: true,
    reorder: true,
  },
  {
    id: "fileType",
    name: "File Type",
    selector: (row) => (row.fileType ? row.fileType : "-"),
    sortable: true,
    reorder: true,
  },
  {
    id: "updatedAt",
    name: "Time Stamp",
    selector: (row) => moment(row.updatedAt).format("MM/DD/yyyy @ HH:mm PT"),
    sortable: true,
    reorder: true,
  },
];

const ActivityLog = () => {
  const dispatch = useDispatch();
  const { getActivityListing } = useSelector((state) => state.activity);
  const [csvData, setCsvData] = useState([]);
  const [isCsvDataShow, setCsvDataShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(GetActivityListing({ search: "" })).finally(() =>
      setLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Trust Name", key: "trustName" },
    { label: "File Name", key: "fileName" },
    { label: "File Type", key: "fileType" },
    { label: "Time Stamp", key: "updatedAt" },
  ];

  const csvLink = useRef();
  const handleCsvDownload = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        GetActivityListing({
          csv: true,
        })
      );

      const appendedData = _.map(response?.payload?.data, (data) => ({
        firstName: data?.userInfo[0]?.firstName,
        lastName: data?.userInfo[0]?.lastName,
        email: data?.email,
        trustName: data?.trustName,
        fileName: data?.fileName,
        fileType: data?.fileType,
        updatedAt: moment(data.updatedAt).format("MM/DD/yyyy @ HH:mm PT"),
      }));

      if (Array.isArray(appendedData)) {
        const sortedData = _.sortBy(appendedData, "firstName");
        setCsvData(sortedData);
        setCsvDataShow(true);
      } else {
        console.error("Invalid data format for CSV export");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (csvData?.length > 0 && isCsvDataShow) {
      csvLink.current.link.click();
      setCsvDataShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  return (
    <div className="w-100">
      <div className="title_bar d-flex align-items-center justify-content-between">
        <div className="text-style">Activity Log</div>
        <div className="d-flex bttn_continue mb-0">
          {getActivityListing.data && (
            <>
              <button
                className="btn btn_continue my-0"
                onClick={handleCsvDownload}
              >
                Export CSV
              </button>
              <CSVLink
                data={csvData}
                filename="Activity-Log"
                ref={csvLink}
                headers={headers}
              />
            </>
          )}
        </div>
      </div>
      {loading && <Loader />}
      <div className="conten-div mx-1">
        <div className="TableData">
          <DataTable
            columns={columns}
            data={getActivityListing?.data}
            pagination
            highlightOnHover
            defaultSortFieldId="firstName"
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
