import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSingleAdminDetail,
  UpdateAdminDetail,
} from "../../../../redux/features/AdminService";
import { useNavigate, useParams } from "react-router-dom";
import Structure from "../../../Common/Structure/Structure";
import { Field, Form, Formik } from "formik";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";

const validate = (values) => {
  let errors = {};
  if (!values?.firstName) {
    errors.firstName = "First Name is required";
  }
  if (!values?.lastName) {
    errors.lastName = "Last Name is required";
  }
  return errors;
};

const EditAdminUserPopup = () => {
  const params = useParams();
  const userId = params.id;
  const { getSingleAdminDetail } = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const id = getSingleAdminDetail?._id;

  useEffect(() => {
    if (userId) {
      setLoading(true);
      dispatch(GetSingleAdminDetail({ userId })).finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleClick = () => {
    setIsEdit(true);
  };

  const handleChangeStatus = async (status) => {
    const values = { isStatus: status, userId: userId };
    const res = await dispatch(UpdateAdminDetail({ values }));
    if (res?.type === "admin/UpdateAdminDetail/fulfilled") {
      await dispatch(GetSingleAdminDetail({ userId }));
    } else if (res?.type === "admin/UpdateAdminDetail/rejected") {
      toast.error(res?.payload?.response?.data?.message);
    }
  };

  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Structure activeText="Admin Users">
        <div className="w-100 center-chat chat_Section_main ">
          <div className="title_bar d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  onClick={() => navigate(-1)}
                  className="cursor-pointer"
                >
                  <path
                    d="M26.6663 14.6667H10.4397L17.893 7.21334L15.9997 5.33334L5.33301 16L15.9997 26.6667L17.8797 24.7867L10.4397 17.3333H26.6663V14.6667Z"
                    fill="#1F1F1F"
                  />
                </svg>
              </div>
              <div>
                <h5>
                  {id
                    ? `System Users / ${getSingleAdminDetail?.firstName +
                    " " +
                    getSingleAdminDetail?.lastName
                    }`
                    : "Create New Trust"}
                </h5>
                {isEdit ? (
                  <p className="mb-0 HeaderTitle2">Edit Details</p>
                ) : (
                  <p className="mb-0 HeaderTitle2">View Details</p>
                )}
              </div>
            </div>
          </div>

          <div className="conten-div btm_details_sec">
            {isEdit ? (
              <div className="profile_main_part_section">
                <div className="btm_details_sec">
                  <div className="">
                    <h2 className="Title mt-1 mb-3">
                      {`${getSingleAdminDetail?.firstName ?? ""}`}{" "}
                      {`${getSingleAdminDetail?.lastName ?? ""}`}
                    </h2>
                  </div>
                  <div className="profile_main_sec_detail">
                    <Formik
                      initialValues={{
                        userId: getSingleAdminDetail?._id || "",
                        firstName: getSingleAdminDetail?.firstName || "",
                        lastName: getSingleAdminDetail?.lastName || "",
                        phoneNumber: getSingleAdminDetail?.phoneNumber || "",
                        email: getSingleAdminDetail?.email || "",
                        address1: getSingleAdminDetail?.address1 || "",
                        address2: getSingleAdminDetail?.address2 || "",
                        city: getSingleAdminDetail?.city || "",
                        state: getSingleAdminDetail?.state || "",
                        zipCode: getSingleAdminDetail?.zipCode || "",
                        dob: getSingleAdminDetail?.dob
                          ? getSingleAdminDetail.dob
                          : "",
                        drivingLicenseFront:
                          getSingleAdminDetail?.drivingLicenseFront || "",
                        drivingLicenseBack:
                          getSingleAdminDetail?.drivingLicenseBack || "",
                        drivingLicenseFrontShow:
                          getSingleAdminDetail?.drivingLicenseFrontInfo
                            ?.oldImgName || "",
                        drivingLicenseBackShow:
                          getSingleAdminDetail?.drivingLicenseBackInfo
                            ?.oldImgName || "",
                        ssnNumber: getSingleAdminDetail?.ssnNumber || "",
                        ssnValue: getSingleAdminDetail?.ssnNumber || "",
                      }}
                      enableReinitialize={true}
                      validate={validate}
                      onSubmit={async (values) => {
                        setLoading(true);
                        const res = await dispatch(
                          UpdateAdminDetail({ values })
                        );
                        if (res?.type === "admin/UpdateAdminDetail/fulfilled") {
                          await dispatch(GetSingleAdminDetail({ userId }));
                          setLoading(false);
                          setIsEdit(false);
                        } else if (
                          res?.type === "admin/UpdateAdminDetail/rejected"
                        ) {
                          toast.error(res?.payload?.response?.data?.message);
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                      }) => (
                        <Form>
                          <div className="profile_title_grid d-flex justify-content-between align-items-center pb-3">
                            <div>
                              <p className="m-0 p-0 fs-5 fw-medium">Details</p>
                            </div>
                            <div className="btn_sec_right color_change">
                              <button className="profile_save_btn rounded-pill text-primary fw-medium">
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="">
                            <div className="">
                              <div className="row d-flex m-0 w-100">
                                <div className="col-md-12 col-lg-12 col-xl-8 pt-2">
                                  <div className="row">
                                    <div className="col-md-6 col-lg-6 col-xl-6 pt-2">
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="First Name"
                                          className="profile_section_label"
                                        >
                                          First Name
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control profile_main_part_user_detail"
                                          name="firstName"
                                          id="firstName"
                                          value={values?.firstName}
                                          placeholder="John Smith"
                                          onChange={handleChange}
                                        />
                                        {errors.firstName && touched.firstName ? (
                                          <div>{errors.firstName}</div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 pt-2">
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="Last Name"
                                          className="profile_section_label"
                                        >
                                          Last Name
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control profile_main_part_user_detail"
                                          name="lastName"
                                          id="lastName"
                                          value={values?.lastName}
                                          placeholder="John Smith"
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {errors.lastName && touched.lastName ? (
                                        <div className="error">
                                          {errors.lastName}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 pt-2">
                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="email"
                                          className="profile_section_label"
                                        >
                                          Email Address
                                        </label>
                                        <Field
                                          type="email"
                                          className="form-control"
                                          name="email"
                                          id="email"
                                          disabled
                                          value={values?.email}
                                          placeholder="Enter Email"
                                          onChange={handleChange}
                                        />
                                      </div>

                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 pt-2">

                                      <div className="main_group1 form-group">
                                        <label
                                          htmlFor="phoneNumber"
                                          className="profile_section_label"
                                        >
                                          Phone Number
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="phoneNumber"
                                          id="phoneNumber"
                                          value={values?.phoneNumber}
                                          placeholder="+1 (123) 123-1234"
                                          onChange={handleChange}
                                          onKeyPress={handleKeyPress}
                                        />
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>

                              <div className="StatusPart mt-3">
                                <p className="Title3">Status</p>
                                <div className="d-flex">
                                  <div
                                    className={`profile_tab_link fw-medium ${parseInt(getSingleAdminDetail?.status) === 1
                                      ? "active"
                                      : "active0"
                                      }`}
                                    onClick={() => {
                                      handleChangeStatus("1");
                                    }}
                                  >
                                    Active
                                  </div>
                                  <div
                                    className={`profile_tab_link fw-medium ${parseInt(getSingleAdminDetail?.status) === 1
                                      ? "Inactive"
                                      : "Inactive0"
                                      }`}
                                    onClick={() => {
                                      handleChangeStatus("0");
                                    }}
                                  >
                                    Inactive
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            ) : (
              <div className="profile_main_part_section">
                <div className="btm_details_sec">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="Title mt-1 mb-3">
                      {" "}
                      {`${getSingleAdminDetail?.firstName ?? ""}`}{" "}
                      {`${getSingleAdminDetail?.lastName ?? ""}`}
                    </h2>
                  </div>

                  <div className="profile_main_sec_detail">
                    <div className="profile_title_grid d-flex justify-content-between align-items-center pb-3">
                      <div>
                        <p className="m-0 p-0 fs-5 fw-medium">Details</p>
                      </div>
                      <div className="btn_sec_right color_change">
                        <button
                          className="profile_edit_btn rounded-pill text-white"
                          onClick={handleClick}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <div className="row d-flex m-0 w-100">
                          <div className="col-md-12 col-lg-12 col-xl-8 pt-2">
                            <div className="row">
                              <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="name"
                                    className="profile_section_label"
                                  >
                                    First Name
                                  </label>
                                  <p className="profile_main_part_user_detail">
                                    {getSingleAdminDetail?.firstName}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-xl-6 ">
                                <div className="form-group">
                                  <label
                                    htmlFor="phoneNumber"
                                    className="profile_section_label"
                                  >
                                    Last Name
                                  </label>
                                  <p className="profile_main_part_user_detail">
                                    {getSingleAdminDetail?.lastName}
                                  </p>
                                </div>
                              </div>


                              <div className="col-md-6 col-lg-6 col-xl-6 ">
                                <div className="form-group">
                                  <label
                                    htmlFor="email"
                                    className="profile_section_label"
                                  >
                                    Email Address
                                  </label>
                                  <p className="profile_main_part_user_detail">
                                    {getSingleAdminDetail?.email}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-xl-6 ">
                                <div className="form-group">
                                  <label
                                    htmlFor="state"
                                    className="profile_section_label"
                                  >
                                    Phone Number
                                  </label>
                                  <p className="profile_main_part_user_detail">
                                    {getSingleAdminDetail?.phoneNumber}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="StatusPart mt-3">
                              <p className="Title3">Status</p>
                              <div className="d-flex">
                                <div
                                  className={`profile_tab_link fw-medium ${parseInt(getSingleAdminDetail?.status) === 1
                                    ? "active"
                                    : "active0"
                                    }`}
                                >
                                  Active
                                </div>
                                <div
                                  className={`profile_tab_link fw-medium ${parseInt(getSingleAdminDetail?.status) === 1
                                    ? "Inactive"
                                    : "Inactive0"
                                    }`}
                                >
                                  Inactive
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            )}
          </div>
        </div>
      </Structure >
    </>
  );
};

export default EditAdminUserPopup;
