import React, { useEffect, useState } from "react";
import Structure from "../../../Common/Structure/Structure";
import AddTrustsForm from "./form";
import AddAttorney from "./attorey/add-attorney";
import AddSuccessor from "./successor/add-successor";
import AddDocuments from "./documents/add-documents";
import {
  AddAllDocumentsTrust,
  AddNoteData,
  AddTrustsData,
  AddTrustsName,
  GetSingleTrustDetail,
} from "../../../../redux/features/TrustsService";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import AddNotes from "./notes/notes";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddGrantor from "./grantor/add-grantor";
import TrustPermissionList from "./notes/TrustPermissionList";
import Loader from "../../../Common/Loader";
import ModalCustom from "../../../Common/ModalCustom";
import Congratulation from "./Congratulation";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import { GetGrantorListTrust } from "../../../../redux/features/GrantorService";

const AddTrusts = () => {
  const { getUserDetail } = useSelector((state) => state.auths);
  const { getSingleTrustDetail } = useSelector((state) => state.trusts);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState("0");
  const { trust_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setTrustId] = useState(trust_id || localStorage.getItem('trust-id-grantor-flow'));
  const [trustName, setTrustName] = useState("");
  const [isAddEditData, setAddEditData] = useState(null);
  const [isDocuments, setDocuments] = useState([]);
  const [isNotesData, setNotesData] = useState([]);
  const [data, setData] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAddDocuments, setAddDocumets] = useState([]);
  const currentUnixTimestamp = moment().unix();
  const [trustIdGrantor, sertTrustIdGrantor] = useState(null)
  const { getGrantorListTrust } = useSelector((state) => state.grantors);

  useEffect(() => {
    if (id && getSingleTrustDetail) {
      handleUpdatedData();
    } else if (!id && !getSingleTrustDetail) {
      setAddEditData(null);
      setDocuments(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSingleTrustDetail, selectedOption]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(GetSingleTrustDetail({ trustId: id })).finally(() =>
        setLoading(false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isAddEditData && isAddEditData.trustName) {
      setTrustName(isAddEditData.trustName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddEditData]);

  useEffect(() => {
    const trustID = localStorage.getItem('trust-id-grantor-flow');
    // if(trustID){
    // console.log(trustID);
    sertTrustIdGrantor(trustID);
    // }
    // console.log(trustID);
    if (trustID && getGrantorListTrust || trustID && !getSingleTrustDetail?.grantorList) {
      dispatch(GetGrantorListTrust());
    }
  }, [trustIdGrantor]);

  // console.log('------------', getUserDetail, '-----------');
  const AutoAddGRantor = async () => {
    // console.log(trustIdGrantor);
    const grantorList = await _.map(
      getGrantorListTrust,
      (grantorDetail) => {
        return {
          trustId: trustIdGrantor,
          userId: grantorDetail?._id,
          firstName: grantorDetail?.firstName || "",
          lastName: grantorDetail?.lastName || "",
          phoneNumber: grantorDetail?.phoneNumber,
          email: grantorDetail?.email,
          address1: grantorDetail?.address1,
          address2: grantorDetail?.address2,
          city: grantorDetail?.city,
          position: grantorDetail?.position,
          state: grantorDetail?.state,
          zipcode: grantorDetail?.zipCode ? grantorDetail?.zipCode : "",
          drivingLicenseFront: grantorDetail?.drivingLicenseFront,
          drivingLicenseBack: grantorDetail?.drivingLicenseBack,
          drivingLicenseFrontInfo:
            grantorDetail?.drivingLicenseFrontInfo?.oldImgName,
          drivingLicenseBackInfo:
            grantorDetail?.drivingLicenseBackInfo?.oldImgName,
          isInvite: grantorDetail?.isInvite,
          deceased: grantorDetail?.deceased
        };
      }
    )

    // console.log(grantorList);
    if (grantorList.length > 0) {
      const grantorResponse = await dispatch(AddTrustsData(grantorList));
      setData(grantorResponse?.meta?.arg?.trustId);
      if (grantorResponse?.type === "trusts/AddTrustsData/fulfilled") {
        sertTrustIdGrantor(null)
        setLoading(false);
        setShowDialog(true);
        localStorage.clear()
      } else if ("trusts/AddTrustsData/rejected") {
        toast.error(grantorResponse?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (trustIdGrantor !== null) {
      // console.log('functioncall');
      AutoAddGRantor();
    }
  }, [trustIdGrantor])


  const handleUpdatedData = async () => {
    const grantorList = await _.map(
      getSingleTrustDetail?.grantorList === undefined ? getGrantorListTrust : getSingleTrustDetail?.grantorList,
      (grantorDetail) => {
        return {
          trustId: getSingleTrustDetail?._id,
          userId: grantorDetail?._id,
          firstName: grantorDetail?.firstName || "",
          lastName: grantorDetail?.lastName || "",
          phoneNumber: grantorDetail?.phoneNumber,
          email: grantorDetail?.email,
          address1: grantorDetail?.address1,
          address2: grantorDetail?.address2,
          city: grantorDetail?.city,
          position: grantorDetail?.position,
          state: grantorDetail?.state,
          zipcode: grantorDetail?.zipCode ? grantorDetail?.zipCode : "",
          drivingLicenseFront: grantorDetail?.drivingLicenseFront,
          drivingLicenseBack: grantorDetail?.drivingLicenseBack,
          drivingLicenseFrontInfo:
            grantorDetail?.drivingLicenseFrontInfo?.oldImgName,
          drivingLicenseBackInfo:
            grantorDetail?.drivingLicenseBackInfo?.oldImgName,
          isInvite: grantorDetail?.isInvite,
          deceased: grantorDetail?.deceased
        };
      }
    );

    const attorneyList = await _.map(
      getSingleTrustDetail?.attorneyList,
      (attornyDetail) => {
        return {
          trustId: getSingleTrustDetail?._id,
          userId: attornyDetail?._id,
          firstName: attornyDetail?.firstName || "",
          lastName: attornyDetail?.lastName || "",
          phoneNumber: attornyDetail?.phoneNumber,
          email: attornyDetail?.email,
          address1: attornyDetail?.address1,
          address2: attornyDetail?.address2,
          city: attornyDetail?.city,
          state: attornyDetail?.state,
          zipcode: attornyDetail?.zipCode ? attornyDetail?.zipCode : "",
          barNumber: attornyDetail?.barNumber,
          firm: attornyDetail?.firm,
          stateLicensed: attornyDetail?.stateLicensed,
          isInvite: attornyDetail?.isInvite,
          position: attornyDetail?.position,
          deceased: attornyDetail?.deceased
        };
      }
    );

    const successorList = await _.map(
      getSingleTrustDetail?.successorList,
      (successorDetail) => {
        return {
          trustId: getSingleTrustDetail?._id,
          userId: successorDetail?._id,
          firstName: successorDetail?.firstName || "",
          lastName: successorDetail?.lastName || "",
          phoneNumber: successorDetail?.phoneNumber,
          email: successorDetail?.email,
          address1: successorDetail?.address1,
          address2: successorDetail?.address2,
          city: successorDetail?.city,
          state: successorDetail?.state,
          zipcode: successorDetail?.zipCode ? successorDetail?.zipCode : "",
          drivingLicenseFront: successorDetail?.drivingLicenseFront,
          drivingLicenseBack: successorDetail?.drivingLicenseBack,
          drivingLicenseFrontInfo:
            successorDetail?.drivingLicenseFrontInfo?.oldImgName,
          drivingLicenseBackInfo:
            successorDetail?.drivingLicenseBackInfo?.oldImgName,
          isInvite: successorDetail?.isInvite,
          position: successorDetail?.position,
          deceased: successorDetail?.deceased
        };
      }
    );
    setAddEditData({
      ...isAddEditData,
      successorData: successorList,
      attorneyData: attorneyList,
      grantorData: grantorList,
      trustName: getSingleTrustDetail?.trustName,
      authorId: getSingleTrustDetail?.holder,
    });

    setDocuments({
      trustDoc: getSingleTrustDetail?.trustDocInfo,
      willsDoc: getSingleTrustDetail?.willsDoc,
      ahcdDoc: getSingleTrustDetail?.ahcdDoc,
      hipaaDoc: getSingleTrustDetail?.hipaaDoc,
      pfaDoc: getSingleTrustDetail?.pfaDoc,
      dcDoc: getSingleTrustDetail?.dcDoc,
      miscellaneousDoc: getSingleTrustDetail?.miscellaneousDoc,
      deathCertificate: getSingleTrustDetail?.deathCertificate,
    });
    const data = _.map(getSingleTrustDetail?.noteList, (noteDetail) => {
      return noteDetail;
    });
    setNotesData(data);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const trustDataUpload = {
      trustName: trustName,
      authorId: getUserDetail?._id,
    };

    if (id) {
      trustDataUpload.trustId = id;
    }
    let res = null;
    const getTrustId = localStorage.getItem('trust-id-grantor-flow');
    if (!getTrustId) {
      res = await dispatch(AddTrustsName(trustDataUpload));

    }
    if (getTrustId || res?.type === "trusts/AddTrustsName/fulfilled") {
      if (getTrustId || res?.payload?.data?._id) {
        const GrantorData = {
          trustId: getGrantorListTrust?.length && getTrustId ? getTrustId : res?.payload?.data?._id,
          userList: isAddEditData?.grantorData,
          role: 2,
        };

        const AttorneyData = {
          trustId: getTrustId ? getTrustId : res?.payload?.data?._id,
          userList: isAddEditData?.attorneyData,
          role: 3,
        };

        const SuccessorData = {
          trustId: getTrustId ? getTrustId : res?.payload?.data?._id,
          userList: isAddEditData?.successorData,
          role: 4,
        };

        // console.log(GrantorData);

        if (GrantorData?.userList?.length) {
          const grantorResponse = await dispatch(AddTrustsData(GrantorData));
          localStorage.removeItem('trust-id-grantor-flow')
          setData(grantorResponse?.meta?.arg?.trustId);
          if (grantorResponse?.type === "trusts/AddTrustsData/fulfilled") {
            setLoading(false);
            setShowDialog(true);
          } else if ("trusts/AddTrustsData/rejected") {
            toast.error(grantorResponse?.payload?.response?.data?.message, {
              autoClose: 3000,
            });
          }
        }

        if (SuccessorData?.userList?.length) {
          const successResponse = await dispatch(AddTrustsData(SuccessorData));
          if (successResponse?.type === "trusts/AddTrustsData/fulfilled") {
            setLoading(false);
            setShowDialog(true);
          } else if ("trusts/AddTrustsData/rejected") {
            toast.error(successResponse?.payload?.response?.data?.message, {
              autoClose: 3000,
            });
          }
        }
        if (AttorneyData?.userList?.length) {
          const attorneyResponse = await dispatch(AddTrustsData(AttorneyData));
          if (attorneyResponse?.type === "trusts/AddTrustsData/fulfilled") {
            setLoading(false);
            setShowDialog(true);
          } else if ("trusts/AddTrustsData/rejected") {
            toast.error(attorneyResponse?.payload?.response?.data?.message, {
              autoClose: 3000,
            });
          }
        }
        if (isAddDocuments?.length > 0) {
          const newData = {
            trustId: res?.payload?.data?._id,
            docList: isAddDocuments,
          };
          await dispatch(AddAllDocumentsTrust(newData));
        }

        await Promise.all(
          isNotesData.map(async (noteDetail) => {
            const noteData = {
              trustId: res?.payload?.data?._id,
              note: noteDetail?.note,
              createdAt: noteDetail?.createdAt,
            };
            setNotesData(noteData);
            const resp = await dispatch(AddNoteData(noteData));
            return resp;
          })
        );
      } else if (res?.payload.status === 0) {
        setLoading(false);
        toast.error(res?.payload?.data?.message, { autoClose: 3000 });
      }
      setLoading(false);
    }
  };

  const handleRemoveData = async (removeIndex, removeArray, addTextData) => {
    const data = await _.filter(
      removeArray,
      (_, index) => index !== removeIndex
    );
    setAddEditData((prevData) => ({
      ...prevData,
      [addTextData]: data,
    }));
  };

  const RenameHandler = async () => {
    setLoading(true);
    const trustDataUpload = {
      trustName: trustName,
      authorId: isAddEditData?.authorId,
    };

    if (id) {
      trustDataUpload.trustId = id;
      const res = await dispatch(AddTrustsName(trustDataUpload));
      if (res?.type === "trusts/AddTrustsName/fulfilled") {
        setLoading(false);
        dispatch(GetSingleTrustDetail({ trustId: id }));
      }
    }
  };

  const onValueChange = async (value) => {
    setLoading(true);
    const selectedValue = value.toString();
    setSelectedOption(selectedValue);

    const trustDataUpload = {
      trustId: id,
      isStatus: selectedValue,
    };
    const res = await dispatch(AddTrustsName(trustDataUpload));
    if (res?.type === "trusts/AddTrustsName/fulfilled") {
      setLoading(false);
      await dispatch(GetSingleTrustDetail({ trustId: id }));
    }
  };

  const BackBtnAction = () => {
    navigate(-1)
    localStorage.clear();
  }

  return (
    <>
      {loading && <Loader />}
      <Structure activeText="Trusts">
        <div className="w-100 px-0 center-chat chat_Section_main ">
          <div className="title_bar d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  className="cursor-pointer"
                  onClick={BackBtnAction}
                >
                  <path
                    d="M26.6663 14.6667H10.4397L17.893 7.21334L15.9997 5.33334L5.33301 16L15.9997 26.6667L17.8797 24.7867L10.4397 17.3333H26.6663V14.6667Z"
                    fill="#1F1F1F"
                  />
                </svg>
              </div>
              <div>
                <h5>
                  {editMode ? (
                    <input
                      type="text"
                      name="trustName"
                      value={trustName}
                      className="form-control"
                      onChange={(e) => setTrustName(e.target.value)}
                    />
                  ) : id ? (
                    getSingleTrustDetail?.trustName
                  ) : (
                    "Create New Trust"
                  )}
                </h5>

                {id && (
                  <p className="mb-0">
                    <b>Status: </b>
                    <span>
                      {getSingleTrustDetail?.status ? "Active" : "Archived"}
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className="btn_sec">
              {id ? (
                <>
                  <div className="d-flex">
                    <div>
                      {editMode ? (
                        <button
                          type="button"
                          className="btn add_trust mx-1"
                          onClick={() => {
                            setEditMode(false);
                            RenameHandler();
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn add_trust btn-transparant mx-1"
                          onClick={() => setEditMode(true)}
                          disabled={getSingleTrustDetail?.isEdit === 0}
                        >
                          Rename
                        </button>
                      )}
                    </div>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="add_trust"
                          id="dropdown-basic"
                          disabled={getSingleTrustDetail?.isEdit === 0}
                        >
                          Change Status
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="box arrow-top"
                          style={{ borderRadius: "13px" }}
                        >
                          <form>
                            <Dropdown.Item onClick={() => onValueChange(1)}>
                              <div className="d-flex">
                                <div>
                                  <input
                                    type="radio"
                                    name="account"
                                    value="1"
                                    checked={getSingleTrustDetail?.status === 1}
                                    onChange={() => onValueChange(1)}
                                  />
                                </div>
                                <label
                                  className={`mx-1 cursor-pointer ${getSingleTrustDetail?.status === "1"
                                      ? "text-red"
                                      : "text-green"
                                    }`}
                                >
                                  Active
                                </label>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onValueChange(0)}>
                              <div className="d-flex">
                                <div>
                                  <input
                                    type="radio"
                                    name="account"
                                    value="0"
                                    checked={getSingleTrustDetail?.status === 0}
                                    onChange={() => onValueChange(0)}
                                  />
                                </div>
                                <label
                                  className={`mx-1 cursor-pointer ${getSingleTrustDetail?.status === "0"
                                      ? "text-green"
                                      : "text-red"
                                    }`}
                                >
                                  Archived
                                </label>
                              </div>
                            </Dropdown.Item>
                          </form>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </>
              ) : (
                <button
                  type="button"
                  className="btn add_trust"
                  onClick={(e) => {
                    e.preventDefault();
                    handleFormSubmit(e);
                  }}
                  disabled={
                    !isAddEditData?.grantorData?.length ||
                    ((getUserDetail?.role == 2 || getUserDetail?.role == 4) && (getUserDetail?.parentInfo
                      ? (!getUserDetail?.parentInfo.graceDurationWillEndAt || (getUserDetail?.graceDurationWillEndAt && getUserDetail?.graceDurationWillEndAt && getUserDetail?.parentInfo.graceDurationWillEndAt == '' || getUserDetail?.parentInfo.graceDurationWillEndAt < Date.now()))
                      : (!getUserDetail?.graceDurationWillEndAt || (getUserDetail?.graceDurationWillEndAt && getUserDetail?.graceDurationWillEndAt && getUserDetail?.graceDurationWillEndAt == '' || getUserDetail?.graceDurationWillEndAt < Date.now()))))
                  }
                >
                  Complete
                </button>
              )}
            </div>
          </div>
          <div className="conten-div">
            <AddTrustsForm
              setAddEditData={setAddEditData}
              isAddEditData={isAddEditData}
              handleRemoveData={handleRemoveData}
              setTrustId={setTrustId}
              id={id}
              trustName={getSingleTrustDetail?.trustName}
              author={getSingleTrustDetail?.holder}
            >
              <div className="btm_details_sec">
                <AddGrantor
                  handleRemoveData={handleRemoveData}
                  isAddEditData={isAddEditData}
                  setAddEditData={setAddEditData}
                  id={id}
                />
              </div>
              <div className="btm_details_sec">
                <AddAttorney
                  handleRemoveData={handleRemoveData}
                  isAddEditData={isAddEditData}
                  setAddEditData={setAddEditData}
                  id={id}
                />
              </div>
              <div className="btm_details_sec">
                <AddSuccessor
                  handleRemoveData={handleRemoveData}
                  isAddEditData={isAddEditData}
                  setAddEditData={setAddEditData}
                  id={id}
                />
              </div>
              <div className="mian_accordian_sec accordian_Sec ">
                <AddDocuments
                  isDocuments={isDocuments}
                  setDocuments={setDocuments}
                  isAddEditData={isAddEditData}
                  getUserDetail={getUserDetail}
                  setAddDocumets={setAddDocumets}
                  isAddDocuments={isAddDocuments}
                  grantorList={getSingleTrustDetail?.grantorList === undefined ? getGrantorListTrust : getSingleTrustDetail?.grantorList}
                  id={id}
                />
              </div>
              <AddNotes
                isTrustName={isAddEditData}
                setNotesData={setNotesData}
                isNotesData={isNotesData}
                id={id}
              />
              {id && getSingleTrustDetail?.permissionUserList?.length > 0 && (
                <TrustPermissionList id={id} />
              )}
            </AddTrustsForm>
          </div>
        </div>
      </Structure>
      <ModalCustom
        size="xl"
        show={showDialog}
        className={"CongratulationsPopup"}
        center="true"
      >
        <Congratulation
          setShowCon={setShowDialog}
          trustName={trustName}
          setNotesData={isNotesData}
          id={id}
          setAddEditData={isAddEditData}
          data={data}
        />
      </ModalCustom>
    </>
  );
};

export default AddTrusts;
