import React from "react";
import { useNavigate } from "react-router-dom";

const SupportSubmitPopup = ({ setShowModal }) => {
  const navigate = useNavigate();
  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };
  return (
    <>
      <div className="text-center mt-3">
        <h3>Request for Support Submitted Successfully</h3>
        <p className="mt-4">
          Thank you for submitting a Request for Support. Our team will work
          promptly to get back to you quickly. Thank you for using The National
          Trust Registry.
        </p>
        <div className="mt-5">
          <button
            className="btn btn-thm"
            type="button"
            onClick={handleModalClose}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default SupportSubmitPopup;
