import React, { useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import { useDispatch } from "react-redux";
import { UploadMedia } from "../../../../../redux/features/CommonService";
import {
  AddTrustsData,
  CheckMailService,
  GetSingleTrustDetail,
} from "../../../../../redux/features/TrustsService";
import Loader from "../../../../Common/Loader";
import _ from "lodash";
import { toast } from "react-toastify";

const validate = (values) => {
  let errors = {};
  if (!values?.firstName) {
    errors.firstName = "This field is required";
  }
  if (!values?.lastName) {
    errors.lastName = "This field is required";
  }
  if (!values?.phoneNumber) {
    errors.phoneNumber = "This field is required";
  }
  if (!values?.email) {
    errors.email = "This field is required";
  }
  if (!values?.address1) {
    errors.address1 = "This field is required";
  }
  if (!values?.city) {
    errors.city = "This field is required";
  }
  if (!values?.state) {
    errors.state = "This field is required";
  }
  if (!values?.zipcode) {
    errors.zipcode = "This field is required";
  }
  return errors;
};

const AddSuccessorPopup = ({
  setAddEditData,
  setShowSuccessorPopup,
  successorData,
  id,
  isAddEditData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleUploadImage = async (file) => {
    let data = file;
    if (file?.type === "image/svg+xml") {
      data = new Blob([file], { type: "image/svg" });
      data.name = file.name;
    }
    const formData = new FormData();
    formData.append("files", data, file.name);
    formData.append("trustId", id);
    const res = await dispatch(UploadMedia(formData));

    if (res.type === "commons/UploadMedia/fulfilled") {
      return res.payload?.files[0];
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="p-0">
            <Formik
              initialValues={{
                userId: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipcode: "",
                drivingLicenseFront: "",
                drivingLicenseBack: "",
                drivingLicenseFrontShow: "",
                drivingLicenseBackShow: "",
                position: 0,
              }}
              validate={validate}
              validateOnBlur={false}
              onSubmit={async (values) => {
                try {
                  setSaving(true);
                  const data = {
                    email: values.email,
                  };
                  const res = await dispatch(CheckMailService(data));
                  if (res?.type === "trusts/CheckMailService/fulfilled") {
                    if (id) {
                      setLoading(true);
                      const SuccessorData = {
                        trustId: id,
                        userList: [...successorData, values],
                        role: 4,
                      };
                      const resp = await dispatch(AddTrustsData(SuccessorData));
                      if (resp?.type === "trusts/AddTrustsData/fulfilled") {
                        setLoading(false);
                        setShowSuccessorPopup(false);
                        dispatch(GetSingleTrustDetail({ trustId: id }));
                      } else if (
                        resp?.type === "trusts/AddTrustsData/rejected"
                      ) {
                        setLoading(false);
                        setShowSuccessorPopup(true);
                        toast.error(resp?.payload?.response?.data?.message);
                      }
                    } else {
                      if (isAddEditData?.successorData?.length) {
                        if (
                          _.some(
                            isAddEditData?.successorData,
                            (entry) => entry.phoneNumber === values?.phoneNumber
                          )
                        ) {
                          toast.error("This phone number is already added");
                        } else {
                          setAddEditData((prevData) => ({
                            ...prevData,
                            successorData: Array.isArray(prevData.successorData)
                              ? [...prevData.successorData, values]
                              : [values],
                          }));
                          setShowSuccessorPopup(false);
                        }
                      } else {
                        setAddEditData((prevData) => ({
                          ...prevData,
                          successorData: Array.isArray(prevData.successorData)
                            ? [...prevData.successorData, values]
                            : [values],
                        }));
                        setShowSuccessorPopup(false);
                      }
                    }
                  }
                } catch (error) {
                  console.log(error);
                } finally {
                  setSaving(false);
                }
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
                handleBlur,
                handleChange,
              }) => (
                <>
                  {loading && <Loader />}

                  <form onSubmit={handleSubmit}>
                    <FieldArray
                      name="userList"
                      render={(arrayHelpers) => (
                        <>
                          <div className="d-flex profile_title_grid">
                            <div className="col-xl-3 p-0">
                              <h4 className="my-3">Add Successor</h4>
                            </div>
                            <div className="d-flex col-xl-9 align-items-center justify-content-end  btn_bottm">
                              <div className="btn_back mr-2">
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowSuccessorPopup(false);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                              <div className="bttn_continue mb-0">
                                <button
                                  type="submit"
                                  className="btn btn_continue"
                                  disabled={saving}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-12">
                            <div className="col-xl-2 p-0">
                              <h5 className="my-3 text-muted">Add Successor</h5>
                            </div>
                            <div className="main_grid_top grid-container pt-2">
                              <div className="row m-0 w-100">
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="firstName"
                                        className="bg-white"
                                      >
                                        First Name
                                      </label>
                                      <Field
                                        type="text"
                                        name="firstName"
                                        placeholder="Bill"
                                        id="firstName"
                                        className="form-control"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "position",
                                            !id
                                              ? !successorData?.length
                                                ? 1
                                                : successorData.length + 1
                                              : successorData
                                                ?.map(
                                                  (successor) =>
                                                    parseInt(
                                                      successor?.position
                                                    ) + 1
                                                )
                                                .join(", ")
                                          );
                                          handleChange(e);
                                        }}
                                      />
                                    </div>

                                    {errors?.firstName && touched?.firstName && (
                                      <span className="error">
                                        {errors?.firstName}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="lastName"
                                        className="bg-white"
                                      >
                                        Last Name
                                      </label>
                                      <Field
                                        type="text"
                                        name="lastName"
                                        placeholder="Smith"
                                        id="lastName"
                                        className="form-control"
                                      />
                                    </div>

                                    {errors?.lastName && touched?.lastName && (
                                      <span className="error">
                                        {errors?.lastName}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="phoneNumber"
                                        className="bg-white"
                                      >
                                        Phone Number
                                      </label>
                                      <Field
                                        type="text"
                                        name="phoneNumber"
                                        placeholder="+1 (123) 123-1233"
                                        id="phoneNumber"
                                        className="form-control"
                                      />
                                    </div>
                                    {errors?.phoneNumber &&
                                      touched?.phoneNumber && (
                                        <span className="error">
                                          {errors?.phoneNumber}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="email" className="bg-white">
                                        Email Address
                                      </label>
                                      <Field
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Bsmith"
                                      />
                                    </div>
                                    {errors?.email && touched?.email && (
                                      <span className="error">{errors?.email}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className=" form-group mb-0">
                                      <label
                                        htmlFor="drivingLicenseFrontShow"
                                        className="bg-white"
                                      >
                                        Drivers License - Front
                                      </label>
                                      <div
                                        className="files-wr"
                                        data-count-files="1"
                                      >
                                        <div className="pt-2">
                                          <label
                                            htmlFor="drivingLicenseFrontShow"
                                            className="bi bi-upload w-50 mb-0"
                                          >
                                            {values?.drivingLicenseFrontShow
                                              ? values?.drivingLicenseFrontShow
                                                .length > 9
                                                ? values.drivingLicenseFrontShow.substring(
                                                  0,
                                                  9
                                                ) + "..."
                                                : values.drivingLicenseFrontShow
                                              : "Upload File"}
                                          </label>
                                          <Field
                                            type="file"
                                            className="form-control"
                                            id="drivingLicenseFrontShow"
                                            name="drivingLicenseFrontShow"
                                            placeholder="Upload File"
                                            value={""}
                                            onChange={async (e) => {
                                              try {
                                                const file =
                                                  e.currentTarget.files[0];
                                                const uploadData =
                                                  await handleUploadImage(file);
                                                setFieldValue(
                                                  "drivingLicenseFront",
                                                  uploadData?.mediaName
                                                );
                                                setFieldValue(
                                                  "drivingLicenseFrontShow",
                                                  file?.name
                                                );
                                              } catch (error) { }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {errors?.drivingLicenseFrontShow &&
                                      touched?.drivingLicenseFrontShow && (
                                        <span className="error">
                                          {errors?.drivingLicenseFrontShow}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="drivingLicenseBacklable"
                                        className="bg-white"
                                      >
                                        Drivers License - Back
                                      </label>
                                      <div
                                        className="files-wr"
                                        data-count-files="1"
                                      >
                                        <div className="pt-2">
                                          <label
                                            htmlFor="drivingLicenseBackShow"
                                            className="bi bi-upload w-50 mb-0"
                                          >
                                            {values?.drivingLicenseBackShow
                                              ? values?.drivingLicenseBackShow
                                                .length > 9
                                                ? values.drivingLicenseBackShow.substring(
                                                  0,
                                                  9
                                                ) + "..."
                                                : values.drivingLicenseBackShow
                                              : "Upload File"}
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control"
                                            id="drivingLicenseBackShow"
                                            name="drivingLicenseBackShow"
                                            placeholder="Upload File"
                                            onChange={async (e) => {
                                              try {
                                                const file =
                                                  e.currentTarget.files[0];
                                                const uploadData =
                                                  await handleUploadImage(
                                                    file
                                                  );
                                                setFieldValue(
                                                  "drivingLicenseBack",
                                                  uploadData?.mediaName
                                                );
                                                setFieldValue(
                                                  "drivingLicenseBackShow",
                                                  file?.name
                                                );
                                              } catch (error) { }
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          <div className="file-item hide-btn">
                                            <span className="file-name"></span>
                                            <span className="btn btn-del-file">
                                              x
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {errors?.drivingLicenseBackShow &&
                                      touched?.drivingLicenseBackShow && (
                                        <span className="error">
                                          {errors?.drivingLicenseBackShow}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="address1"
                                        className="bg-white"
                                      >
                                        Address Line 1
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="address1"
                                        name="address1"
                                        placeholder="Address 1"
                                      />
                                    </div>
                                    {errors?.address1 && touched?.address1 && (
                                      <span className="error">
                                        {errors?.address1}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label
                                        htmlFor="address2"
                                        className="bg-white"
                                      >
                                        Address Line 2
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="address2"
                                        name="address2"
                                        placeholder="Address 2"
                                      />
                                    </div>
                                    {errors?.address2 && touched?.address2 && (
                                      <span className="error">
                                        {errors?.address2}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                <div>
                                  <div className="form-group mb-0">
                                      <label htmlFor="city" className="bg-white">
                                        City
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        name="city"
                                        placeholder="Austin"
                                      />
                                    </div>
                                    {errors?.city && touched?.city && (
                                      <span className="error">{errors?.city}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="state">State</label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        name="state"
                                        placeholder="Texas"
                                      />
                                    </div>
                                    {errors?.state && touched?.state && (
                                      <span className="error">{errors?.state}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-3 pt-2">
                                  <div>
                                    <div className="form-group mb-0">
                                      <label htmlFor="zipcode" className="bg-white">
                                        ZIP Code
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="zipcode"
                                        name="zipcode"
                                        placeholder="78711"
                                      />
                                    </div>
                                    {errors?.zipcode && touched?.zipcode && (
                                      <span className="error">
                                        {errors?.zipcode}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSuccessorPopup;
