import React, { useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  AddAttorneyService,
  GetAttorneyListing,
} from "../../../../redux/features/AttorneyService";
import { toast } from "react-toastify";

const validate = (values) => {
  let errors = {};
  if (!values?.firstName) {
    errors.firstName = "*Please enter a first Name";
  }
  if (!values?.lastName) {
    errors.lastName = "*Please enter a last Name";
  }
  if (!values?.phoneNumber) {
    errors.phoneNumber = "*Please enter a phone number";
  }
  if (!values.email) {
    errors.email = "*Email is required to Sign Up";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const AddAttorney = ({ setShowAttornyPopup }) => {
  const [isSaving, setSaving] = useState(false);
  const dispatch = useDispatch();
  function handleKeyPress(event) {
    const allowedCharacters = /[0-9()\-+]/;
    const key = event.key;
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <Formik
            initialValues={{
              role: "3",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              address1: "",
              city: "",
              state: "",
              zipCode: "",
              barNumber: "",
              stateLicensed: "",
              firm: "",
            }}
            enableReinitialize={true}
            validate={validate}
            validateOnBlur={false}
            onSubmit={async (values) => {
              const res = await dispatch(AddAttorneyService(values));
              if (res.payload.status === 1) {
                setSaving(true);
                toast.success(res.payload.message);
                await dispatch(GetAttorneyListing({ role: "3" }));
                setShowAttornyPopup(false);
              } else if (res.payload.status === 0) {
                toast.error(res.payload.message);
                setShowAttornyPopup(true);
              }
            }}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              handleBlur,
            }) => (
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                formgroup="editform"
              >
                <FieldArray
                  name="userList"
                  render={(arrayHelpers) => (
                    <>
                      <div>
                        <div className="col-xl-12">
                          <h4 className="text-center">Create an Attorney</h4>
                        </div>
                        <div className="px-0 col-xl-12">
                          <div className="main_form form-group mb-0 mt-4">
                            <label htmlFor="firstName" className="bg-white">
                              First Name{" "}
                              <span className="required-star-text">*</span>
                            </label>
                            <Field
                              type="text"
                              name="firstName"
                              placeholder="Enter your First Name"
                              id="firstName"
                              className="form-control"
                            />
                          </div>
                          {errors?.firstName && touched?.firstName && (
                            <span className="error">{errors?.firstName}</span>
                          )}
                        </div>
                        <div className="px-0 col-xl-12">
                          <div className="main_form form-group mb-0 mt-4">
                            <label htmlFor="lastName" className="bg-white">
                              Last Name{" "}
                              <span className="required-star-text">*</span>
                            </label>
                            <Field
                              type="text"
                              name="lastName"
                              placeholder="Enter Your Last Name"
                              id="lastName"
                              className="form-control"
                            />
                          </div>
                          {errors?.lastName && touched?.lastName && (
                            <span className="error">{errors?.lastName}</span>
                          )}
                        </div>
                        <div className="px-0 col-xl-12">
                          <div className="main_form form-group mb-0 mt-4">
                            <label htmlFor="phoneNumber" className="bg-white">
                              Phone Number{" "}
                              <span className="required-star-text">*</span>
                            </label>
                            <Field
                              type="text"
                              name="phoneNumber"
                              placeholder="Enter Your Phone Number"
                              id="phoneNumber"
                              className="form-control"
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                          {errors?.phoneNumber && touched?.phoneNumber && (
                            <span className="error">{errors?.phoneNumber}</span>
                          )}
                        </div>
                        <div className="px-0 col-xl-12">
                          <div className="main_form form-group mb-0 mt-4">
                            <label htmlFor="email" className="bg-white">
                              Email Address{" "}
                              <span className="required-star-text">*</span>
                            </label>
                            <Field
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Enter Your Email"
                            />
                          </div>
                          {errors?.email && touched?.email && (
                            <span className="error">{errors?.email}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                />
                <div className="d-flex align-items-center justify-content-end  btn_bottm">
                  <div className="btn_back mr-2">
                    <button
                      type="button"
                      className="btn"
                      onClick={(e) => {
                        setShowAttornyPopup(false);
                        e.preventDefault();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="bttn_continue mb-0">
                    <button
                      type="submit"
                      className="btn btn_continue"
                      disabled={isSaving}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddAttorney;
