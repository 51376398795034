import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";

const url = process.env.REACT_APP_API_URL;
export const GetActivityListing = createAsyncThunk(
  "activity/GetActivityListing",
  async (args, { rejectWithValue }) => {
    try {
      const { csv } = args;
      const resp = await axios.post(
        `${url}/common/activity_list`,
        {
        },
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );
      return { ...resp.data, ...{ csv: csv } };
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

const ActivitySlice = createSlice({
  name: "activity",
  initialState: {
    error: false,
    loading: false,
    getActivityListing: [],
  },
  reducers: {
    setActivityPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetActivityListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetActivityListing.fulfilled, (state, action) => {
        state.loading = false;
        if (!action?.payload?.csv) {
          state.getActivityListing = action?.payload;
        }
      })
      .addCase(GetActivityListing.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setAuthPage } = ActivitySlice.actions;

export default ActivitySlice.reducer;
