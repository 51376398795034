import React from "react";
import SignIn from "../components/Authentication/SignIn";
import PrivateWrapper from "../components/Wrappers/PrivateWrapper";
import ResetPassword from "../components/Authentication/ResetPassword";
import PasswordResetRequest from "../components/Authentication/PasswordResetRequest";
import ResetPasswordToken from "../components/Authentication/ResetPasswordToken";
import SignUp from "../components/Authentication/SignUp";
import RegAccountType from "../components/Authentication/RegAccountType";
import Subscription from "../components/Authentication/Subscription";
import PaymentMethod from "../components/Authentication/PaymentMethod";
import ThankYou from "../components/Common/ThankYou";
import PublicWrapper from "../components/Wrappers/PublicWrapper";
import PaymentPlan from "../components/Authentication/PaymentPlan";
import NotFound from "../components/NotFound";
import Trusts from "../components/Admin/Trusts";
import AddTrusts from "../components/Admin/Trusts/Create";
import GrantorsList from "../components/Admin/Grantor/GrantorsList";
import Structure from "../components/Common/Structure/Structure";
import Profile from "../components/Admin/Profile";
import ResetPasswordModal from "../components/Admin/ResetPasswordModal";
import SuccessfullyReset from "../components/Admin/SuccessfullyReset";
import AdminUser from "../components/Admin/AdminUser";
import AddAdminUserPopup from "../components/Admin/AdminUser/Create/AddAdminUserPopup";
import EditAdminUserPopup from "../components/Admin/AdminUser/Create/EditAdminUserPopup";
import Attorney from "../components/Admin/Attorney";
import AddAttorney from "../components/Admin/Attorney/Create/AddAttorney";
import EditAttorney from "../components/Admin/Attorney/Create/EditAttorney";
import SingleGrantor from "../components/Admin/Grantor/SingleGrantor";
import AddGrantor from "../components/Admin/Grantor/AddGrantor";
import ActivityLog from "../components/Admin/ActivityLog/ActivityLog";
import AttorneySignUp from "../components/Authentication/AttorneySignUp";
import SuccessorSignUp from "../components/Authentication/SuccessorSignUp";
import Congratulation from "../components/Admin/Trusts/Create/Congratulation";
import Invitation from "../components/Admin/Trusts/Create/Invitation";
import SetPassword from "../components/Admin/SetPassword";
import SupportSubmitPopup from "../components/Dashboard/SupportSubmitPopup";
import RequestSupportLogin from "../components/Dashboard/RequestSupportLogin";
import RequestSupport from "../components/Dashboard/ReqestSupport";
import Cookies from "js-cookie";
import VerificationSignIn from "../components/Authentication/VerificationSignIn";
import VerificationSignUp from "../components/Authentication/VerificationSignUp";
import SuccessorUser from "../components/Admin/Successor";
import AddSuccessorPopup from "../components/Admin/Successor/AddSuccessorPopup";
import EditSuccessorPopup from "../components/Admin/Successor/EditSuccessorPopUp";
import SetupGrantor from "../components/Authentication/SetupGrantor";
import SetupAttorney from "../components/Authentication/SetupAttorney";
import SetupSuccessor from "../components/Authentication/SetupSuccessor";
import GrantorVerify from "../components/Authentication/GrantorVerify";
import SpouseUser from "../components/Authentication/SpouseUser";
import SpouseSignUp from "../components/Authentication/SpouseSignup";
import SetPasswordSpouse from "../components/Authentication/SetPasswordSpouse";
import SpouseVerification from "../components/Authentication/SpouseVerification";
import Notice from "../components/Notice";

const accessToken = Cookies.get("ntr-token");
const routes = [
  {
    path: "/",
    element: <PrivateWrapper />,
    children: [

      {
        path: "/",
        element: (
          <Structure activeText="Trusts">
            <Trusts />
          </Structure>
        ),
      },
      {
        path: "/trusts",
        element: (
          <Structure activeText="Trusts">
            <Trusts />
          </Structure>
        ),
      },
      {
        path: "/trusts/add",
        element: <AddTrusts />,
      },
      {
        path: "/trusts/edit/:trust_id",
        element: <AddTrusts />,
      },
      {
        path: "/grantor",
        element: <GrantorsList />,
      },
      {
        path: "/grantor/:id",
        element: <SingleGrantor />,
      },
      {
        path: "/grantor/add",
        element: <AddGrantor />,
      },
      {
        path: "/admin-users",
        element: (
          <Structure activeText="Admin Users">
            <AdminUser />
          </Structure>
        ),
      },
      {
        path: "/admin-users/add",
        element: <AddAdminUserPopup />,
      },
      {
        path: "/admin-users/:id",
        element: <EditAdminUserPopup />,
      },
      {
        path: "/successor",
        element: (
          <Structure activeText="Successor Users">
            <SuccessorUser />
          </Structure>
        ),
      },
      {
        path: "/successor/add",
        element: <AddSuccessorPopup />,
      },
      {
        path: "/successor/:id",
        element: <EditSuccessorPopup />,
      },
      {
        path: "/attorney",
        element: (
          <Structure activeText="Attorney Users">
            <Attorney />
          </Structure>
        ),
      },
      {
        path: "/attorney/add",
        element: <AddAttorney />,
      },
      {
        path: "/attorney/:id",
        element: <EditAttorney />,
      },
      {
        path: "/activity-log",
        element: (
          <Structure activeText="Activity Log">
            <ActivityLog />
          </Structure>
        ),
      },
      {
        path: "profile",
        element: (
          <Profile />
        ),
      },
      {
        path: "forget-password",
        element: <ResetPasswordModal />,
      },
      {
        path: "reset-success",
        element: <SuccessfullyReset />,
      },
      {
        path: "*",
        element: (
          <>
            <NotFound />
          </>
        ),
      },
      {
        path: "/new-trust",
        element: (
          <>
            <Congratulation />
          </>
        ),
      },
      {
        path: "/invite",
        element: (
          <>
            <Invitation />
          </>
        ),
      },
      {
        path: "/support",
        element: (
          <>
            <Structure activeText="Support">
              <RequestSupportLogin />
            </Structure>
          </>
        ),
      },
      {
        path: "thank-you",
        element: (
          <>
            <ThankYou />
          </>
        ),
      },
      {
        path: "subscription-successor",
        element: (
          <>
            <Subscription />
          </>
        ),
      },
      {
        path: "purchase_subscription",
        element: (
          <>
            <Subscription />
          </>
        ),
      },
      {
        path: "notice",
        element: (
          <>
            <Notice />
          </>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <PublicWrapper />,
    children: [
      {
        path: "sign-in",
        element: (
          <>
            <SignIn />
          </>
        ),
      },
      {
        path: "sign-in/:id",
        element: (
          <>
            <SignIn />
          </>
        ),
      },

      {
        path: "payment-plan",
        element: (
          <>
            <PaymentPlan />
          </>
        ),
      },
      {
        path: "sign-in-verify",
        element: (
          <>
            <VerificationSignIn text="signIn" />
          </>
        ),
      },
      {
        exactPath: true,
        path: "sign-up-verify",
        element: (
          <>
            <VerificationSignUp text="signUp" />
          </>
        ),
      },
      {
        exactPath: true,
        path: "spouse-verify",
        element: (
          <>
            <SpouseVerification text="Spouse Verification" />
          </>
        ),
      },
      {
        path: "password-reset",
        element: (
          <>
            <ResetPassword />
          </>
        ),
      },
      {
        path: "password-reset-requested",
        element: (
          <>
            <PasswordResetRequest />
          </>
        ),
      },
      {
        path: "reset-password/:id",
        element: (
          <>
            <ResetPasswordToken />
          </>
        ),
      },
      {
        path: "set-password/:id",
        element: (
          <>
            <SetPassword />
          </>
        ),
      },
      {
        path: "set-spouse-password/:id",
        element: (
          <>
            <SetPasswordSpouse />
          </>
        ),
      },
      {
        exactPath: true,
        path: "sign-up",
        element: (
          <>
            <RegAccountType />
          </>
        ),
      },
      {
        path: "sign-up-account-grantor/:id/:id/:id",
        element: (
          <>
            <SignUp />
          </>
        ),
      },
      {
        path: "setup-account-grantor/:id",
        element: (
          <>
            <SetupGrantor />
          </>
        ),
      },
      {
        path: "spouse-sign-up/",
        element: (
          <>
            <SpouseSignUp />
          </>
        ),
      },
      {
        path: "setup-account-successor/:id",
        element: (
          <>
            <SetupSuccessor />
          </>
        ),
      },
      {
        path: "setup-account-attorney/:id",
        element: (
          <>
            <SetupAttorney />
          </>
        ),
      },
      {

        path: "sign-up-account-grantor",
        element: (
          <>
            <SignUp />
          </>
        ),
      },
      {
        exactPath: true,
        path: "sign-up-account-attorney/:id/:id/:id",
        element: (
          <>
            <AttorneySignUp />
          </>
        ),
      },
      {
        exactPath: true,
        path: "sign-up-account-attorney",
        element: (
          <>
            <AttorneySignUp />
          </>
        ),
      },
      {
        exactPath: true,
        path: "sign-up-account-successor/:id/:id/:id",
        element: (
          <>
            <SuccessorSignUp />
          </>
        ),
      },
      {
        exactPath: true,
        path: "sign-up-account-successor",
        element: (
          <>
            <SuccessorSignUp />
          </>
        ),
      },
      {
        path: "subscription",
        element: (
          <>
            <Subscription />
          </>
        ),
      },
      {
        path: "payment",
        element: (
          <>
            <PaymentMethod />
          </>
        ),
      },
      {
        path: "thanks-you",
        element: (
          <>
            <ThankYou />
          </>
        ),
      },
      {
        path: "*",
        element: (
          <>
            <NotFound />
          </>
        ),
      },
      {
        path: "grantor-verify",
        element: (
          <>
            <GrantorVerify text="grantor" />
          </>
        ),
      },
      {
        path: "SpouseUser-add",
        element: (
          <>
            <SpouseUser text="SpouseUser-add" />
          </>
        ),
      },
    ],
  },
  {
    path: "/supports",
    element: <>{!accessToken && <RequestSupport />}</>,
  },
  {
    path: "s",
    element: (
      <>
        <SupportSubmitPopup />
      </>
    ),
  },
];

export default routes;
