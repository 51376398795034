import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { GetSingleTrustDetail, SetPermission } from "../../../../../redux/features/TrustsService";
import _ from "lodash";
import Loader from "../../../../Common/Loader";

const TrustPermissionList = ({ id }) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { getSingleTrustDetail } = useSelector((state) => state.trusts);
  const { getUserDetail } = useSelector((state) => state.auths);
  const [loading, setLoading] = useState(false);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleOnChanged = async (checked, row, type) => {
    try {
      setLoading(true);
      let data = {
        ...row,
        trustId: id,
        [type]: checked ? 1 : 0,
      };
  
      if (type === 'isEdit' && checked && !row.isRead) {
        await dispatch(SetPermission({
          ...data,
          isRead: 1
        }));
        data = { ...data, isRead: 1 };
      }
  
      if (type === 'isDocument' && checked && (!row.isRead || !row.isEdit)) {
        await Promise.all([
          dispatch(SetPermission({
            ...data,
            isRead: 1, 
          })),
          dispatch(SetPermission({
            ...data,
            isEdit: 1, 
          })),
        ]);
        data = { ...data, isRead: 1, isEdit: 1 };
      }
  
      const response = await dispatch(SetPermission(data));
      if (response?.type === "trusts/SetPermission/fulfilled") {
        await dispatch(GetSingleTrustDetail({ trustId: id }));
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      id: "firstName",
      name: "Full Name",
      selector: (row) => row.userName,
    },
    {
      id: "email",
      name: "User Type",
      selector: (row) => (row.userTypeText ? row.userTypeText : "-"),
    },
    {
      id: "isRead",
      name: "Read Permissions",
      center: "true",
      selector: (row) => (
        <Switch
          onChange={(checked) => handleOnChanged(checked, row, "isRead")}
          checked={row.isRead === 1}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00639B"
          offColor="#DEE3EB"
          height={29}
          width={70}
          onHandleColor="#FFFFFF"
          offHandleColor="#DEE3EB"
          handleDiameter={22}
          activeBoxShadow="null"
          className={`${row.isRead === 1 ? "" : "inactive-border"}`}
          uncheckedHandleIcon={
            <div
              style={{
                width: "16px",
                height: "16px",
                background: "#72777F",
                borderRadius: "50px",
                margin: "3.5px 3px 3px 3px",
              }}
            ></div>
          }
        />
      ),
    },
    {
      id: "isEdit",
      name: "Edit Permissions",
      center: "true",
      selector: (row) => (
        <Switch
          onChange={(checked) => handleOnChanged(checked, row, "isEdit")}
          checked={row.isEdit === 1}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00639B"
          offColor="#DEE3EB"
          height={29}
          width={70}
          onHandleColor="#FFFFFF"
          offHandleColor="#DEE3EB"
          handleDiameter={22}
          activeBoxShadow="null"
          className={`${row.isEdit === 1 ? "" : "inactive-border"}`}
          uncheckedHandleIcon={
            <div
              style={{
                width: "16px",
                height: "16px",
                background: "#72777F",
                borderRadius: "50px",
                margin: "3.5px 3px 3px 3px",
              }}
            ></div>
          }
        />
      ),
    },
    {
      id: "isDocument",
      name: "Document Permissions",
      center: "true",
      selector: (row) => (
        <Switch
          onChange={(checked) => handleOnChanged(checked, row, "isDocument")}
          checked={row.isDocument === 1}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00639B"
          offColor="#DEE3EB"
          height={29}
          width={70}
          onHandleColor="#FFFFFF"
          offHandleColor="#DEE3EB"
          handleDiameter={22}
          activeBoxShadow="null"
          className={`${row.isDocument === 1 ? "" : "inactive-border"}`}
          uncheckedHandleIcon={
            <div
              style={{
                width: "16px",
                height: "16px",
                background: "#72777F",
                borderRadius: "50px",
                margin: "3.5px 3px 3px 3px",
              }}
            ></div>
          }
        />
      ),
    },
  ];

  const serverSideData = getSingleTrustDetail?.permissionUserList || [];
  const filteredData = _.filter(serverSideData, (item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <>
      {loading && <Loader />}
      {getUserDetail?.role !== 4 && (
        <div className="mt-5">
          <div className="mian_part_sec ">
            <div className="">
              <div className="title_acordian">
                <h5>Trust Permissions</h5>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div className="flex-1">
                      <div className="border-0 d-flex position-relative">
                        <input
                          type="text"
                          className="search-input-field"
                          name="search"
                          id="search"
                          placeholder="Search"
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <button
                          className="search-btn position-absolute"
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1479_513)">
                              <path
                                d="M18.0831 16.3333H17.1614L16.8348 16.0183C18.2348 14.385 18.9581 12.1567 18.5614 9.78833C18.0131 6.545 15.3064 3.955 12.0398 3.
                                55833C7.09809 2.955 2.95575 7.09833 3.55809 12.0433C3.95575 15.3083 6.54575 18.0183 9.78809 18.565C12.1564 18.9617 14.3781 18.2383 16.0148 16.8342L16.3331 17.1525V18.0733L21.2914 23.0317C21.7698 23.51 22.5514 23.51 23.0298 23.0317C23.5081 22.5533 23.5081 21.7717 23.0298 21.2933L18.0831 16.3333ZM11.0831 16.3333C8.17809 16.3333 5.83309 13.9883 5.83309 11.0833C5.83309 8.17833 8.17809 5.83333 11.0831 5.83333C13.9881 5.83333 16.3331 8.17833 16.3331 11.0833C16.3331 13.9883 13.9881 16.3333 11.0831 16.3333Z"
                                fill="#42474E"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1479_513">
                                <rect width="28" height="28" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  className="trust-permission-tbl"
                  defaultSortFieldId={1}
                  pagination
                  paginationServer
                  data={filteredData}
                  highlightOnHover
                  rowsPerPageOptions={[5, 10, 25, 50]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrustPermissionList;
