import React from 'react'
import { toast } from 'react-toastify';
import { AddInvitation } from '../../../../redux/features/TrustsService';
import { useDispatch } from 'react-redux';

const GrantorInvitation = ({ setInvitePopup, isEmail, id, trustName, isIndex }) => {
    const dispatch = useDispatch();

    const handleInvite = async (value) => {
        const res = await dispatch(
            AddInvitation({
                trustId: id,
                grantorList: [value],
            })
        );
        if (res?.type === "trusts/AddInvitation/fulfilled") {
            toast.success(res?.payload?.message);
            setInvitePopup(false)
        } else if (res?.type === "trusts/AddInvitation/rejected") {
            toast.error(res?.payload?.response?.data?.message);
        }
    };

    return (
        <div className="mian_center_box thankyou_sec Invite-title">
            <h3 className="my-2">Please Confirm The Email To Proceed</h3>
            <p className="mt-2 mb-2 invite-text">
                An email will be sent to <strong>{isEmail}</strong> as a  <strong>{isIndex === 0 ? "Primary Grantor" : "Secondary Grantor"}</strong> to view the details for <strong>{trustName}.</strong> If the information is correct,
            </p>
            <p className='mt-0'>please proceed by confirming this invite.</p>
            <div className="d-flex align-items-center justify-content-between mb-0 mt-2">
                <div className="btn_back mr-2">
                    <button
                        type="button"
                        className="btn cursor-pointer"
                        onClick={() => {
                            setInvitePopup(false)
                        }}
                    >
                        Cancel Invite
                    </button>
                </div>
                <div className="bttn_continue mb-0">
                    <button
                        className="btn btn_continue cursor-pointer"
                        onClick={() => {
                            handleInvite(isEmail)
                            setInvitePopup(true);
                        }}
                    >
                        Confirm Invite
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GrantorInvitation
