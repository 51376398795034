import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalCustom from "../../../Common/ModalCustom";
import Invitation from "../../Trusts/Create/Invitation";

const Congratulation = ({ setAddEditData, trustName, data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="mian_center_box thankyou_sec cradit_Sec">
      <h3 className="my-3">Congratulations on Registering Your Trust!</h3>
      <p className="mt-4 ">
        An email will be sent to your account with details and confirmation for
        this trust.
      </p>
      <p>Thank you for using the National Trust Registry!</p>
      <div className="d-flex align-items-center justify-content-between mb-0 mt-5 ">
        <div className="btn_back mr-2">
          <button
            type="button"
            className="btn cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Back to Dashboard
          </button>
        </div>
      </div>
      <ModalCustom size="xl" handleClose="grantorModal" show={showDialog}>
        <Invitation
          setShowInvitation={setShowDialog}
          setAddEditData={setAddEditData}
          trustName={trustName}
          id={data}
        />
      </ModalCustom>
    </div>
  );
};

export default Congratulation;
