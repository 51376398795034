import React from 'react'
import { useNavigate } from 'react-router-dom';


const Confirmation = ({ setShowModal }) => {
    const navigate = useNavigate()
    const subsription = () => {
        navigate("/subscription")
    }
    return (
        <div className=" thankyou_sec cradit_Sec">
            <h3 className="my-3">Are you sure make a Subscription!</h3>
            <div className="d-flex align-items-center justify-content-between btn_bottm">
                <div className="btn_back mr-2">
                    <button
                        type="button"
                        className="btn"
                        onClick={() => {
                            setShowModal(false)
                        }}
                    >
                        Back to Dashboard
                    </button>
                </div>
                <div className="bttn_continue mb-0">
                    <button type="button" className="btn btn_continue" onClick={subsription}>
                        Subscription
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Confirmation
