import React from 'react'
import { useNavigate } from 'react-router-dom';

const SuccessorProfilePopup = ({ setSuccessorPopup }) => {

    const navigate = useNavigate();

    const handleSubscription = async (value) => {
        navigate('/subscription-successor')
    };


    return (
        <div className="mian_center_box">
            <h5 className="my-2" style={{color : 'black', fontSize:'24px'}}>Are you sure you wish to upgrade your account?</h5>
            <h5 className="my-2" style={{color : 'rgba(68, 70, 78, 1)', fontSize:'24px'}}>
            Select continue to upgrade your account and <h5 style={{color : 'rgba(68, 70, 78, 1)', fontSize:'24px'}}> create trusts!</h5>
            </h5>
            <div className="d-flex align-items-center justify-content-between mb-0 mt-2">
                <div className="btn_back mr-2">
                    <button
                        type="button"
                        className="btn cursor-pointer"
                        onClick={() => {
                            setSuccessorPopup(false)
                        }}
                    >
                        No Thanks
                    </button>
                </div>
                <div className="bttn_continue mb-0">
                    <button
                        className="btn btn_continue cursor-pointer"
                        onClick={() => {
                            setSuccessorPopup(true);
                            handleSubscription();
                        }}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SuccessorProfilePopup
