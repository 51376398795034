import React, { useState } from "react";
import _ from "lodash";
import Slider from 'react-slick';
import ModalCustom from "../Common/ModalCustom";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_URL);

const arrowprev = {
  position: 'absolute',
  top: '53%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  backgroundColor: '#1E2938',
  width: '100px',
  height: '1000px',
  cursor: 'pointer',
  outline: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '18px',
  opacity: '50%',
};

const arrownext = {
  position: 'absolute',
  top: '53%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  backgroundColor: '#1E2938',
  width: '100px',
  height: '1000px',
  cursor: 'pointer',
  outline: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '18px',
  opacity: '50%',
};

// Custom Previous Arrow Component
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="custom-prev-arrow container" style={{ ...arrowprev, left: '0' }} onClick={onClick}>
      
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="31" viewBox="0 0 18 31" fill="none">
        <path d="M17.2266 4.73519L6.87993 15.1208L17.2266 25.5064C18.2666 26.5503 18.2666 28.2366 17.2266 29.2805C16.1866 30.3245 14.5066 30.3245 13.4666 29.2805L1.22659 16.9945C0.186592 15.9506 0.186592 14.2642 1.22659 13.2203L13.4666 0.93428C14.5066 -0.109632 16.1866 -0.109632 17.2266 0.93428C18.2399 1.97819 18.2666 3.69128 17.2266 4.73519Z" fill="white" />
      </svg>
    </button>
  );
};

// Custom Next Arrow Component
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="custom-next-arrow container" style={{ ...arrownext, right: '0' }} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="3s1" viewBox="0 0 18 31" fill="none">
        <path d="M0.773164 25.506L11.1198 15.1204L0.773164 4.73482C-0.266836 3.6909 -0.266836 2.00458 0.773164 0.960669C1.81316 -0.083244 3.49316 -0.083244 4.53316 0.960669L16.7732 13.2467C17.8132 14.2906 17.8132 15.977 16.7732 17.0209L4.53316 29.3069C3.49316 30.3508 1.81316 30.3508 0.773164 29.3069C-0.240169 28.263 -0.266836 26.5499 0.773164 25.506Z" fill="white" />
      </svg>
    </button>
  );
};

const SubscriptionBox = ({ subscriptionDataList }) => {
  const [showStripeCheckoutForm, handleStripeCheckoutFormPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  const handleStripeCheckoutForm = (subscriptionData) => {
    handleStripeCheckoutFormPopup(false);
    setSelectedProduct(subscriptionData)
    handleStripeCheckoutFormPopup(true);
  };

  const settings = {
    slidesToShow: 3,
    autoplaySpeed: 5000,
    arrows: true, // Show arrows
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    centerMode: true,
    centerPadding: '22%',
    infinite: true,
    speed: 500,
    dots: true
  };

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  return (
    <>
      <div>
        <Slider {...settings}  >
          {_?.map(subscriptionDataList, (subscriptionData, index) => (
            <div className="" key={index}>
              <div className="card" key={index}>
                <div className="card-content">
                  <div className='card-btn'>
                    <h6 className="thm-black fs20 text-uppercase">{subscriptionData?.title}</h6>
                  </div>
                  <div className="border-bottom mb-4">
                    <div className="w-100 d-flex mt-3">
                      <h4 className='subscription-AmountTxt me-2'>$</h4>
                      <h4 className="subscription-AmountTxt thm-black f4bold">
                        {Math.floor(subscriptionData?.price)}.
                        <sup className="subscription-pointtxt mx-1">{subscriptionData?.price.toString().split('.')[1]}</sup>
                      </h4>
                      <sup className="thm-black fs16 f6bold mt-3"></sup>
                      <span className="subscriptionPeriodTxt thm-graydark mx-1 mt-2">/ {subscriptionData?.subTitle}</span>
                    </div>
                    {/* {subscriptionData?.isDiscount ? (
                      <div className="dis-text-plans pe-5">
                        {subscriptionData?.isDiscount + " Savings"}
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </div>
                  <div className="mb-4 mt-2">
                    {_?.map(subscriptionData?.description, (item, descriptionIndex) => {
                      return (
                        <div className="d-flex text-left mt-2" key={`${descriptionIndex}-descriptionIndex`}>
                          <div className="me-2 mt-2 subscription-right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                              <path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill="black" />
                            </svg>
                          </div>
                          <div className="FeatureTxt-subscription text-capitalize">{item}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="text-center">
                    <button type="button" className="subscriprtion-btn text-uppercase" onClick={() => handleStripeCheckoutForm(subscriptionData)}>
                      {"CHOOSE" + "  " + subscriptionData?.title}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          ))}
        </Slider>
      </div>

      <ModalCustom
        size="xs"
        show={showStripeCheckoutForm}
      >
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm
            checkoutFormType='subscription'
            selectedProduct={selectedProduct}
            handleStripeCheckoutFormPopup={handleStripeCheckoutFormPopup}
          />
        </Elements>
      </ModalCustom>
    </>
  );
};

export default SubscriptionBox;
