import React from 'react'
import { useNavigate } from 'react-router-dom';
import { SignUpService, SignUpServiceSpouse } from '../../redux/features/AuthService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const ConfirmSpousePopup = ({ setSuccessorPopup }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const StringData = localStorage.getItem('spouse-data');
    const JsonDataSpouse = JSON.parse(StringData);
    const handleSubscriptionSubmit = async () => {
        const response = await dispatch(SignUpServiceSpouse(JsonDataSpouse));
        if (response.type === "auths/SignUpServiceSpouse/fulfilled") {
            navigate('/subscription');
        } else {
            toast.error(response?.payload?.response?.data?.message, {
                autoClose: 3000,
            });
        }
    };

    const handleSubscription = async (value) => {
        navigate('/subscription');
    };


    return (
        <div className="text-center mt-3">
            <h5 className="my-2" style={{ color: 'black', fontSize: '24px' }}>Are you sure you wish to add this user?</h5>
            <h5 className="my-2" style={{ color: 'rgba(68, 70, 78, 1)', fontSize: '24px' }}>
                Select continue to send an invite
            </h5>
            <div className="d-flex align-items-center mb-0 mt-4 mx-5">
                <div className="btn_back mx-3">
                    <button
                        type="button"
                        className="btn cursor-pointer"
                        onClick={() => {
                            setSuccessorPopup(true)
                            handleSubscription();
                        }}
                    >
                        No, Cancel
                    </button>
                </div>
                <div className="bttn_continue mb-0">
                    <button
                        className="btn btn_continue cursor-pointer"
                        onClick={() => {
                            setSuccessorPopup(true);
                            handleSubscriptionSubmit();
                        }}
                    >
                        Yes, Continue to Payment
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmSpousePopup