import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";

const url = process.env.REACT_APP_API_URL;
export const AddAttorneyService = createAsyncThunk(
  "attorney/AddAttorneyService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_common_user`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const GetAttorneyListing = createAsyncThunk(
  "attorney/GetAttorneyListing",
  async (args, { rejectWithValue }) => {
    try {
      const { role, status, search, csv } = args;
      const resp = await axios.post(
        `${url}/user/get_user_list_common`,
        {
          role: role,
          status: status,
          search: search,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return { ...resp.data, ...{ csv: csv } };
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const UpdateAttorneyDetail = createAsyncThunk(
  "attorney/updateAttorneyDetail",
  async (args, { rejectWithValue }) => {
    try {
      const { values } = args;
      const resp = await axios.post(`${url}/user/common_user_update`, values, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const UpdateAttorneyDetailStatus = createAsyncThunk(
  "attorney/UpdateAttorneyDetailStatus",
  async (args, { rejectWithValue }) => {
    try {
      const { userId, isStatus } = args;
      const resp = await axios.post(
        `${url}/user/common_user_update`,
        {
          userId: userId,
          isStatus: isStatus,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

export const GetSingleAttorneyDetail = createAsyncThunk(
  "attorney/GetSingleAttorneyDetail",
  async (args, { rejectWithValue }) => {
    try {
      const { userId } = args;
      const resp = await axios.post(
        `${url}/user/get_user_info`,
        {
          userId: userId,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

const AttorneySlice = createSlice({
  name: "attorney",
  initialState: {
    error: false,
    loading: false,
    getAttorneyListing: [],
    getSingleAttorneyDetail: null,
  },
  reducers: {
    setAttorneyPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddAttorneyService.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddAttorneyService.fulfilled, (state, action) => {
        state.loading = false;
        state.addAdmin = action?.payload?.data;
      })
      .addCase(AddAttorneyService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetAttorneyListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAttorneyListing.fulfilled, (state, action) => {
        state.loading = false;
        if (!action?.payload?.csv) {
          state.getAttorneyListing = action?.payload;
        }
      })
      .addCase(GetAttorneyListing.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetSingleAttorneyDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSingleAttorneyDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleAttorneyDetail = action.payload?.data;
      })
      .addCase(GetSingleAttorneyDetail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateAttorneyDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateAttorneyDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleAttorneyDetail = action?.payload?.data;
      })
      .addCase(UpdateAttorneyDetail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateAttorneyDetailStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateAttorneyDetailStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleAttorneyDetail = action?.payload?.data;
      })
      .addCase(UpdateAttorneyDetailStatus.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setAttorneyPage } = AttorneySlice.actions;

export default AttorneySlice.reducer;
