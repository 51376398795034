import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const Sidebar = ({ activeText, handleHideShowClass }) => {
  const [sidebarData, setSidebarData] = useState([]);
  const { getUserDetail } = useSelector((state) => state.auths)
  // useEffect(()=> {
  //   localStorage.clear();
  // })
  useEffect(() => {

    if (getUserDetail?.role === 1 || getUserDetail?.role === 5) {
      setSidebarData([
        { 
          id: "admin-trust",
          title: "Trusts",
          url: "/trusts",
        },
        {
          id: "admin-grantor-users",
          title: "Grantor Users",
          url: "/grantor",
        },
        {
          id: "admin-successor-users",
          title: "Successor Users",
          url: "/successor",
        },
        {
          id: "admin-attorney-users",
          title: "Attorney Users",
          url: "/attorney",
        },
        {
          id: "admin-users",
          title: "Admin Users",
          url: "/admin-users",
        },
        {
          id: "admin-activity-log",
          title: "Activity Log",
          url: "/activity-log",
        },
      ]);
    } else if (getUserDetail?.role === 2 || getUserDetail?.role === 4) {

      setSidebarData([
        {
          id: "admin-trust",
          title: "Trusts",
          url: "/trusts",
        },
      ]);

    } else if (getUserDetail?.role === 3) {
      setSidebarData([
        {
          id: "admin-trust",
          title: "Trusts",
          url: "/trusts",
        },
        {
          id: "admin-grantor-users",
          title: "Grantor Users",
          url: "/grantor",
        },
      ]);
    } else if (getUserDetail?.role === 1 || getUserDetail?.role === 5) {
      setSidebarData([
        {
          id: "admin-trust",
          title: "Trusts",
          url: "/trusts",
        },
        {
          id: "admin-grantor-users",
          title: "Grantor Users",
          url: "/grantor",
        },
        {
          id: "admin-attorney-users",
          title: "Attorney Users",
          url: "/attorney",
        },
        {
          id: "admin-users",
          title: "Admin Users",
          url: "/admin-users",
        },
        {
          id: "admin-activity-log",
          title: "Activity Log",
          url: "/activity-log",
        },
      ]);
    }

  }, [getUserDetail?.role]);

  return (
    <>
      <div className="col-xl-2 col-lg-3 px-0 left_Side_chat">
        <div className="close-btn-chat">
          <button type="button" className="close" aria-label="Close" onClick={(e) => { e.preventDefault(); handleHideShowClass(false); }}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="side_nav_Sec">
          <div className="title_nav">
            <h5>National Trust Registry</h5>
          </div>
          <div className="navigation_tools">
            {_.map(sidebarData, (sidebarDetail) => (
              <div
                className={`${
                  getUserDetail?.role === 1
                    ? `linkes_sec ${
                        sidebarDetail?.title === activeText ? "active" : ""
                      }`
                    : (getUserDetail?.role === 3 ||
                        getUserDetail?.role === 2 ||
                        getUserDetail?.role === 4) &&
                      sidebarDetail?.title === activeText
                    ? "line_remove active"
                    : getUserDetail?.role !== 1 && getUserDetail?.role !== 3
                    ? "line_remove"
                    : "dots"
                }`}
                key={sidebarDetail?.id}
              >
                <Link to={`${sidebarDetail?.url}`} onClick={(e) =>{localStorage.clear();}}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
                      fill="#141B2C"
                    />
                  </svg>
                  {sidebarDetail?.title}
                </Link>
              </div>
            ))}
          </div>
          <div className="supprt-link-sidebar m-auto text-center position-absolute support-text">
            <NavLink className="support-link-text" to={"/support"}>
              Request Support
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
