import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalCustom from "../Common/ModalCustom";
import StripeCheckoutForm from "../Common/StripeCheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getCurrentProduct } from "../../redux/features/SubscriptionService";
import { getSubscriptionDetail, cancelSubscription } from "../../redux/features/SubscriptionService";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_URL);

const SubscribedPlanDetails = ({ userId, isAdminUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState();
  const [showStripeCheckoutForm, setShowStripeCheckoutForm] = useState(false);
  const { subscriptionData, isLoading } = useSelector((state) => state.subscription);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getSubscriptionDetail({ userId }));
    }, 500);

    return () => clearTimeout(timer);
  }, [dispatch, userId, subscriptionData]);

  const handleCancelSubscription = () => {
    const { stripeSubscriptionId } = subscriptionData?.subscriptionDetail || {};
    if (stripeSubscriptionId) {
      dispatch(cancelSubscription({ stripeSubscriptionId, userId }))
        .then(res => {
          if (res.type === "subscription/cancelSubscription/fulfilled") {
            navigate('/profile');
          }
        });
    }
  };

  const formatDate = (milliseconds) => {
    if (!milliseconds) { return; }

    const date = new Date(milliseconds);
    const day = date.getUTCDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short', timeZone: 'UTC' }).format(date);
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  };

  const getCurrentlyUseProduct = () => {
    if (subscriptionData && subscriptionData?.userInfo?.stripeSubscriptionId) {
      let getUserDetail = subscriptionData?.userInfo;
      dispatch(getCurrentProduct({ stripeSubscriptionId: getUserDetail.stripeSubscriptionId }))
        .then((res) => {
          if (res.type === "subscription/getCurrentProduct/fulfilled") {
            if (res.payload.paymentPlanInfo) {
              setSelectedProduct(res.payload.paymentPlanInfo);
            }
          }
        });
    }
  };

  const handleStripeCheckoutForm = () => {
    getCurrentlyUseProduct();
    setShowStripeCheckoutForm(true);
  };

  const renderSubscriptionBtn = (userInfo, subscriptionDetail, isAdminUser) => {
    if (((subscriptionDetail.status === 1) || (isAdminUser && userInfo.paymentStatus === 1 && userInfo.profileStatus === 1))) {
      return <button className="btn btn-danger" onClick={handleCancelSubscription}>Cancel Subscription</button>;
    } else if (!isAdminUser && subscriptionDetail.status === 2) {
      return <div className="text-danger"><strong>Cancel At:</strong> {formatDate(expirySeconds)}</div>;
    } else if (!isAdminUser && subscriptionDetail.status === 0) {
      return <div className="btn btn-secondary" onClick={handleStripeCheckoutForm}><strong>Renew</strong></div>;
    } else {
      return <strong className="text-danger">Cancelled</strong>;
    }
  };

  const { subscriptionDetail, stripePlan, userInfo } = subscriptionData || {};

  if (isLoading || !subscriptionDetail || !stripePlan || !userInfo) { return null; }
  if ([3].includes(parseInt(userInfo.role))) { return; }

  const { title, price, subTitle } = stripePlan;
  const expirySeconds = (userInfo.planExpiryDate) ? parseInt(userInfo.planExpiryDate) : null;

  return (
    <>
      <div className="m-3 profile_main_part_section">
        <div className="mx-3">
          <h3 className="text-center pt-3">Subscription Detail</h3>
          <div className="d-flex my-3 py-3 justify-content-around px-3 bg-light border rounded-3 align-items-center">
            <div><strong>Subscription: </strong>{title}</div>
            <div><strong>Price: </strong>{price} USD</div>
            <div><strong>Type: </strong>{subTitle}</div>
            <div>{renderSubscriptionBtn(userInfo, subscriptionDetail, isAdminUser)}</div>
          </div>
        </div>
      </div>

      {
        ([2,4].includes(userInfo.role)) ? (
          <ModalCustom size="xs" show={showStripeCheckoutForm}>
            <Elements stripe={stripePromise}>
              <StripeCheckoutForm
                checkoutFormType="renew_subscription"
                selectedProduct={selectedProduct}
                handleStripeCheckoutFormPopup={setShowStripeCheckoutForm}
              />
            </Elements>
          </ModalCustom>
        ) : null
      }
    </>
  );
};

export default SubscribedPlanDetails;
