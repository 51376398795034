import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RedirectToRoute } from "../../components/Common/Authentication";

const url = process.env.REACT_APP_API_URL;
export const AddSuccessorServices = createAsyncThunk(
  "successor/AddSuccessorServices",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/user/set_common_user`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const GetSuccessorListing = createAsyncThunk(
  "successor/GetSuccessorListing",
  async (args, { rejectWithValue }) => {
    try {
      const { role, status, search, csv } = args;
      const resp = await axios.post(
        `${url}/user/get_user_list_common`,
        {
          role: role,
          status: status,
          search: search,
        },
        {
          headers: getAuthenticationHeader(),
          mode: "cors",
        }
      );
      return { ...resp.data, ...{ csv: csv } };
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const UpdateSuccessorDetail = createAsyncThunk(
  "successor/UpdateSuccessorDetail",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = args;
      const resp = await axios.post(`${url}/user/common_user_update`, data, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const GetSuccessorDetail = createAsyncThunk(
  "successor/GetSuccessorDetail",
  async (args, { rejectWithValue }) => {
    try {
      const { userId } = args;
      const resp = await axios.post(
        `${url}/user/get_user_info`,
        {
          userId: userId,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute();
      return rejectWithValue(err);
    }
  }
);

export const UpdateSuccessorDetailStatus = createAsyncThunk(
  "successor/UpdateSuccessorDetailStatus",
  async (args, { rejectWithValue }) => {
    try {
      const { userId, isStatus } = args;
      const resp = await axios.post(
        `${url}/user/common_user_update`,
        {
          userId: userId,
          isStatus: isStatus,
        },
        {
          headers: getAuthenticationHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      RedirectToRoute(err);
      return rejectWithValue(err);
    }
  }
);

const SuccessorSlice = createSlice({
  name: "successor",
  initialState: {
    error: false,
    loading: false,
    addSuccessor: null,
    updateSuccessor: null,
    getSuccessorListing: [],
    getSingleSuccessorDetail: [],
  },
  reducers: {
    setSuccesssorPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddSuccessorServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddSuccessorServices.fulfilled, (state, action) => {
        state.loading = false;
        state.addSuccessor = action?.payload?.data;
      })
      .addCase(AddSuccessorServices.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetSuccessorListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSuccessorListing.fulfilled, (state, action) => {
        state.loading = false;
        if (!action?.payload?.csv) {
          state.getSuccessorListing = action?.payload;
        }
      })
      .addCase(GetSuccessorListing.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateSuccessorDetailStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateSuccessorDetailStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpdateSuccessorDetailStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetSuccessorDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSuccessorDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleSuccessorDetail = action?.payload?.data;
      })
      .addCase(GetSuccessorDetail.rejected, (state) => {
        state.loading = false;
      })

      .addCase(UpdateSuccessorDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateSuccessorDetail.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.getSingleSuccessorDetail = action?.payload?.data;
        }
      })
      .addCase(UpdateSuccessorDetail.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setSuccesssorPage } = SuccessorSlice.actions;

export default SuccessorSlice.reducer;
