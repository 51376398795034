import React, { useRef, useState } from 'react'
import { useFormik } from 'formik';
import { Form } from "react-bootstrap";
import Button from "../Common/Button";
import DatePicker from "react-datepicker";
import { useDispatch } from 'react-redux';
import { UploadMedia } from '../../redux/features/CommonService';
import { toast } from 'react-toastify';
import { SignUpServiceSpouse } from '../../redux/features/AuthService';

const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = "*Email is required to Sign Up";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
    }
    if (!values.firstName) {
        errors.firstName = "*First Name is required to Sign Up";
    }
    if (!values.lastName) {
        errors.lastName = "*Last Name is required to Sign Up";
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = "*Phone Number is required to Sign Up";
    } else if (!/\d{3,10}$/.test(values.phoneNumber)) {
        errors.phoneNumber = "Invalid phone number";
    }
    if (!values.dob) {
        errors.dob = "*Date Of Birth is required to Sign Up";
    }
    if (!values.state) {
        errors.state = "*State is required to Sign Up";
    }
    if (!values.city) {
        errors.city = "*City is required to Sign Up";
    }
    if (!values.zipCode) {
        errors.zipCode = "*Zip code is required to Sign Up";
    }
    if (!values.address1) {
        errors.address1 = "*Address 1 is required to Sign Up";
    }
    if (!values.ssnNumber) {
        errors.ssnNumber = "*SSN/ITIN Number is required to Sign Up";
    }
    if (!values.ssnConfirmNumber) {
        errors.ssnConfirmNumber = "*Confirm SSN/ITIN Number is required to Sign Up";
    } else if (values.ssnNumber !== values.ssnConfirmNumber) {
        errors.ssnConfirmNumber = "*SSN/ITIN and confirm SSN/ITIN do not match";
    }
    if (!values.ssnNumber) {
        errors.ssnNumber = "*SSN/ITIN Number is required to Sign Up";
    } else if (values.ssnNumber?.length < 9) {
        errors.ssnNumber = "SSN/ITIN number must be 9 digits long";
    }
    return errors;
};

const AddSpouseFormPopup = ({ setFormSpousePopup, setAskSpousePopup, formData }) => {

    document.title = "National Trust Registry | Sign Up";
    const dispatch = useDispatch();
    const fileFrontRef = useRef(null);
    const fileBackRef = useRef(null);
    const [value, setValue] = useState("");
    const [ssnValue, setSsnValue] = useState("");
    const [valueCnf, setValueCnf] = useState("");
    const [ssnValueCnf, setSsnValueCnf] = useState("");

    const handleSvgFrontClick = () => {
        fileFrontRef.current.click();
    };
    const handleSvgBackClick = () => {
        fileBackRef.current.click();
    };

    let initialValuesWithoutEmail = {
        timezone: "Asia/Kolkata",
        role: 2,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        dob: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        isEmail: "",
        ssnConfirmNumberValue: "",
        ssnConfirmNumber: "",
        ssnNumber: "",
        ssnValue: "",
        drivingLicenseFront: "",
        drivingLicenseBack: "",
        drivingLicenseFrontShow: "",
        drivingLicenseBackShow: "",
    };

    const path = window.location.pathname;
    const parts = path.split("/").filter((part) => part !== "");
    if (parts.length >= 3) {
    }

    function handleKeyPress(event) {
        const allowedCharacters = /[0-9()\-+]/;
        const key = event.key;
        if (!allowedCharacters.test(key)) {
            event.preventDefault();
        }
    }

    const formik = useFormik({
        initialValues: initialValuesWithoutEmail,
        validate,
        onSubmit: async (values) => {
            console.log(values);
            const response = await dispatch(SignUpServiceSpouse(values));
            console.log(response);
            console.log(typeof (response?.payload?.status));
            if (response?.payload?.status === 1) {
                toast.success("Spouse Added Successfully", {
                    autoClose: 3000,
                    onClose: () => {
                        window.location.reload();
                    },
                });
            } else if (response?.payload?.status === 0) {
                toast.error(response?.payload?.response?.data?.message, {
                    autoClose: 3000,
                });
            }


        },
    });

    const handleUploadImage = async (file) => {
        if (file) {
            let data = file;
            if (file?.type === "image/svg+xml") {
                data = new Blob([file], { type: "image/svg" });
                data.name = file.name;
            }
            const formData = new FormData();
            formData.append("files", data, file.name);
            const res = await dispatch(UploadMedia(formData));
            if (res.type === "commons/UploadMedia/fulfilled") {
                return res.payload?.files[0];
            }
        }
    };

    const drivingLicenseFront = async (e) => {
        const file = e.target.files[0];
        const uploadData = await handleUploadImage(file);
        formik.setFieldValue("drivingLicenseFront", uploadData.mediaName);
        formik.setFieldValue("drivingLicenseFrontShow", file.name);
    };

    const drivingLicenseBack = async (e) => {
        const file = e.target.files[0];
        const uploadData = await handleUploadImage(file);
        formik.setFieldValue("drivingLicenseBack", uploadData?.mediaName);
        formik.setFieldValue("drivingLicenseBackShow", file?.name);
    };

    const replaceAt = (str, index, character) => {
        return (
            str.substr(0, index) + character + str.substr(index + character.length)
        );
    };

    const transformDisplay = (val) => {
        let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

        if (displayVal.length >= 4) {
            displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3);
        }

        if (displayVal.length >= 7) {
            displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6);
            const lastFour = displayVal.slice(7);
            displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour;
        } else {
            displayVal = displayVal.replace(/\d/g, "*");
        }

        return displayVal;
    };

    const transformValue = (val) => {
        let cleanVal = val.replace(/[^0-9|\\*]/g, "");
        cleanVal = cleanVal.substr(0, 9);
        let updatedValue = value ? value : "";

        for (let i = 0, l = cleanVal.length; i < l; i++) {
            if (/[0-9]/g.exec(cleanVal[i])) {
                updatedValue = replaceAt(updatedValue, i, cleanVal[i]);
            }
        }
        updatedValue = updatedValue.substr(0, cleanVal.length);
        formik.setFieldValue("ssnNumber", updatedValue);
        setValue(updatedValue);
    };

    const syncInput = (event) => {
        const val = event.target.value;
        formik.setFieldValue("ssnValue", val);
        transformDisplay(val);
        transformValue(val);
        event.target.setSelectionRange(val.length, val.length);
        setSsnValue(value);
    };

    const replaceAtCnf = (str, index, character) => {
        return (
            str.substr(0, index) + character + str.substr(index + character.length)
        );
    };

    const transformDisplayCnf = (val) => {
        let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

        if (displayVal.length >= 4) {
            displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3);
        }

        if (displayVal.length >= 7) {
            displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6);
            const lastFour = displayVal.slice(7);
            displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour;
        } else {
            displayVal = displayVal.replace(/\d/g, "*");
        }

        return displayVal;
    };

    const transformValueCnf = (val) => {
        let cleanVal = val.replace(/[^0-9|\\*]/g, "");
        cleanVal = cleanVal.substr(0, 9);
        let updatedValue = valueCnf ? valueCnf : "";

        for (let i = 0, l = cleanVal.length; i < l; i++) {
            if (/[0-9]/g.exec(cleanVal[i])) {
                updatedValue = replaceAtCnf(updatedValue, i, cleanVal[i]);
            }
        }
        updatedValue = updatedValue.substr(0, cleanVal.length);
        formik.setFieldValue("ssnConfirmNumber", updatedValue);
        setValueCnf(updatedValue);
    };

    const syncInputCnf = (event) => {
        const val = event.target.value;
        formik.setFieldValue("ssnConfirmNumber", val);
        transformDisplayCnf(val);
        transformValueCnf(val);
        event.target.setSelectionRange(val.length, val.length);
        setSsnValueCnf(valueCnf);
    };

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <h2 className='text-center mt-3 mb-0'>Add a Spouse</h2>
                <div className="row p-4">
                    <div className="col-12 mt-0 mb-0">
                        <span>Spouse Information</span>
                    </div>
                    <div className="col-md-6 ">
                        <div className="main_form_box mt-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_4487_48705)">
                                    <path
                                        d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
                                        fill="#323232"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4487_48705">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    id="firstName"
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    className="input-field"
                                />
                            </div>
                        </div>
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="error">{formik.errors.firstName}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <div className="main_form_box mt-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_4487_48705)">
                                    <path
                                        d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
                                        fill="#323232"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4487_48705">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    name="lastName"
                                    id="lastName"
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    className="input-field"
                                />
                            </div>
                        </div>
                        {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="error">{formik.errors.lastName}</div>
                        ) : null}
                    </div>
                    <div className="col-md-12">
                        <div className="main_form_box mt-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_1659_28499)">
                                    <path
                                        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                                        fill="#42474E"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1659_28499">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="form-group">

                                <input
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    id="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    className="input-field"
                                />

                            </div>
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <div className="main_form_box mt-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_2348_29835)">
                                    <path
                                        d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.05999 13.63 6.61999 10.79L8.47 8.94001C8.89999 8.51001 9.10999 7.91001 9.03999 7.30001L8.74999 4.78001C8.62999 3.77001 7.77999 3.01001 6.76 3.01001H5.03C3.9 3.01001 2.95999 3.95001 3.02999 5.08001C3.55999 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
                                        fill="#42474E"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2348_29835">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="form-group">
                                <input
                                    type="tel"
                                    placeholder="Phone Number"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    onChange={formik.handleChange}
                                    value={formik.values.phoneNumber}
                                    className="input-field"
                                    maxLength={13}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                        </div>
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                            <div className="error">{formik.errors.phoneNumber}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <div className="main_form_box mt-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_4487_48747)">
                                    <path
                                        d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                                        fill="#323232"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4487_48747">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="form-group">
                                <DatePicker
                                    selected={formik?.values?.dob}
                                    onChange={(date) => formik.setFieldValue("dob", date)}
                                    className="border-0 bg-transparent"
                                    placeholderText="Date Of Birth"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={1000}
                                    maxDate={new Date()}
                                />
                            </div>
                        </div>
                        {formik.touched.dob && formik.errors.dob ? (
                            <div className="error">{formik.errors.dob}</div>
                        ) : null}
                    </div>

                    <div className="col-12 mt-4 mb-0">
                        <span>Address Information</span>
                    </div>

                    <div className="col-md-12">
                        <div className="main_form_box mt-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_4487_48813)">
                                    <path
                                        d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                                        fill="#323232"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4487_48813">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Address Line 1"
                                    name="address1"
                                    id="address1"
                                    onChange={formik.handleChange}
                                    value={formik.values.address1}
                                    className="input-field"
                                />
                            </div>
                        </div>
                        {formik.touched.address1 && formik.errors.address1 ? (
                            <div className="error">{formik.errors.address1}</div>
                        ) : null}
                    </div>

                    <div className="col-md-6">
                        <div className="main_form_box mt-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_4487_48813)">
                                    <path
                                        d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                                        fill="#323232"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4487_48813">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Address Line 2 (Optional)"
                                    name="address2"
                                    id="address2"
                                    onChange={formik.handleChange}
                                    value={formik.values.address2}
                                    className="input-field"
                                />
                            </div>
                        </div>
                        {formik.touched.address2 && formik.errors.address2 ? (
                            <div className="error">{formik.errors.address2}</div>
                        ) : null}
                    </div>
                    <div className="col-md-2">
                        <div className="main_form_box mt-3">
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="City"
                                    name="city"
                                    id="city"
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    className="input-field"
                                />
                            </div>
                        </div>
                        {formik.touched.city && formik.errors.city ? (
                            <div className="error">{formik.errors.city}</div>
                        ) : null}
                    </div>
                    <div className="col-md-2">
                        <div className="main_form_box mt-3">
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="State"
                                    name="state"
                                    id="state"
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    className="input-field"
                                />
                            </div>
                        </div>
                        {formik.touched.state && formik.errors.state ? (
                            <div className="error">{formik.errors.state}</div>
                        ) : null}
                    </div>
                    <div className="col-md-2">
                        <div className="main_form_box mt-3">
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Zip Code"
                                    name="zipCode"
                                    id="zipCode"
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/\D/g, "");
                                        formik.handleChange({
                                            target: {
                                                name: "zipCode",
                                                value: numericValue,
                                            },
                                        });
                                    }}
                                    value={formik.values.zipCode}
                                    className="input-field"
                                />
                            </div>
                        </div>
                        {formik.touched.zipCode && formik.errors.zipCode ? (
                            <div className="error">{formik.errors.zipCode}</div>
                        ) : null}
                    </div>

                    <div className="col-6 mt-3 mb-0">
                        <span>Security Information</span>
                    </div>
                    <div className="col-6 mt-3 mb-0">
                        <span>Identity Verification</span>
                    </div>

                    <div className="col-6 mt-2 mb-0">
                        <div className="col-md-12 p-0">
                            <div className="main_form_box d-flex align-items-center mt-1">
                                <svg
                                    width="20"
                                    height="16"
                                    viewBox="0 0 20 16"
                                    fill="#808080"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
                                        fill="#323232"
                                    />
                                </svg>

                                <div className="form-group d-flex align-items-center">
                                    <input
                                        className="number input-field"
                                        style={{ position: "absolute" }}
                                        onChange={syncInput}
                                        value={transformDisplay(value)}
                                        maxLength={11}
                                        placeholder="SSN/ ITIN"
                                    />
                                    <input
                                        className="value input-field"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            background: "transparent",
                                            color: "transparent",
                                            border: "none",
                                            visibility: "hidden",
                                        }}
                                        value={ssnValue}
                                        maxLength={9}
                                        readOnly
                                        disabled
                                        type="hidden"
                                    />
                                </div>
                            </div>
                            <p className="SSN-input-message">
                                *Please enter your SSN/ITIN carefully.
                            </p>

                            {formik.touched.ssnNumber && formik.errors.ssnNumber ? (
                                <div className="error sstn-error pt-2">
                                    {formik.errors.ssnNumber}
                                </div>
                            ) : null}
                        </div>
                        <div className="col-md-12 p-0">

                            <div className="main_form_box d-flex align-items-center mt-4  ">
                                <svg
                                    width="20"
                                    height="16"
                                    viewBox="0 0 20 16"
                                    fill="#808080"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
                                        fill="#323232"
                                    />
                                </svg>
                                <div className="form-group d-flex align-items-center">
                                    <input
                                        className="number1 input-field"
                                        style={{ position: "absolute" }}
                                        onChange={syncInputCnf}
                                        maxLength={11}
                                        value={transformDisplayCnf(valueCnf)}
                                        placeholder="Confirm SSN / ITIN"
                                    />
                                    <input
                                        className="value1 input-field"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            background: "transparent",
                                            color: "transparent",
                                            border: "none",
                                            visibility: "hidden",
                                        }}
                                        value={ssnValueCnf}
                                        maxLength={9}
                                        readOnly
                                        disabled
                                        type="hidden"
                                    />
                                </div>
                            </div>
                            {formik.touched.ssnConfirmNumber &&
                                formik.errors.ssnConfirmNumber ? (
                                <div className="error">{formik.errors.ssnConfirmNumber}</div>
                            ) : null}

                        </div>
                    </div>

                    <div className="col-6 mt-2 mb-0">
                        <div className="col-md-12 p-0">
                            <div className="main_form_box d-flex align-items-center mt-2">
                                <svg
                                    width="20"
                                    height="24"
                                    viewBox="0 0 20 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17 0H3C2.45 0 2 0.45 2 1C2 1.55 2.45 2 3 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0ZM3 24H17C17.55 24 18 23.55 18 23C18 22.45 17.55 22 17 22H3C2.45 22 2 22.45 2 23C2 23.55 2.45 24 3 24ZM18 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4ZM10 6.75C11.24 6.75 12.25 7.76 12.25 9C12.25 10.24 11.24 11.25 10 11.25C8.76 11.25 7.75 10.24 7.75 9C7.75 7.76 8.76 6.75 10 6.75ZM15 17H5V15.5C5 13.83 8.33 13 10 13C11.67 13 15 13.83 15 15.5V17Z"
                                        fill="#42474E"
                                    />
                                </svg>

                                <div className="form-group d-flex justify-content-between align-items-center">
                                    <label
                                        htmlFor="drivingLicenseFrontShow"
                                        className="cursor-pointer w-100 mb-0"
                                    >
                                        {formik.values.drivingLicenseFrontShow
                                            ? formik.values.drivingLicenseFrontShow.length > 25
                                                ? formik.values.drivingLicenseFrontShow.substr(0, 25) +
                                                "..."
                                                : formik.values.drivingLicenseFrontShow
                                            : "Driver's License Front"}
                                    </label>
                                    <div style={{ position: "relative" }}>
                                        <svg
                                            width="26"
                                            height="32"
                                            viewBox="0 0 26 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ cursor: "pointer" }}
                                            onClick={handleSvgFrontClick}
                                        >
                                            <path
                                                d="M16.1666 0.166504H3.49992C1.75825 0.166504 0.349086 1.5915 0.349086 3.33317L0.333252 28.6665C0.333252 30.4082 1.74242 31.8332 3.48408 31.8332H22.4999C24.2416 31.8332 25.6666 30.4082 25.6666 28.6665V9.6665L16.1666 0.166504ZM22.4999 28.6665H3.49992V3.33317H14.5833V11.2498H22.4999V28.6665ZM6.66658 20.7657L8.89909 22.9982L11.4166 20.4965V27.0832H14.5833V20.4965L17.1008 23.014L19.3333 20.7657L13.0158 14.4165L6.66658 20.7657Z"
                                                fill="#42474E"
                                            />
                                        </svg>
                                        <input
                                            type="file"
                                            id="drivingLicenseFrontShow"
                                            name="drivingLicenseFrontShow"
                                            placeholder="Upload File"
                                            onChange={drivingLicenseFront}
                                            style={{ display: "none" }}
                                            ref={fileFrontRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 p-0 mt-4">
                            <div className="main_form_box d-flex align-items-center mt-2">
                                <svg
                                    width="20"
                                    height="16"
                                    viewBox="0 0 20 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM17 14H3C2.45 14 2 13.55 2 13V8H18V13C18 13.55 17.55 14 17 14ZM18 4H2V2H18V4Z"
                                        fill="#42474E"
                                    />
                                </svg>

                                <div className="form-group d-flex justify-content-between align-items-center">
                                    <label
                                        htmlFor="drivingLicenseBackShow"
                                        className="cursor-pointer mb-0"
                                    >
                                        {formik.values.drivingLicenseBackShow
                                            ? formik.values.drivingLicenseBackShow.length > 25
                                                ? formik.values.drivingLicenseBackShow.substr(0, 25) +
                                                "..."
                                                : formik.values.drivingLicenseBackShow
                                            : "Driver's License  Back"}
                                    </label>
                                    <div style={{ position: "relative" }}>
                                        <svg
                                            width="26"
                                            height="32"
                                            viewBox="0 0 26 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ cursor: "pointer" }}
                                            onClick={handleSvgBackClick}
                                        >
                                            <path
                                                d="M16.1666 0.166504H3.49992C1.75825 0.166504 0.349086 1.5915 0.349086 3.33317L0.333252 28.6665C0.333252 30.4082 1.74242 31.8332 3.48408 31.8332H22.4999C24.2416 31.8332 25.6666 30.4082 25.6666 28.6665V9.6665L16.1666 0.166504ZM22.4999 28.6665H3.49992V3.33317H14.5833V11.2498H22.4999V28.6665ZM6.66658 20.7657L8.89909 22.9982L11.4166 20.4965V27.0832H14.5833V20.4965L17.1008 23.014L19.3333 20.7657L13.0158 14.4165L6.66658 20.7657Z"
                                                fill="#42474E"
                                            />
                                        </svg>
                                        <input
                                            type="file"

                                            id="drivingLicenseBackShow"
                                            name="drivingLicenseBackShow"
                                            placeholder="Upload File"
                                            onChange={drivingLicenseBack}
                                            style={{ display: "none" }}
                                            ref={fileBackRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-3 btn-div d-flex justify-content-between align-items-center p-3">
                    <div className="btn_back mr-2">
                        <button
                            type="button"
                            className="btn cursor-pointer"
                            onClick={() => {
                                setFormSpousePopup(false)
                            }}
                        >
                            No Thanks
                        </button>
                    </div>
                    <Button type="submit" className="btn-thm SiteBtn" text="Add Spouse" />
                </div>
            </Form>
        </div>
    )
}

export default AddSpouseFormPopup;
