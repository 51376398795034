import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Button from "../Common/Button";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { UploadMedia } from "../../redux/features/CommonService";
import { toast } from "react-toastify";
import { SetUserInfo } from "../../redux/features/AuthService";
import Cookies from "js-cookie";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "*Password is required to Sign Up";
  } else if (values.password.length < 6 || values.password.length > 20) {
    errors.password = "*Password must be between 6 to 20 characters";
  } else if (!/(?=.*[a-z])/.test(values.password)) {
    errors.password = "*Password must contain at least one lowercase letter";
  } else if (!/(?=.*[A-Z])/.test(values.password)) {
    errors.password = "*Password must contain at least one uppercase letter";
  } else if (!/(?=.*\d)/.test(values.password)) {
    errors.password = "*Password must contain at least one digit";
  } else if (!/(?=.*[!@#$%&])/.test(values.password)) {
    errors.password =
      "*Password must contain at least one special character (@#$%&!)";
  }
  if (!values.ssnNumber) {
    errors.ssnNumber = "*SSN/ITIN Number is required to Sign Up";
  }
  if (!values.ssnConfirmNumber) {
    errors.ssnConfirmNumber = "*Confirm SSN/ITIN Number is required to Sign Up";
  } else if (values.ssnNumber !== values.ssnConfirmNumber) {
    errors.ssnConfirmNumber = "*SSN/ITIN and confirm SSN/ITIN do not match";
  }
  if (!values.ssnNumber) {
    errors.ssnNumber = "*SSN/ITIN Number is required to Sign Up";
  } else if (values.ssnNumber?.length < 9) {
    errors.ssnNumber = "SSN/ITIN number must be 9 digits long";
  }
  if (!values.cPassword) {
    errors.cPassword = "*Confirm Password is required to Sign Up";
  } else if (values.password !== values.cPassword) {
    errors.cPassword = "*Password and confirm Password do not match";
  }
  return errors;
};
const SetupGrantorForm2 = ({ handleBack, data1 }) => {
  document.title = "National Trust Registry | Sign Up";
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [ssnValue, setSsnValue] = useState("");
  const [valueCnf, setValueCnf] = useState("");
  const [ssnValueCnf, setSsnValueCnf] = useState("");
  const dispatch = useDispatch();
  const fileFrontRef = useRef(null);
  const fileBackRef = useRef(null);

  const handleSvgFrontClick = () => {
    fileFrontRef.current.click();
  };

  const handleSvgBackClick = () => {
    fileBackRef.current.click();
  };

  let initialValuesWithoutEmail = {
    password: "",
    cPassword: "",
    timezone: "Asia/Kolkata",
    role: 2,
    ssnConfirmNumberValue: "",
    ssnConfirmNumber: "",
    ssnNumber: "",
    ssnValue: "",
    drivingLicenseFront: "",
    drivingLicenseBack: "",
    drivingLicenseFrontShow: "",
    drivingLicenseBackShow: "",
  };

  const formik = useFormik({
    initialValues: initialValuesWithoutEmail,
    validate,
    onSubmit: async (values) => {
      const data = {
        ...values,
        ...data1,
      };
      const response = await dispatch(SetUserInfo(data));
      if (response.type === "auths/SetUserInfo/fulfilled") {
        Cookies.set("ntr-email-register", data.email, { expires: 36400 });
        if (response?.payload?.status === 3) {
          navigate("/sign-up-verify", { state: response?.payload?.data?._id });
          toast.success(response?.payload?.message, { autoClose: 3000 });
        } else {
          toast.error(response?.payload?.message, {
            autoClose: 3000,
          });
        }
      } else {
        toast.error(response?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
      localStorage.setItem("userTitle", formik.values.firstName);
    },
  });

  const replaceAt = (str, index, character) => {
    return (
      str.substr(0, index) + character + str.substr(index + character.length)
    );
  };

  const transformDisplay = (val) => {
    let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9);

    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3);
    }

    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6);
      const lastFour = displayVal.slice(7);
      displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour;
    } else {
      displayVal = displayVal.replace(/\d/g, "*");
    }

    return displayVal;
  };

  const transformValue = (val) => {
    let cleanVal = val.replace(/[^0-9|\\*]/g, "");
    cleanVal = cleanVal.substr(0, 9);
    let updatedValue = value ? value : "";

    for (let i = 0, l = cleanVal.length; i < l; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        updatedValue = replaceAt(updatedValue, i, cleanVal[i]);
      }
    }
    updatedValue = updatedValue.substr(0, cleanVal.length);
    formik.setFieldValue("ssnNumber", updatedValue);
    setValue(updatedValue);
  };

  const syncInput = (event) => {
    const val = event.target.value;
    formik.setFieldValue("ssnValue", val);
    transformDisplay(val);
    transformValue(val);
    event.target.setSelectionRange(val.length, val.length);
    setSsnValue(value);
  };

  const replaceAtCnf = (str, index, character) => {
    return (
      str.substr(0, index) + character + str.substr(index + character.length)
    );
  };

  const transformDisplayCnf = (val) => {
    let displayVal = val.replace(/[^0-9]/g, "").substr(0, 9); 
    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3); 
    }
    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6); 
      const lastFour = displayVal.slice(7); 
      displayVal = displayVal.slice(0, 7).replace(/\d/g, "*") + lastFour; 
    } else {
      displayVal = displayVal.replace(/\d/g, "*"); 
    }
    return displayVal;
  };

  const transformValueCnf = (val) => {
    let cleanVal = val.replace(/[^0-9|\\*]/g, "");
    cleanVal = cleanVal.substr(0, 9);
    let updatedValue = valueCnf ? valueCnf : "";

    for (let i = 0, l = cleanVal.length; i < l; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        updatedValue = replaceAtCnf(updatedValue, i, cleanVal[i]);
      }
    }
    updatedValue = updatedValue.substr(0, cleanVal.length);
    formik.setFieldValue("ssnConfirmNumber", updatedValue);
    setValueCnf(updatedValue);
  };

  const syncInputCnf = (event) => {
    const val = event.target.value;
    formik.setFieldValue("ssnConfirmNumber", val);
    transformDisplayCnf(val);
    transformValueCnf(val);
    event.target.setSelectionRange(val.length, val.length);
    setSsnValueCnf(valueCnf);
  };

  const handleUploadImage = async (file) => {
    if (file) {
      let data = file;
      if (file?.type === "image/svg+xml") {
        data = new Blob([file], { type: "image/svg" });
        data.name = file.name;
      }
      const formData = new FormData();
      formData.append("files", data, file.name);
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "commons/UploadMedia/fulfilled") {
        return res.payload?.files[0];
      }
    }
  };

  const drivingLicenseFront = async (e) => {
    const file = e.target.files[0];
    const uploadData = await handleUploadImage(file);
    formik.setFieldValue("drivingLicenseFront", uploadData.mediaName);
    formik.setFieldValue("drivingLicenseFrontShow", file.name);
  };

  const drivingLicenseBack = async (e) => {
    const file = e.target.files[0];
    const uploadData = await handleUploadImage(file);
    formik.setFieldValue("drivingLicenseBack", uploadData?.mediaName);
    formik.setFieldValue("drivingLicenseBackShow", file?.name);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mt-0 mb-0">
            <span>Security Information</span>
          </div>
          <div className="col-md-12">
            <div className="col-md-6 p-0">
              <div className="main_form_box d-flex align-items-center mt-1">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="#808080"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
                    fill="#323232"
                  />
                </svg>

                <div className="form-group d-flex align-items-center">
                  <input
                    className="number input-field"
                    style={{ position: "absolute" }}
                    onChange={syncInput}
                    value={transformDisplay(value)}
                    maxLength={11}
                    placeholder="SSN/ ITIN "
                  />
                  <input
                    className="value input-field"
                    style={{
                      position: "absolute",
                      top: 0,
                      background: "transparent",
                      color: "transparent",
                      border: "none",
                      visibility: "hidden",
                    }}
                    value={ssnValue}
                    maxLength={9}
                    readOnly
                  />
                </div>
              </div>
              <p className="SSN-input-message">
                *Please enter your SSN/ITIN carefully.
              </p>
            </div>
            {formik.touched.ssnNumber && formik.errors.ssnNumber ? (
              <div className="error sstn-error pt-2">
                {formik.errors.ssnNumber}
              </div>
            ) : null}
          </div>
          <div className="col-md-12">
            <div className="col-md-6 p-0">
              <div className="main_form_box d-flex align-items-center mt-4">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="#808080"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
                    fill="#323232"
                  />
                </svg>
                <div className="form-group d-flex align-items-center">
                  <input
                    className="number1 input-field"
                    style={{ position: "absolute" }}
                    onChange={syncInputCnf}
                    maxLength={11}
                    value={transformDisplayCnf(valueCnf)}
                    placeholder="Confirm SSN / ITIN"
                  />
                  <input
                    className="value1 input-field"
                    style={{
                      position: "absolute",
                      top: 0,
                      background: "transparent",
                      color: "transparent",
                      border: "none",
                      visibility: "hidden",
                    }}
                    value={ssnValueCnf}
                    maxLength={9}
                    readOnly
                  />
                </div>
              </div>
              {formik.touched.ssnConfirmNumber &&
              formik.errors.ssnConfirmNumber ? (
                <div className="error">{formik.errors.ssnConfirmNumber}</div>
              ) : null}
            </div>
          </div>
          <div className="col-12 mt-2 mb-0">
            <span>Identity Verification</span>
          </div>
          <div className="col-md-12">
            <div className="main_form_box d-flex align-items-center mt-2">
              <svg
                width="20"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 0H3C2.45 0 2 0.45 2 1C2 1.55 2.45 2 3 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0ZM3 24H17C17.55 24 18 23.55 18 23C18 22.45 17.55 22 17 22H3C2.45 22 2 22.45 2 23C2 23.55 2.45 24 3 24ZM18 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4ZM10 6.75C11.24 6.75 12.25 7.76 12.25 9C12.25 10.24 11.24 11.25 10 11.25C8.76 11.25 7.75 10.24 7.75 9C7.75 7.76 8.76 6.75 10 6.75ZM15 17H5V15.5C5 13.83 8.33 13 10 13C11.67 13 15 13.83 15 15.5V17Z"
                  fill="#42474E"
                />
              </svg>

              <div className="form-group d-flex justify-content-between">
                <label
                  htmlFor="drivingLicenseFrontShow"
                  className="cursor-pointer"
                >
                  {formik.values.drivingLicenseFrontShow
                    ? formik.values.drivingLicenseFrontShow.length > 25
                      ? formik.values.drivingLicenseFrontShow.substr(0, 25) +
                        "..."
                      : formik.values.drivingLicenseFrontShow
                    : "Driver's License  Front"}
                </label>

                <svg
                  width="26"
                  height="32"
                  viewBox="0 0 26 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ cursor: "pointer" }}
                  onClick={handleSvgFrontClick}
                >
                  <path
                    d="M16.1666 0.166504H3.49992C1.75825 0.166504 0.349086 1.5915 0.349086 3.33317L0.333252 28.6665C0.333252 30.4082 1.74242 31.8332 3.48408 31.8332H22.4999C24.2416 31.8332 25.6666 30.4082 25.6666 28.6665V9.6665L16.1666 0.166504ZM22.4999 28.6665H3.49992V3.33317H14.5833V11.2498H22.4999V28.6665ZM6.66658 20.7657L8.89909 22.9982L11.4166 20.4965V27.0832H14.5833V20.4965L17.1008 23.014L19.3333 20.7657L13.0158 14.4165L6.66658 20.7657Z"
                    fill="#42474E"
                  />
                </svg>
                <input
                  type="file"
                  id="drivingLicenseFrontShow"
                  name="drivingLicenseFrontShow"
                  placeholder="Upload File"
                  onChange={drivingLicenseFront}
                  style={{ display: "none" }}
                  ref={fileFrontRef}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="main_form_box d-flex align-items-center mt-2">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM17 14H3C2.45 14 2 13.55 2 13V8H18V13C18 13.55 17.55 14 17 14ZM18 4H2V2H18V4Z"
                  fill="#42474E"
                />
              </svg>

              <div className="form-group d-flex justify-content-between">
                <label
                  htmlFor="drivingLicenseBackShow"
                  className="cursor-pointer"
                >
                  {formik.values.drivingLicenseBackShow
                    ? formik.values.drivingLicenseBackShow.length > 25
                      ? formik.values.drivingLicenseBackShow.substr(0, 25) +
                        "..."
                      : formik.values.drivingLicenseBackShow
                    : "Driver's License  Back"}
                </label>

                <svg
                  width="26"
                  height="32"
                  viewBox="0 0 26 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ cursor: "pointer" }}
                  onClick={handleSvgBackClick}
                >
                  <path
                    d="M16.1666 0.166504H3.49992C1.75825 0.166504 0.349086 1.5915 0.349086 3.33317L0.333252 28.6665C0.333252 30.4082 1.74242 31.8332 3.48408 31.8332H22.4999C24.2416 31.8332 25.6666 30.4082 25.6666 28.6665V9.6665L16.1666 0.166504ZM22.4999 28.6665H3.49992V3.33317H14.5833V11.2498H22.4999V28.6665ZM6.66658 20.7657L8.89909 22.9982L11.4166 20.4965V27.0832H14.5833V20.4965L17.1008 23.014L19.3333 20.7657L13.0158 14.4165L6.66658 20.7657Z"
                    fill="#42474E"
                  />
                </svg>
                <input
                  type="file"
                  id="drivingLicenseBackShow"
                  name="drivingLicenseBackShow"
                  placeholder="Upload File"
                  onChange={drivingLicenseBack}
                  style={{ display: "none" }}
                  ref={fileBackRef}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-2 mb-0">
            <span>Password Setup</span>
          </div>
          <div className="col-md-12">
            <div className="main_form_box d-flex align-items-center mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 15.225C7.9 15.225 8.6625 14.9125 9.2875 14.2875C9.9125 13.6625 10.225 12.9 10.225 12C10.225 11.1 9.9125 10.3375 9.2875 9.7125C8.6625 9.0875 7.9 8.775 7 8.775C6.1 8.775 5.3375 9.0875 4.7125 9.7125C4.0875 10.3375 3.775 11.1 3.775 12C3.775 12.9 4.0875 13.6625 4.7125 14.2875C5.3375 14.9125 6.1 15.225 7 15.225ZM7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.41667 6 9.60417 6.3875 10.5625 7.1625C11.5208 7.9375 12.1917 8.925 12.575 10.125H20.875C20.975 10.125 21.0667 10.1417 21.15 10.175C21.2333 10.2083 21.3167 10.2667 21.4 10.35L22.475 11.425C22.5583 11.5083 22.6167 11.5917 22.65 11.675C22.6833 11.7583 22.7 11.85 22.7 11.95C22.7 12.05 22.6833 12.1417 22.65 12.225C22.6167 12.3083 22.5583 12.3917 22.475 12.475L19.875 15.075C19.7917 15.1583 19.7083 15.2167 19.625 15.25C19.5417 15.2833 19.45 15.3 19.35 15.3C19.25 15.3 19.1583 15.2833 19.075 15.25C18.9917 15.2167 18.9083 15.1583 18.825 15.075L17.25 13.5L15.675 15.075C15.5917 15.1583 15.5083 15.2167 15.425 15.25C15.3417 15.2833 15.25 15.3 15.15 15.3C15.05 15.3 14.9583 15.2833 14.875 15.25C14.7917 15.2167 14.7083 15.1583 14.625 15.075L13.375 13.825H12.575C12.2583 15.075 11.5875 16.0833 10.5625 16.85C9.5375 17.6167 8.35 18 7 18Z"
                  fill="black"
                />
              </svg>
              <div className="form-group d-flex align-items-center">
                <input
                  type={"password"}
                  placeholder="Password"
                  className="input-field"
                  name="password"
                  id="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
              </div>
            </div>

            {formik.touched.password && formik.errors.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="col-md-12">
            <div className="main_form_box d-flex align-items-center mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 15.225C7.9 15.225 8.6625 14.9125 9.2875 14.2875C9.9125 13.6625 10.225 12.9 10.225 12C10.225 11.1 9.9125 10.3375 9.2875 9.7125C8.6625 9.0875 7.9 8.775 7 8.775C6.1 8.775 5.3375 9.0875 4.7125 9.7125C4.0875 10.3375 3.775 11.1 3.775 12C3.775 12.9 4.0875 13.6625 4.7125 14.2875C5.3375 14.9125 6.1 15.225 7 15.225ZM7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.41667 6 9.60417 6.3875 10.5625 7.1625C11.5208 7.9375 12.1917 8.925 12.575 10.125H20.875C20.975 10.125 21.0667 10.1417 21.15 10.175C21.2333 10.2083 21.3167 10.2667 21.4 10.35L22.475 11.425C22.5583 11.5083 22.6167 11.5917 22.65 11.675C22.6833 11.7583 22.7 11.85 22.7 11.95C22.7 12.05 22.6833 12.1417 22.65 12.225C22.6167 12.3083 22.5583 12.3917 22.475 12.475L19.875 15.075C19.7917 15.1583 19.7083 15.2167 19.625 15.25C19.5417 15.2833 19.45 15.3 19.35 15.3C19.25 15.3 19.1583 15.2833 19.075 15.25C18.9917 15.2167 18.9083 15.1583 18.825 15.075L17.25 13.5L15.675 15.075C15.5917 15.1583 15.5083 15.2167 15.425 15.25C15.3417 15.2833 15.25 15.3 15.15 15.3C15.05 15.3 14.9583 15.2833 14.875 15.25C14.7917 15.2167 14.7083 15.1583 14.625 15.075L13.375 13.825H12.575C12.2583 15.075 11.5875 16.0833 10.5625 16.85C9.5375 17.6167 8.35 18 7 18Z"
                  fill="black"
                />
              </svg>
              <div className="form-group d-flex align-items-center">
                <input
                  type={"password"}
                  placeholder="Confirm Password"
                  className="input-field"
                  name="cPassword"
                  id="cPassword"
                  onChange={formik.handleChange}
                  value={formik.values.cPassword}
                />
              </div>
            </div>
            {formik.touched.cPassword && formik.errors.cPassword ? (
              <div className="error mt-1">{formik.errors.cPassword}</div>
            ) : null}
            <div className="pwdreqments mt-1">Password Criteria:</div>
            <div className="pwdreqments ">At least 1 lowercase letter</div>
            <div className="pwdreqments ">At least 1 UPPERCASE letter</div>
            <div className="pwdreqments ">At least 1 number</div>
            <div className="pwdreqments   ">At least 6 characters</div>
          </div>
        </div>

        <div className="mt-1 btn-div d-flex justify-content-between align-items-center">
          <div type="submit" className="SiteBtn2" onClick={handleBack}>
            Back
          </div>
          <Button type="submit" className="btn-thm SiteBtn" text="Continue" />
        </div>
      </Form>
    </>
  );
};

export default SetupGrantorForm2;
