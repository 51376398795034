import React, { useState } from "react";
import MainCenterBox from "../MainCenterBox";
import SignupSuccessorForm1 from "./SignupSuccessorForm1";
import SignupSuccessorForm2 from "./SignupSuccessorForm2";

const SuccessorSignUp = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(null); 

  const handleContinue = (formData) => {
    setFormData(formData);
    setCurrentStep(currentStep + 1);
  };


  const handleBack = () => {
    setCurrentStep(currentStep - 1);
};

  return (
    <>
      <MainCenterBox
        title={"Create an Account"}
        className="col-xl-8 col-lg-10 col-md-12"
      >
        <p className="text-center">Peace of mind is a few easy clicks away.</p>
        {currentStep === 1 && <SignupSuccessorForm1 handleContinue={handleContinue} formData={formData} />}
        {currentStep === 2 && <SignupSuccessorForm2 data1={formData}  handleBack={handleBack}/>}
      </MainCenterBox>
    </>
  );
};

export default SuccessorSignUp;
