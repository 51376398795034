import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { GetActiveGrantorList } from "../../../redux/features/GrantorService";
const columns = [
  {
    id: "firstName",
    name: "First Name",
    selector: (row) => row.firstName,
    sortable: true,
  },
  {
    id: "lastName",
    name: "Last Name",
    selector: (row) => row.lastName,
    sortable: true,
  },
  {
    id: "email",
    name: "Email",
    selector: (row) => (row.email ? row.email : "-"),
    sortable: true,
  },
  {
    id: "phoneNumber",
    name: "Phone Number",
    selector: (row) => (row.phoneNumber ? row.phoneNumber : "-"),
    sortable: true,
  },
  {
    id: "lastSession",
    name: "Last Session",
    selector: (row) => moment(row.createdAt).format("MM/DD/yyyy @ HH:mm PT"),
    sortable: true,
  },
  {
    id: "lastUpdated",
    name: "Last Updated",
    selector: (row) => moment(row.updatedAt).format("MM/DD/yyyy @ HH:mm PT"),
    sortable: true,
  },
];

const ArchivedGrantor = ({ filteredData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getGrantorListing } = useSelector((state) => state.grantors);

  useEffect(() => {
    dispatch(
      GetActiveGrantorList({
        search: "",
        role: 2,
        status: "0",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (row) => {
    navigate(`/grantor/${row?._id}`);
  };
  return (
    <>
      <DataTable
        columns={columns}
        data={filteredData || getGrantorListing?.data}
        pagination
        onRowClicked={handleRowClick}
        highlightOnHover
        defaultSortFieldId="firstName"
        persistTableHead
      />
    </>
  );
};

export default ArchivedGrantor;
